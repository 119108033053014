import {useEffect, useState} from 'react'
import {activeSubheading} from './my-work-constants'
import {IHeader} from './my-work-interface'
import {fetchReviewAndEvalCountData} from './_service/my-work-services'
import {toast} from 'react-toastify'
import { useIntl } from 'react-intl'
import { IErrorResponse } from '../auth'
export function Header({activeSubheader, onItemClick}: IHeader): JSX.Element {
  const [myWorkHeaderCountData, setMyWorkHeaderCountDta] = useState({ reviewCount: 0, evaluationCount: 0 });
  const translator = useIntl();
  useEffect(() => {
    const fetchReviewAndEvalCount = async () => {
      try {
        const data = await fetchReviewAndEvalCountData()
        if (data) {
          setMyWorkHeaderCountDta({ reviewCount: data?.reviewCount, evaluationCount: data?.evaluationCount });
        }
      } catch (error: unknown) {
        toast.error((error as IErrorResponse)?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
    fetchReviewAndEvalCount()
  }, [])
  return (
    <div className='d-flex overflow-auto h-55px d-flex align-items-center justify-content-center mb-2'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item'>
          <div
            className={`fs-1 fw-bold nav-link ${
              activeSubheader === activeSubheading.Evaluation
                ? 'active border-5 border-primary text-primary'
                : 'false text-black'
            }`}
            style={{cursor: 'pointer'}}
            onClick={() => onItemClick(activeSubheading.Evaluation)}
          >
            {translator.formatMessage({id: 'MY_WORK.EVALUATION'})}
            <span className='bg-primary fs-4 fw-bold mx-5 px-4 py-2 badge rounded-pill text-white'>
              {myWorkHeaderCountData.evaluationCount}
            </span>
          </div>
        </li>
        <li className='nav-item'>
          <div
            className={`fs-1 fw-bold nav-link ${
              activeSubheader === activeSubheading.Review
                ? 'active border-5 border-primary text-primary'
                : 'false text-black'
            }`}
            style={{cursor: 'pointer'}}
            onClick={() => onItemClick(activeSubheading.Review)}
          >
            {translator.formatMessage({ id: 'MY_WORK.REVIEW' })}
            <span className='bg-primary fs-4 fw-bold mx-5 px-4 py-2 badge rounded-pill text-white'>
              {myWorkHeaderCountData.reviewCount}
            </span>
          </div>
        </li>
      </ul>
    </div>
  )
}
