import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../modules/dashboard/dashboard-wrapper'
import BuilderPageWrapper from '../modules/layout-builder/builder-page-wrapper'
// import {CourseByCourseEval} from '../modules/evaluation/course-by-course/course-by-course-eval'
//import TypeOfEvaluation from '../modules/evaluation/type-of-evaluation/type-of-evaluation'

import {MyWork} from '../modules/my-work/my-work'
import MyTeamWrapper from '../modules/my-team/my-team-wrapper'
//import EvalMain from '../modules/evaluation/general-evaluation/eval-main-wrapper'
import {CreateUser} from '../modules/apps/user-management/create-user/create-user-wrapper'
import GradeScales from '../modules/grade-scales/grade-scales-wrapper'
import ApplicationPriorityPage from '../modules/application-priority/application-priority-wrapper'
import TaskApproverMain from '../modules/approver-list/task-approver-main-wrapper'
import AssignAppToSpecific from '../modules/distribution/assign-app-to-specific'
import ReassignApp from '../modules/distribution/reassign-app/reassign-app'
import { MasterLayout } from '../modules/layout/master-layout'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import { WithChildren } from '../../utils/helpers'
import { EvaluationRoutingPage } from '../modules/evaluation/evaluation-routing'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/profile-page-route'))
  const WizardsPage = lazy(() => import('../modules/wizards/wizards-page'))
  const AccountPage = lazy(() => import('../modules/accounts/account-page'))
  const WidgetsPage = lazy(() => import('../modules/widgets/widgets-page'))
  const ChatPage = lazy(() => import('../modules/apps/chat/chat-page'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/users-page-route'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/my-work' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='task-approver' element={<TaskApproverMain />} />
        <Route path='type-of-evaluation/*' element={<EvaluationRoutingPage />} />
        <Route path='my-work' element={<MyWork />} />
        <Route path='my-team' element={<MyTeamWrapper />} />
        <Route path='appid-to-specific-user' element={<AssignAppToSpecific />} />
        <Route path='reassign-app-user' element={<ReassignApp />} />
        <Route path='create-user' element={<CreateUser />} />
        {/* application priority: */}
        <Route path='applications-list' element={<ApplicationPriorityPage />} />
        <Route path='grade-scales' element={<GradeScales />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 10,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
