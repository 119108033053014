import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react'
import * as authHelper from './auth-helpers'
import {getUserByToken, logoutUser, removeValueLocalStorage} from './_requests'
import {toast} from 'react-toastify'
import {WithChildren} from '../../../../utils/helpers'
import {LayoutSplashScreen} from '../../layout/core'
import {removeRefreshToken} from '../core/auth-helpers'
import { AuthContextProps, IAuthModel, IUserModel, initAuthContextPropsState } from '../auth-model'
import { commonConstant } from '../../../../utils/common-constant'

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<IAuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IUserModel | undefined>()
  const tokenName = process.env.REACT_APP_CRED_EVAL_AUTH as string
  const saveAuth = (auth: IAuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    if (authHelper.getAuth()?.api_token) {
      logoutUser()
        .then(() => {
          toast.success('Logged out successfully!')
          saveAuth(undefined)
          setCurrentUser(undefined)
          removeValueLocalStorage(tokenName)
          removeRefreshToken()
          removeValueLocalStorage(commonConstant.credEvalData)
          localStorage.removeItem('isTokenApiTriggered')
          sessionStorage.removeItem('autoSave');
        })
        .catch((error) => console.log(error))
    }
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const response = await getUserByToken(apiToken)
          const data = {
            user_code: response.data.success?.user_code || '',
            username: response.data.success?.email || '',
            pic: response.data.success?.picture || '',
            role: response.data.success?.role || '',
            reviewer_capacity: response.data.success?.reviewer_capacity || 0,
            evaluator_capacity: response.data.success?.evaluator_capacity || 0,
            email: response.data.success?.email || '',
            first_name: response.data.success?.given_name || '',
            last_name: response.data.success?.family_name || '',
            organisation_name: response.data.success?.organisation_name || [],
          }
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current && auth && auth.api_token) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line 
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}