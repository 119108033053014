export const activeSubheading = {
  Evaluation: 'Evaluation',
  Review: 'Review',
};

export enum MY_WORK_STATUS {
  EVAL_NEW = 0,
  EVAL_IN_PROGRESS = 1,
  EVAL_COMPLETE = 2,
  REVIEW_NEW = 3,
  REVIEW_IN_PROGRESS = 4,
  REVIEW_COMPLETE = 5,
  RE_EVAL_NEW = 6,
  RE_EVAL_IN_PROGRESS = 7,
  RE_EVAL_COMPLETE = 8,
  RE_REVIEW_NEW = 9,
  RE_REVIEW_IN_PROGRESS = 10,
  RE_REVIEW_COMPLETE = 11,
}

const STATUS_MAPPER = {
  [MY_WORK_STATUS.EVAL_NEW]: {bgColor: 'badge-light-info'},
  [MY_WORK_STATUS.EVAL_IN_PROGRESS]: {bgColor: 'badge-light-warning'},
  [MY_WORK_STATUS.EVAL_COMPLETE]: {bgColor: 'badge-light-success'},
  [MY_WORK_STATUS.REVIEW_NEW]: {bgColor: 'badge-light-info'},
  [MY_WORK_STATUS.REVIEW_IN_PROGRESS]: {bgColor: 'badge-light-warning'},
  [MY_WORK_STATUS.REVIEW_COMPLETE]: {bgColor: 'badge-light-success'},
  [MY_WORK_STATUS.RE_EVAL_NEW]: {bgColor: 'badge-light-info'},
  [MY_WORK_STATUS.RE_EVAL_IN_PROGRESS]: {bgColor: 'badge-light-warning'},
  [MY_WORK_STATUS.RE_EVAL_COMPLETE]: {bgColor: 'badge-light-success'},
  [MY_WORK_STATUS.RE_REVIEW_NEW]: {bgColor: 'badge-light-info'},
  [MY_WORK_STATUS.RE_REVIEW_IN_PROGRESS]: {bgColor: 'badge-light-warning'},
  [MY_WORK_STATUS.RE_REVIEW_COMPLETE]: {bgColor: 'badge-light-success'},
};

export const getStatusString = (status: MY_WORK_STATUS) => {
  return STATUS_MAPPER[status] || {bgColor: 'badge'};
};

export const ITEMS_PER_PAGE = 10;

export const ORG_CODE_PATTERN = /#(.*?)\d/;
export const ORG_APP_ID_PATTERN = /(\d+)/g;
export const ORG_CODE_CODE_WITH_APPID_PATTERN = /#([a-zA-Z0-9]+)/;
