import {get, post} from '../app/modules/auth/core/_requests'
import {
  ICourseByCourse,
  IEvalPage,
  IGradeScale,
  IOrganization,
  IPercentToCgpa,
} from '../app/modules/evaluation/evaluation-interfaces'
import {IGetAllUserEmails, IKeyValues, IResponse} from './common-interface'
import {
  STREAM_TRANSCRIPT,
  GET_ORGANIZATION,
  AUTO_SAVE_CRED_EVAL_DATA,
  GET_PERCENTAGE_TO_CGPA,
  PG_UG_COURSE_EVAL,
  GET_GRADE_SCALE_DATA,
  GET_ALL_USER_WITH_EMAILS,
} from './service-constants'

export const streamTranscript = async (url : string): Promise<string> => {
  try {
    const response = await get<Blob>(STREAM_TRANSCRIPT + url, {}, {responseType: 'blob'});
    const responseURL = URL.createObjectURL(response);
    return responseURL;
  } catch (error) {
    throw error;
  }
};

export const fetchOrganizationData = async (
  page: string,
  orgCode?: string
): Promise<IOrganization[]> => {
  try {
    const response = await get<IResponse<IOrganization[]>>(GET_ORGANIZATION + '/' + page, {
      orgCode: orgCode,
    })
    return response.data?.success || []
  } catch (error) {
    throw error
  }
}
export const fetchPercentageToCgpa = async (): Promise<IPercentToCgpa[]> => {
  try {
    const response = await get<IResponse<IPercentToCgpa[]>>(GET_PERCENTAGE_TO_CGPA)
    return response.data?.success || []
  } catch (error) {
    throw error
  }
}
export const autoSaveCredentialEvalData = async (
  appid: string,
  data: IKeyValues,
  pageName: string
): Promise<IEvalPage> => {
  try {
    const response = await post<IResponse<IEvalPage>>(
      AUTO_SAVE_CRED_EVAL_DATA + `?appId=${appid}&page=${pageName}`,
      {data: data}
    );
    return response.data?.success || ({} as IEvalPage);
  } catch (error) {
    throw error;
  }
};
export const autoSave = async (appid: string, data: IKeyValues) => {
  try {
    const responseData = await post<IResponse<boolean>>(
      AUTO_SAVE_CRED_EVAL_DATA + `?appId=${appid}&page=generalEvaluation`,
      {data: data}
    );
    return responseData?.data?.success ? true : false;
  } catch (error) {
    throw error;
  }
};

export const pgEvalData = async (appid: string): Promise<ICourseByCourse | undefined> => {
  try {
    const responseData = await get<IResponse<ICourseByCourse>>(
      PG_UG_COURSE_EVAL + appid + '/PGCourseByCourseEvaluation'
    )
    return responseData?.data?.success
  } catch (error) {
    throw error
  }
}
export const ugEvalData = async (appid: string): Promise<ICourseByCourse | undefined> => {
  try {
    const responseData = await get<IResponse<ICourseByCourse>>(
      PG_UG_COURSE_EVAL + appid + '/UGCourseByCourseEvaluation'
    )
    return responseData?.data?.success
  } catch (error) {
    throw error
  }
}

export const fetchPercentGradeScaleData = async (
  gradCountry: string,
  isHighSchoolEval: boolean
): Promise<{gradeScaleData: IGradeScale[]}> => {
  try {
    const response = await get<IResponse<{gradeScaleData: IGradeScale[]}>>(GET_GRADE_SCALE_DATA, {
      country: encodeURIComponent(gradCountry),
      isHighSchoolEval: isHighSchoolEval,
    })
    return response.data?.success || {gradeScaleData: []}
  } catch (error) {
    throw error
  }
}

export const getAllUserEmails = async (
  lastEvaluatedKey?: string,
  searchValue?: string,
): Promise<IGetAllUserEmails | undefined> => {
  try {
    let params = '';
    if (searchValue) {
      params += `email=${searchValue}&`
    }
    if (lastEvaluatedKey) {
      params += `lastEvaluatedKey=${lastEvaluatedKey}`
    }
    let url = GET_ALL_USER_WITH_EMAILS;
    if(params && params?.length > 0) {
      url += "?" +params; 
    }
    const response = await get<IResponse<IGetAllUserEmails>>(url);
    return response.data?.success;
  } catch (error) {
    throw error;
  }
};