import { GET_USER_APP_REF_DATA, SUBMIT_REASSIGNMENT } from "../../../../../utils/service-constants"
import { IResponse } from "../../../../../utils/common-interface"
import { get, post } from "../../../auth/core/_requests"
import { IUserAppDistributionResponse } from "../../distribution.interface"

export const getUserAppIdData = async (userId: string): Promise<IUserAppDistributionResponse[]> => {
  try {
    const response = await get<IResponse<IUserAppDistributionResponse[]>>(GET_USER_APP_REF_DATA, {
      userId: userId,
    })
    return response.data?.success || ([] as IUserAppDistributionResponse[])
  } catch (error) {
    throw error
  }
}

export const submitReassignment = async (userId: string): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(SUBMIT_REASSIGNMENT, {
      userId: userId,
    })
    return response.data?.success || false
  } catch (error) {
    throw error
  }
}
