import {PageTitle} from '../../layout/core';
import {useState, useEffect, ChangeEvent, useCallback, useRef} from 'react';
import {useFormik} from 'formik';
import Transcript from '../../../components/transcript/transcript';
import './course-by-course-style.scss';
import ToggleSwitch from '../../../components/toggle-switch/toggle-switch';
import {getValueLocalStorage} from '../../auth/core/_requests';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {
  TOTAL_US_CREDITS_DEFAULT_VALUE,
  TOTAL_US_CREDITS_DROPDOWN,
} from '../../page-common-constants';
import {IErrorResponse, useAuth} from '../../auth';
import CreatableSelect from 'react-select/creatable';
import {
  clearCbcData,
  deleteRowCBC,
  fetchCByCData,
  fetchPrevCByCData,
  updateCByCData,
} from '../_service/type-of-evaluation-services';
import GenericDialogueBox from '../../../components/generic-dialogue-box/generic-dialogue-box';
import { ICredEvalLocalStorageData } from '../../../../utils/common-interface';
import { useIntl } from 'react-intl';
import { commonConstant } from '../../../../utils/common-constant';
import { CbcUgPgSchema, ICbcData, ICbcUgPg, IGradeScale, IUniversityMajor } from '../evaluation-interfaces';

const CourseByCourseEval = () => {
  const translator = useIntl();
  const {currentUser} = useAuth();
  const localStorageData = getValueLocalStorage(commonConstant.credEvalData) || '';
  const credEvalData: ICredEvalLocalStorageData | undefined =
    localStorageData.length > 0 ? JSON.parse(localStorageData) : undefined;
  const gradType = credEvalData?.isPgEvaluation ? 'PG' : 'UG';
  const navigate = useNavigate();
  const [cbcDataFromApi, setCbcDataFromApi] = useState<ICbcData>({} as ICbcData);
  const [toggle, setToggle] = useState(
    sessionStorage.getItem('autoSave')
      ? sessionStorage.getItem('autoSave') === 'true'
      : process.env.REACT_APP_IS_AUTO_SAVE === 'true'
  );
  const [totalRequiredCredits, setTotalRequiredCredits] = useState({label: 0, value: 0});

  const [gradeScaleType, setGradeScaleType] = useState('');
  const [gradeScaleData, setGradeScaleData] = useState<IGradeScale[]>([]);
  const [universityMajor, setUniversityMajor] = useState<IUniversityMajor | undefined>(undefined);

  const [totalUsCreditOptions, setTotalUsCreditOptions] = useState(TOTAL_US_CREDITS_DROPDOWN);
  const [isSaving, setIsSaving] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [noOfCol, setNoOfCol] = useState(0);
  const [rowCount, setRowCount] = useState(1);
  const [rowsToAdd, setRowsToAdd] = useState(0);
  const [touched, setTouched] = useState<string[]>([]);
  const [invalid, setInvalid] = useState<string[]>([]);
  const [totalNCredits, setTotalNCredits] = useState(0);
  const [totalObtainedMarks, setTotalObtainedMarks] = useState(0);
  const [totalMaximumMarks, setTotalMaximumMarks] = useState(0);
  const [fetchingPrevCbcData, setFetchingPrevCbcData] = useState(false);
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDataClearing, setIsDataClearing] = useState(false);

  const [fastTrackApplication, setFastTrackApplication] = useState(false);

  const formIk = useFormik({
    initialValues: [
      {
        id: generateUniqueId(),
        index: 0,
        sem: '',
        course: '',
        grade: '',
        o_mark: 0,
        m_mark: 0,
        native_credit: 0,
        us_credit: 0,
        us_gpa: 0,
        ma: 0,
        mb: currentUser?.user_code || '',
      },
    ],
    validationSchema: CbcUgPgSchema(translator),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });

  // initialize the formIk
  useEffect(() => {
    if (!credEvalData?.appIdWithOrgCode || '') {
      navigate('/type-of-evaluation');
    }
    const fetchCBCData = async () => {
      try {
        const data = await fetchCByCData(credEvalData?.appIdWithOrgCode || '', gradType);

        if (data) {
          gradType === 'UG'
            ? setUniversityMajor({university: data.ug_university || '', major: data.ug_major || ''})
            : setUniversityMajor({
                university: data.pg_university || '',
                major: data.pg_major || '',
              });

          if (gradType === 'UG') {
            setCbcDataFromApi({...data.cbc});
            setRowCount(data?.cbc?.ug?.length || formIk.values.length);
            if (data?.cbc?.ug?.length > 0) {
              const cbcValues = new Array(...data.cbc.ug);
              formIk.setValues(() => [...cbcValues]);
            }
            setGradeScaleType(data.ug_scale);
            setTotalRequiredCredits({
              label: data?.cbc?.ug_required_credit
                ? data.cbc.ug_required_credit
                : TOTAL_US_CREDITS_DEFAULT_VALUE[data.ug_us_equivalency] || 0,
              value: data?.cbc?.ug_required_credit
                ? data.cbc.ug_required_credit
                : TOTAL_US_CREDITS_DEFAULT_VALUE[data.ug_us_equivalency] || 0,
            });
          } else if (gradType === 'PG') {
            setCbcDataFromApi({...data.cbc});
            setRowCount(data?.cbc?.pg?.length || formIk.values.length);
            if (data?.cbc?.pg?.length > 0) {
              const cbcValues = new Array(...data.cbc.pg);
              formIk.setValues(() => [...cbcValues]);
            }
            setGradeScaleType(data.pg_scale);
            setTotalRequiredCredits({
              label: data?.cbc?.pg_required_credit
                ? data.cbc.pg_required_credit
                : TOTAL_US_CREDITS_DEFAULT_VALUE[data.pg_us_equivalency] || 0,
              value: data?.cbc?.pg_required_credit
                ? data.cbc.pg_required_credit
                : TOTAL_US_CREDITS_DEFAULT_VALUE[data.pg_us_equivalency] || 0,
            });
          }

          if (credEvalData?.gradeScaleData) {
            setGradeScaleData(
              credEvalData?.gradeScaleData[gradType === 'UG' ? 'ugData' : 'pgData']
            );
            let range = credEvalData?.gradeScaleData[gradType === 'UG' ? 'ugData' : 'pgData']?.[0]
              ?.column_range
              ? credEvalData?.gradeScaleData[
                  gradType === 'UG' ? 'ugData' : 'pgData'
                ]?.[0]?.column_range.split(', ')
              : [];
            setNoOfCol(() => range.length);
            calculateMarks(data?.cbc?.[gradType === 'UG' ? 'ug' : 'pg'] || []);
          } else {
            toast.error(translator.formatMessage({id: 'COMMON.INVALID_COUNTRY_SCALE'}), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setFastTrackApplication(data.fast_track_application || false);
          setIsLoading(false);
        }
      } catch (err: unknown) {
        setIsLoading(false);
        toast.error((err as IErrorResponse)?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    fetchCBCData();
  // eslint-disable-next-line
  }, []);

const autoSaveData = async () => {
  try {
    setIsAutoSaving(true);
    const data = await updateCByCData(
      credEvalData?.appIdWithOrgCode || '',
      gradType,
      gradeScaleData,
      gradeScaleType,
      {
        cbc: getChanges(),
        total_required_credits: totalRequiredCredits.value,
        mb: currentUser?.user_code,
        fast_track_application: fastTrackApplication,
      },
      credEvalData?.educationLevel === commonConstant.highSchool
    );
    if (data) {
      if (
        gradType === 'UG' &&
        cbcDataFromApi?.ug_required_credit !== data.cbc.ug_required_credit
      ) {
        setTotalRequiredCredits({
          label: data.cbc.ug_required_credit,
          value: data.cbc.ug_required_credit,
        });
      } else if (cbcDataFromApi?.pg_required_credit !== data.cbc.pg_required_credit) {
        setTotalRequiredCredits({
          label: data.cbc.pg_required_credit,
          value: data.cbc.pg_required_credit,
        });
      }
      setCbcDataFromApi({...data.cbc});
    }
  } catch (error) {
  } finally {
    setIsAutoSaving(false);
  }
};

const useDebounce = (callback: () => void, delay: number) => {
  const timeoutRef = useRef<any>(null);
  const debounceTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay);
  }, [callback, delay]);

  return debounceTimeout;
};

const debounceValue = useDebounce(() => {
  const changes = getChanges();
  if (changes.length > 0) {
    autoSaveData();
  }
}, 3000);

// Effect to handle auto-saving when changes are detected
useEffect(() => {
  if (invalid.length === 0 && toggle && !isLoading) {
    debounceValue();
  }
// eslint-disable-next-line
}, [formIk.values, invalid, toggle, isLoading]);
  // This will return data if there is any changes in previous data and current data
  const getChanges = (): ICbcUgPg[] => {
    let changes: ICbcUgPg[] = [];
    const currentTime = new Date().getTime();

    const updateRow = (row: ICbcUgPg, index: number) => {
      row.mb = currentUser?.user_code || '';
      row.ma = currentTime;
      row.o_mark = Number(row.o_mark || '0');
      row.m_mark = noOfCol > 1 ? Number(row.m_mark || '0') : 0;
      row.native_credit = Number(row.native_credit || '0');
      if (fastTrackApplication && parseInt(row.course)) {
        row.sem = index + 1 + '';
        row.course = index + 1 + '';
      }
    };

    formIk.values.forEach((row, index) => {
      updateRow(row, index);
      // check for old data
      const oldData =
        cbcDataFromApi &&
        cbcDataFromApi[gradType === 'UG' ? 'ug' : 'pg']?.find((item) => item.id === row.id);
      // if has changes
      const hasChanges =
        oldData &&
        (oldData.course !== row.course ||
          oldData.sem !== row.sem ||
          oldData.native_credit !== row.native_credit ||
          (noOfCol > 1 && (oldData.m_mark !== row.m_mark || oldData.o_mark !== row.o_mark)) ||
          (noOfCol === 1 && row.grade !== oldData.grade));

      // if row is newly created
      const isNewRow =
        !oldData &&
        row.course &&
        row.sem &&
        row.native_credit &&
        ((noOfCol > 1 && row.m_mark) || (noOfCol === 1 && row.grade));

      if (hasChanges || isNewRow) {
        changes.push(row);
      }

      if (
        row.index !== index &&
        row.course &&
        row.sem &&
        row.native_credit &&
        ((noOfCol > 1 && row.m_mark && /^\d+(\.\d{1,2})?$/.test('' + row.o_mark)) ||
          (noOfCol === 1 && row.grade))
      ) {
        row.index = index;
        updateRow(row, index);
        changes = changes.filter((item) => item.id !== row.id).concat(row);
      }
    });
  
    return changes.map((item) => {
      item.course = item.course.trim();
      item.sem = item.sem.trim();
      return item;
    });
  };

  async function finalSave() {
    if (formIk.errors.length > 0) {
      toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.PLEASE_CORRECT_THE_INVALID_DATA'}));
      return;
    }
    const changes = getChanges();
    if (
      ((!cbcDataFromApi || Object.keys(cbcDataFromApi).length === 0) &&
        credEvalData?.educationLevel === commonConstant.globalGradCourses &&
        !totalRequiredCredits.value) ||
      (changes.length === 0 &&
        cbcDataFromApi &&
        Object.keys(cbcDataFromApi).length > 0 &&
        totalRequiredCredits.value ===
          cbcDataFromApi[gradType === 'UG' ? 'ug_required_credit' : 'pg_required_credit'])
    ) {
      if (!toast.isActive('cbc-save-not-required') && !toast.isActive('cbc-save-success')) {
        toast.success(translator.formatMessage({id: 'COMMON.NO_CHANGES_TO_SAVE'}), {
          // Toast message updated
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'cbc-save-not-required',
        });
      }
      return;
    }
    setIsSaving(true);
    const cbcDataToUpdate = {
      cbc: changes.map((row) => {
        row.o_mark = Number(row.o_mark || 0 + '');
        row.m_mark = Number(row.m_mark || 0 + '');
        row.native_credit = Number(row.native_credit || 0 + '');
        return row;
      }),
      total_required_credits: totalRequiredCredits.value,
      mb: currentUser?.user_code,
      fast_track_application: fastTrackApplication,
    };
    const updateCBCData = async () => {
      try {
        const data = await updateCByCData(
          credEvalData?.appIdWithOrgCode || '',
          gradType,
          gradeScaleData,
          gradeScaleType,
          cbcDataToUpdate,
          credEvalData?.educationLevel === commonConstant.highSchool
        );
        if (data) {
          if (!toast.isActive('cbc-save-success')) {
            toast.success(
              translator.formatMessage({id: 'COMMON.MESSAGE.SUCCESSFULLY_UPDATED_DATA'}),
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'cbc-save-success',
              }
            );
          }
          setCbcDataFromApi({...data.cbc});
          setRowCount(data.cbc[gradType === 'UG' ? 'ug' : 'pg']?.length || 1);
          if (data.cbc[gradType === 'UG' ? 'ug' : 'pg'].length > 0) {
            const cbcValues = new Array(...data.cbc[gradType === 'UG' ? 'ug' : 'pg']);
            formIk.setValues(() => [...cbcValues]);
          }
        }
      } catch (err: unknown) {}
      setIsSaving(false);
    };
    updateCBCData();
  }

  // on save click
  const handleEvaluationOnClick = async () => {
    await finalSave();
    const type: string = gradType === 'PG' ? 'Pg' : 'Ug';
    const evalType = credEvalData?.educationLevel === commonConstant.highSchool ? 'Hs' : '';
    if (type !== '') {
      navigate(`/type-of-evaluation/evalMain?page=eval${evalType}${type}`);
    }
  };

  function DataTable(): JSX.Element {

  // add row on click 
  const handleAddRow = (isMultipleRow: boolean, index: number) => {
    const createNewRow = (idx: number) => ({
      id: generateUniqueId(),
      index: idx,
      sem: fastTrackApplication ? (index + 1).toString() : formIk.values[index].sem,
      course: fastTrackApplication ? (index + 1).toString() : '',
      grade: '',
      o_mark: 0,
      m_mark: formIk.values[index].m_mark,
      native_credit: 0,
      us_credit: 0,
      us_gpa: 0,
      ma: 0,
      mb: currentUser?.user_code || '',
    });  
    const rowsToInsert = isMultipleRow
      ? Array.from({ length: rowsToAdd }, (item, i) => createNewRow(index + 1 + i))
      : [createNewRow(index + 1)];
  
    formIk.setValues((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(index + 1, 0, ...rowsToInsert);
      return newArray;
    });  
    setRowCount(rowCount + rowsToInsert.length);
  };

  // add multiple row
    const handleAddMultipleRow = () => {
      handleAddRow(true, formIk.values.length - 1);
    };

    // check delete row from db
    const canDeleteRowFromDb = (row: ICbcUgPg) => {
      return (noOfCol > 1 && row.course && row.sem && row.m_mark && row.native_credit) ||
        (noOfCol === 1 && row.course && row.sem && row.grade && row.native_credit)
        ? true
        : false;
    };

    // handle row remove
    const handleRemoveRow = (index: number) => {
      if (
        formIk.values[index].id !== '' &&
        Object.keys(cbcDataFromApi).length > 0 &&
        cbcDataFromApi?.[gradType === 'UG' ? 'ug' : 'pg']?.[index] &&
        cbcDataFromApi?.[gradType === 'UG' ? 'ug' : 'pg']?.[index]?.id ===
          formIk.values[index].id &&
        canDeleteRowFromDb(formIk.values[index])
      ) {
        const cbcDataToUpdate = {
          deletedObject: formIk.values[index].id,
          total_required_credits: totalRequiredCredits.value,
          modifiedBy: currentUser?.user_code,
          fast_track_application: fastTrackApplication,
        };
        const deleteOneRawCBC = async () => {
          try {
            setIsDeleting(true);
            const data = await deleteRowCBC(
              credEvalData?.appIdWithOrgCode || '',
              gradType,
              gradeScaleData,
              gradeScaleType,
              cbcDataToUpdate,
              credEvalData?.educationLevel === commonConstant.highSchool
            );
            if (data) {
              setCbcDataFromApi({...data.cbc});
              setRowCount((prev) => prev - 1);
              formIk.setValues((prev) => prev.filter((item, id) => id !== index));
              calculateMarks();
              toast.success(
                translator.formatMessage({id: 'COMMON.MESSAGE.SUCCESSFULLY_DELETED_DATA'}),
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            }
          } catch (err: unknown) {
          } finally {
            setIsDeleting(false);
          }
        };
        deleteOneRawCBC();
      } else {
        setRowCount((prev) => prev - 1);
        formIk.setValues((prev) => prev.filter((item, id) => id !== index));
        calculateMarks();
      }
    };

    // remove zero 
    function removeLeadingZero(num: string): string {
      const numStr: string = num.toString();
      if (numStr.startsWith('0')) {
        return numStr.substring(1);
      }
      return num;
    }

    // input value data
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      let name: string = event.target.name;
      let value = event.target.value;

      switch (name) {
        case 'grade':
          formIk.values[index] = {...formIk.values[index], grade: value};
          setTouched((prev) => [...prev, 'grade' + index]);
          if (!value) setInvalid((prev) => [...prev, 'grade' + index]);
          else setInvalid((prev) => prev.filter((item) => item !== 'grade' + index));
          break;
        case 'o_mark':
          formIk.values[index] = {...formIk.values[index], o_mark: Number(value || '0')};
          setTouched((prev) => [...prev, 'o_mark' + index]);
          if (formIk.values[index].m_mark < Number(value || '0'))
            setInvalid((prev) => [...prev, 'o_mark' + index]);
          else {
            setInvalid((prev) => prev.filter((item) => item !== 'o_mark' + index));
            setInvalid((prev) => prev.filter((item) => item !== 'm_mark' + index));
          }
          break;
        case 'm_mark':
          formIk.values[index] = {...formIk.values[index], m_mark: Number(value || '0')};
          setTouched((prev) => [...prev, 'm_mark' + index]);
          if (Number(value || '0') === 0 || formIk.values[index].o_mark > Number(value || '0'))
            setInvalid((prev) => [...prev, 'm_mark' + index]);
          else {
            setInvalid((prev) => prev.filter((item) => item !== 'm_mark' + index));
            setInvalid((prev) => prev.filter((item) => item !== 'o_mark' + index));
          }
          break;
        case 'sem':
          formIk.values[index] = {...formIk.values[index], sem: value};
          setTouched((prev) => [...prev, 'sem' + index]);
          if (!value) setInvalid((prev) => [...prev, 'sem' + index]);
          else setInvalid((prev) => prev.filter((item) => item !== 'sem' + index));
          break;
        case 'course':
          formIk.values[index] = {...formIk.values[index], course: value};
          setTouched((prev) => [...prev, 'course' + index]);
          if (!value) setInvalid((prev) => [...prev, 'course' + index]);
          else setInvalid((prev) => prev.filter((item) => item !== 'course' + index));
          break;
        case 'native_credit':
          formIk.values[index] = {...formIk.values[index], native_credit: Number(value || '0')};
          setTouched((prev) => [...prev, 'native_credit' + index]);
          if (Number(value || '0') === 0) setInvalid((prev) => [...prev, 'native_credit' + index]);
          else setInvalid((prev) => prev.filter((item) => item !== 'native_credit' + index));
          break;
        default:
          break;
      }
      if (fastTrackApplication) {
        formIk.values[index] = {...formIk.values[index], sem: index + 1 + ''};
        formIk.values[index] = {...formIk.values[index], course: index + 1 + ''};
      }
      calculateMarks();
    };

    const handleRowsInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (/^[0-9]+$/.test(event.target.value)) {
        setRowsToAdd(parseInt(removeLeadingZero(event.target.value)));
      } else {
        setRowsToAdd(0);
      }
    };

    const renderTableRows = () => {
      const rows = [];
      for (let i = 0; i < rowCount; i++) {
        rows.push(
          <tr key={i}>
            <td className='mx-1 px-0 col-2'>
              <input
                disabled={
                  fastTrackApplication ||
                  (credEvalData?.educationLevel === commonConstant.globalGradCourses &&
                    !totalRequiredCredits.value)
                }
                className={`form-control mx-0 px-1 text-center fs-6 ${
                  touched.find((item) => item === 'sem' + i) &&
                  invalid.find((item) => item === 'sem' + i)
                    ? 'is-invalid'
                    : ''
                }`}
                id='sem'
                name='sem'
                type='text'
                value={fastTrackApplication ? (i + 1).toString() : formIk.values[i]?.sem || ''}
                onChange={(event) => handleInputChange(event, i)}
                onBlur={formIk.handleBlur}
                data-bs-toggle='tooltip'
                title={fastTrackApplication ? (i + 1).toString() : formIk.values[i]?.sem || ''}
                required
              />
            </td>
            <td className='mx-1 px-0 col-4'>
              <input
                className={`form-control mx-0 px-1 text-center fs-6 ${
                  touched.find((item) => item === 'course' + i) &&
                  invalid.find((item) => item === 'course' + i)
                    ? 'is-invalid'
                    : ''
                }`}
                id='course'
                name='course'
                type='text'
                disabled={
                  fastTrackApplication ||
                  (credEvalData?.educationLevel === commonConstant.globalGradCourses &&
                    !totalRequiredCredits.value)
                }
                value={fastTrackApplication ? (i + 1).toString() : formIk.values[i]?.course || ''}
                onChange={(event) => handleInputChange(event, i)}
                onBlur={formIk.handleBlur}
                data-bs-toggle='tooltip'
                title={fastTrackApplication ? (i + 1).toString() : formIk.values[i]?.course || ''}
                required
              />
            </td>
            {noOfCol === 1 && (
              <td className='mx-1 px-0 col-2'>
                <input
                  className={`form-control mx-0 px-1 text-center fs-6 ${
                    touched.find((item) => item === 'grade' + i) &&
                    invalid.find((item) => item === 'grade' + i)
                      ? 'is-invalid'
                      : ''
                  }`}
                  placeholder={translator.formatMessage({id: 'INPUT.ENTER_GRADE'})}
                  id='grade'
                  name='grade'
                  type='text'
                  disabled={
                    credEvalData?.educationLevel === commonConstant.globalGradCourses &&
                    !totalRequiredCredits.value
                  }
                  onBlur={formIk.handleBlur}
                  value={formIk.values[i]?.grade || ''}
                  onChange={(event) => handleInputChange(event, i)}
                  required
                />
              </td>
            )}
            {noOfCol === 2 && (
              <>
                <td className='mx-1 px-0 col-1'>
                  <input
                    className={`form-control mx-0 px-1 text-center fs-6 no-spinners ${
                      touched.find((item) => item === 'o_mark' + i) &&
                      invalid.find((item) => item === 'o_mark' + i)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id='o_mark'
                    name='o_mark'
                    type='number'
                    inputMode='numeric'
                    pattern='^\d*\.?\d+$'
                    onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
                      const target = e.target as HTMLDivElement;
                      target.blur();
                      e.stopPropagation();
                      setTimeout(() => {
                        target.focus();
                      }, 0);
                    }}
                    min={0}
                    disabled={
                      credEvalData?.educationLevel === commonConstant.globalGradCourses &&
                      !totalRequiredCredits.value
                    }
                    onBlur={formIk.handleBlur}
                    value={formIk.values[i]?.o_mark || 0}
                    onChange={(event) => handleInputChange(event, i)}
                    required
                  />
                </td>
                <td className='mx-1 px-0 col-1'>
                  <input
                    className={`form-control mx-0 px-1 text-center fs-6 no-spinners ${
                      touched.find((item) => item === 'm_mark' + i) &&
                      invalid.find((item) => item === 'm_mark' + i)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id='m_mark'
                    name='m_mark'
                    type='number'
                    inputMode='numeric'
                    pattern='^\d*\.?\d+$'
                    onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
                      const target = e.target as HTMLDivElement;
                      target.blur();
                      e.stopPropagation();
                      setTimeout(() => {
                        target.focus();
                      }, 0);
                    }}
                    min={0}
                    disabled={
                      credEvalData?.educationLevel === commonConstant.globalGradCourses &&
                      !totalRequiredCredits.value
                    }
                    value={formIk.values[i]?.m_mark || 0}
                    onBlur={formIk.handleBlur}
                    onChange={(event) => handleInputChange(event, i)}
                    required
                  />
                </td>
              </>
            )}
            <td className='mx-1 px-0 col-2'>
              <input
                className={`form-control mx-0 px-1 text-center fs-6 no-spinners ${
                  touched.find((item) => item === 'native_credit' + i) &&
                  invalid.find((item) => item === 'native_credit' + i)
                    ? 'is-invalid'
                    : ''
                }`}
                id='native_credit'
                name='native_credit'
                type='number'
                inputMode='numeric'
                pattern='^\d*\.?\d+$'
                onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
                  const target = e.target as HTMLDivElement;
                  target.blur();
                  e.stopPropagation();
                  setTimeout(() => {
                    target.focus();
                  }, 0);
                }}
                min={0}
                disabled={
                  credEvalData?.educationLevel === commonConstant.globalGradCourses &&
                  !totalRequiredCredits.value
                }
                value={formIk.values[i]?.native_credit || 0}
                onChange={(event) => handleInputChange(event, i)}
                required
              />
            </td>

            <td className='mx-2 d-flex flex-row col-2'>
              <div
                className='btn btn-light btn-primary me-1'
                onClick={(event) => handleAddRow(false, i)}
              >
                <i className='fa-solid fa-plus fa-2xl'></i>
              </div>
              {isDeleting ? (
                <div className='text-center mx-2'>
                  <span className='spinner-border spinner-border-sm'></span>
                </div>
              ) : (
                <div
                  className='btn btn-light btn-danger me-1'
                  onClick={() => {
                    if (formIk.values.length > 1) handleRemoveRow(i);
                    else
                      toast.error(
                        translator.formatMessage({id: 'COMMON.MESSAGE.CAN_NOT_REMOVE_SINGLE_ROW'})
                      );
                  }}
                >
                  <i className='fa-solid fa-trash fa-2xl'></i>
                </div>
              )}
            </td>
          </tr>
        );
      }

      return rows;
    };

    const showPreviousData = async () => {
      try {
        setIsLoading(true);
        if (universityMajor?.university && universityMajor?.major) {
          const mostRecentlyModifiedItem = await fetchPrevCByCData(
            credEvalData?.appIdWithOrgCode || '',
            gradType.toLowerCase(),
            universityMajor?.university?.trim() || '',
            universityMajor?.major?.trim() || ''
          );

          if (mostRecentlyModifiedItem.data.length > 0) {
            setFetchingPrevCbcData(true);
            setRowCount(mostRecentlyModifiedItem?.data?.length || formIk.values.length);
            if (mostRecentlyModifiedItem?.data?.length > 0) {
              const modifiedTableData = mostRecentlyModifiedItem?.data
                ?.map((item: ICbcUgPg) => ({
                  ...item,
                  o_mark: 0,
                  grade: '',
                  us_gpa: 0,
                  m_mark: 0,
                  us_credit: 0,
                }))
                .sort((a, b) => a.index - b.index);
              formIk.setValues(() => modifiedTableData);
              setCbcDataFromApi({} as ICbcData);
              setTotalRequiredCredits({
                label: mostRecentlyModifiedItem.totalRequiredCredits,
                value: mostRecentlyModifiedItem.totalRequiredCredits,
              });
            }
          } else {
            if (!toast.isActive('no-prev-cbc-data-found')) {
              toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.NO_DATA_FOUND'}), {
                toastId: 'no-prev-cbc-data-found',
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        } else if (!toast.isActive('no-prev-cbc-data-found')) {
          toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.NO_DATA_FOUND'}), {
            toastId: 'no-prev-cbc-data-found',
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
      } finally {
        setFetchingPrevCbcData(false);
        setIsLoading(false);
      }
    };

    const handleYesClearCbc = async () => {
      try {
        setIsDataClearing(true);
        await clearCbcDataAll();
        setDialogOpen(false);
      } catch (error) {
        setDialogOpen(false);
      } finally {
        setIsDataClearing(false);
      }
    };

    const clearCbcDataAll = async () => {
      try {
        const result = await clearCbcData(credEvalData?.appIdWithOrgCode || '', gradType);
        if (result) {
          if (!toast.isActive('cbc-save-success')) {
            toast.success(
              translator.formatMessage({id: 'COMMON.MESSAGE.SUCCESSFULLY_UPDATED_DATA'}),
              {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'cbc-save-success',
              }
            );
          }
          setTotalRequiredCredits({
            label: 0,
            value: 0,
          });
          setRowCount(1);
          setCbcDataFromApi({} as ICbcData);
          formIk.setValues([
            {
              id: generateUniqueId(),
              index: 0,
              sem: '',
              course: '',
              grade: '',
              o_mark: 0,
              m_mark: 0,
              native_credit: 0,
              us_credit: 0,
              us_gpa: 0,
              ma: 0,
              mb: currentUser?.user_code || '',
            },
          ]);
        }
      } catch (error) {}
    };

    return (
      <div>
        <div>
          <div className='d-flex justify-content-between align-items-center flex-wrap'>
            <div>
            <button
              type='button'
              className='btn btn-primary m-2'
              onClick={showPreviousData}
              disabled={
                isLoading ||
                fetchingPrevCbcData ||
                cbcDataFromApi?.[gradType === 'PG' ? 'pg' : 'ug']?.length > 0
              }
            >
              {fetchingPrevCbcData ? (
                <>
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                  <span className='ms-2'>
                    {translator.formatMessage({id: 'COMMON.PLEASE_WAIT'})}
                  </span>
                </>
              ) : (
                <span>
                  {translator.formatMessage({
                    id: 'COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.FETCH_PREV_DATA',
                  })}
                </span>
              )}
            </button>

            <button
              type='button'
              className='btn btn-primary m-2'
              disabled={
                fetchingPrevCbcData ||
                isLoading ||
                Object.keys(cbcDataFromApi).length === 0 ||
                cbcDataFromApi?.[gradType === 'PG' ? 'pg' : 'ug']?.length === 0
              }
              onClick={() => setDialogOpen(true)}
            >
              {translator.formatMessage({id: 'BUTTONS.CLEAR_COURSE_BY_COURSE_DATA'})}
            </button>
            </div>
            <div className='form-check form-switch m-2'>
              <label className='form-label cursor-pointer text-hover-primary' htmlFor='flexSwitchCheckChecked'>{ translator.formatMessage({id: 'INPUT.FAST_TRACK_APPLICATION'}) }</label>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckChecked'
                checked={fastTrackApplication}
                onChange={() => setFastTrackApplication(() => !fastTrackApplication)}
              />
            </div>
          </div>
          <div className='row flex-row-fluid flex-wrap'>
            <div className='input-group mb-3 w-md-50 w-100'>
              <span className='input-group-text fs-6 fw-bold'>{ translator.formatMessage({ id: 'INPUT.APPLICATION_IDS' })} : </span>
              <input className='form-control' value={credEvalData?.appIdWithOrgCode || ''} disabled={true} />
            </div>
            <div className='input-group mb-3 w-md-50 w-100'>
              <span className='input-group-text fs-6 fw-bold'>
                {translator.formatMessage({id: 'INPUT.ROW_REQUIRED'})}:{' '}
              </span>
              <input
                className='form-control px-3'
                value={rowsToAdd || 0}
                type='text'
                onChange={(event) => handleRowsInputChange(event)}
              />
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleAddMultipleRow}
                disabled={rowsToAdd < 1}
              >
                {translator.formatMessage({id: 'INPUT.ADD_ROWS'})}
              </button>
            </div>
          </div>
          <div className="row flex-row-fluid flex-wrap">
            {credEvalData?.educationLevel === commonConstant.globalGradCourses && (
              <div className='input-group mb-3 w-md-50 w-100'>
                <span className='input-group-text fs-6 fw-bold'>
                  {translator.formatMessage({
                    id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TOTAL_US_CREDIT',
                  })}
                  :{' '}
                </span>
                <CreatableSelect
                  className='form-control z-index-2'
                  inputId='total-us-credits'
                  options={totalUsCreditOptions}
                  value={totalRequiredCredits}
                  onChange={(e) => {
                    setTotalRequiredCredits({label: e?.label || 0, value: e?.value || 0});
                    setIsValuesChanged(() => !isValuesChanged);
                  }}
                  isValidNewOption={(inputValue) => /^[0-9]+$/.test(inputValue)}
                  onCreateOption={(inputValue) => {
                    try {
                      setTotalUsCreditOptions([
                        ...totalUsCreditOptions,
                        {value: parseInt(inputValue), label: parseInt(inputValue)},
                      ]);
                      setTotalRequiredCredits({
                        label: parseInt(inputValue) || 0,
                        value: parseInt(inputValue) || 0,
                      });
                      setIsValuesChanged(() => !isValuesChanged);
                    } catch (e) {
                      toast.error(
                        translator.formatMessage({
                          id: 'COMMON.MESSAGE.PLEASE_ENTER_VALID_US_CREDITS',
                        })
                      );
                    }
                  }}
                />
              </div>
            )}
            <div className='input-group mb-3 w-md-50 w-100'>
              <span className='input-group-text fs-6 fw-bold'>
                {translator.formatMessage({id: 'INPUT.TOTAL_N_CREDITS'})} :{' '}
              </span>
              <input
                type='text'
                className='form-control'
                value={totalNCredits}
                placeholder={translator.formatMessage({id: 'INPUT.TOTAL_N_CREDITS'})}
                disabled={true}
              />
            </div>
          </div>
          <div className='row flex-row-fluid flex-wrap'>
            <div className='input-group mb-3 w-md-50 w-100'>
              <span className='input-group-text fs-6 fw-bold'>
                {translator.formatMessage({id: 'INPUT.TOTAL_OM'})}:{' '}
              </span>
              <input
                type='number'
                className='form-control'
                value={totalObtainedMarks.toFixed(2)}
                placeholder={translator.formatMessage({id: 'INPUT.TOTAL_OBTAINED_MARKS'})}
                disabled={true}
              />
            </div>
            <div className='input-group mb-3 w-md-50 w-100'>
              <span className='input-group-text fs-6 fw-bold'>
                {' '}
                {translator.formatMessage({id: 'INPUT.TOTAL_MM'})} :{' '}
              </span>
              <input
                type='number'
                className='form-control'
                value={totalMaximumMarks}
                placeholder={translator.formatMessage({id: 'INPUT.TOTAL_MAXIMUM_MARKS'})}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {credEvalData?.educationLevel === commonConstant.globalGradCourses &&
          !totalRequiredCredits.value && (
            <span className='text-danger'>
              {translator.formatMessage({id: 'COMMON.MESSAGE.PLEASE_ENTER_VALID_US_CREDITS'})}
            </span>
          )}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='dataTables_scroll'>
              <div
                className='dataTables_scrollHead position-relative overflow-hidden w-100'
              >
                <table className='table table-separate table-head-custom table-checkable dataTable no-footer'>
                  <thead>
                    <tr>
                      <th className='fs-5 fw-bold'>
                        {translator.formatMessage({id: 'INPUT.SEMESTER'})}
                      </th>
                      <th className='fs-5 fw-bold'>
                        {translator.formatMessage({id: 'INPUT.COURSE_NAME'})}
                      </th>
                      {noOfCol === 1 && (
                        <th className='fs-5 fw-bold'>
                          {translator.formatMessage({id: 'INPUT.GRADE'})}
                        </th>
                      )}
                      {noOfCol === 2 && (
                        <>
                          <th className='fs-5 fw-bold'>
                            {translator.formatMessage({id: 'INPUT.OM'})}
                          </th>
                          <th className='fs-5 fw-bold'>
                            {translator.formatMessage({id: 'INPUT.MM'})}
                          </th>
                        </>
                      )}
                      <th className='fs-5 fw-bold'>
                        {translator.formatMessage({id: 'INPUT.CREDITS'})}
                      </th>
                      <th className='fs-5 fw-bold'>
                        {translator.formatMessage({id: 'INPUT.ACTIONS'})}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>

              <GenericDialogueBox
                name={translator.formatMessage(
                  {id: 'COMMON.MESSAGE.BY_THIS_ACTION_CBE_DATA_REMOVED_CONTINUE'},
                  {gradType}
                )}
                open={dialogOpen}
                onYes={handleYesClearCbc}
                onNo={() => setDialogOpen(false)}
                onClose={() => setDialogOpen(false)}
                isLoading={isDataClearing}
                key={'cbc-data-fetch'}
              />
              <div
                className='dataTables_scrollBody'
                style={{position: 'relative', overflow: 'auto', width: '100%', maxHeight: '600px'}}
              >
                <table
                  className='dataTable table table-checkable table-head-custom table-separate'
                  id='kt_datatable1'
                  aria-describedby='kt_datatable1_info'
                >
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={4} className='text-center'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <div
                              className='spinner-border'
                              style={{width: '3rem', height: '3rem'}}
                              role='status'
                              aria-hidden='true'
                            ></div>
                            <span className='ms-2'>
                              {translator.formatMessage({id: 'COMMON.PLEASE_WAIT'})}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      renderTableRows()
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-center'>
          <button
            type='button'
            className='btn btn-success m-2'
            onClick={finalSave}
            disabled={
              isSaving ||
              isLoading ||
              (credEvalData?.educationLevel === commonConstant.globalGradCourses &&
                !totalRequiredCredits.value)
            }
          >
            {isSaving ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
                <span className='ms-2'>{translator.formatMessage({id: 'COMMON.PLEASE_WAIT'})}</span>
              </>
            ) : (
              <span>{translator.formatMessage({id: 'BUTTONS.SAVE'})}</span>
            )}
          </button>
          <button
            type='button'
            className='btn btn-success m-2'
            onClick={() => {
              handleEvaluationOnClick();
            }}
          >
            {credEvalData?.educationLevel === commonConstant.globalGradCourses
              ? gradType
              : gradType === 'UG'
              ? 'HS1'
              : 'Hs2'}{' '}
            {translator.formatMessage({id: 'INPUT.EVALUATION'})}
          </button>
        </div>
      </div>
    );
  }

  function generateUniqueId(): string {
    const timestamp = new Date().getTime().toString(); // Get current timestamp as a string
    const randomDigits = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0'); // Generate 6 random digits
    return timestamp + randomDigits;
  }
  function calculateMarks(cbcValues: ICbcUgPg[] = []) {
    let maximum_marks = 0;
    let obtained_marks = 0;
    let native_credit = 0;
    if (cbcValues.length === 0) {
      formIk.values.forEach((ele) => {
        maximum_marks += ele.m_mark ? Number(ele.m_mark + '') : 0;
        obtained_marks += ele.o_mark ? Number(ele.o_mark + '') : 0;
        native_credit += ele.native_credit ? Number(ele.native_credit + '') : 0;
      });
    } else {
      //update for first time
      cbcValues.forEach((ele) => {
        maximum_marks += ele.m_mark ? Number(ele.m_mark + '') : 0;
        obtained_marks += ele.o_mark ? Number(ele.o_mark + '') : 0;
        native_credit += ele.native_credit ? Number(ele.native_credit + '') : 0;
      });
    }
    setIsValuesChanged(() => !isValuesChanged);
    setTotalMaximumMarks(maximum_marks);
    setTotalObtainedMarks(obtained_marks);
    setTotalNCredits(native_credit);
  }

  const getPageTitle = () => {
    const page = window.location.href.split('page=')?.[1];
    switch (page) {
      case 'evalUg':
        return translator.formatMessage({
          id: 'COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.UG',
        });
      case 'evalPg':
        if (credEvalData?.degreeOfEval === 2) {
          return translator.formatMessage({
            id: 'COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.PG',
          });
        } else {
          return '';
        }
      case 'evalHsUg':
        return translator.formatMessage({
          id: 'COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.HS1',
        });
      case 'evalHsPg':
        return translator.formatMessage({
          id: 'COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.HS2',
        });
      default:
        return '';
    }
  };

  const DataTableComp = DataTable();

  return (
    <div className='px-5'>
      <PageTitle breadcrumbs={[]}>{getPageTitle()}</PageTitle>
      <div className='bg-body d-flex justify-content-between p-3 mb-3 rounded'>
        <div>
          <h1 className='d-flex flex-center mt-2'>{getPageTitle()}</h1>
        </div>
        <div className='btn-group-toggle mt-2 flex-end' data-toggle='buttons'>
          <ToggleSwitch
            label={translator.formatMessage({
              id: 'COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.AUTO_SAVE',
            })}
            toggleHandler={() => setToggle(() => !toggle)}
            isAutoSaving={isAutoSaving}
            value={toggle}
          ></ToggleSwitch>
        </div>
      </div>
      <div className='d-flex flex-column flex-xl-row flex-row-fluid custom-row'>
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto custom-col me-5 custom-min-height'>
          <Transcript appId={credEvalData?.appId || ''} organizationCode={credEvalData?.organizationCode || ""} />
        </div>

        <div className='d-flex flex-row-fluid flex-center custom-col card bg-body rounded'>
          <div className='card-body p-5'>{DataTableComp}</div>
        </div>
      </div>
    </div>
  );
};

export {CourseByCourseEval};
