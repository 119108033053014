import {ChangeEvent, useEffect, useState} from 'react';
import Select from 'react-select';
import ToggleSwitch from '../../../components/toggle-switch/toggle-switch';
import Transcript from '../../../components/transcript/transcript';
import {
  IOrganizationTypeOfEval,
  IOrganization,
  ITypeOfEvaluation,
  IUniversityOptions,
  IGradeScale,
} from '../evaluation-interfaces';
import {toast} from 'react-toastify';
import {
  getValueLocalStorage,
  removeValueLocalStorage,
  setValueLocalStorage,
} from '../../auth/core/_requests';
import {useNavigate} from 'react-router-dom';
import {
  fetchOrganizationData,
  autoSaveCredentialEvalData,
  fetchPercentGradeScaleData,
} from '../../../../utils/common-services';
import {initialFormData, useTypeOfEvalGradSchema} from '../evaluation-constants';
import {
  saveCredentialEvalData,
  fetchTypeOfEvaluationData,
  fetchApplicationByPersonalId,
  sendToNextLevel,
} from '../_service/type-of-evaluation-services';
import {ROLES} from '../../page-common-constants';
import {useAuth} from '../../auth';
import GenericDialogueBox from '../../../components/generic-dialogue-box/generic-dialogue-box';
import CreatableSelect from 'react-select/creatable';
import {useFormik} from 'formik';
import RadioInputGroup from '../../../components/radio-input-group/radio-Input-group';
import {DEFAULT_COMMENTS} from '../general-evaluation/general-evaluation-constants';
import {useDebounce} from '@uidotdev/usehooks';
import {useIntl} from 'react-intl';
import PersonalIdData from './personal-ids-data';
import {COUNTRIES_DATA} from '../../../../utils/helpers/crud-helper/country-model';
import {ICredEvalLocalStorageData, IKeyValues} from '../../../../utils/common-interface';
import {commonConstant, customStylesSelect} from '../../../../utils/common-constant';
import {INameValue} from '../../feedback/feedback-interface';
import {ILableValue} from '../../page-common-interface';
import {deepEqual} from '../../../../utils/utils';

const TypeOfEvaluation = () => {
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const localStorageData = getValueLocalStorage(commonConstant.credEvalData) || '';
  const credEvalData: ICredEvalLocalStorageData | undefined =
    localStorageData.length > 0 ? JSON.parse(localStorageData) : undefined;
  const [appId, setAppId] = useState(credEvalData?.appId || '');
  const [isGradeScaleLoading, setIsGradeScaleLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isAutoSaveEnabled, setIsAutoSaveEnabled] = useState(
    sessionStorage.getItem('autoSave')
      ? sessionStorage.getItem('autoSave') === 'true'
      : process.env.REACT_APP_IS_AUTO_SAVE === 'true'
  );
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [newEducationLevel, setNewEducationLevel] = useState('');
  const [isChangeEducationLevel, setIsChangeEducationLevel] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [transcriptIncompleteReasons, setTranscriptIncompleteReasons] = useState<ILableValue[]>([]);
  const [degreeOfEvaluationOptions, setDegreeOfEvaluationOptions] = useState<ILableValue[]>([]);
  const [isSendToNextLevel, setIsSendToNextLevel] = useState(false);
  const [sendToNextLevelClicked, setSendToNextLevelClicked] = useState(false);
  const [dataFromApi, setDataFromApi] = useState<ITypeOfEvaluation>({} as ITypeOfEvaluation);
  const [organization, setOrganization] = useState<IOrganizationTypeOfEval>({
    name: credEvalData?.organizationName || '',
    code: credEvalData?.organizationCode || '',
    no_process_reason_list: [],
  });
  const [organizationData, setOrganizationFetchData] = useState<IOrganization>({} as IOrganization);
  const [countryOptions, setCountryOptions] = useState<ILableValue[]>([]);
  const [pgScaleOptions, setPgScaleOptions] = useState<ILableValue[]>([]);
  const [ugScaleOptions, setUgScaleOptions] = useState<ILableValue[]>([]);
  const [universityOptions, setUniversityOptions] = useState<IUniversityOptions[]>([]);
  const [gradeScaleDataUg, setGradeScaleDataUg] = useState<IGradeScale[]>([]);
  const [gradeScaleDataPg, setGradeScaleDataPg] = useState<IGradeScale[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showGrad, setShowGrad] = useState(true);
  const [isDataNotFound, setIsDataNotFound] = useState(true);
  const [selectedName, setSelectedName] = useState('');
  const [previousData, setPreviousData] = useState([{}]);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [isRestored, setIsRestored] = useState(false);
  const debouncedValues = useDebounce(isValuesChanged, 3000);
  const translator = useIntl(); // translator
  const [additionalComments, setAdditionalComments] = useState<ILableValue[]>(
    DEFAULT_COMMENTS(translator)
  );
  // initialize the form here
  const formIk = useFormik({
    initialValues: {
      ...initialFormData,
    },
    validationSchema: useTypeOfEvalGradSchema(translator),
    onSubmit: async (values: ITypeOfEvaluation) => {
      await onNextClick(values);
      if (sendToNextLevelClicked) {
        if (formIk.isValid) {
          await sendToNextLevelApp();
          setSendToNextLevelClicked(false);
        } else {
          toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.PLEASE_FILL_REQUIRED_FIELDS'}));
        }
      }
    },
  });

  // This is for auto save, after 3 second this will trigger
  useEffect(() => {
    if (
      !isAutoSaving &&
      appId &&
      organization.code &&
      isAutoSaveEnabled &&
      !isDataLoading &&
      !deepEqual(formIk.values, initialFormData) // do not do this call if no data is filled
    ) {
      const autoSaveData = async () => {
        const code = organization.code.toLowerCase();
        try {
          const changes: IKeyValues = {};
          Object.entries(formIk.values || {}).forEach(([key, value]) => {
            if (dataFromApi[key as keyof ITypeOfEvaluation] !== value) changes[key] = value;
          });
          if (Object.keys(changes).length === 0) {
            setIsAutoSaving(false);
            return;
          }
          const response = await autoSaveCredentialEvalData(
            code + appId,
            changes,
            commonConstant.typeOfEvaluation
          );
          if (response) {
            setDataFromApi((prevData) => ({
              ...prevData,
              ...response,
            }));
            formIk.setValues((prev) => ({
              ...prev,
              ...response,
            }));
          }
          setIsAutoSaving(false);
        } catch (err) {
          setIsAutoSaving(false);
        }
      };

      setIsAutoSaving(true);
      autoSaveData();
    }
    // eslint-disable-next-line
  }, [debouncedValues]);

  // Initialize dropdown options
  useEffect(() => {
    formIk.setErrors({});
    const fetchOrganizationsData = async () => {
      try {
        const data = await fetchOrganizationData(commonConstant.typeOfEval);
        if (data) {
          const universities = data.map((organization) => {
            return {
              label: organization.name || '',
              value: organization.name || '',
              code: organization.code.toLowerCase() || '',
              no_process_reason_list: organization.no_process_reason_list || [],
              process_reason_list: organization.no_process_reason_list || [],
            };
          });
          setUniversityOptions(universities);
          const org = universities.find((org) => organization.code === org.code);
          setTranscriptIncompleteReasons([
            ...(org?.no_process_reason_list.map((item) => ({label: item, value: item})) || []),
            ...(org?.process_reason_list.map((item) => ({label: item, value: item})) || []),
          ]);
          setOrganization((prev) => ({
            ...prev,
            no_process_reason_list: org?.no_process_reason_list || [],
          }));
          setCountryOptions(
            COUNTRIES_DATA.map((country: {name: string}) => {
              return {
                label: country.name,
                value: country.name,
              };
            })
          );
        }
      } catch (error) {}
    };
    fetchOrganizationsData();
    // eslint-disable-next-line
  }, []);

  // As this will be run after every App ID and organization change.
  useEffect(() => {
    if (!appId || !organization.code || isDataLoading) {
      return;
    }
    if (appId !== credEvalData?.appId || organization.code !== credEvalData?.organizationCode) {
      setIsDataNotFound(true);
      removeValueLocalStorage(commonConstant.credEvalData);
    }
    const debounceTimer = setTimeout(() => {
      const fetchDataFromAPI = async () => {
        try {
          const code = organization.code.toLowerCase();
          if (!appId || !code) {
            return;
          }
          setIsDataLoading(true);
          const data = await fetchTypeOfEvaluationData(code + appId);
          if (data && Object.keys(data).length > 0) {
            setIsDataNotFound(false);
            const setFormData = {
              education_level: data.education_level,
              ug_is_additional_credentials: data.ug_is_additional_credentials,
              pg_is_additional_credentials: data.pg_is_additional_credentials,
              type_of_eval: data.type_of_eval || '',
              ug_is_degree_awarded: data.ug_is_degree_awarded,
              pg_is_degree_awarded: data.pg_is_degree_awarded,
              ug_is_transcript_complete: data.ug_is_transcript_complete,
              ug_transcript_incomplete_reason: data.ug_transcript_incomplete_reason || '',
              ug_no_process_reason: data.ug_no_process_reason,
              pg_no_process_reason: data.pg_no_process_reason,
              pg_is_transcript_complete: data.pg_is_transcript_complete,
              pg_transcript_incomplete_reason: data.pg_transcript_incomplete_reason || '',
              pg_txt: data.pg_txt || '',
              pg_country: data.pg_country || '',
              pg_scale: data.pg_scale || '',
              ug_txt: data.ug_txt || '',
              ug_country: data.ug_country || '',
              ug_scale: data.ug_scale || '',
              degree_of_eval: data?.degree_of_eval,
              ug_additional_comment: data?.ug_additional_comment,
              pg_additional_comment: data?.pg_additional_comment,
              ug_out_of: data?.ug_out_of,
              pg_out_of: data?.pg_out_of,
            };
            setValueToDegreeOfEvaluation('ug_txt', data.ug_txt);
            setDataFromApi(() => setFormData);
            const updatedData = {
              ...setFormData,
              education_level: data.education_level
                ? data.education_level
                : organization.name.includes(commonConstant.highSchool)
                ? commonConstant.highSchool
                : commonConstant.globalGradCourses,
            };
            // Map data to the formIk if it's not in Eval in
            formIk.setValues(() => updatedData);
            setShowGrad(() => updatedData.education_level === commonConstant.globalGradCourses);
            try {
              setIsGradeScaleLoading(true);
              if (data.ug_country) {
                const res = await fetchPercentGradeScaleData(
                  data.ug_country,
                  updatedData.education_level === commonConstant.highSchool
                );
                setUgScaleOptions(
                  res.gradeScaleData
                    .map((item) => item.grade_scale_type)
                    .filter((value: string, index: number, self: string[]) => {
                      return value && self.indexOf(value) === index;
                    })
                    .map((item: string) => {
                      return {label: item, value: item};
                    })
                );
                setGradeScaleDataUg(res.gradeScaleData);
              }
              if (data.pg_country) {
                const res = await fetchPercentGradeScaleData(
                  data.pg_country,
                  setFormData.education_level === commonConstant.highSchool
                );
                setPgScaleOptions(
                  res.gradeScaleData
                    .map((item) => item.grade_scale_type)
                    .filter((value: string, index: number, self: string[]) => {
                      return value && self.indexOf(value) === index;
                    })
                    .map((item: string) => {
                      return {label: item, value: item};
                    })
                );
                setGradeScaleDataPg(res.gradeScaleData);
              }

              setIsGradeScaleLoading(false);
            } catch (error) {}
            setIsDataLoading(false);
            return true;
          } else {
            formIk.setErrors({});
            toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.DATA_IS_NOT_EXIST_IN_DB'}));
            setIsDataNotFound(true);
            formIk.setValues(initialFormData);
            formIk.setTouched({}, true);
            return true;
          }
        } finally {
          setIsDataLoading(false);
        }
      };
      const fetchOrgData = async (code: string) => {
        try {
          const responseData = await fetchOrganizationData('evalUgPg');
          const orgData = responseData.find((org) => org.code === code.toUpperCase());
          if (!orgData) {
            toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.FAILED_TO_FETCH_ORG_DATA'}), {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
          setOrganizationFetchData(orgData);
        } catch (error) {
          toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG'}), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
      const getDataOfPersonalId = async () => {
        const code = organization.code.toLowerCase();
        try {
          if (!appId || !code) return;
          setIsDataLoading(true);
          if (appId) {
            const data = await fetchApplicationByPersonalId(code.toLowerCase() + appId);
            if (data && data.length > 0) {
              setPreviousData(data);
              if (data.length > 0 && Object.keys(data[0]).length > 0) {
                setIsTableOpen(true);
              } else {
                setIsTableOpen(false);
              }
            }
          }
        } catch (error) {
          setIsDataLoading(false);
          setPreviousData([]);
        }
        fetchOrgData(code);
      };

      if (parseInt(appId || '0') > 0) {
        formIk.setErrors({});
        formIk.setValues(initialFormData);
        formIk.setTouched({}, true);
        fetchDataFromAPI();
        getDataOfPersonalId();
      }
    }, 1000);
    return () => clearTimeout(debounceTimer);
    // eslint-disable-next-line
  }, [appId, organization, isRestored]);

  // Calculation of ug_scale and pg_scale
  const calculateOutOf = (
    ug_scale: string,
    pg_scale: string,
    values: ITypeOfEvaluation
  ): {ug_out_of: number; pg_out_of: number} => {
    let maxUg = 0,
      maxPg = 0;
    if (values.degree_of_eval === 2) {
      gradeScaleDataUg
        ?.filter((item) => item.grade_scale_type === ug_scale)
        .forEach((item) => {
          maxUg = Math.max(maxUg, item.scale);
        });
      gradeScaleDataPg
        ?.filter((item) => item.grade_scale_type === pg_scale)
        .forEach((item) => {
          maxPg = Math.max(maxPg, item.scale);
        });
    } else if (values.degree_of_eval === 1) {
      gradeScaleDataUg
        ?.filter((item) => item.grade_scale_type === ug_scale)
        .forEach((item) => {
          maxUg = Math.max(maxUg, item.scale);
        });
    }
    return {
      ug_out_of: maxUg,
      pg_out_of: maxPg,
    };
  };

  // Auto save handle due to void
  const handleAutoSaveToggle = () => {
    setIsAutoSaveEnabled(!isAutoSaveEnabled);
  };

  // Set value to particular input
  const handleFieldChange = (name: string, value: any) => {
    formIk.setFieldValue(name, value);
    setIsValuesChanged(!isValuesChanged);
    setValueToDegreeOfEvaluation(name, value);
  };

  // Open dialog for clear data actions
  const openDialog = (name: string) => {
    setSelectedName(name);
    setDialogOpen(true);
  };

  // Set value to the dropdown options
  const setValueToDegreeOfEvaluation = (name: string, inputValue: string) => {
    if (name === 'ug_txt' && inputValue === 'Undergraduate') {
      setDegreeOfEvaluationOptions([
        {label: translator.formatMessage({id: 'INPUT.UNDERGRADUATE_2'}), value: 'Undergraduate 2'},
        {label: translator.formatMessage({id: 'INPUT.POST_GRADUATE'}), value: 'Postgraduate'},
        {label: translator.formatMessage({id: 'INPUT.DOCTORATE'}), value: 'Doctorate'},
      ]);
    } else if (name === 'ug_txt' && inputValue === 'Postgraduate') {
      setDegreeOfEvaluationOptions([
        {label: translator.formatMessage({id: 'INPUT.UNDER_GRADUATE'}), value: 'Undergraduate'},
        {label: translator.formatMessage({id: 'INPUT.POST_GRADUATE_2'}), value: 'Postgraduate 2'},
        {label: translator.formatMessage({id: 'INPUT.DOCTORATE'}), value: 'Doctorate'},
      ]);
    } else if (name === 'ug_txt' && inputValue === 'Doctorate') {
      setDegreeOfEvaluationOptions([
        {label: translator.formatMessage({id: 'INPUT.UNDER_GRADUATE'}), value: 'Undergraduate'},
        {label: translator.formatMessage({id: 'INPUT.POST_GRADUATE'}), value: 'Postgraduate'},
        {label: translator.formatMessage({id: 'INPUT.DOCTORATE_2'}), value: 'Doctorate 2'},
      ]);
    }
    if (
      name === 'ug_txt' &&
      inputValue === 'Undergraduate' &&
      formIk?.values['pg_txt'] === 'Undergraduate'
    ) {
      formIk.setFieldValue('pg_txt', '');
    }
    if (
      name === 'ug_txt' &&
      inputValue === 'Postgraduate' &&
      formIk?.values['pg_txt'] === 'Postgraduate'
    ) {
      formIk.setFieldValue('pg_txt', '');
    }
    if (
      name === 'ug_txt' &&
      inputValue === 'Doctorate' &&
      formIk?.values['pg_txt'] === 'Doctorate'
    ) {
      formIk.setFieldValue('pg_txt', '');
    }
  };

  // handle change in education level
  const handleChangeEducationLevel = async () => {
    setNewEducationLevel(() =>
      formIk.values.education_level === commonConstant.globalGradCourses
        ? commonConstant.highSchool
        : commonConstant.globalGradCourses
    );
    //make country and scales empty as need to fetch again
    formIk.setValues((prev) => ({
      ...prev,
      ug_country: '',
      ug_scale: '',
      pg_country: '',
      pg_scale: '',
    }));
    setIsChangeEducationLevel(false);
  };

  // on click of change education level popup
  const handleYes = async () => {
    try {
      formIk.setValues((prev) => {
        return {
          ...prev,
          degree_of_eval: 1,
          pg_txt: '',
          pg_is_transcript_complete: false,
          pg_is_additional_credentials: false,
          pg_is_degree_awarded: false,
          pg_transcript_incomplete_reason: '',
          pg_no_process_reason: '',
          pg_scale: '',
          pg_country: '',
        };
      });

      const code = organization.code.toLowerCase();
      if (!code) {
        toast.error(
          translator.formatMessage({id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG_REFRESH_PAGE'})
        );
        return;
      }
      if (isAutoSaveEnabled) {
        const response = await autoSaveCredentialEvalData(
          code.toLowerCase() + appId,
          {
            degree_of_eval: 1,
            pg_txt: '',
          },
          commonConstant.typeOfEvaluation
        );
        if (response) {
          setDataFromApi((previousFields) => {
            return {
              ...previousFields,
              ...response,
            };
          });
        }
      }
      setDialogOpen(false);
    } catch (error) {}
  };
  // on click of change education level popup
  const handleNo = () => {
    setDialogOpen(false);
  };

  // on click of change education level popup
  const handleClose = () => {
    setDialogOpen(false);
  };

  // on change of app id
  const handleAppIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAppId(e.target.value);
  };

  // on country change
  const handleCountryChange = async (event: INameValue) => {
    handleFieldChange(event.name, event.value);
    handleFieldChange(event.name === 'pg_country' ? 'pg_scale' : 'ug_scale', '');
    let gradeScaleDataRes: IGradeScale[] = [];
    try {
      setIsGradeScaleLoading(true);
      const data = await fetchPercentGradeScaleData(
        event.value,
        formIk.values.education_level === commonConstant.highSchool
      );
      gradeScaleDataRes = data.gradeScaleData;
      setIsGradeScaleLoading(false);
    } catch (error) {
    } finally {
      setIsGradeScaleLoading(false);
    }
    if (event.name === 'pg_country') {
      setPgScaleOptions(
        gradeScaleDataRes
          .map((item) => {
            return item.grade_scale_type;
          })
          .filter((value: string, index: number, self: string[]) => {
            return value && self.indexOf(value) === index;
          })
          .map((item: string) => {
            return {label: item, value: item};
          })
      );
      setGradeScaleDataPg(gradeScaleDataRes);
    } else {
      setUgScaleOptions(
        gradeScaleDataRes
          .map((item) => item.grade_scale_type)
          .filter((value: string, index: number, self: string[]) => {
            return value && self.indexOf(value) === index;
          })
          .map((item: string) => {
            return {label: item, value: item};
          })
      );
      setGradeScaleDataUg(gradeScaleDataRes);
    }
  };

  // on next click form ik data will submit
  const onNextClick = async (values: ITypeOfEvaluation) => {
    const changes: IKeyValues = {};
    for (const key of Object.keys(values)) {
      const ketAttribute = key as keyof ITypeOfEvaluation;
      if (key && dataFromApi[ketAttribute] !== values[ketAttribute]) {
        changes[key] = values[ketAttribute];
      }
    }
    if (
      !dataFromApi.ug_out_of ||
      !dataFromApi.pg_out_of ||
      (dataFromApi.pg_out_of !== values.pg_out_of &&
        dataFromApi.ug_out_of !== values.ug_out_of &&
        values.degree_of_eval === 2 &&
        formIk.values.pg_scale &&
        formIk.values.ug_scale) ||
      (dataFromApi.ug_out_of !== formIk.values.ug_out_of &&
        formIk.values.degree_of_eval === 1 &&
        formIk.values.ug_scale)
    ) {
      const outOf = calculateOutOf(formIk.values.ug_scale, formIk.values.pg_scale, formIk.values);
      changes['ug_out_of'] = outOf.ug_out_of;
      changes['pg_out_of'] = outOf.pg_out_of;
    }
    const code = organization?.code.toLowerCase() || '';
    const credEvalData: ICredEvalLocalStorageData = {
      appId: appId,
      appIdWithOrgCode: organization.code.toLowerCase() + appId,
      typeOfEval: formIk.values.type_of_eval || '',
      degreeOfEval: formIk.values.degree_of_eval || 1,
      educationLevel: formIk.values.education_level,
      ugTxt: formIk.values.ug_txt || '',
      pgTxt: formIk.values.pg_txt || '',
      isPgEvaluation: false,
      organizationName: organization.name,
      organizationCode: organization.code.toLowerCase(),
      gradeScaleData: {
        ugData:
          gradeScaleDataUg?.filter((item) => item.grade_scale_type === formIk.values.ug_scale) ||
          [],
        pgData:
          gradeScaleDataPg?.filter((item) => item.grade_scale_type === formIk.values.pg_scale) ||
          [],
      },
    };
    if (
      appId &&
      code &&
      Object.keys(formIk.errors).length === 0 &&
      Object.keys(changes).length > 0
    ) {
      setIsUpdatingData(true);
      try {
        const response = await saveCredentialEvalData(
          code.toLowerCase() + appId,
          changes,
          commonConstant.typeOfEvaluation
        );
        if (response) {
          setDataFromApi(response);
          formIk.setErrors({});
          if (!toast.isActive('save-success'))
            toast.success(
              translator.formatMessage({id: 'COMMON.MESSAGE.SUCCESSFULLY_SAVED_THE_DATA'}),
              {
                toastId: 'save-success',
              }
            );
          //if incomplete transcript do not go to next page
          if (formIk.values.ug_no_process_reason || formIk.values.pg_no_process_reason) {
            setIsUpdatingData(false);
            return;
          }
          setValueLocalStorage(commonConstant.credEvalData, JSON.stringify(credEvalData));
          if (formIk.values.education_level === commonConstant.globalGradCourses)
            navigate('/type-of-evaluation/evalMain?page=evalUg');
          else navigate('/type-of-evaluation/evalMain?page=evalHsUg');
        } else {
          toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.FAILED_TO_SAVE_DATA'}), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setIsUpdatingData(false);
      } catch (error) {
        setIsUpdatingData(false);
      }
    }

    if (
      appId &&
      code &&
      Object.keys(formIk.errors).length === 0 &&
      Object.keys(changes).length === 0
    ) {
      setValueLocalStorage(commonConstant.credEvalData, JSON.stringify(credEvalData));
      if (formIk.values.education_level === commonConstant.globalGradCourses)
        navigate('/type-of-evaluation/evalMain?page=evalUg');
      else navigate('/type-of-evaluation/evalMain?page=evalHsUg');
    }
  };

  // handle sendToNextLevel button
  const sendToNextLevelApp = async () => {
    setIsSendToNextLevel(true);
    try {
      const response = await sendToNextLevel(organization.code.toLowerCase() + appId);
      if (response) {
        toast.success(
          translator.formatMessage({id: 'COMMON.MESSAGE.SUCCESSFULLY_SENT_TO_NEXT_LEVEL'})
        );
        navigate('/my-work');
      } else {
        toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG'}));
      }
    } catch {}
    setIsSendToNextLevel(false);
  };

  const handleYesNextLevel = () => {
    formIk.handleSubmit();
    setSendToNextLevelClicked(true);
  };

  const GradComponent = () => {
    return (
      <>
        <div className='mb-3'>
          <label className='required-field form-check-label'>
            {translator.formatMessage({id: 'TYPES_OF_EVALUATION.DEGREE_OF_EVALUATION'})}
          </label>
          <Select
            inputId='ug_txt'
            name='ug_txt'
            options={[
              {
                label: translator.formatMessage({id: 'INPUT.UNDER_GRADUATE'}),
                value: 'Undergraduate',
              },
              {label: translator.formatMessage({id: 'INPUT.POST_GRADUATE'}), value: 'Postgraduate'},
              {label: translator.formatMessage({id: 'INPUT.DOCTORATE'}), value: 'Doctorate'},
            ]}
            value={
              appId
                ? {label: formIk.values?.ug_txt || '', value: formIk.values?.ug_txt || ''}
                : {label: '', value: ''}
            }
            required={true}
            isDisabled={isDataNotFound}
            isLoading={isDataLoading}
            onBlur={formIk.handleBlur}
            onChange={(e) => {
              handleFieldChange('ug_txt', e?.value || '');
              if (formIk.values?.ug_txt !== e?.value) {
                handleFieldChange('degree_of_eval', !formIk.values?.pg_txt ? 1 : 2);
              }
            }}
            isSearchable={true}
          />
        </div>
        <div className='mb-3'>
          <Select
            inputId='pg_txt'
            name='pg_txt'
            options={degreeOfEvaluationOptions}
            value={
              appId
                ? {label: formIk.values?.pg_txt || '', value: formIk.values?.pg_txt || ''}
                : {label: '', value: ''}
            }
            required={true}
            isClearable={formIk.values.pg_txt !== ''}
            isLoading={isDataLoading}
            onBlur={formIk.handleBlur}
            isDisabled={!appId || !formIk.values.ug_txt}
            onChange={(e) => {
              if (!e?.value) {
                openDialog(
                  translator.formatMessage({
                    id: 'COMMON.MESSAGE.ACTION_WILL_CLEAR_DATA_DO_YOU_WANT_CONTINUE',
                  })
                );
              } else if (formIk.values?.pg_txt !== e?.value) {
                handleFieldChange('pg_txt', e?.value || '');
                handleFieldChange('degree_of_eval', 2);
              }
            }}
            isSearchable={true}
          />
          {formIk.touched.ug_txt && formIk.errors.ug_txt && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_txt}</span>
              </div>
            </div>
          )}
          {formIk.touched.pg_txt && formIk.errors.pg_txt && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.pg_txt}</span>
              </div>
            </div>
          )}
        </div>
        <GenericDialogueBox
          key={'is-clear-postgrad'}
          name={selectedName}
          open={dialogOpen}
          onYes={handleYes}
          onNo={handleNo}
          onClose={handleClose}
        />

        <div className='mb-3'>
          <label className='required-field form-check-label'>
            {translator.formatMessage({id: 'INPUT.TYPES_OF_EVALUATION'})}
          </label>
          <Select
            name='type_of_eval'
            inputId='type_of_eval'
            options={[
              {label: translator.formatMessage({id: 'INPUT.GENERAL_EVALUATION'}), value: 'ge'},
              {label: translator.formatMessage({id: 'INPUT.COURSE_BY_COURSE'}), value: 'cbc'},
            ]}
            value={
              appId
                ? {
                    label:
                      formIk.values?.type_of_eval === 'ge'
                        ? translator.formatMessage({id: 'INPUT.GENERAL_EVALUATION'})
                        : formIk.values?.type_of_eval === 'cbc'
                        ? translator.formatMessage({id: 'INPUT.COURSE_BY_COURSE'})
                        : '',
                    value: formIk.values?.type_of_eval || '',
                  }
                : {label: '', value: ''}
            }
            required={true}
            onBlur={formIk.handleBlur}
            isLoading={isDataLoading}
            isDisabled={isDataNotFound}
            onChange={(e) => {
              if (formIk.values?.type_of_eval !== e?.value) {
                handleFieldChange('type_of_eval', e?.value || '');
              }
            }}
            isSearchable={true}
          />
          {formIk.touched.type_of_eval && formIk.errors.type_of_eval && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.type_of_eval}</span>
              </div>
            </div>
          )}
        </div>
        <div className='mb-3'>
          <label className='required-field form-check-label'>
            {translator.formatMessage({id: 'INPUT.UNDER_GRADUATE_COUNTRY'})}
          </label>
          <Select
            inputId='ug_country'
            name='ug_country'
            options={countryOptions}
            value={
              appId
                ? {
                    label: formIk.values.ug_country || '',
                    value: formIk.values.ug_country || '',
                  }
                : {label: '', value: ''}
            }
            required={true}
            onBlur={formIk.handleBlur}
            isDisabled={isDataNotFound}
            isLoading={isDataLoading}
            onChange={(e) => {
              handleCountryChange({
                name: 'ug_country',
                value: e?.value || '',
              });
            }}
            isSearchable={true}
          />
          {formIk.touched.ug_country && formIk.errors.ug_country && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_country}</span>
              </div>
            </div>
          )}
        </div>
        <div className='mb-5'>
          <label className='required-field form-check-label'>
            {translator.formatMessage({id: 'INPUT.UG_SCALE'})}
          </label>
          <Select
            inputId='ug_scale'
            name='ug_scale'
            options={ugScaleOptions}
            value={
              appId
                ? {label: formIk.values.ug_scale || '', value: formIk.values.ug_scale || ''}
                : {label: '', value: ''}
            }
            styles={customStylesSelect}
            onBlur={formIk.handleBlur}
            isDisabled={isDataNotFound || isGradeScaleLoading}
            isLoading={isDataLoading || isGradeScaleLoading}
            onChange={(e) => {
              handleFieldChange('ug_scale', e?.value || '');
            }}
            isSearchable={true}
          />
          {formIk.touched.ug_scale && formIk.errors.ug_scale && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_scale}</span>
              </div>
            </div>
          )}
        </div>
        {formIk.values.pg_txt && (
          <div className='mb-3'>
            <label className='required-field form-check-label'>
              {translator.formatMessage({id: 'INPUT.POST_GRADUATE_COUNTRY'})}
            </label>
            <Select
              inputId='pg_country'
              name='pg_country'
              options={countryOptions}
              value={
                appId
                  ? {
                      label: formIk.values.pg_country || '',
                      value: formIk.values.pg_country || '',
                    }
                  : {label: '', value: ''}
              }
              onBlur={formIk.handleBlur}
              isDisabled={isDataNotFound}
              isLoading={isDataLoading}
              onChange={(e) => {
                handleCountryChange({
                  name: 'pg_country',
                  value: e?.value || '',
                });
              }}
              isSearchable={true}
            />
            {formIk.touched.pg_country && formIk.errors.pg_country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formIk.errors.pg_country}</span>
                </div>
              </div>
            )}
          </div>
        )}
        {formIk.values.pg_txt && (
          <div className='mb-3'>
            <label className='required-field form-check-label'>
              {translator.formatMessage({id: 'INPUT.PG_SCALE'})}
            </label>
            <Select
              inputId='pg_scale'
              name='pg_scale'
              options={pgScaleOptions}
              value={
                appId
                  ? {
                      label: formIk.values.pg_scale || '',
                      value: formIk.values.pg_scale || '',
                    }
                  : {label: '', value: ''}
              }
              styles={customStylesSelect}
              onBlur={formIk.handleBlur}
              isDisabled={isDataNotFound || isGradeScaleLoading}
              isLoading={isDataLoading || isGradeScaleLoading}
              onChange={(e) => {
                handleFieldChange('pg_scale', e?.value || '');
              }}
              isSearchable={true}
            />
            {formIk.touched.pg_scale && formIk.errors.pg_scale && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formIk.errors.pg_scale}</span>
                </div>
              </div>
            )}
          </div>
        )}

        <label className='required-field form-check-label mt-3'>
          {translator.formatMessage({id: 'INPUT.UG'})}
        </label>
        <div className='mb-3'>
          <label className='required-field form-check-label mt-3'>
            {translator.formatMessage({id: 'INPUT.COMPLETE_TRANSCRIPT_AVAILABLE'})}
          </label>
          <RadioInputGroup
            id='ug_is_transcript_complete'
            name='ug_is_transcript_complete'
            checked={formIk.values.ug_is_transcript_complete}
            onBlur={formIk.handleBlur}
            onChange={(e) => {
              handleFieldChange(
                'ug_is_transcript_complete',
                e.target.checked && e.target.id === 'ug_is_transcript_complete-Yes'
              );
              if (e.target.checked && e.target.id === 'ug_is_transcript_complete-Yes') {
                handleFieldChange('ug_transcript_incomplete_reason', '');
                handleFieldChange('ug_no_process_reason', '');
              }
            }}
            options={[
              {label: translator.formatMessage({id: 'INPUT.YES'}), value: true},
              {label: translator.formatMessage({id: 'INPUT.NO'}), value: false},
            ]}
            disabled={isDataNotFound}
          />
          {formIk.touched.ug_is_transcript_complete && formIk.errors.ug_is_transcript_complete && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_is_transcript_complete}</span>
              </div>
            </div>
          )}
          {!formIk.values.ug_is_transcript_complete && (
            <div className='mb-3 mt-2'>
              <label className='required-field form-check-label'>
                {translator.formatMessage({id: 'INPUT.CONDITION_FOR_IN_COMPLETE_TRANSCRIPT'})}
              </label>
              <CreatableSelect
                id='ug_transcript_incomplete_reason'
                inputId='ug_transcript_incomplete_reason'
                name='ug_transcript_incomplete_reason'
                options={transcriptIncompleteReasons}
                isDisabled={isDataNotFound}
                isLoading={isDataLoading}
                onBlur={formIk.handleBlur}
                value={
                  appId
                    ? {
                        label: formIk.values.ug_transcript_incomplete_reason || '',
                        value: formIk.values.ug_transcript_incomplete_reason || '',
                      }
                    : {label: '', value: ''}
                }
                styles={customStylesSelect}
                onChange={(e) => {
                  handleFieldChange('ug_transcript_incomplete_reason', e?.value || '');
                  handleFieldChange(
                    'ug_no_process_reason',
                    organization?.no_process_reason_list.find((reason) => e?.value === reason)
                      ? e?.value || ''
                      : ''
                  );
                }}
                isSearchable={true}
              />
              {formIk.touched.ug_transcript_incomplete_reason &&
                formIk.errors.ug_transcript_incomplete_reason && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formIk.errors.ug_transcript_incomplete_reason}</span>
                    </div>
                  </div>
                )}

              <label htmlFor='ug_additional_comment' className='form-check-label'>
                {translator.formatMessage({id: 'INPUT.ADDITION_COMMENTS'})} :
              </label>
              <div className='w-100'>
                <CreatableSelect
                  inputId='ug_additional_comment'
                  name='ug_additional_comment'
                  placeholder={translator.formatMessage({id: 'INPUT.ADDITION_COMMENTS'})}
                  isDisabled={isDataLoading}
                  styles={customStylesSelect}
                  onCreateOption={(inputValue) => {
                    handleFieldChange('ug_additional_comment', inputValue);
                    setAdditionalComments([
                      ...additionalComments,
                      {value: inputValue, label: inputValue},
                    ]);
                  }}
                  isSearchable={true}
                  value={{
                    label: formIk.values.ug_additional_comment,
                    value: formIk.values.ug_additional_comment,
                  }}
                  onBlur={formIk.handleBlur}
                  onChange={(e) => {
                    handleFieldChange('ug_additional_comment', e?.value);
                  }}
                  options={additionalComments}
                />
                {formIk.touched.ug_additional_comment && formIk.errors.ug_additional_comment && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formIk.errors.ug_additional_comment}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <label className='required-field form-check-label mt-3'>
            {translator.formatMessage({id: 'INPUT.DEGREE_AWARDED'})}
          </label>
          <RadioInputGroup
            id='ug_is_degree_awarded'
            name='ug_is_degree_awarded'
            checked={formIk.values.ug_is_degree_awarded}
            onBlur={formIk.handleBlur}
            onChange={(e) => {
              handleFieldChange(
                'ug_is_degree_awarded',
                e.target.checked && e.target.id === 'ug_is_degree_awarded-Yes'
              );
            }}
            options={[
              {label: translator.formatMessage({id: 'INPUT.YES'}), value: true},
              {label: translator.formatMessage({id: 'INPUT.NO'}), value: false},
            ]}
            disabled={isDataNotFound}
          />
          {formIk.touched.ug_is_degree_awarded && formIk.errors.ug_is_degree_awarded && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_is_degree_awarded}</span>
              </div>
            </div>
          )}
          <label className='required-field form-check-label mt-3'>
            {translator.formatMessage({id: 'INPUT.ANY_ADDITIONAL_CRED_FOR_EVAL'})}
          </label>
          <RadioInputGroup
            id='ug_is_additional_credentials'
            name='ug_is_additional_credentials'
            checked={formIk.values.ug_is_additional_credentials}
            onBlur={formIk.handleBlur}
            onChange={(e) => {
              handleFieldChange(
                'ug_is_additional_credentials',
                e.target.checked && e.target.id === 'ug_is_additional_credentials-Yes'
              );
            }}
            options={[
              {label: translator.formatMessage({id: 'INPUT.YES'}), value: true},
              {label: translator.formatMessage({id: 'INPUT.NO'}), value: false},
            ]}
            disabled={isDataNotFound}
          />
          {formIk.touched.ug_is_additional_credentials &&
            formIk.errors.ug_is_additional_credentials && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formIk.errors.ug_is_additional_credentials}</span>
                </div>
              </div>
            )}
        </div>

        {formIk.values.pg_txt && (
          <>
            <div className='border-top border-primary border-dashed border-1 w-100 my-5'></div>
            <label className='required-field form-check-label mt-3'>
              {translator.formatMessage({id: 'INPUT.PG'})}
            </label>
            <div className='mb-3'>
              <label className='required-field form-check-label mt-3'>
                {translator.formatMessage({id: 'INPUT.COMPLETE_TRANSCRIPT_AVAILABLE'})}
              </label>
              <RadioInputGroup
                id='pg_is_transcript_complete'
                name='pg_is_transcript_complete'
                checked={formIk.values.pg_is_transcript_complete}
                onBlur={formIk.handleBlur}
                onChange={(e) => {
                  handleFieldChange(
                    'pg_is_transcript_complete',
                    e.target.checked && e.target.id === 'pg_is_transcript_complete-Yes'
                  );
                  if (e.target.checked && e.target.id === 'pg_is_transcript_complete-Yes') {
                    handleFieldChange('pg_transcript_incomplete_reason', '');
                    handleFieldChange('pg_no_process_reason', '');
                  }
                }}
                options={[
                  {label: translator.formatMessage({id: 'INPUT.YES'}), value: true},
                  {label: translator.formatMessage({id: 'INPUT.NO'}), value: false},
                ]}
                disabled={isDataNotFound}
              />
              {formIk.touched.pg_is_transcript_complete &&
                formIk.errors.pg_is_transcript_complete && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formIk.errors.pg_is_transcript_complete}</span>
                    </div>
                  </div>
                )}
              {!formIk.values.pg_is_transcript_complete && (
                <div className='mb-3 mt-2'>
                  <label className='required-field form-check-label'>
                    {translator.formatMessage({id: 'INPUT.CONDITION_FOR_IN_COMPLETE_TRANSCRIPT'})}
                  </label>
                  <CreatableSelect
                    inputId='pg_transcript_incomplete_reason'
                    name='pg_transcript_incomplete_reason'
                    options={transcriptIncompleteReasons}
                    value={
                      appId
                        ? {
                            label: formIk.values.pg_transcript_incomplete_reason || '',
                            value: formIk.values.pg_transcript_incomplete_reason || '',
                          }
                        : {label: '', value: ''}
                    }
                    styles={customStylesSelect}
                    isDisabled={isDataNotFound}
                    isLoading={isDataLoading}
                    onBlur={formIk.handleBlur}
                    onChange={(e) => {
                      handleFieldChange('pg_transcript_incomplete_reason', e?.value || '');
                      handleFieldChange(
                        'pg_no_process_reason',
                        organization?.no_process_reason_list.find((reason) => e?.value === reason)
                          ? e?.value || ''
                          : ''
                      );
                    }}
                    isSearchable={true}
                  />
                  {formIk.touched.pg_transcript_incomplete_reason &&
                    formIk.errors.pg_transcript_incomplete_reason && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formIk.errors.pg_transcript_incomplete_reason}</span>
                        </div>
                      </div>
                    )}
                  <label htmlFor='pg_additional_comment' className='form-check-label'>
                    {translator.formatMessage({id: 'INPUT.ADDITION_COMMENTS'})} :
                  </label>
                  <div className='w-100'>
                    <CreatableSelect
                      inputId='pg_additional_comment'
                      name='pg_additional_comment'
                      placeholder={translator.formatMessage({id: 'INPUT.ADDITION_COMMENTS'})}
                      isDisabled={isDataLoading}
                      styles={customStylesSelect}
                      onCreateOption={(inputValue) => {
                        handleFieldChange('pg_additional_comment', inputValue);
                        setAdditionalComments([
                          ...additionalComments,
                          {value: inputValue, label: inputValue},
                        ]);
                      }}
                      isSearchable={true}
                      value={{
                        label: formIk.values.pg_additional_comment,
                        value: formIk.values.pg_additional_comment,
                      }}
                      onBlur={formIk.handleBlur}
                      onChange={(e) => {
                        handleFieldChange('pg_additional_comment', e?.value);
                      }}
                      options={additionalComments}
                    />
                    {formIk.touched.pg_additional_comment &&
                      formIk.errors.pg_additional_comment && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formIk.errors.pg_additional_comment}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
              <label className='required-field form-check-label mt-3'>
                {translator.formatMessage({id: 'INPUT.DEGREE_AWARDED'})}
              </label>
              <RadioInputGroup
                id='pg_is_degree_awarded'
                name='pg_is_degree_awarded'
                checked={formIk.values.pg_is_degree_awarded}
                onBlur={formIk.handleBlur}
                onChange={(e) => {
                  handleFieldChange(
                    'pg_is_degree_awarded',
                    e.target.checked && e.target.id === 'pg_is_degree_awarded-Yes'
                  );
                }}
                options={[
                  {label: translator.formatMessage({id: 'INPUT.YES'}), value: true},
                  {label: translator.formatMessage({id: 'INPUT.NO'}), value: false},
                ]}
                disabled={isDataNotFound}
              />
              {formIk.touched.pg_is_degree_awarded && formIk.errors.pg_is_degree_awarded && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_is_degree_awarded}</span>
                  </div>
                </div>
              )}

              <label className='required-field form-check-label mt-3'>
                {translator.formatMessage({id: 'INPUT.ANY_ADDITIONAL_CRED_FOR_EVAL'})}
              </label>
              <RadioInputGroup
                id='pg_is_additional_credentials'
                name='pg_is_additional_credentials'
                checked={formIk.values.pg_is_additional_credentials}
                onBlur={formIk.handleBlur}
                onChange={(e) => {
                  handleFieldChange(
                    'pg_is_additional_credentials',
                    e.target.checked && e.target.id === 'pg_is_additional_credentials-Yes'
                  );
                }}
                options={[
                  {label: translator.formatMessage({id: 'INPUT.YES'}), value: true},
                  {label: translator.formatMessage({id: 'INPUT.NO'}), value: false},
                ]}
                disabled={isDataNotFound}
              />
              {formIk.touched.pg_is_additional_credentials &&
                formIk.errors.pg_is_additional_credentials && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formIk.errors.pg_is_additional_credentials}</span>
                    </div>
                  </div>
                )}
            </div>
          </>
        )}
      </>
    );
  };
  const HighSchoolComponent = () => {
    return (
      <>
        <div className='mb-3'>
          <label className='required-field form-check-label'>
            {' '}
            {translator.formatMessage({id: 'TYPES_OF_EVALUATION.DEGREE_OF_EVALUATION'})}
          </label>
          <Select
            inputId='ug_txt'
            name='ug_txt'
            options={[
              {
                label: translator.formatMessage({id: 'INPUT.HIGH_SCHOOL_1'}),
                value: 'High School 1',
              },
              {
                label: translator.formatMessage({id: 'INPUT.HIGH_SCHOOL_2'}),
                value: 'High School 2',
              },
            ]}
            value={
              appId
                ? {label: formIk.values?.ug_txt || '', value: formIk.values?.ug_txt || ''}
                : {label: '', value: ''}
            }
            required={true}
            isDisabled={isDataNotFound}
            isLoading={isDataLoading}
            onBlur={formIk.handleBlur}
            onChange={(e) => {
              handleFieldChange('ug_txt', e?.value || '');
              if (formIk.values?.ug_txt !== e?.value) {
                if (!formIk.values?.pg_txt) {
                  handleFieldChange('degree_of_eval', 1);
                } else {
                  handleFieldChange('degree_of_eval', 2);
                }
              }
            }}
            isSearchable={true}
          />
        </div>
        <div className='mb-3'>
          <Select
            inputId='pg_txt'
            name='pg_txt'
            options={[
              {label: translator.formatMessage({id: 'INPUT.HIGH_SCHOOL_1'}), value: 'HighSchool1'},
              {label: translator.formatMessage({id: 'INPUT.HIGH_SCHOOL_2'}), value: 'HighSchool2'},
            ]}
            value={
              appId
                ? {label: formIk.values?.pg_txt || '', value: formIk.values?.pg_txt || ''}
                : {label: '', value: ''}
            }
            required={true}
            isClearable={formIk.values.pg_txt !== ''}
            isLoading={isDataLoading}
            onBlur={formIk.handleBlur}
            isDisabled={!appId || !formIk.values.ug_txt}
            onChange={(e) => {
              if (!e?.value) {
                openDialog(
                  translator.formatMessage({
                    id: 'COMMON.MESSAGE.ACTION_WILL_CLEAR_DATA_DO_YOU_WANT_CONTINUE',
                  })
                );
              } else if (formIk.values?.pg_txt !== e?.value) {
                handleFieldChange('degree_of_eval', 2);
                handleFieldChange('pg_txt', e?.value || '');
              }
            }}
            isSearchable={true}
          />
          {formIk.touched.ug_txt && formIk.errors.ug_txt && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_txt}</span>
              </div>
            </div>
          )}
          {formIk.touched.pg_txt && formIk.errors.pg_txt && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.pg_txt}</span>
              </div>
            </div>
          )}
        </div>

        <GenericDialogueBox
          name={selectedName}
          open={dialogOpen}
          onYes={handleYes}
          onNo={handleNo}
          onClose={handleClose}
        />

        <div className='mb-3'>
          <label className='required-field form-check-label'>
            {translator.formatMessage({id: 'INPUT.TYPES_OF_EVALUATION'})}
          </label>
          <Select
            name='type_of_eval'
            inputId='type_of_eval'
            options={[
              {label: translator.formatMessage({id: 'INPUT.GENERAL_EVALUATION'}), value: 'ge'},
              {label: translator.formatMessage({id: 'INPUT.COURSE_BY_COURSE'}), value: 'cbc'},
            ]}
            value={
              appId
                ? {
                    label:
                      formIk.values?.type_of_eval === 'ge'
                        ? translator.formatMessage({id: 'INPUT.GENERAL_EVALUATION'})
                        : formIk.values?.type_of_eval === 'cbc'
                        ? translator.formatMessage({id: 'INPUT.COURSE_BY_COURSE'})
                        : '',
                    value: formIk.values?.type_of_eval || '',
                  }
                : {label: '', value: ''}
            }
            required={true}
            onBlur={formIk.handleBlur}
            isLoading={isDataLoading}
            isDisabled={isDataNotFound}
            onChange={(e) => {
              if (formIk.values?.type_of_eval !== e?.value) {
                handleFieldChange('type_of_eval', e?.value || '');
              }
            }}
            isSearchable={true}
          />
          {formIk.touched.type_of_eval && formIk.errors.type_of_eval && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.type_of_eval}</span>
              </div>
            </div>
          )}
        </div>
        <div className='mb-3'>
          <label className='required-field form-check-label'>
            {translator.formatMessage({id: 'INPUT.UNDER_GRADUATE_COUNTRY'})}
          </label>
          <Select
            inputId='ug_country'
            name='ug_country'
            options={countryOptions}
            value={
              appId
                ? {
                    label: formIk.values.ug_country || '',
                    value: formIk.values.ug_country || '',
                  }
                : {label: '', value: ''}
            }
            required={true}
            onBlur={formIk.handleBlur}
            isDisabled={isDataNotFound}
            isLoading={isDataLoading}
            onChange={(e) => {
              handleCountryChange({
                name: 'ug_country',
                value: e?.value || '',
              });
            }}
            isSearchable={true}
          />
          {formIk.touched.ug_country && formIk.errors.ug_country && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_country}</span>
              </div>
            </div>
          )}
        </div>
        <div className='mb-5'>
          <label className='required-field form-check-label'>
            {translator.formatMessage({id: 'INPUT.UG_SCALE'})}
          </label>
          <Select
            inputId='ug_scale'
            name='ug_scale'
            options={ugScaleOptions}
            value={
              appId
                ? {label: formIk.values.ug_scale || '', value: formIk.values.ug_scale || ''}
                : {label: '', value: ''}
            }
            styles={customStylesSelect}
            onBlur={formIk.handleBlur}
            isDisabled={isDataNotFound || isGradeScaleLoading}
            isLoading={isDataLoading || isGradeScaleLoading}
            onChange={(e) => {
              handleFieldChange('ug_scale', e?.value || '');
            }}
            isSearchable={true}
          />
          {formIk.touched.ug_scale && formIk.errors.ug_scale && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_scale}</span>
              </div>
            </div>
          )}
        </div>

        {formIk.values.pg_txt && (
          <div className='mb-3'>
            <label className='required-field form-check-label'>
              {translator.formatMessage({id: 'INPUT.POST_GRADUATE_COUNTRY'})}
            </label>
            <Select
              inputId='pg_country'
              name='pg_country'
              options={countryOptions}
              value={
                appId
                  ? {
                      label: formIk.values.pg_country || '',
                      value: formIk.values.pg_country || '',
                    }
                  : {label: '', value: ''}
              }
              onBlur={formIk.handleBlur}
              isDisabled={isDataNotFound}
              isLoading={isDataLoading}
              onChange={(e) => {
                handleCountryChange({
                  name: 'pg_country',
                  value: e?.value || '',
                });
              }}
              isSearchable={true}
            />
            {formIk.touched.pg_country && formIk.errors.pg_country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formIk.errors.pg_country}</span>
                </div>
              </div>
            )}
          </div>
        )}
        {formIk.values.pg_txt && (
          <div className='mb-3'>
            <label className='required-field form-check-label'>
              {translator.formatMessage({id: 'INPUT.PG_SCALE'})}
            </label>
            <Select
              inputId='pg_scale'
              name='pg_scale'
              options={pgScaleOptions}
              value={
                appId
                  ? {
                      label: formIk.values.pg_scale || '',
                      value: formIk.values.pg_scale || '',
                    }
                  : {label: '', value: ''}
              }
              styles={customStylesSelect}
              onBlur={formIk.handleBlur}
              isDisabled={isDataNotFound || isGradeScaleLoading}
              isLoading={isDataLoading || isGradeScaleLoading}
              onChange={(e) => {
                handleFieldChange('pg_scale', e?.value || '');
              }}
              isSearchable={true}
            />
            {formIk.touched.pg_scale && formIk.errors.pg_scale && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formIk.errors.pg_scale}</span>
                </div>
              </div>
            )}
          </div>
        )}

        <label className='required-field form-check-label mt-3'>
          {translator.formatMessage({id: 'INPUT.UG'})}
        </label>
        <div className='mb-3'>
          <label className='required-field form-check-label mt-3'>
            {translator.formatMessage({id: 'INPUT.COMPLETE_TRANSCRIPT_AVAILABLE'})}
          </label>
          <RadioInputGroup
            id='ug_is_transcript_complete'
            name='ug_is_transcript_complete'
            checked={formIk.values.ug_is_transcript_complete}
            onBlur={formIk.handleBlur}
            onChange={(e) => {
              handleFieldChange(
                'ug_is_transcript_complete',
                e.target.checked && e.target.id === 'ug_is_transcript_complete-Yes'
              );
              if (e.target.checked && e.target.id === 'ug_is_transcript_complete-Yes') {
                handleFieldChange('ug_transcript_incomplete_reason', '');
                handleFieldChange('ug_no_process_reason', '');
              }
            }}
            options={[
              {label: translator.formatMessage({id: 'INPUT.YES'}), value: true},
              {label: translator.formatMessage({id: 'INPUT.NO'}), value: false},
            ]}
            disabled={isDataNotFound}
          />
          {formIk.touched.ug_is_transcript_complete && formIk.errors.ug_is_transcript_complete && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formIk.errors.ug_is_transcript_complete}</span>
              </div>
            </div>
          )}
          {!formIk.values.ug_is_transcript_complete && (
            <div className='mb-3 mt-2'>
              <label className='required-field form-check-label'>
                {translator.formatMessage({id: 'INPUT.CONDITION_FOR_IN_COMPLETE_TRANSCRIPT'})}
              </label>
              <CreatableSelect
                id='ug_transcript_incomplete_reason'
                inputId='ug_transcript_incomplete_reason'
                name='ug_transcript_incomplete_reason'
                options={transcriptIncompleteReasons}
                isDisabled={isDataNotFound}
                isLoading={isDataLoading}
                onBlur={formIk.handleBlur}
                value={
                  appId
                    ? {
                        label: formIk.values.ug_transcript_incomplete_reason || '',
                        value: formIk.values.ug_transcript_incomplete_reason || '',
                      }
                    : {label: '', value: ''}
                }
                styles={customStylesSelect}
                onChange={(e) => {
                  handleFieldChange('ug_transcript_incomplete_reason', e?.value || '');
                  handleFieldChange(
                    'ug_no_process_reason',
                    organization?.no_process_reason_list.find((reason) => e?.value === reason)
                      ? e?.value || ''
                      : ''
                  );
                }}
                isSearchable={true}
              />
              {formIk.touched.ug_transcript_incomplete_reason &&
                formIk.errors.ug_transcript_incomplete_reason && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formIk.errors.ug_transcript_incomplete_reason}</span>
                    </div>
                  </div>
                )}

              <label htmlFor='ug_additional_comment' className='form-check-label'>
                {translator.formatMessage({id: 'INPUT.ADDITION_COMMENTS'})} :
              </label>
              <div className='w-100'>
                <CreatableSelect
                  inputId='ug_additional_comment'
                  name='ug_additional_comment'
                  placeholder={translator.formatMessage({id: 'INPUT.ADDITION_COMMENTS'})}
                  isDisabled={isDataLoading}
                  styles={customStylesSelect}
                  onCreateOption={(inputValue) => {
                    handleFieldChange('ug_additional_comment', inputValue);
                    setAdditionalComments([
                      ...additionalComments,
                      {value: inputValue, label: inputValue},
                    ]);
                  }}
                  isSearchable={true}
                  value={{
                    label: formIk.values.ug_additional_comment,
                    value: formIk.values.ug_additional_comment,
                  }}
                  onBlur={formIk.handleBlur}
                  onChange={(e) => {
                    handleFieldChange('ug_additional_comment', e?.value);
                  }}
                  options={additionalComments}
                />
                {formIk.touched.ug_additional_comment && formIk.errors.ug_additional_comment && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formIk.errors.ug_additional_comment}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {formIk.values.pg_txt && (
          <>
            <label className='required-field form-check-label mt-3'>
              {translator.formatMessage({id: 'INPUT.PG'})}
            </label>
            <div className='mb-3'>
              <label className='required-field form-check-label mt-3'>
                {translator.formatMessage({id: 'INPUT.COMPLETE_TRANSCRIPT_AVAILABLE'})}
              </label>
              <RadioInputGroup
                id='pg_is_transcript_complete'
                name='pg_is_transcript_complete'
                checked={formIk.values.pg_is_transcript_complete}
                onBlur={formIk.handleBlur}
                onChange={(e) => {
                  handleFieldChange(
                    'pg_is_transcript_complete',
                    e.target.checked && e.target.id === 'pg_is_transcript_complete-Yes'
                  );
                  if (e.target.checked && e.target.id === 'pg_is_transcript_complete-Yes') {
                    handleFieldChange('pg_transcript_incomplete_reason', '');
                    handleFieldChange('pg_no_process_reason', '');
                  }
                }}
                options={[
                  {label: translator.formatMessage({id: 'INPUT.YES'}), value: true},
                  {label: translator.formatMessage({id: 'INPUT.NO'}), value: false},
                ]}
                disabled={isDataNotFound}
              />
              {formIk.touched.pg_is_transcript_complete &&
                formIk.errors.pg_is_transcript_complete && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formIk.errors.pg_is_transcript_complete}</span>
                    </div>
                  </div>
                )}
              {!formIk.values.pg_is_transcript_complete && (
                <div className='mb-3 mt-2'>
                  <label className='required-field form-check-label'>
                    {translator.formatMessage({id: 'INPUT.CONDITION_FOR_IN_COMPLETE_TRANSCRIPT'})}
                  </label>
                  <CreatableSelect
                    inputId='pg_transcript_incomplete_reason'
                    name='pg_transcript_incomplete_reason'
                    options={transcriptIncompleteReasons}
                    value={
                      appId
                        ? {
                            label: formIk.values.pg_transcript_incomplete_reason || '',
                            value: formIk.values.pg_transcript_incomplete_reason || '',
                          }
                        : {label: '', value: ''}
                    }
                    styles={customStylesSelect}
                    isDisabled={isDataNotFound}
                    isLoading={isDataLoading}
                    onBlur={formIk.handleBlur}
                    onChange={(e) => {
                      handleFieldChange('pg_transcript_incomplete_reason', e?.value || '');
                      handleFieldChange(
                        'pg_no_process_reason',
                        organization?.no_process_reason_list.find((reason) => e?.value === reason)
                          ? e?.value || ''
                          : ''
                      );
                    }}
                    isSearchable={true}
                  />
                  {formIk.touched.pg_transcript_incomplete_reason &&
                    formIk.errors.pg_transcript_incomplete_reason && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formIk.errors.pg_transcript_incomplete_reason}</span>
                        </div>
                      </div>
                    )}
                  <label htmlFor='pg_additional_comment' className='form-check-label'>
                    {translator.formatMessage({id: 'INPUT.ADDITION_COMMENTS'})} :
                  </label>
                  <div className='w-100'>
                    <CreatableSelect
                      inputId='pg_additional_comment'
                      name='pg_additional_comment'
                      placeholder={translator.formatMessage({id: 'INPUT.ADDITION_COMMENTS'})}
                      isDisabled={isDataLoading}
                      styles={customStylesSelect}
                      onCreateOption={(inputValue) => {
                        handleFieldChange('pg_additional_comment', inputValue);
                        setAdditionalComments([
                          ...additionalComments,
                          {value: inputValue, label: inputValue},
                        ]);
                      }}
                      isSearchable={true}
                      value={{
                        label: formIk.values.pg_additional_comment,
                        value: formIk.values.pg_additional_comment,
                      }}
                      onBlur={formIk.handleBlur}
                      onChange={(e) => {
                        handleFieldChange('pg_additional_comment', e?.value);
                      }}
                      options={additionalComments}
                    />
                    {formIk.touched.pg_additional_comment &&
                      formIk.errors.pg_additional_comment && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formIk.errors.pg_additional_comment}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <div className='bg-body d-flex justify-content-between p-3 mb-2 rounded'>
        <div>
          <h1 className='d-flex flex-center mt-2'>
            {translator.formatMessage({id: 'INPUT.TYPES_OF_EVALUATION'})}
          </h1>
        </div>
        <div className='btn-group-toggle mt-2 flex-end' data-toggle='buttons'>
          <ToggleSwitch
            label={translator.formatMessage({id: 'INPUT.AUTO_SAVE'})}
            toggleHandler={handleAutoSaveToggle}
            isAutoSaving={isAutoSaving}
            value={isAutoSaveEnabled}
          ></ToggleSwitch>
        </div>
      </div>
      <div>
        <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_create_account_stepper'
        >
          {/* begin::formdata*/}

          <form
            className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-500px w-xxl-400px me-9'
            noValidate
          >
            <div className='container my-10'>
              <label className='required-field form-check-label'>
                {translator.formatMessage({id: 'VALIDATIONS.REQUIRED_FIELDS'})}
              </label>

              <div className='mb-3 mt-3'>
                <label className='required-field form-check-label'>
                  {translator.formatMessage({id: 'INPUT.ORGANIZATION'})}
                </label>
                <Select
                  inputId='university'
                  options={universityOptions}
                  value={{label: organization.name || '', value: organization.name || ''}}
                  name='university'
                  required={true}
                  isDisabled={currentUser?.role !== ROLES.Admin}
                  onChange={(e) => {
                    const org = universityOptions.find((item) => item.label === e?.label);
                    setOrganization({
                      name: e?.value || '',
                      code: org?.code || '',
                      no_process_reason_list: org?.no_process_reason_list || [],
                    });
                    setTranscriptIncompleteReasons([
                      ...(org?.no_process_reason_list.map((item) => ({label: item, value: item})) ||
                        []),
                      ...(org?.process_reason_list.map((item) => ({label: item, value: item})) ||
                        []),
                    ]);
                    formIk.setValues((prev) => {
                      return {
                        ...prev,
                        ug_no_process_reason: '',
                        pg_no_process_reason: '',
                      };
                    });
                  }}
                  isSearchable={true}
                />
              </div>
              <div className='mb-3 '>
                <label htmlFor='appid' className='form-label label-inside'>
                  {translator.formatMessage({id: 'INPUT.APPLICATION_IDS'})}
                </label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control'
                    id='universityCode'
                    disabled={true}
                    value={organization.code.toLowerCase() || ''}
                  />
                  <input
                    type='text'
                    className='form-control'
                    id='appid'
                    placeholder={translator.formatMessage({id: 'INPUT.APPLICATION_IDS'})}
                    value={appId}
                    disabled={!organization || currentUser?.role !== ROLES.Admin}
                    onChange={handleAppIdChange}
                  />
                </div>
              </div>
              <div>
                <div className='mb-3'>
                  <label className='required-field form-check-label'>
                    {translator.formatMessage({id: 'INPUT.EVALUATION_LEVEL'})}
                  </label>
                  <Select
                    inputId='education_level'
                    name='education_level'
                    options={[
                      {
                        label: commonConstant.globalGradCourses,
                        value: commonConstant.globalGradCourses,
                      },
                      {label: commonConstant.highSchool, value: commonConstant.highSchool},
                    ]}
                    value={
                      appId
                        ? {
                            label: formIk.values?.education_level || '',
                            value: formIk.values?.education_level || '',
                          }
                        : {label: '', value: ''}
                    }
                    required={true}
                    isDisabled={isDataNotFound || !newEducationLevel}
                    isLoading={isDataLoading}
                    onBlur={formIk.handleBlur}
                    onChange={(e) => {
                      handleFieldChange('education_level', e?.value || '');
                      setShowGrad(() => e?.value === commonConstant.globalGradCourses);
                      setNewEducationLevel(() => '');
                    }}
                    isSearchable={true}
                  />
                  <div className='m-1 text-info cursor-pointer'>
                    <span
                      onClick={() => {
                        setSelectedName(() =>
                          translator.formatMessage(
                            {id: 'VALIDATIONS.EDUCATION_LEVEL_WILL_CHANGED_DO_YOU_WANT_CONTINUE'},
                            {education_level: formIk.values.education_level}
                          )
                        );
                        setIsChangeEducationLevel(() => true);
                      }}
                    >
                      {translator.formatMessage({
                        id: 'TYPES_OF_EVALUATION.CHANGE_EVALUATION_LEVEL',
                      })}
                    </span>
                  </div>
                </div>
                {showGrad ? GradComponent() : HighSchoolComponent()}
              </div>
            </div>
          </form>
          {/* end::formdata*/}
          <GenericDialogueBox
            key={'is-change-education-level'}
            name={selectedName}
            open={isChangeEducationLevel}
            onYes={handleChangeEducationLevel}
            onNo={() => setIsChangeEducationLevel(false)}
            onClose={() => setIsChangeEducationLevel(false)}
          />
          {isTableOpen && (
            <PersonalIdData
              data={previousData}
              setIsTableOpen={setIsTableOpen}
              organizationData={organizationData}
              appidToRestoreData={organization.code.toLowerCase() + appId}
              setIsRestored={setIsRestored}
            />
          )}
          {appId ? (
            <Transcript appId={appId} organizationCode={organization.code} />
          ) : (
            <div className='card flex-root'>
              <span className='mt-5 ms-5 fs-5'>
                {translator.formatMessage({
                  id: 'COMMON.MESSAGE.TRANSCRIPT_NOT_AVAILABLE',
                })}
              </span>
            </div>
          )}
        </div>
        <div className='my-5 d-flex flex-center flex-column flex-row rounded'>
          <div>
            {formIk.values.ug_no_process_reason || formIk.values.pg_no_process_reason ? (
              <>
                <button
                  type='button'
                  onClick={() => {
                    openDialog(
                      translator.formatMessage({
                        id: 'Are_sure_you_want_send_to_next_Level',
                        defaultMessage: 'Are you sure you want to send to the next level?',
                      })
                    );
                  }}
                  disabled={isSendToNextLevel || !organization.code}
                  className='btn btn-primary m-5'
                >
                  {isSendToNextLevel ? (
                    <>
                      <span className='spinner-border spinner-border-sm me-2'></span>
                      {translator.formatMessage({id: 'COMMON.PLEASE_WAIT'})}
                    </>
                  ) : (
                    <span>{translator.formatMessage({id: 'BUTTONS.SENT_TO_NEXT_LEVEL'})}</span>
                  )}
                </button>
                {dialogOpen && (
                  <GenericDialogueBox
                    key={'is-Send-Next-Level'}
                    name={selectedName}
                    open={dialogOpen}
                    onYes={handleYesNextLevel}
                    onNo={handleNo}
                    onClose={handleClose}
                  />
                )}

                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={(e) => {
                    e.preventDefault();
                    formIk.handleSubmit();
                  }}
                  disabled={isUpdatingData}
                >
                  {isUpdatingData ? (
                    <>
                      <span className='spinner-border spinner-border-sm me-2'></span>
                      {translator.formatMessage({id: 'COMMON.PLEASE_WAIT'})}
                    </>
                  ) : (
                    <span>{translator.formatMessage({id: 'BUTTONS.SAVE'})}</span>
                  )}
                </button>
              </>
            ) : (
              <button
                type='button'
                className='btn btn-primary'
                onClick={(e) => {
                  e.preventDefault();
                  formIk.handleSubmit();
                }}
                disabled={isUpdatingData || !organization.code || isDataLoading}
              >
                {isUpdatingData || isDataLoading ? (
                  <>
                    <span className='spinner-border spinner-border-sm me-2'></span>
                    {translator.formatMessage({id: 'COMMON.PLEASE_WAIT'})}
                  </>
                ) : (
                  <span> {translator.formatMessage({id: 'BUTTONS.NEXT'})}</span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TypeOfEvaluation;
