import { IntlShape } from 'react-intl';
import * as yup from 'yup';

export interface ITypeOfEvaluation extends ITypeOfEvalGrad, ITypeOfEvalHs {}
export interface ITypeOfEvalGrad {
  education_level: string;
  ug_no_process_reason: string;
  pg_no_process_reason: string;
  degree_of_eval: number;
  ug_is_additional_credentials: boolean;
  ug_additional_comment: string;
  pg_additional_comment: string;
  pg_is_additional_credentials: boolean;
  type_of_eval: string;
  ug_is_degree_awarded: boolean;
  pg_is_degree_awarded: boolean;
  ug_is_transcript_complete: boolean;
  ug_transcript_incomplete_reason: string;
  pg_is_transcript_complete: boolean;
  pg_transcript_incomplete_reason: string;
  pg_txt: string;
  pg_country: string;
  pg_scale: string;
  ug_txt: string;
  ug_country: string;
  ug_scale: string;
  ug_out_of: number;
  pg_out_of: number;
}
export interface ITypeOfEvalHs {
  education_level: string;
  degree_of_eval: number;
  type_of_eval: string;
  pg_txt: string;
  pg_country: string;
  pg_scale: string;
  ug_txt: string;
  ug_country: string;
  ug_is_transcript_complete: boolean;
  pg_is_transcript_complete: boolean;
  pg_transcript_incomplete_reason: string;
  ug_transcript_incomplete_reason: string;
  ug_no_process_reason: string;
  pg_no_process_reason: string;
  pg_additional_comment: string;
  ug_additional_comment: string;
  ug_scale: string;
  ug_out_of: number;
  pg_out_of: number;
}

export interface IOrganization {
  pk: string;
  sk: string;
  name?: string;
  code: string;
  no_process_reason_list: string[];
  process_reason_list: string[];
  cbc_gr_ug_html: string;
  cbc_gr_ugpg_html: string;
  ge_gr_ug_html: string;
  ge_gr_ugpg_html: string;
  cbc_hs_ugpg_html?: string;
  cbc_hs_ug_html?: string;
  ge_hs_ugpg_html?: string;
  ge_hs_ug_html?: string;
}

export interface IPercentToCgpa {
  country: string;
  grade_scale_type: string;
  column_range: string;
  high_range: number;
  low_range: number;
  scale: string;
  out_of: number;
}

export interface ICourseByCourse {
  cbc: ICbcData;
  ug_country: string;
  pg_country: string;
  ug_scale: string;
  pg_scale: string;
}

export interface IEvalPage extends IEvalUg, IEvalPg, IEvalHsUg, IEvalHsPg {
  eval_given_Name: any;
  eval_family_Name: any;
  cbc: ICbcData;
  sk:string;
  degree_of_eval:number;
  education_level:string;
  ug_txt:string;
  pg_txt:string;
}

export interface IOrganizationTypeOfEval {
  name: string;
  code: string;
  no_process_reason_list: string[];
}

export interface IEvaluatorName {
  givenName: string;
  familyName: string;
};

export interface IUniversityOptions {
  label: string;
  value: string;
  code: string;
  no_process_reason_list: string[];
  process_reason_list: string[];
};

export interface IPersonalIdTableData {
  data: Array<any>;
  setIsTableOpen: React.Dispatch<React.SetStateAction<boolean>>;
  organizationData: IOrganization;
  appidToRestoreData: string;
  setIsRestored: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ICbcUgPg {
  id: string;
  index: number;
  sem: string;
  course: string;
  o_mark: number;
  m_mark: number;
  grade: string;
  native_credit: number;
  us_credit: number;
  us_gpa: number;
  ma: number;
  mb: string;
}

export interface IGradeScale {
  column_range: string
  country: string
  grade_scale_type: string
  grade_description: string
  high_range: number
  low_range: number
  out_of: string
  scale: number
}
export interface ICbcData {
  ug: ICbcUgPg[];
  ug_gpa: number;
  ug_required_credit: number;
  ug_total_o_marks: number;
  ug_total_m_marks: number;
  ug_total_n_credits: number;
  pg: ICbcUgPg[];
  pg_gpa: number;
  pg_required_credit: number;
  pg_total_o_marks: number;
  pg_total_m_marks: number;
  pg_total_n_credits: number;
}
export interface IAppData {
  fast_track_application: boolean;
  cbc: ICbcData;
  ug_us_equivalency: string;
  pg_us_equivalency: string;
  ug_university: string;
  pg_university: string;
  ug_major: string;
  pg_major: string;
  pg_country: string;
  pg_scale: string;
  ug_country: string;
  ug_scale: string;
}

export interface IUniversityMajor {
  university: string; 
  major: string
}

export interface IEvalUg {
  status: number;
  first_name: string;
  last_name: string;
  ug_university: string;
  type_of_eval: string;
  ug_scale: string;
  ug_no_of_repeats: number;
  ug_accreditation: string;
  ug_graduation_year: string;
  ug_gpa: number;
  ug_n_gpa: number;
  ug_country: string;
  ug_gpa_letter_grade: string;
  ug_n_gpa_letter_grade:string;
  ug_credential_name: string;
  ug_admission_req: string;
  ug_college: string;
  ug_us_equivalency: string;
  ug_major: string;
  ug_admission_year: string;
  ug_total_cgpa: string;
  ug_additional_comment: string;
  ug_state: string;
  ug_city: string;
  ug_program_duration: number;
  eval_date: number;
  ug_temp_comments: string;
  eval_by: string;
  review_by: string;
  ug_out_of: number;
}

export interface IEvalPg {
  status: number;
  pg_university: string;
  type_of_eval: string;
  pg_scale: string;
  pg_no_of_repeats: number;
  pg_accreditation: string;
  pg_graduation_year: string;
  pg_gpa: number;
  pg_n_gpa: number;
  pg_country: string;
  pg_gpa_letter_grade: string;
  pg_n_gpa_letter_grade:string;
  pg_credential_name: string;
  pg_admission_req: string;
  pg_college: string;
  pg_us_equivalency: string;
  pg_major: string;
  pg_admission_year: string;
  pg_total_cgpa: string;
  pg_additional_comment: string;
  pg_state: string;
  pg_city: string;
  pg_program_duration: number;
  eval_date: number;
  pg_temp_comments: string;
  eval_by: string;
  review_by: string;
  pg_out_of: number;
}

export interface IEvalHsUg {
  status:number,
  first_name: string;
  last_name: string;
  type_of_eval: string;
  ug_school: string;
  ug_scale: string;
  ug_gpa: number;
  ug_country: string;
  ug_gpa_letter_grade: string;
  ug_additional_comment: string;
  ug_state: string;
  ug_city: string;
  ug_program_duration: number;
  eval_date: number;
  ug_temp_comments: string;
  eval_by: string;
  review_by: string;
  ug_out_of: number;
}

export interface IEvalHsPg {
  first_name: string;
  last_name: string;
  type_of_eval: string;
  pg_school: string;
  pg_scale: string;
  pg_gpa: number;
  pg_country: string;
  pg_gpa_letter_grade: string;
  pg_additional_comment: string;
  pg_state: string;
  pg_city: string;
  pg_program_duration: number;
  eval_date: number;
  pg_temp_comments: string;
  eval_by: string;
  review_by: string;
  pg_out_of: number;
}

export interface IAppEvalUgPgData {
  ug_city: string;
  ug_school: string;
  pg_school: string;
  ug_state: string;
  ug_credential_name: string;
  ug_college: string;
  ug_university: string;
  pg_university: string;
  pg_college: string;
  pg_credential_name: string;
  pg_city: string;
  pg_state: string;
}

export interface IEvalUgComponent {
  isAutoSave: boolean;
  sendToNextLevelApp: () => void;
  isSendToNextLevelLoading: boolean;
  setCbcData: (cbcData: ICbcData) => void;
  goToPreview: (previewData: IEvalUg) => void;
  setPreviewData: (previewData: IEvalUg) => void;
}
export interface IEvalHsComponent {
  isAutoSave: boolean;
  setCbcData: (cbcData: ICbcData) => void;
  goToPreview: (previewData: IEvalHsPg) => void;
  setPreviewData: (previewData: IEvalHsPg) => void;
}

export interface ITranscript {
  appId: string
  organizationCode: string
}

export interface IEvalPgComponent {
  isAutoSave: boolean;
  setCbcData: (cbcData: ICbcData) => void;
  goToPreview: (previewData: IEvalPg) => void;
  setPreviewData: (previewData: IEvalPg) => void;
}


export interface IEvalHighSchoolInDifferentComponent {
  isAutoSave: boolean;
  setIsAutoSavingMain: (isAutoSaving: boolean) => void;
  sendToNextLevelApp: () => void;
  isSendToNextLevelLoading: boolean;
  setCbcData: (cbcData: ICbcData) => void;
  goToPreview: (previewData: IEvalHsUg) => void;
  setPreviewData: (previewData: IEvalHsUg) => void;
}

export const CbcUgPgSchema = (translator: IntlShape) => {
  return yup.array().of(
    yup.object().shape({
      id: yup.string().required(translator.formatMessage({ id: 'VALIDATIONS.APP_ID_IS_REQUIRED' })),
      index: yup.number().required(translator.formatMessage({ id: 'VALIDATIONS.INDEX_IS_REQUIRED' })),
      sem: yup.string().required(translator.formatMessage({ id: 'VALIDATIONS.SEMESTER_IS_REQUIRED' })),
      course: yup.string().required(translator.formatMessage({ id: 'VALIDATIONS.COURSE_NAME_IS_REQUIRED' })),
      o_mark: yup.number().required(),
      m_mark: yup.number().required(),
      grade: yup.string().optional(),
      native_credit: yup.number().required(translator.formatMessage({ id: 'VALIDATIONS.NATIVE_CREDITS_IS_REQUIRED' })),
      us_credit: yup.number().required(translator.formatMessage({ id: 'VALIDATIONS.US_CREDITS_IS_REQUIRED' })),
      us_gpa: yup.number().required(translator.formatMessage({ id: 'VALIDATIONS.US_GPA_IS_REQUIRED' })),
      ma: yup.number().required(translator.formatMessage({ id: 'VALIDATIONS.MA_IS_REQUIRED' })),
      mb: yup.string().required(translator.formatMessage({ id: 'VALIDATIONS.MB_IS_REQUIRED' })),
    })
  );
}
