/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import EvalMainWrapper from './general-evaluation/eval-main-wrapper'
import TypeOfEvaluation from './type-of-evaluation/type-of-evaluation'
import { CourseByCourseEval } from './course-by-course/course-by-course-eval'

const EvaluationRoutingPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route path='/evalMain' element={<EvalMainWrapper />} />
      <Route path='/course-by-course-eval' element={<CourseByCourseEval />} />
      <Route index element={<TypeOfEvaluation />} />
      <Route path='*' element={<Navigate to='/type-of-evaluation' />} />
    </Route>
  </Routes>
)

export {EvaluationRoutingPage}
