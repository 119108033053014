import {IKeyValues} from './common-interface';

// format date (DD-MM-YYYY)
export const getFormattedDate = (unformattedDate: number | string) => {
  const dateObj = new Date(unformattedDate || new Date());
  // get the month in this format of 04, the same for months
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

// get number of days from dates
export const getNumberOfDays = (startDate: Date, endDate: Date): number => {
  if (!startDate || !endDate) return 0;
  if (
    new Date(startDate.setHours(0, 0, 0, 0)).toString() ===
    new Date(endDate.setHours(23, 59, 59, 999)).toString()
  ) {
    return 1;
  }
  const noOfDaysDiff = Math.round(
    Math.abs(
      (new Date(endDate.setHours(23, 59, 59, 999)).getTime() -
        new Date(startDate.setHours(0, 0, 0, 0)).getTime()) /
        (1000 * 60 * 60 * 24)
    )
  );
  return noOfDaysDiff > 0 ? noOfDaysDiff : 0;
};

// return Sentence case
export function convertToSentenceCase(camelCaseString: string) {
  const words = camelCaseString.match(/([A-Z][a-z]*|\d+|[^a-zA-Z0-9]+)/g);
  if (!words) return camelCaseString;
  const sentence = words.join(' ').toLowerCase();
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

// return date and time
export const getFormattedDateAndTime = (currentDate: Date) => {
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedDate = `${day}-${month}-${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  return formattedDate;
};
// compare two objects
export function deepEqual(obj1: IKeyValues, obj2: IKeyValues) {
  if (obj1 === obj2) {
    return true;
  }
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}
