import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import { IGenericDialogueBox } from '../component-interface';

const GenericDialogueBox: React.FC<IGenericDialogueBox> = ({
  name,
  open,
  onClose,
  onYes,
  onNo,
  isLoading = false,
}) => {
  if (!open) return null;

  return (
    <Modal show={open} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{name}?</p>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-between w-100'>
          <Button variant='primary' onClick={onYes}>
            {isLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
                <span className='ms-2'>Please wait...</span>
              </>
            ) : (
              <span>Yes</span>
            )}
          </Button>
          <Button variant='secondary' onClick={onNo}>
            No
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericDialogueBox;
