import {get, post} from '../../auth/core/_requests'
import { IWorkItem, IWorkItemArchiveUserResponse, IWorkItemCounts } from '../approver-list-interface'
import { IResponse } from '../../../../utils/common-interface'
import {ACCEPT_REJECT_WORKITEM, GET_ALL_WORKITEM_COUNTS, GET_WORK_ITEMS_DATA} from '../../../../utils/service-constants'

export const getWorkItemCounts = async (): Promise<IWorkItemCounts> => {
  try {
    const response = await get<IResponse<IWorkItemCounts>>(GET_ALL_WORKITEM_COUNTS)
    return (
      response.data?.success || {
        UserArchive: 0,
        GradeScaleArchive: 0,
        GradeScaleEdit: 0,
        NewGradeScale: 0,
      }
    )
  } catch (error) {
    throw error
  }
}

export const getWorkItems = async (
  workItemType: string,
  lastEvaluatedKey:string | undefined 
): Promise<{
  result: IWorkItemArchiveUserResponse[]
  lastEvaluatedKey:string | undefined
}> => {
  try {
    const response = await post<
      IResponse<{
        result: IWorkItemArchiveUserResponse[]
        lastEvaluatedKey:string | undefined
      }>
    >(GET_WORK_ITEMS_DATA + '/' + workItemType, {
      lastEvaluatedKey: lastEvaluatedKey,
    })
    return response.data?.success || {result: [], lastEvaluatedKey: undefined}
  } catch (error) {
    throw error
  }
}

export const acceptOrRejectWorkItem = async (
  workItemSk: string,
  workItemType: string,
  decision: boolean,
  reason: string
): Promise<IWorkItem[]> => {
  try {
    const response = await post<IResponse<IWorkItem[]>>(ACCEPT_REJECT_WORKITEM + workItemType, {
      workItemSk,
      decision,
      reason,
    })
    return response.data?.success || []
  } catch (error) {
    throw error
  }
}
