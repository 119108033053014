import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import {useDebounce} from '@uidotdev/usehooks';
import {IUserMyTeamResponse} from './my-team-interface';
import {
  archiveUsers,
  fetchAllUsersData,
  searchUsersData,
  updateUserData,
} from './_service/my-teams-services';
import {USER_ATTRIBUTES} from '../../../utils/common-constant';
import { IRowOrgCodes } from './my-team-interface';
import GenericDialogueBox from '../../components/generic-dialogue-box/generic-dialogue-box';
import {REGEX} from '../page-common-constants';
import Chip from '@mui/material/Chip';

const MyTeams = () => {
  const [isEditable, setIsEditable] = useState<null | number>(null);
  const [userData, setUserData] = useState<IUserMyTeamResponse[]>([]);
  const [rowOrgCodes, setRowOrgCode] = useState<IRowOrgCodes>();
  const [allOrgCodes, setAllOrgCodes] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedOrgCodes, setSelectedOrgCodes] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [attribute, setAttribute] = useState<string>('email');
  const [isAllSelected, setAllSelected] = useState(false);
  const [isArchiveUsersSubmitting, setIsArchiveUsersSubmitting] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [prevLastEvaluatedKey, setPrevLastEvaluatedKey] = useState<string | undefined>(
    undefined
  );
  const [autoFocus, setAutoFocus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isMoreUsersLoading, setIsMoreUsersLoading] = useState(false);
  const [prevEvalCapacity, setPreviousEvalCapacity] = useState<number>();
  const [prevReviewCapacity, setPreviousReviewCapacity] = useState<number>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  useEffect(() => {
    setIsLoading(true);
    setIsMoreUsersLoading(true);
    const fetchUsers = async () => {
      try {
        await fetchAllUsers();
      } catch (error: any) {}
      setIsLoading(false);
      setIsMoreUsersLoading(false);
    };
    fetchUsers();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const searchUsers = async () => {
      try {
        setIsSearching(true);
        setIsMoreUsersLoading(() => true);
        setUserData(() => []);
        const res = await searchUsersData(
          attribute.trim(),
          (searchValue && searchValue.trim()) || ''
        );
        setUserData(res.userData);
        const rowOrg: IRowOrgCodes = {};
        res.userData.forEach((user, i) => {
          rowOrg[i] = user.organisation_code;
        });
        setRowOrgCode(() => rowOrg);
        setLastEvaluatedKey(() => undefined);
        setHasMore(!!res.lastEvaluatedKey);
      } catch (error) {
        setIsMoreUsersLoading(() => false);
        setIsSearching(false);
      }
      setIsMoreUsersLoading(false);
      setIsSearching(false);
    };
    if (attribute && searchValue && !isSearching) {
      searchUsers();
    } else if (!attribute || !searchValue) {
      if (!attribute) {
        setAttribute('email');
      }
      setIsMoreUsersLoading(() => true);
      const fetchUsers = async () => {
        setUserData(() => []);
        await fetchAllUsers();
        setIsMoreUsersLoading(() => false);
      };
      fetchUsers();
    }
  // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const fetchAllUsers = async () => {
    try {
      const data = await fetchAllUsersData(lastEvaluatedKey);
      if (data) {
        setUserData(() => data.userData);
        setAllOrgCodes(() => data.allOrgCodes || []);
        const rowOrg: IRowOrgCodes = {};
        data.userData.forEach((user, i) => {
          rowOrg[i] = user.organisation_code;
        });
        setRowOrgCode(() => rowOrg);
        setLastEvaluatedKey(() => data.lastEvaluatedKey);
        setHasMore(() => !!data.lastEvaluatedKey);
      }
    } catch (error) {}
  };

  const openDialog = (name: string) => {
    setSelectedName(name);
    setDialogOpen(true);
  };
  const handleYes = () => {
    handleArchiveUsers();
    handleClose();
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

 
  function DataTable(): JSX.Element {
    function handleInputChange(event: any, index: number) {
      let {name, value} = event.target;
      //setting autofocus for input element
      setAutoFocus(name === 'reviewer_capacity' ? 1 : 2);
      const updatedData = [...userData];
      updatedData[index] = {...updatedData[index], [name]: value.replace(/\D/g, '')};
      setUserData(updatedData);
    }
    function saveData(index: number) {
      if (isEditable == null) {
        setPreviousEvalCapacity(userData[index].evaluator_capacity);
        setPreviousReviewCapacity(userData[index].reviewer_capacity);
      }
      if (isEditable === index) {
        const reviewerCapacity = userData[index].reviewer_capacity
          ? parseInt(userData[index].reviewer_capacity + '')
          : 0;
        const evaluatorCapacity = userData[index].evaluator_capacity
          ? parseInt(userData[index].evaluator_capacity + '')
          : 0;
        const dataToUpdate = {
          sk: userData[index].id,
          reviewer_capacity: reviewerCapacity,
          evaluator_capacity: evaluatorCapacity,
          orgCodes: selectedOrgCodes
        };
        //api call to update data
        if (
          prevEvalCapacity !== userData[index].evaluator_capacity ||
          prevReviewCapacity !== userData[index].reviewer_capacity || 
          selectedOrgCodes.length >= 0
        ) {
          const updateUsersData = async () => {
            try {
              const data = await updateUserData(dataToUpdate);
              if (data) {
                toast.success('Data updated successfully', {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } catch (error: any) {
              toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          };
          updateUsersData();
        }
        setIsEditable(null);
      } else if (isEditable === null) {
        setIsEditable(index);
      } else {
        toast.error('Please save selected row first', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    const renderTableRows = () => {
      if (userData.length === 0 && !isMoreUsersLoading && !isSearching) {
        return (
          <tr>
            <td colSpan={10}>
              <div className='text-center my-auto'>
                <span className='fs-1 fw-bold'>DATA NOT FOUND</span>
              </div>
            </td>
          </tr>
        );
      }
      return userData.map((row, i) => (
        <tr key={i}>
          <td className='p-4'>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='cursor-pointer widget-9-check w-20px h-20px'
                type='checkbox'
                checked={isAllSelected || selectedUsers.includes(row.id)}
                onChange={() => {
                  if (selectedUsers.includes(row.id)) {
                    setSelectedUsers((prev) => prev.filter((userSk) => row.id !== userSk));
                    setAllSelected(false);
                  } else {
                    if (selectedUsers.length === userData.length - 1) {
                      setAllSelected(true);
                    }
                    setSelectedUsers((prev) => [...prev, row.id]);
                  }
                }}
              />
            </div>
          </td>
          <td className='p-4'>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.given_name + ' ' + row.family_name}
            </div>
          </td>
          {/* <td>
              <div className='text-dark fw-bold d-block fs-6'>{row.phone_no}</div>
            </td> */}
          <td>
            <div className='text-dark fw-bold d-block fs-6'>{row.email}</div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>{row.user_code}</div>
          </td>
          <td>
            <div className='text-dark fw-bold'>
            {isEditable !== i ? (
                rowOrgCodes?.[i]?.length ? (
                  rowOrgCodes[i].map((orgCode) => (
                    <Chip key={orgCode} label={orgCode} variant="outlined" size="small" className='me-2 mb-2' />
                  ))
                ) : (
                  <span>No org codes exist</span>
                )
              ) : (
                <Select
                  placeholder='no organization codes'
                  className='border-none'
                  isDisabled={isLoading || isEditable !== i}
                  isLoading={isLoading}
                  menuPortalTarget={document.body}
                  value={rowOrgCodes?.[i]?.map((orgCode) => ({ label: orgCode, value: orgCode }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setRowOrgCode((prev) => ({
                      ...prev,
                      [i]: selectedValues,
                    }));
                    setSelectedOrgCodes(selectedValues.map((code) => code.toLowerCase()));
                  }}
                  isClearable={false}
                  options={allOrgCodes.map((orgCode) => ({ label: orgCode, value: orgCode }))}
                  isMulti
                />
              )}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.review_pending}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.evaluation_pending}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.review_completed}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.evaluation_completed}
            </div>
          </td>
          {isEditable === i ? (
            <>
              <td>
                <input
                  className='form-control mx-0 px-1 text-center fs-6 no-spinners'
                  name='reviewer_capacity'
                  autoFocus={autoFocus === 1 ? true : false}
                  type='number'
                  onKeyDown={(event) => {
                    if (
                      !REGEX.test(event.key) &&
                      ![
                        'Backspace',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                        'ArrowUp',
                        'ArrowDown',
                      ].includes(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  value={row?.reviewer_capacity || 0}
                  onChange={(event) => handleInputChange(event, i)}
                  min={0}
                  required
                />
              </td>
              <td>
                <input
                  className='form-control mx-0 px-1 text-center fs-6 no-spinners'
                  name='evaluator_capacity'
                  type='number'
                  onKeyDown={(event) => {
                    if (
                      !REGEX.test(event.key) &&
                      ![
                        'Backspace',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                        'ArrowUp',
                        'ArrowDown',
                      ].includes(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  key={row?.evaluator_capacity}
                  autoFocus={autoFocus === 2 ? true : false}
                  value={row?.evaluator_capacity || 0}
                  onChange={(event) => handleInputChange(event, i)}
                  min={0}
                  required
                />
              </td>
            </>
          ) : (
            <>
              <td>
                <div className='text-dark fw-bold d-block fs-6'>
                  {row.reviewer_capacity || 0}
                </div>
              </td>
              <td>
                <div className='text-dark fw-bold d-block fs-6'>
                  {row.evaluator_capacity || 0}
                </div>
              </td>
            </>
          )}
          <td>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => saveData(i)}
            >
              {isEditable === i ? (
                <i className='ki-duotone ki-switch fs-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              ) : (
                <i className='ki-duotone ki-pencil fs-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              )}
            </button>
          </td>
        </tr>
      ));
    };

    return (
      <div className='card-body py-3'>
        <div
          className={`table-responsive ${isMoreUsersLoading ? 'overflow-hidden' : 'overflow-auto'}`}
        >
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='align-middle fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='cursor-pointer w-20px h-20px widget-9-check'
                      type='checkbox'
                      style={{width: '20px', height: '20px'}}
                      checked={isAllSelected}
                      onChange={() => {
                        if (isAllSelected) {
                          setSelectedUsers([]);
                        } else {
                          setSelectedUsers(userData.map((user) => user.id));
                        }
                        setAllSelected(!isAllSelected);
                      }}
                    />
                  </div>
                </th>
                <th className='ps-4 min-w-125px'>NAME</th>
                {/* <th className='min-w-125px'>PHONE NO</th> */}
                <th className='min-w-125px'>EMAIL ID</th>
                <th className='min-w-100px'>USER CODE</th>
                <th className='min-w-200px'>ORGANIZATIONS CODE</th>
                <th className='min-w-80px'>REVIEW PENDING</th>
                <th className='min-w-80px'>EVALUATION PENDING</th>
                <th className='min-w-80px'>REVIEW COMPLETED</th>
                <th className='min-w-80px'>EVALUATION COMPLETED</th>
                <th className='min-w-80px'>REVIEW CAPACITY</th>
                <th className='min-w-80px'>EVALUATE CAPACITY</th>
                <th className='min-w-80px rounded-end'>ACTIONS</th>
              </tr>
            </thead>

            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
      </div>
    );
  }
  const handleSearchUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value || '');
  };

  const loadUsers = async () => {
    try {
      if (!isMoreUsersLoading && !isLoading && !isSearching) {
        setIsMoreUsersLoading(true);

        if (prevLastEvaluatedKey !== lastEvaluatedKey) {
          setPrevLastEvaluatedKey(() => lastEvaluatedKey);
          const data = await fetchAllUsersData(lastEvaluatedKey);
          if (data) {
            const dataToSet = userData.concat(data.userData);
            setUserData(() => dataToSet);
            const rowOrg: IRowOrgCodes = {};
            dataToSet.forEach((user, i) => {
              rowOrg[i] = user.organisation_code;
            });
            setRowOrgCode(() => rowOrg);
            setLastEvaluatedKey(() => data.lastEvaluatedKey);
            setHasMore(!!data.lastEvaluatedKey);
          }
        }
      }
    } catch (error) {}
    setIsMoreUsersLoading(false);
  };
  const handleArchiveUsers = async () => {
    try {
      setIsArchiveUsersSubmitting(true);
      const data = await archiveUsers(selectedUsers);
      if (data) {
        toast.success('Request sent to approver for archiving users');
        setSelectedUsers([]);
        setAllSelected(false);
      }
    }  catch (error) { } 
    finally {
     setIsArchiveUsersSubmitting(false);
   }
  };
  return (
    <>
      <div className='card mb-5 mb-xl-8 mt-5'>
        {isLoading && userData?.length === 0 ? (
          <div className='text-center my-auto'>
            <div className='spinner-border h-75px w-75px text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className='card-header border-0 pt-6 d-flex justify-content-between align-items-center'>
            <div className='card-title d-flex flex-wrap gap-2 justify-content-center'>
                <label
                  htmlFor='filter-by'
                  className='form-check-label'
                >
                  Filter By:
                </label>
                <div className='me-2 w-150px'>
                  <Select
                    options={Object.keys(USER_ATTRIBUTES).map((key) => ({label: key, value: key}))}
                    placeholder='Attribute'
                    inputId='filter-by'
                    value={{label: attribute, value: attribute}}
                    onChange={(e) => {
                      setAttribute(e?.value || '');
                      setSearchValue('');  
                    }}
                  />
                </div>
                <div className='d-flex align-items-center position-relative my-1 ml-2'>
                  <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search value'
                    onChange={(e) => handleSearchUsers(e)}
                    value={searchValue}
                  />
                </div>
              </div>
              <div className='card-toolbar d-flex justify-content-around justify-content-lg-end flex-grow-1'>
                <div className='d-flex align-items-center'>
                  {selectedUsers.length > 0 && (
                    <div className='fw-bolder me-5'>
                      <span className='me-2'>{selectedUsers.length}</span> Selected
                    </div>
                  )}
                  <button
                    type='button'
                    className='btn btn-danger'
                    disabled={selectedUsers.length === 0}
                    onClick={(e) => {
                      e.preventDefault();
                      openDialog('Are you sure you want to archive selected users');
                    }}
                  >
                    {isArchiveUsersSubmitting ? (
                      <>
                        Please Wait...
                        <span className='spinner-border spinner-border-sm'></span>
                      </>
                    ) : (
                      <span> Archive Selected</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <GenericDialogueBox
              name={selectedName}
              open={dialogOpen}
              onYes={handleYes}
              onNo={handleClose}
              onClose={handleClose}
            />
            <div className='h-auto'>
              <InfiniteScroll
                dataLength={userData.length}
                next={loadUsers}
                hasMore={hasMore}
                refreshFunction={() => {}}
                loader={<></>}
                endMessage={
                  userData.length !== 0 && (
                    <p className='text-center'>
                      <b>All users data is loaded</b>
                    </p>
                  )
                }
              >
                <DataTable />
                {(isMoreUsersLoading || isSearching) && (
                  <div className='text-center mb-5'>
                    <span className='ml-2'>Please wait...</span>
                    <span className='spinner-border spinner-border-sm'></span>
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyTeams;
