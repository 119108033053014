import {toast} from 'react-toastify';
import {useRef, useState} from 'react';
import Select from 'react-select';
import {feedbackCategoryOptions, maxAllowedFileSize, supportedImageVideoType} from './feedback-constants';
import { IFeedbackDialogueBox, INameValue } from './feedback-interface'
import { sendFeedback } from './_service/feedback-services'
import {Dialog, DialogActions, DialogContent, IconButton} from '@mui/material';

const FeedbackPage: React.FC<IFeedbackDialogueBox> = ({isOpen, onClose}) => {
  const [feedbackCategory, setFeedbackCategory] = useState({label: '', value: ''});
  const [feedbackSubject, setFeedbackSubject] = useState('');
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const feedbackCategoryDropdownOptions = feedbackCategoryOptions;
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef: React.RefObject<HTMLInputElement> = useRef(null);

  // handle input change
  const handleInputChange = (event: {target: INameValue}) => {
    let {name, value} = event.target;
    if (name === 'feedbackSubject') {
      setFeedbackSubject(value);
    } else if (name === 'feedbackDescription') {
      setFeedbackDescription(value);
    }
  };

  // submit feedback on btn click
  async function submitFeedback() {
    setIsLoading(true);
    const formData = new FormData();
    if (file !== null) {
      formData.append('file', file);
    }
    formData.append('category', feedbackCategory.value);
    formData.append('subject', feedbackSubject);
    formData.append('description', feedbackDescription);
    const uploadFeedback = async () => {
      try {
        const data = await sendFeedback(formData);
        if (data) {
          onCloseDialog();
          toast.success('Feedback submitted successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setIsLoading(false);
      } catch (error: any) {
        onCloseDialog();
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    uploadFeedback();
  }

  // clear form btn click
  function clearInputs() {
    setFeedbackCategory({label: '', value: ''});
    setFeedbackSubject('');
    setFeedbackDescription('');
    if (fileInputRef?.current) {
      fileInputRef.current.value = '';
      setFile(null);
    }
    setIsLoading(false);
  }

  // On file select (from the pop up)
  const onFileChange = (event: {target: {files: FileList | null}}) => {
    let fileObj;
    if (event.target.files !== null) {
      fileObj = event.target.files[0];
    }
    if (fileObj) {
      if (fileObj.size < maxAllowedFileSize) {
        // Update the file
        setFile(fileObj);
      } else {
        setFile(null);
        toast.error('Max file size allowed: 5 MB', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  // on close icon
  const onCloseDialog = () => {
    onClose();
    clearInputs();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseDialog}
      maxWidth='md'
      PaperProps={{
        style: {
          position: 'absolute',
          right: 0,
          bottom: 0,
        },
      }}
    >
      <div className='card w-100 pb-10'>
        <div className='px-5 py-3 border-bottom border-1 border-secondary d-flex align-items-center'>
          <h1 className='text-start fs-3 text-dark my-5'>Send us your enquiries</h1>
          <IconButton
            aria-label='close'
            className='justify-content-end w-35px ms-auto'
            onClick={onCloseDialog}
          >
            <div className="fa fa-close fa-1x"></div>
          </IconButton>
        </div>
        <form className='card mx-auto my-5'>
          <DialogContent>
            <div className='mb-3'>
              <label className='form-label required-field'>Category:</label>
              <Select
                options={feedbackCategoryDropdownOptions}
                value={feedbackCategory}
                onChange={(e: {label: string; value: string} | null) => {
                  setFeedbackCategory({label: e?.label || '', value: e?.value || ''});
                }}
                required
              />
            </div>
            <div className='mb-3'>
              <label className='form-label required-field'>Subject:</label>
              <input
                className='form-control p-2'
                placeholder='Subject of your feedback'
                name='feedbackSubject'
                type='text'
                value={feedbackSubject || ''}
                onChange={(event) => handleInputChange(event)}
                required
              />
            </div>
            <div className='mb-3'>
              <label className='form-label required-field'>Description:</label>
              <textarea
                className='form-control p-2'
                rows={4}
                placeholder='Please describe a little bit more about your feedback'
                name='feedbackDescription'
                value={feedbackDescription || ''}
                onChange={(event) => handleInputChange(event)}
                required
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Upload supported Image/Video file:</label>
              <input
                type='file'
                ref={fileInputRef}
                className='my-3 form-control p-3 bg-gray-100i'
                accept={supportedImageVideoType}
                onChange={onFileChange}
              />
            </div>
          </DialogContent>
          <div className='d-flex justify-content-around'>
            <DialogActions>
              <button
                className='btn btn-sm btn-primary'
                type='button'
                disabled={
                  feedbackCategory.value === '' ||
                  feedbackSubject === '' ||
                  feedbackDescription === '' ||
                  isLoading
                }
                onClick={submitFeedback}
              >
                {!isLoading && <span>Submit</span>}
                {isLoading && (
                  <>
                    <span>Please wait..</span>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </>
                )}
              </button>
              <button
                className='btn btn-sm btn-secondary ms-3'
                type='button'
                disabled={
                  feedbackCategory.value === '' ||
                  feedbackSubject === '' ||
                  feedbackDescription === '' ||
                  isLoading
                }
                onClick={clearInputs}
              >
                Clear form
              </button>
            </DialogActions>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default FeedbackPage;
