import React from 'react'
import { IRadioInputGroup } from './radio-interface'

const RadioInputGroup: React.FC<IRadioInputGroup> = ({
  id,
  name,
  checked,
  onChange,
  options,
  disabled,
  onBlur,
}) => (
  <div className="d-flex flex-wrap mb-5">
    {options.map((option) => (
      <div className='form-check my-3' key={option.label}>
        <input
          type='radio'
          id={`${id}-${option.label}`}
          name={name}
          className='form-check-input'
          disabled={disabled}
          checked={checked === option.value}
          onBlur={onBlur}
          onChange={onChange}
        />
        <label className='form-check-label me-10'>{option.label}</label>
      </div>
    ))}
  </div>
)

export default RadioInputGroup
