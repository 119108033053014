import * as Yup from 'yup'

export interface ICreateUserInfo {
    emp_code: string
    organisation: string[]
    email: string
    first_name: string
    last_name: string
    role: string
    profile_pic?: File
    reviewer_capacity: number
    evaluator_capacity: number
  }
  
export const initialValues = {
    emp_code: '',
    organisation: [],
    email: '',
    first_name: '',
    last_name: '',
    role: '',
    profile_pic: undefined,
    reviewer_capacity: 0,
    evaluator_capacity: 0,
  }

export const CreateUserSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('First name is required'),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    last_name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Last name is required'),
    emp_code: Yup.string().required('Employee code is required'),
    organisation: Yup.array()
      .of(Yup.string())
      .required('Organization is required')
      .test('atLeastOne', 'Select at least one organization', (value) => value && value.length > 0),
    role: Yup.string()
      .oneOf(['Admin', 'Team Leader', 'Moderator'], 'Invalid Role')
      .min(1, 'Select at least one role')
      .required('Role is required'),
    evaluator_capacity: Yup.number().min(0).required(),
    reviewer_capacity: Yup.number().min(0).required(),
    profile_pic: Yup.mixed().nullable(),
  })
  
  export const employeeRoleDropdown = [
    {label: 'Admin', value: 'Admin'},
    {label: 'Team Leader', value: 'Team Leader'},
    {label: 'Moderator', value: 'Moderator'},
  ];

  export const supportedImageTypeForCreateUser = '.jpg, .jpeg, .png, .svg'