import { IResponse } from "../../../../utils/common-interface";
import { APPLICATIONS_CHECK, GET_ALL_APP_LIST, SEARCH_APP_IDS, UPDATE_APP_PRIO_CSV, UPDATE_PRIO_APP } from "../../../../utils/service-constants";
import { post } from "../../auth/core/_requests";
import { IAppPriorityRes, ICheckAppComplete } from "../application-priority-interface";


export const fetchAllAppList = async (lastEvaluatedKey: string | undefined, id: string) => {
  try {
    const response = await post<
      IResponse<{
        appData: IAppPriorityRes[];
        lastEvaluatedKey: string;
      }>
    >(GET_ALL_APP_LIST, {lastEvaluatedKey: lastEvaluatedKey, id});

    return (
      response.data.success || {
        appData: [],
        lastEvaluatedKey: undefined,
      }
    );
  } catch (error) {
    throw error;
  }
};

export const checkCsvFileApps = async (data: string[]) => {
  try {
    const response = await post<IResponse<{errors: ICheckAppComplete[]; data: IAppPriorityRes[]}>>(
      APPLICATIONS_CHECK,
      {data: data}
    );
    return response.data.success;
  } catch (error) {
    throw error;
  }
};

export const updateData = async (id: string, priority: string) => {
  try {
    const response = await post<IResponse<string[]>>(UPDATE_PRIO_APP + id, {priority});
    return response.data?.success || [];
  } catch (error) {
    throw error;
  }
};

export const checkUploadedAppIds = async (data: string[]) => {
  try {
    const response = await post<IResponse<string []>>(UPDATE_APP_PRIO_CSV, {data: data});
    return response.data?.success || [];
  } catch (error) {
    throw error;
  }
};

export const searchAppIds = async (id: string, lastEvaluatedKey: string | undefined) => {
  try {
    const response = await post<
      IResponse<{
        appData: IAppPriorityRes[];
        lastEvaluatedKey: string;
      }>
    >(SEARCH_APP_IDS + id, {lastEvaluatedKey: lastEvaluatedKey});
    return (
      response.data.success || {
        appData: [],
        lastEvaluatedKey: undefined,
      }
    );
  } catch (error) {
    throw error;
  }
};
