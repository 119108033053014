import {useState, useEffect, useCallback, useRef} from 'react';
import Select from 'react-select';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {getValueLocalStorage, setValueLocalStorage} from '../../auth/core/_requests';
import {toast} from 'react-toastify';
import {CBC, DEFAULT_COMMENTS, US_DEGREE_EQU_OPTIONS_LIST, getChanges, initialEvalPgValues} from './general-evaluation-constants';
import {EvalPgSchema} from './general-evaluation-constants';
import DialogBoxWithInput from '../../../components/dialog-box-with-input/dialog-box-with-input';
import {useAuth} from '../../auth';
import { useIntl } from 'react-intl';
import { autoSaveCredentialEvalData } from '../../../../utils/common-services';
import { ICredEvalLocalStorageData, IKeyValues } from '../../../../utils/common-interface';
import { ILableValue } from '../../page-common-interface';
import EditableSelect from '../../../components/editable-select/editable-select';
import { commonConstant, customStylesSelect, yearOptions } from '../../../../utils/common-constant';
import { getFormattedDate } from '../../../../utils/utils';
import { IAppEvalUgPgData, IEvalPg, IEvalPgComponent } from '../evaluation-interfaces';
import { dropdownData, fetchGeneralEvalDbData, updatePgData } from '../_service/type-of-evaluation-services';
import Transcript from '../../../components/transcript/transcript';

const EvalPostGraduate: React.FC<IEvalPgComponent> = ({
  isAutoSave,
  setCbcData,
  goToPreview,
  setPreviewData,
}) => {
  const translator = useIntl();
  const formIk = useFormik({
    initialValues: initialEvalPgValues,
    validationSchema: EvalPgSchema(translator),
    onSubmit: () => {},
  });
  const navigate = useNavigate();
  const {currentUser} = useAuth();
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const localStorageData = getValueLocalStorage(commonConstant.credEvalData) || '';
  const credEvalData: ICredEvalLocalStorageData | undefined =
    localStorageData.length > 0 ? JSON.parse(localStorageData) : undefined;
  const [dataFromApi, setDataFromApi] = useState<IEvalPg>({} as IEvalPg);
  const [isLoading, setIsLoading] = useState(false);
  const [colleges, setColleges] = useState<ILableValue[]>([]);
  const [universities, setUniversities] = useState<ILableValue[]>([]);
  const [cities, setCities] = useState<ILableValue[]>([]);
  const [states, setStates] = useState<ILableValue[]>([]);
  const [credentials, setCredentials] = useState<ILableValue[]>([]);
  const [isPgEvaluation, setIsPgEvaluation] = useState<boolean>(
    credEvalData?.isPgEvaluation ? true : false
  );
  const [isDropDownLoading, setIsDropDownLoading] = useState<boolean>(false);
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
  const [additionalComments, setAdditionalComments] = useState<ILableValue[]>(DEFAULT_COMMENTS(translator));

  // Effect to handle auto-saving when the queue changes
  const autoSaveData = async (dataToUpdate: IEvalPg) => {
    try {
      if (isAutoSaving || !formIk.values.type_of_eval || !isAutoSave) {
        return;
      }
      const localStorage = getValueLocalStorage(commonConstant.credEvalData) || '';
      const credEval: ICredEvalLocalStorageData | undefined =
      localStorage.length > 0 ? JSON.parse(localStorage) : undefined;
      setIsAutoSaving(() => true);
      const changes: IKeyValues = {};
      Object.entries(dataToUpdate).forEach(([key, value]) => {
        if (dataFromApi[key as keyof IEvalPg] !== value)
          changes[key] = typeof value === 'string' ? value.trim() : value;
      });
      if (Object.keys(changes).length === 0) {
        setIsAutoSaving(false);
        return;
      }
      const response = await autoSaveCredentialEvalData(
        credEval?.appIdWithOrgCode || '',
        changes,
        commonConstant.typeOfEvaluation
      );
      if (response) {
        setDataFromApi((prevData) => ({
          ...prevData,
          ...response,
        }));
        formIk.setValues((prev) => ({
          ...prev,
          ...response,
        }));
      }
    } catch (err) { } 
    finally {
      setIsAutoSaving(() => false);
    }
  };

  const useDebounce = (callback: (args: any) => void, delay: number) => {
    const timeoutRef = useRef<any>(null);
    const debounceTimeout = useCallback(
      (args: any) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          callback(args);
        }, delay);
      },
      [callback, delay]
    );
    return debounceTimeout;
  };

  const debounceValue = useDebounce(autoSaveData, 3000);
  // Effect to handle auto-saving when the queue changes
  useEffect(() => {
    if (!isAutoSaving && formIk.values.type_of_eval && isAutoSave) {
      debounceValue(formIk.values);
    }
  // eslint-disable-next-line
  }, [debounceValue, isAutoSave]);

  // Patch value to the formIk from Api
  useEffect(() => {
    const localStorage = getValueLocalStorage(commonConstant.credEvalData) || '';
    const credEval: ICredEvalLocalStorageData | undefined =
      localStorage.length > 0 ? JSON.parse(localStorage) : undefined;
    const fetchDataFromAPI = async () => {
      try {
        const data = await fetchGeneralEvalDbData(credEval?.appIdWithOrgCode || '');
        if (data) {
          const resultData: IEvalPg = {
            status: data.status || 0,
            pg_university: data.pg_university || '',
            type_of_eval: data.type_of_eval || '',
            pg_scale: data.pg_scale || '',
            pg_country: data.pg_country || '',
            pg_no_of_repeats: data.pg_no_of_repeats,
            pg_accreditation: data.pg_accreditation || '',
            pg_graduation_year: data.pg_graduation_year || '',
            pg_gpa: data.pg_gpa || 0,
            pg_n_gpa: data.pg_n_gpa || 0,
            pg_gpa_letter_grade: data.pg_gpa_letter_grade || '',
            pg_n_gpa_letter_grade: data.pg_n_gpa_letter_grade || '',
            pg_credential_name: data.pg_credential_name || '',
            pg_admission_req: data.pg_admission_req || '',
            pg_college: data.pg_college || '',
            pg_us_equivalency: data.pg_us_equivalency || '',
            pg_major: data.pg_major || '',
            pg_admission_year: data.pg_admission_year || '',
            pg_total_cgpa: data.pg_total_cgpa || '',
            pg_additional_comment: data.pg_additional_comment || '',
            pg_state: data.pg_state || '',
            pg_city: data.pg_city || '',
            pg_program_duration: data.pg_program_duration,
            eval_date: data.eval_date,
            pg_temp_comments: data.pg_temp_comments || '',
            eval_by: data.eval_by || currentUser?.user_code || '',
            review_by: data.review_by || currentUser?.user_code || '',
            pg_out_of: data.pg_out_of || 0,
          };
          formIk.setValues({
            ...resultData,
            pg_no_of_repeats: !data.pg_no_of_repeats ? 0 : data.pg_no_of_repeats, // setting 0 when undefined
            pg_program_duration: !data.pg_program_duration ? 0 : data.pg_program_duration,
          });
          setDataFromApi(resultData);
          setPreviewData(data);
          setCbcData(data.cbc);
        }
      } catch (error) {}
    };
    const fetchAllDropDownData = async () => {
      try {
        setIsDropDownLoading(true);
        const responseData: IAppEvalUgPgData[] = await dropdownData();   
        const processResponse = (key: string): ILableValue[] => {
          const uniqueItems = new Set<string>();
          responseData?.forEach(item => {
            const resValue = (item as IKeyValues)[key];
            if (resValue) {
              uniqueItems.add(resValue!);
            }
          });
          return Array.from(uniqueItems).map(item => ({ label: item, value: item }));
        };
        setColleges(processResponse('pg_college'));
        setUniversities(processResponse('pg_university'));
        setCities(processResponse('pg_city'));
        setStates(processResponse('pg_state'));
        setCredentials(processResponse('pg_credential_name'));
        setIsDropDownLoading(false);
      } catch (error) {
        setIsDropDownLoading(false);
      }
    };
    fetchDataFromAPI();
    fetchAllDropDownData();
  // eslint-disable-next-line
  }, []);

  // navigate to url
  const handleChangeNavigation = async (navigateTo: string) => {
    await onSaveClick();
    navigate(navigateTo);
  };
  
  // on save click handle data
  const onSaveClick = async (temp_comments?: string) => {
    setIsLoading(true);
    const changes = getChanges<IEvalPg>(dataFromApi, formIk.values);

    //add temp_comments
    if (temp_comments && dataFromApi.pg_temp_comments !== temp_comments)
      changes['pg_temp_comments'] = temp_comments;
    if (!formIk.values.eval_date) changes['eval_date'] = new Date().getTime();
    if (Object.keys(changes).length === 0) {
      if (!toast.isActive('save-success'))
        toast.success(translator.formatMessage({ id: 'COMMON.NO_CHANGES_TO_SAVE' }), {toastId: 'save-success'});
      setIsLoading(false);
      return;
    }
    try {
      const response = await updatePgData(credEvalData?.appIdWithOrgCode || '', changes);
      if (response) {
        if (!toast.isActive('save-success'))
          toast.success(translator.formatMessage({ id: 'COMMON.MESSAGE.SUCCESSFULLY_UPDATED_DATA' }), {toastId: 'save-success'});

        setDataFromApi((prevData) => {
          return {
            ...prevData,
            ...response,
          };
        });
        formIk.setValues((prev) => {
          return {
            ...prev,
            ...response,
          };
        });
        setPreviewData(response);
      } else {
        toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG' }));
      }
    } catch (error) {}
    setIsLoading(false);
  };

  // set the data in formIk
  const handleInputChange = (event: {name: string; value: any}) => {
    const {name, value} = event;
    formIk.setFieldValue(name, value);
  };

  // on click of save button in popup
  function handleCommentDialogueClose() {
    setIsCommentDialogOpen(false);
  }

  // on click of close button in re eval popup
  async function handleCommentDialogueSave(inputData?: string) {
    await onSaveClick(inputData);
  }

  // on route navigate
  const routeChange = async () => {
    setValueLocalStorage(
      commonConstant.credEvalData,
      JSON.stringify({
        ...credEvalData,
        isPgEvaluation: !isPgEvaluation,
      })
    );
    setIsPgEvaluation(!isPgEvaluation);
    await onSaveClick();
    navigate('/type-of-evaluation/evalMain?page=evalUg');
  };

  return (
    <div>
      <div className='d-flex flex-column  flex-xl-row flex-row-fluid mt-3'>
        <div className='card col-12 col-sm-12 col-xl-4 p-10 m-1'>
          <div className='form-group row mt-2'>
            <label htmlFor='appId' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.APPLICATION_IDS' })}:
            </label>
            <div className=''>
              <input
                id='appId'
                readOnly
                value={credEvalData?.appId}
                placeholder={ translator.formatMessage({ id: 'INPUT.APPLICATION_IDS' })}
                className='w-100 ps-xl-3 form-control'
                disabled
              />
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label className=' ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.COLLAGE' })} :</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_college'
              placeholder={translator.formatMessage({ id: 'INPUT.SELECT_COLLAGE' })}
              formIkData={formIk.values.pg_college}
              option={colleges}
              setOptions={setColleges}
              formik={formIk}
              alertData={formIk.touched.pg_college}
              alertMessage={formIk.errors.pg_college}
            />
          </div>
          <div className='form-group row mt-2'>
            <label className=' ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.UNIVERSITY' })} :</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_university'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_UNIVERSITY' }) }
              formIkData={formIk.values.pg_university}
              option={universities}
              setOptions={setUniversities}
              formik={formIk}
              alertData={formIk.touched.pg_university}
              alertMessage={formIk.errors.pg_university}
            />
          </div>
          <div className='form-group row mt-2'>
            <label className=' ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.CITY' })}:</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_city'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_CITY' })}
              formIkData={formIk.values.pg_city}
              option={cities}
              setOptions={setCities}
              formik={formIk}
              alertData={formIk.touched.pg_city}
              alertMessage={formIk.errors.pg_city}
            />
          </div>
          <div className='form-group row mt-2'>
            <label className=' ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.STATE' })} :</label>
            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_state'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_STATE' })}
              formIkData={formIk.values.pg_state}
              option={states}
              setOptions={setStates}
              formik={formIk}
              alertData={formIk.touched.pg_state}
              alertMessage={formIk.errors.pg_state}
            />
          </div>
          <div className='form-group row mt-2'>
            <label className=' ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.CREDENTIAL_NAME' })}:</label>
            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_credential_name'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_CREDENTIAL_NAME' })}
              formIkData={formIk.values.pg_credential_name}
              option={credentials}
              setOptions={setCredentials}
              formik={formIk}
              alertData={formIk.touched.pg_credential_name}
              alertMessage={formIk.errors.pg_credential_name}
            />
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='pg_major' className='form-check-label  ps-3 required-field '>
            { translator.formatMessage({ id: 'INPUT.MAJOR' })}:
            </label>
            <div className='w-100'>
              <input
                id='pg_major'
                name='pg_major'
                disabled={isDropDownLoading}
                onBlur={(e) => {
                  formIk.handleBlur(e);
                }}
                placeholder={ translator.formatMessage({ id: 'INPUT.MAJOR' })}
                value={formIk.values.pg_major}
                onChange={(e) => handleInputChange({name: 'pg_major', value: e.target.value})}
                className='w-100 ps-xl-3 form-control'
              />
              {formIk.touched.pg_major && formIk.errors.pg_major && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_major}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label className=' ps-3 form-check-label'>{ translator.formatMessage({ id: 'INPUT.ADMISSION_YEAR' })}:</label>
            <div className='w-100'>
              <Select
                placeholder={ translator.formatMessage({ id: 'INPUT.ADMISSION_YEAR_FORMAT' })}
                isSearchable={true}
                inputId='pg_admission_year'
                name='pg_admission_year'
                isDisabled={isDropDownLoading}
                value={{
                  label: formIk.values.pg_admission_year,
                  value: formIk.values.pg_admission_year,
                }}
                onBlur={formIk.handleBlur}
                onChange={(e) => {
                  handleInputChange({name: 'pg_admission_year', value: e?.value});
                }}
                options={yearOptions}
              />
              {formIk.touched.pg_admission_year && formIk.errors.pg_admission_year && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_admission_year}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label className=' ps-3 form-check-label'>{ translator.formatMessage({ id: 'INPUT.GRADUATING_YEAR' })}:</label>
            <div className='w-100'>
              <Select
                placeholder={ translator.formatMessage({ id: 'INPUT.GRADUATING_YEAR_FORMAT' })}
                isSearchable={true}
                inputId='pg_graduation_year'
                name='pg_graduation_year'
                isDisabled={isDropDownLoading}
                value={{
                  label: formIk.values.pg_graduation_year,
                  value: formIk.values.pg_graduation_year,
                }}
                onBlur={formIk.handleBlur}
                onChange={(e) => {
                  handleInputChange({name: 'pg_graduation_year', value: e?.value});
                }}
                options={yearOptions}
              />
              {formIk.touched.pg_graduation_year && formIk.errors.pg_graduation_year && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_graduation_year}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='pg_program_duration' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.DURATION_OF_PROGRAM' })} :
            </label>
            <div className='w-100'>
              <input
                id='pg_program_duration'
                type='number'
                min='0'
                name='pg_program_duration'
                disabled={isDropDownLoading}
                placeholder={ translator.formatMessage({ id: 'INPUT.DURATION_OF_PROGRAM' })}
                value={formIk.values.pg_program_duration}
                onChange={(e) =>
                  handleInputChange({
                    name: 'pg_program_duration',
                    value: parseInt('' + e.target.value),
                  })
                }
                onBlur={(e) => {
                  formIk.handleBlur(e);
                }}
                className='w-100 ps-3 form-control'
              />
              {formIk.touched.pg_program_duration && formIk.errors.pg_program_duration && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_program_duration}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='totalcgpa' className=' ps-3 form-check-label required-field'>
            { translator.formatMessage({ id: 'INPUT.TOTAL_CGPA' })} :
            </label>
            <div className='w-100'>
              <input
                id='pg_total_cgpa'
                type={
                  credEvalData?.typeOfEval === 'cbc' || 
                  credEvalData?.gradeScaleData?.[
                    isPgEvaluation ? 'pgData' : 'ugData'
                  ]?.[0]?.column_range?.split(',')?.length === 1
                    ? 'text'
                    : 'number'
                }
                step='0.01'
                placeholder={ translator.formatMessage({ id: 'INPUT.TOTAL_CGPA' })}
                name='pg_total_cgpa'
                disabled={isDropDownLoading}
                value={formIk.values.pg_total_cgpa}
                onChange={(e) => handleInputChange({name: 'pg_total_cgpa', value: e.target.value})}
                onBlur={(e) => {
                  formIk.handleBlur(e);
                }}
                className='w-100 ps-xl-3 form-control'
              />
              {formIk.touched.pg_total_cgpa && formIk.errors.pg_total_cgpa && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_total_cgpa}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='noofrepeats' className=' ps-3 form-check-label required-field'>
            { translator.formatMessage({ id: 'INPUT.NO_OF_REPEATS' })}:
            </label>
            <div className='w-100'>
              <input
                id='pg_no_of_repeats'
                type='number'
                min='0'
                placeholder={ translator.formatMessage({ id: 'INPUT.NO_OF_REPEATS' })}
                disabled={isDropDownLoading}
                name='pg_no_of_repeats'
                value={formIk.values.pg_no_of_repeats}
                onChange={(e) =>
                  handleInputChange({
                    name: 'pg_no_of_repeats',
                    value: parseInt('' + e.target.value),
                  })
                }
                onBlur={(e) => {
                  formIk.handleBlur(e);
                }}
                className='w-100 ps-xl-3 form-control'
              />
              {formIk.touched.pg_no_of_repeats && formIk.errors.pg_no_of_repeats && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_no_of_repeats}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label className=' ps-3 form-check-label'>{ translator.formatMessage({ id: 'INPUT.US_DEGREE_EQUIVALENCY' })} :</label>
            <div className='w-100'>
              <Select
                inputId='pg_us_equivalency'
                name='pg_us_equivalency'
                placeholder={ translator.formatMessage({ id: 'INPUT.US_DEGREE_EQUIVALENCY' })}
                isDisabled={isDropDownLoading}
                styles={customStylesSelect}
                isSearchable={true}
                value={{
                  label: formIk.values.pg_us_equivalency,
                  value: formIk.values.pg_us_equivalency,
                }}
                onBlur={formIk.handleBlur}
                onChange={(e) => {
                  handleInputChange({
                    name: 'pg_us_equivalency',
                    value: e?.value || '',
                  });
                }}
                options={US_DEGREE_EQU_OPTIONS_LIST(translator)}
              />
              {formIk.touched.pg_us_equivalency && formIk.errors.pg_us_equivalency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_us_equivalency}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='pg_accreditation' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.ACCREDITATION' })} :
            </label>
            <div className='w-100'>
              <Select
                inputId='pg_accreditation'
                placeholder={ translator.formatMessage({ id: 'INPUT.ACCREDITATION' })} 
                isDisabled={isDropDownLoading}
                options={[
                  {label: translator.formatMessage({ id: 'INPUT.YES' }), value: "Yes" },
                  {label: translator.formatMessage({ id: 'INPUT.NO' }), value: "No" },
                ]}
                value={{
                  label: formIk.values.pg_accreditation,
                  value: formIk.values.pg_accreditation,
                }}
                onBlur={formIk.handleBlur}
                onChange={(e) => {
                  handleInputChange({name: 'pg_accreditation', value: e?.value || ''});
                }}
                name='pg_accreditation'
              />
              {formIk.touched.pg_accreditation && formIk.errors.pg_accreditation && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_accreditation}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='pg_additional_comment' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.ADDITION_COMMENTS' })} :
            </label>
            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_additional_comment'
              placeholder={ translator.formatMessage({ id: 'INPUT.ADDITION_COMMENTS' })}
              formIkData={formIk.values.pg_additional_comment}
              option={additionalComments}
              setOptions={setAdditionalComments}
              formik={formIk}
              alertData={formIk.touched.pg_additional_comment}
              alertMessage={formIk.errors.pg_additional_comment}
            />
          </div>
        </div>
        <DialogBoxWithInput
          dynamicData={{
            title: translator.formatMessage({ id: 'COMMON.MESSAGE.ARE_YOU_SURE_YOU_WANT_TO_SAVE_ANY_COMMENTS' }),
            inputData: formIk.values.pg_temp_comments,
            inputLabel: translator.formatMessage({ id: 'INPUT.COMMENTS' }),
            inputPlaceHolder: translator.formatMessage({ id: 'INPUT.ENTER_COMMENTS' }),
            isLoading: isLoading,
            leftButton: translator.formatMessage({ id: 'BUTTONS.CLOSE' }),
            rightButton: translator.formatMessage({ id: 'BUTTONS.SAVE' }),
          }}
          onClose={handleCommentDialogueClose}
          onSubmit={handleCommentDialogueSave}
          isOpen={isCommentDialogOpen}
        ></DialogBoxWithInput>

        <Transcript
          appId={credEvalData?.appId || ''}
          organizationCode={credEvalData?.organizationCode || ''}
        />

        <div className='card p-10 m-1'>
          <div className=' ps-xl-3 pe-xl-3'>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='pg_country'
                  readOnly
                  value={formIk.values.pg_country}
                  placeholder={ translator.formatMessage({ id: 'INPUT.COUNTRY' })}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='pg_gpa'
                  readOnly
                  placeholder={ translator.formatMessage({ id: 'INPUT.GPA' })}
                  value={formIk.values.pg_gpa}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='pg_gpa_letter_grade'
                  readOnly
                  placeholder={ translator.formatMessage({ id: 'INPUT.GPA_LETTER_GRADE' })}
                  value={formIk.values.pg_gpa_letter_grade}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='pg_admission_req'
                  placeholder={ translator.formatMessage({ id: 'INPUT.ADDITIONAL_REQUIREMENTS' })}
                  name='pg_admission_req'
                  value={formIk.values.pg_admission_req}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  type='text'
                  id='pg_eval_date'
                  placeholder={ translator.formatMessage({ id: 'INPUT.EVALUATION_DATE' })}
                  value={getFormattedDate(formIk.values.eval_date || Date.now())}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='pg_eval_by'
                  placeholder={ translator.formatMessage({ id: 'INPUT.EVALUATOR_NAME' })}
                  value={`${formIk.values.eval_by}`}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-center flex-column flex-md-row flex-row-fluid flex-sm-column flex-wrap flex-xl-row mt-10 rounded'>
        <button
          type='button'
          onClick={() => {
            handleChangeNavigation('/type-of-evaluation');
          }}
          className='btn btn-primary m-5'
        >
          { translator.formatMessage({ id: 'INPUT.TYPES_OF_EVALUATION' })}
        </button>

        <button
          type='button'
          onClick={(e) => goToPreview(formIk.values)}
          className='btn btn-primary m-5'
        >
          <i className='bi bi-eye'></i>
          { translator.formatMessage({ id: 'BUTTONS.PREVIEW' })}
        </button>
        <button
          type='button'
          className='btn btn-primary m-5'
          disabled={isLoading}
          onClick={() => {
            formIk.handleSubmit();
            if (formIk.isValid) {
              const changes = getChanges<IEvalPg>(dataFromApi, formIk.values);
              if (Object.keys(changes).length === 0) {
                setIsCommentDialogOpen(false);
                onSaveClick();
              } else {
                setIsCommentDialogOpen(true);
              }
            } else {
              toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.PLEASE_FILL_REQUIRED_FIELDS' }));
            }
          }}
        >
          {isLoading ? (
            <>
              <span className='spinner-border spinner-border-sm'></span>
              { translator.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
            </>
          ) : (
            <span>{ translator.formatMessage({ id: 'BUTTONS.SAVE' })}</span>
          )}
        </button>
        {credEvalData?.degreeOfEval === 2 && (
          <button type='button' onClick={routeChange} className='btn btn-primary m-5'>
            { translator.formatMessage({ id: 'BUTTONS.EVALUATE_UG' })}
          </button>
        )}

        {(credEvalData?.typeOfEval || '') === CBC && (
          <button
            type='button'
            className='btn btn-primary m-5'
            onClick={() => {
              handleChangeNavigation('/type-of-evaluation/course-by-course-eval?page=evalPg');
            }}
          >
            { translator.formatMessage({ id: 'BUTTONS.COURSE_BY_COURSE' })}
          </button>
        )}
      </div>
    </div>
  );
};

export default EvalPostGraduate;
