import React, {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import { IPersonalIdTableData} from '../evaluation-interfaces';
import {
  ORG_APP_ID_PATTERN,
  ORG_CODE_CODE_WITH_APPID_PATTERN,
} from '../../my-work/my-work-constants';
import {fetchGeneralEvalDbData, updateApplicationByPersonalId} from '../_service/type-of-evaluation-services';
import GenericDialogueBox from '../../../components/generic-dialogue-box/generic-dialogue-box';
import DialogBox from '../../../components/dialogue-box/dialogue-box';
import { getHtml } from '../html-output';
import Loader from '../../../components/loader/loader';
import { useIntl } from 'react-intl';

const customModalStyles = {
  content: {
    transform: 'translate(-50%, -50%)',
  },
};

const PersonalIdData = ({
  data,
  setIsTableOpen,
  organizationData,
  appidToRestoreData,
  setIsRestored,
}: IPersonalIdTableData) => {
  // const [cbcData] = useState<ICbcData>({} as ICbcData);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [pdfHtml, setPdfHtml] = useState<string>('');
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [appidToFetchDataFrom, setAppidToFetchDataFrom] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const translator = useIntl();

const fetchGeneralEvolutionData = async (appidWithOrg: string) => {
  try {
    setLoading(true);
    const generalEvalData = await fetchGeneralEvalDbData(appidWithOrg);
    const credEvalData = {
      ...generalEvalData,
      organizationName: organizationData.name || '',
      organizationCode: organizationData?.code?.toLowerCase(),
      appId: generalEvalData.sk.match(ORG_APP_ID_PATTERN)?.[0] || '',
      appIdWithOrgCode: generalEvalData.sk.match(ORG_CODE_CODE_WITH_APPID_PATTERN)?.[1] || '',
      degreeOfEval: generalEvalData.degree_of_eval,
      educationLevel: generalEvalData.education_level,
      typeOfEval: generalEvalData.type_of_eval,
      pgTxt: generalEvalData.pg_txt,
      ugTxt: generalEvalData.ug_txt,
    };
    const html = await getHtml(organizationData, generalEvalData?.cbc, generalEvalData, credEvalData);
    setPdfHtml(() => html);
    setDialogOpen(() => true);
    setLoading(() => false);
  } catch (e) { } 
  finally {
    setLoading(() => false);
  }
};

  // close dialog box for html
  const closeDialog = () => {
    setDialogOpen(false);
  };

  // update the data
  const handleUpdateData = async () => {
    try {
      setIsUpdating(true);
      const response = await updateApplicationByPersonalId(appidToFetchDataFrom, appidToRestoreData);
      if (response) {
        toast.success(translator.formatMessage({ id: 'COMMON.MESSAGE.CURRENT_APPLICATION_DATA_UPDATED_SUCCESSFULLY' }));
        setConfirmationOpen(false);
        setIsRestored(() => true);
        setIsTableOpen(() => false);
      }
    } catch (error) {
    } finally {
      setIsUpdating(false);
    }
  };

  // handle close for html dialog
  const handleClose = () => {
    setIsTableOpen(false);
  };

  // handle restore click
  const handleRestoreButton = (idOrg: string) => {
    setAppidToFetchDataFrom(() => idOrg);
    setConfirmationOpen(true);
  };

  return (
    <Modal
      isOpen={true} // Set to true or false based on your logic
      onRequestClose={handleClose}
      style={customModalStyles}
      contentLabel='Popup Table Modal'
      className="top-50 start-50 end-auto bottom-auto p-4 rounded-3 position-absolute card"
    >
     <div className='d-flex justify-content-between'>
      <h3 className='my-4 text-center'>{ translator.formatMessage({ id: 'TYPES_OF_EVALUATION.PREVIOUS_APPLICATIONS_WITH_SAME_PERSONAL_ID_FOUND' })}</h3>
      <button
        type='button'
        className='btn-close bg-state-secondary p-3 btn-lg ms-auto'
        onClick={handleClose}
      ></button>
      </div> 
      <div className='table-responsive overflow-y-auto mh-400px'>
        <table className='table table-striped table-bordered table-hover fs-5'>
          <thead>
            <tr>
              <th scope='col' className='text-center text-gray-500'>
              { translator.formatMessage({ id: 'INPUT.APP_IDS' })}
              </th>
              <th scope='col' className='text-center text-gray-500'>
              { translator.formatMessage({ id: 'INPUT.ORGANIZATION_CODE' })}
              </th>
              <th scope='col' className='text-center text-gray-500'>
              { translator.formatMessage({ id: 'INPUT.GPA' })}
              </th>
              <th scope='col' className='text-center text-gray-500'>
              { translator.formatMessage({ id: 'INPUT.MODIFIED_AT' })}
              </th>
              <th scope='col' className='text-center text-gray-500'>
              { translator.formatMessage({ id: 'COMMON.VIEW' })}
              </th>
              <th scope='col' className='text-center text-gray-500'>
              { translator.formatMessage({ id: 'COMMON.ACTION' })}
              </th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
          {data.sort((a,b)=>b.modified_at-a.modified_at).map((obj, index) => (
              <tr key={index} className={index % 2 === 0 ? 'table-light' : ''}>
                <td className='text-center'>{obj.sk.split('#')[1].match(/\d+/)[0]}</td>
                <td className='text-center'>{obj.sk.split('#')[1].match(/[a-zA-Z]+/)[0]}</td>
                <td className='text-center'>{obj.ug_gpa}</td>
                <td className='text-center'>{new Date(obj.modified_at).toLocaleDateString()}</td>
                <td className='text-center'>
                  <div
                    className='text-info cursor-pointer'
                    onClick={() => fetchGeneralEvolutionData(obj?.sk?.split('#')[1])}
                  >
                  { translator.formatMessage({ id: 'COMMON.VIEW' })}
                  </div>
                </td>
                <td className='text-center'>
                  <div
                    className='text-info cursor-pointer'
                    onClick={() => handleRestoreButton(obj?.sk?.split('#')[1])}
                  >
                    { translator.formatMessage({ id: 'COMMON.RESTORE' })}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <DialogBox isOpen={isDialogOpen} onClose={closeDialog} zIndex={9999}>
          <div
            style={{padding: '20px', border: '5px solid black'}}
            dangerouslySetInnerHTML={{__html: pdfHtml || ''}}
          />
        </DialogBox>
      )}

      {/* Confirmation Dialog */}
      {isConfirmationOpen ? (
        <GenericDialogueBox
          name={ translator.formatMessage({ id: 'COMMON.CONFIRM_RESTORE_DATA_PREVIOUSLY_FILLED_APP_TO_CURRENT_APP' }) }
          onClose={() => setConfirmationOpen(false)}
          onNo={() => setConfirmationOpen(false)}
          onYes={handleUpdateData}
          isLoading={isUpdating}
          open={isConfirmationOpen}
          key={'restore-confirmation'}
        />
      ) : null}
    </Modal>
  );
};

export default PersonalIdData;
