import {Suspense, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {LayoutProvider, LayoutSplashScreen} from './modules/layout/core';
import {MasterInit} from './modules/layout/master-init';
import {AuthInit} from './modules/auth';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {I18nProvider} from '../utils/i18n/i18n-provider';
import FeedbackPage from './modules/feedback/feedback-page-wrapper';
import './app.scss';

const App = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const onFeedbackFormClose = () => {
    setDialogOpen(false);
  };
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <>
              <button
                onClick={(e) => setDialogOpen(true)}
                className='position-fixed rounded-circle w-60px h-60px bg-black costume-chatbot z-index-3'
                type='button'
                aria-haspopup='dialog'
                aria-expanded='false'
                data-state='closed'
              >
                <svg
                  xmlns=' http://www.w3.org/2000/svg'
                  width='30'
                  height='40'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='text-white block border-gray-200 align-middle'
                >
                  <path d='m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z' className='border-gray-200'></path>
                </svg>
              </button>
              <FeedbackPage isOpen={isDialogOpen} onClose={onFeedbackFormClose}></FeedbackPage>
            </>
            <MasterInit />
            <ToastContainer />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export {App};
