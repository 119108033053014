import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { IDialogueBoxWithInput } from '../component-interface';

const DialogBoxWithInput: React.FC<IDialogueBoxWithInput> = ({
  isOpen,
  additionalData,
  onClose,
  onSubmit,
  dynamicData,
}) => {
  const [inputData, setInputData] = useState(dynamicData?.inputData || '');
  useEffect(() => {
    setInputData(() => dynamicData?.inputData);
  }, [dynamicData.inputData]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await onSubmit(inputData, additionalData);
    setInputData('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='md'>
      <IconButton
        aria-label='close'
        className='position-absolute top-0 end-0 m-2'
        onClick={onClose}
      >
        <div className="fa fa-close fa-1x"></div>
      </IconButton>
      <DialogContent className=' border border-3 rounded'>
        <div>
          <h2 className='mt-4 mb-3'>{dynamicData.title}</h2>
          <form className='p-1'>
            <div className='mb-3'>
              <label className='form-label form-check-label'>{dynamicData.inputLabel}</label>
              <textarea
                value={inputData}
                placeholder={dynamicData.inputPlaceHolder}
                onChange={(e) => setInputData(e.target.value)}
                className='form-control'
                rows={4}
                required
              />
            </div>
          </form>
        </div>
      </DialogContent>
      <DialogActions className='justify-content-between p-5'>
        <button className='btn btn-sm btn-primary' onClick={onClose}>
          Cancel
        </button>
        {!dynamicData.isLoading ? (
          <button
            type='submit'
            onClick={handleSubmit}
            className='btn btn-sm btn-primary'
            color='primary'
          >
            {dynamicData.rightButton}
          </button>
        ) : (
          <button className='btn btn-sm btn-primary' disabled>
            <span className='indicator-progress d-flex align-items-center'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogBoxWithInput;
