import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/auth'
import {removeRefreshToken} from './core/auth-helpers'
import {removeValueLocalStorage} from './core/_requests'
import { commonConstant } from '../../../utils/common-constant'

export function Logout() {
  const {logout} = useAuth()
  useEffect(() => {
    logout()
    removeRefreshToken()
    removeValueLocalStorage(commonConstant.credEvalData)
    localStorage.removeItem('isTokenApiTriggered')
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
