import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import { activeSubheading } from './my-work-constants';
import { fetchAllUniversitiesData, fetchAllWorkItemData, fetchAllSearchItemData } from './_service/my-work-services';
import { IUniversity, IUserAppRef } from './my-work-interface';
import { UniversityCards } from './university-cards';
import { Header } from './my-work-header';
import { IErrorResponse } from '../auth';
import { useIntl } from 'react-intl';
import { getFormattedDate } from '../../../utils/utils';

const MyWork = () => {
  const [activeSubheader, setActiveSubheader] = useState(activeSubheading.Evaluation);
  const [isExpanded, setIsExpanded] = useState<null | number>(null);
  const [universityData, setUniversityData] = useState<IUniversity[]>([]);
  const [listData, setListData] = useState<IUserAppRef[]>([]);
  const [currPageNumber, setCurrPageNumber] = useState(1);
  const [totalListDataCount, setTotalListDataCount] = useState(0);
  const [isUniversityLoading, setIsUniversityLoading] = useState(false);
  const [isListDataLoading, setIsListDataLoading] = useState(false);
  const [cacheUniversityData, setCacheUniversityData] = useState<{[key: string]: IUserAppRef[] }>({});
  const [cacheHeaderData, setCacheHeaderData] = useState<{[key: string]: IUniversity[] }>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(listData);
  const translator = useIntl();

  // fetch university sub headers data if it's not expanded
  useEffect(() => {
    setIsExpanded(null);
    if (activeSubheader !== '') { 
      const fetchAllUniversitiesData = () => {
      const cacheKey = `${activeSubheader}-0`;
      if (cacheHeaderData[cacheKey]) {
        handleExpandedItem(0);
        setUniversityData(cacheHeaderData[cacheKey]);
      } else {
         fetchAllUniversities();
      }
    }
    fetchAllUniversitiesData();
  }
  // eslint-disable-next-line
  }, [activeSubheader]);

  // fetch subheader data function
  const fetchAllUniversities = async () => {
    try {
      setIsUniversityLoading(true);
      const data = await fetchAllUniversitiesData(activeSubheader);
      if (data) {
        setUniversityData(data);
        const cacheKey = `${activeSubheader}-0`;
        setCacheHeaderData((prevCache) => ({ ...prevCache, [cacheKey]: data }));
      }
      handleExpandedItem(0);
      setIsUniversityLoading(false);
    } catch (error: unknown) {
      setIsUniversityLoading(false);
      toast.error((error as IErrorResponse)?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsUniversityLoading(false);
    }
  };

  // fetch university table data if it is expanded
  useEffect(() => {
    if (isExpanded !== null) {
      setTotalListDataCount(
        universityData[isExpanded]?.new_app_count + universityData[isExpanded]?.in_progress_app_count
      );
      const fetchAllWorkItems = async () => {
        const cacheKey = `${activeSubheader}-${universityData[isExpanded]?.code}-${currPageNumber}`;
        if (cacheUniversityData[cacheKey]) {
          setListData(cacheUniversityData[cacheKey]);
        } else {
          await fetchAllMyWorkData(currPageNumber);
        }
      };

      fetchAllWorkItems();
    }
  // eslint-disable-next-line
  }, [isExpanded, activeSubheader, currPageNumber, universityData]);

  // fetch table data function
  const fetchAllMyWorkData = async (currPageNumber: number) => {
    try {
      if (universityData.length > 0) {
        setIsListDataLoading(true);
        const data = await fetchAllWorkItemData(
          activeSubheader,
          universityData[isExpanded ?? 0].code,
          currPageNumber
        );
        if (data) {
          data.statusData.forEach((ele: IUserAppRef) => {
            ele.created_at = getFormattedDate(ele.created_at);
          });
          setListData(data.statusData);
          const cacheKey = `${activeSubheader}-${universityData[isExpanded ?? 0].code}-${currPageNumber}`;
          setCacheUniversityData((prevCache) => ({ ...prevCache, [cacheKey]: data.statusData }));
        }
      }
    } catch (error: unknown) {
      toast.error((error as IErrorResponse)?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("error", error)
    } finally {
      setIsListDataLoading(false);
    }
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!searchQuery) {
        setFilteredData(listData);
        return;
      }
      setIsListDataLoading(true);
      try {
        
        const data = await fetchAllSearchItemData(
          searchQuery,
          universityData[isExpanded ?? 0].code,
          activeSubheader
        );
        if (data) {
          data.statusData.forEach((ele: IUserAppRef) => {
            ele.created_at = getFormattedDate(ele.created_at);
          });
          setFilteredData(data.statusData);
        }
      } catch (error: unknown) {
      }
      finally {
        setIsListDataLoading(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      fetchSearchResults();
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  const handleItemClick = (subheader: string) => {
    setSearchQuery("");
    setActiveSubheader(subheader);
  };
  const handleExpandedItem = (index: number | null) => {
    setSearchQuery("");
    setIsExpanded(index);
    setCurrPageNumber(1);
  };

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header justify-content-center border-0 pt-5'>
        <h2 className='border-0 pt-5 text-center'>
          <span className='card-label fw-bolder fs-1 mb-1'>
          {translator.formatMessage({ id: 'MY_WORK.MY_WORK_TITLE' })}
          </span>
        </h2>
      </div>
      <Header activeSubheader={activeSubheader} onItemClick={handleItemClick} />
      {isUniversityLoading ? (
        <div className='text-center my-10 h-200px'>
          <div className='spinner-border w-75px h-75px text-primary' role='status'>
            <span className='visually-hidden'>{translator.formatMessage({ id: 'COMMON.LOADING' })}</span>
          </div>
        </div>
      ) : universityData.length === 0 && !isListDataLoading ? (
        <div className='text-center my-auto p-10'>
          <span className='fs-1 fw-bold p-4'>{translator.formatMessage({ id: 'COMMON.DATA_NOT_FOUND' })}</span>
        </div>
      ) : (
        <>
          <UniversityCards
            universityData={universityData}
            listData={listData}
            isListDataLoading={isListDataLoading}
            currPageNumber={currPageNumber}
            totalListDataCount={totalListDataCount}
            isExpanded={isExpanded}
            onExpandingItem={handleExpandedItem}
            setCurrentPageNumber={(e) => setCurrPageNumber(e)}
            translator ={ translator }
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filteredData={filteredData}
          />
        </>
      )}
    </div>
  );
};
export {MyWork};
