import {FC} from 'react';
import {Link} from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../utils/helpers';

const Error404: FC = () => {
  return (
    <div className='my-10 mx-10 d-flex flex-column align-items-center'>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>We can't find that page.</div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className='mb-10'>
        <Link to='/my-work' className='btn btn-sm btn-primary'>
          Return Home
        </Link>
      </div>
      {/* end::Link */}

      {/* begin::Illustration */}
      <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/illustrations/sigma-1/18-dark.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/illustrations/sigma-1/18.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}
    </div>
  );
};

export {Error404};
