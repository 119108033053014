import { IGradeScale } from '../evaluation/evaluation-interfaces';
import * as Yup from 'yup';

export interface IGradeScaleData  extends IGradeScale {id:string,created_at:number,modified_at:number}
export interface IAttributeForGradeScale {
  attribute: string;
  condition: string;
  searchValue: string;
}
export interface IAttributeAndConditions extends IAttributeForGradeScale {

  operation: string;
}
export interface IAttributeAndConditionsBody extends IAttributeForGradeScale {
  isAnd: boolean;
  isOr: boolean;
}
export const csvFilesSchema = Yup.object().shape({
  destination_country: Yup.string().required().default('USA'),
  country: Yup.string().required(),
  grade_scale_type: Yup.string().required(),
  high_range: Yup.string(),
  low_range: Yup.string(),
  grade_description: Yup.string().optional().default(""),
  scale: Yup.number().when(
    ['destination_country', 'high_range', 'low_range'],
    ([destination_country, high_range, low_range], schema) =>
      destination_country === 'USA' && high_range !== undefined && low_range !== undefined
        ? schema.min(0).max(4).required()
        : schema.required()
  ),
});

export interface ParseResult<T> {
  data: T[];
  meta: Papa.ParseMeta;
}


export interface ISearchGradeScale {
  setFilterConditions: React.Dispatch<React.SetStateAction<JSX.Element[]>>;
  filterConditions: JSX.Element[];
  attributeAndCondition: {
    attribute: string;
    condition: string;
    searchValue: string;
    operation: string;
  }[];
  isSearching: boolean;
  setAttributeAndCondition: React.Dispatch<
    React.SetStateAction<
    IAttributeAndConditions[]
    >
  >;
  setSearchLastEvaluatedKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleSearchParent: () => void;
}

export interface IUploadNewScale {
  onClose: () => void;
}

export interface IGradeScaleEditDialog {
  show: boolean;
  onHide: () => void;
  editableIndex: number;
  data: IGradeScaleData;
}