import {useState, useEffect, useMemo} from 'react';
import {MyWorkDataTable} from './my-work-data-table';
import {IUniversityCards} from './my-work-interface';
import {ITEMS_PER_PAGE} from './my-work-constants';
export function UniversityCards({
  universityData,
  listData,
  isListDataLoading,
  currPageNumber,
  totalListDataCount,
  setCurrentPageNumber,
  isExpanded,
  onExpandingItem,
  translator,
  searchQuery,
  setSearchQuery,
  filteredData,
}: IUniversityCards): JSX.Element {
  const [localCurrPageNumber, setLocalCurrPageNumber] = useState(currPageNumber);

  const totalPages = Math.ceil(
    (searchQuery ? filteredData.length : totalListDataCount) / ITEMS_PER_PAGE
  );

  // this will return filtered data depending on the current page
  const activePageData = useMemo(() => {
    const start = (localCurrPageNumber - 1) * ITEMS_PER_PAGE;
    const end = localCurrPageNumber * ITEMS_PER_PAGE;
    return filteredData.slice(start, end);
  }, [filteredData, localCurrPageNumber]);

  useEffect(() => {
    if (searchQuery) {
      setLocalCurrPageNumber(1);
    } else {
      setLocalCurrPageNumber(currPageNumber);
    }
  }, [searchQuery, currPageNumber]);

  const handlePageChange = (newPage: number) => {
    if (searchQuery) {
      setLocalCurrPageNumber(newPage);
    } else {
      setCurrentPageNumber(newPage);
    }
  };

  const renderPages = () => {
    return Array.from({length: totalPages}, (_, index) => (
      <li key={index + 1} className='page-item'>
        <button
          className={`btn ${localCurrPageNumber === index + 1 ? 'btn-primary' : 'btn-light'}`}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </button>
      </li>
    ));
  };

  return (
    <div className='accordion' id='kt_accordion_1'>
      {universityData.map((ele, index) => (
        <div className='accordion-item' key={index}>
          <h2 className='accordion-header text-center' id='kt_accordion_1_header_1'>
            <button
              style={{ backgroundColor: 'white' }}
              key={ele.code || index}
              className={`accordion-button fs-4 fw-semibold ${isExpanded === index ? '' : 'collapsed'
              }`}
              data-bs-toggle='collapse'
              data-bs-target='#kt_accordion_1_body_1'
              aria-expanded={isExpanded === index ? true : false}
              aria-controls='kt_accordion_1_body_1'
              onClick={() => {
                isExpanded === index ? onExpandingItem(null) : onExpandingItem(index)
              }}
            >
              <div className='d-flex flex-column'>
                <h3 className='fw-bolder text-black mb-2'>{ele.name}</h3>
                <div className='fs-6 text-muted fw-semibold'>
                  {translator.formatMessage({ id: 'MY_WORK.UNIVERSITY_CARDS.NEW_APPLICATIONS' })} {ele.new_app_count} {translator.formatMessage({ id: 'MY_WORK.UNIVERSITY_CARDS.IN_EVALUATION_APPLICATIONS' })} {' '}
                  {ele.in_progress_app_count}
                </div>
              </div>
            </button>
          </h2>
          {isExpanded === index ? (
            <div
              className='accordion-collapse collapse show'
              id='kt_accordion_1_body_1'
              aria-labelledby='kt_accordion_1_header_1'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <MyWorkDataTable
                  organizationName={ele.name}
                  organizationCode={ele.code}
                  isListDataLoading={isListDataLoading}
                  translator={translator}
                  searchQuery={searchQuery}
                  filteredData={searchQuery ? activePageData : listData}
                  setSearchQuery={setSearchQuery}
                />
                {isListDataLoading ? (
                  <div className='justify-content-between mb-6 row h-400px'>
                    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end text-primary fs-2x'>
                      <span className='spinner-border spinner-border me-5 p-5'></span> {translator.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              { listData.length === 0 ? (
                <div className='text-center my-auto p-10'>
                  <span className='fs-1 fw-bold p-4'>{translator.formatMessage({ id: 'COMMON.DATA_NOT_FOUND' })}</span>
                </div>
              ) : (
                <div className='justify-content-between m-5 row'>
                    <div id='kt_table_users_paginate'>
                      <ul className='pagination align-items-center'>
                        <li className='page-item'>                          
                          <button
                            className='btn btn-light my-1'
                            disabled={currPageNumber === 1}
                            onClick={() => {
                              setCurrentPageNumber(currPageNumber - 1)
                            }}
                          >
                          <i className="fa-solid fa-angles-left"></i>{translator.formatMessage({ id: 'BUTTONS.PREVIOUS' })}
                          </button>
                        </li>
                        {renderPages()}
                        <li className='page-item'>
                          <button
                            className='btn btn-light my-1'
                            disabled={currPageNumber >= totalListDataCount / 10}
                            onClick={() => {
                              setCurrentPageNumber(currPageNumber + 1)
                            }}
                          >
                         {translator.formatMessage({ id: 'BUTTONS.NEXT' })} <i className="fa-solid fa-angles-right"></i>
                          </button>
                        </li>
                      </ul>
                  </div>
                </div>
                )}
              </div>
            </div>            
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  )
}
