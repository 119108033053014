import {useMemo, useState, useEffect} from 'react'
import './create-user-style.scss'
import Select from 'react-select'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {
  createUser,
  fetchOrganizationNames,
  verifyEmpCode,
} from './_service/create-user-service'
import {maxAllowedFileSize} from '../../../feedback/feedback-constants'
import {CreateUserSchema, employeeRoleDropdown, initialValues, supportedImageTypeForCreateUser} from './create-user-constants'
import { ICodeName } from '../../../../../utils/common-interface'

export function CreateUser() {
  const [fileData, setFileData] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [organizationsNames, setOrganizationsNames] = useState<Array<ICodeName>>(
    []
  );

  useEffect(() => {
    const getOrganizationsNames = async () => {
      try {
          const response = await fetchOrganizationNames()
          setOrganizationsNames(response);
       } catch (error: unknown) {
      }
    }
    getOrganizationsNames()
  }, [])

  const formIk = useFormik({
    initialValues: initialValues,
    validationSchema: CreateUserSchema,
    onSubmit: async () => {
      onSavingChanges()
    },
  })

  const profilePicElement = useMemo(() => {
    return (
      <div
        className='image-input-wrapper'
        style={{
          backgroundImage: `${
            fileData
              ? `url("${URL.createObjectURL(fileData)}")`
              : 'url("./media/avatars/blank.png")'
          }`,
        }}
      ></div>
    )
  }, [fileData])

  // reset the form
  const handleReset = () => {
    formIk.resetForm()
    setFileData(null)
  }

  // on save changes
  const onSavingChanges = async () => {
    try {
      setIsLoading(true)
      const isUniqueEmpCode: boolean = await handleVerifyEmpCode(formIk.values.emp_code)
      if (!isUniqueEmpCode) {
        toast.error('Employee code already exists', {
          position: toast.POSITION.TOP_RIGHT,
        })
        setIsLoading(false)
      } else {
        const formData = new FormData()
        if (fileData !== null) {
          formData.append('file', fileData)
        }
        formData.append('email', formIk.values.email)
        formData.append('emp_code', formIk.values.emp_code)
        formData.append('evaluator_capacity', formIk.values.evaluator_capacity.toString())
        formData.append('first_name', formIk.values.first_name)
        formData.append('last_name', formIk.values.last_name)
        formData.append('organisation', formIk.values.organisation.toString())
        formData.append('reviewer_capacity', formIk.values.reviewer_capacity.toString())
        formData.append('role', formIk.values.role)
        const data = await createUser(formData)
        if (data) {
          toast.success('Verification email sent successfully for creation of account', {
            position: toast.POSITION.TOP_RIGHT,
          })
          setIsLoading(false)
          setFileData(null)
          formIk.resetForm()
        }
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  // pic should remove on click of cancel
  const handleCancelAvatar = () => {
    setFileData(null)
    formIk.setFieldValue('profile_pic', null)
  }

  const onFileChange = (event: {target: {files: FileList | null}}) => {
    let fileObj;
    if (event.target.files !== null) {
      fileObj = event.target.files[0]
    }
    if (fileObj) {
      const uploadedFile = fileObj.name.split(".");
      let fileType = uploadedFile[uploadedFile.length - 1];
      fileType = "." + fileType?.toString()?.toLowerCase();
      if (!supportedImageTypeForCreateUser.includes(fileType)) {
        setFileData(null);
        toast.error('Invalid file type. Only .png, .jpg, .jpeg, .svg are allowed.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (fileObj.size < maxAllowedFileSize) {
        // Update the file
        setFileData(fileObj)
        formIk.setFieldValue('profile_pic', fileObj)
      } else {
        setFileData(null)
        toast.error('Max file size allowed: 5 MB', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }

  // verify api call 
  const handleVerifyEmpCode = async (emp_code: string) => {
    try {
      const data = await verifyEmpCode(emp_code);
      return data;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className='card bg-white mb-10 pb-5'>
      <h1 className='text-center p-5'>Create User</h1>
      <div className='row pb-10'>
        <form onSubmit={formIk.handleSubmit} className='form' noValidate>
          <div className='card-body'>
            <div className='mb-10'>
              {/* profile photo */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>Profile Photo:</label>
                <div className='col-lg-9 col-xl-6'>
                  <div
                    className='image-input image-input-outline'
                    id='kt_profile_avatar'
                    style={{
                      backgroundImage: `url("./media/avatars/blank.png")`,
                    }}
                  >
                    {profilePicElement}
                    <label
                      className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow'
                      data-action='change'
                      data-toggle='tooltip'
                      title=''
                      data-original-title='Change avatar'
                    >
                      <i className='fa fa-pen icon-sm text-muted'></i>
                      <input type='file' id="profile-upload" accept={supportedImageTypeForCreateUser} onChange={onFileChange} />
                    </label>
                    <span
                      className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow'
                      data-action='remove'
                      data-toggle='tooltip'
                      title=''
                      onClick={handleCancelAvatar}
                      data-original-title='Remove avatar'
                    >
                      <i className='fa fa-close icon-xs'></i>
                    </span>
                  </div>
                  <span className='form-text text-muted'>Allowed file types: png, jpg, jpeg, svg.</span>
                </div>
              </div>

              {/* firstName */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>First Name:</label>
                <div className='col-lg-6'>
                  <input
                    type='text'
                    id="first_name"
                    placeholder='Enter first name'
                    {...formIk.getFieldProps('first_name')}
                    className='form-control'
                  />
                  {formIk.touched.first_name && formIk.errors.first_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formIk.errors.first_name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* lastName */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>Last Name:</label>
                <div className='col-lg-6'>
                  <input
                    type='text'
                    id="last_name"
                    placeholder='Enter last name'
                    {...formIk.getFieldProps('last_name')}
                    className='form-control'
                  />
                  {formIk.touched.last_name && formIk.errors.last_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formIk.errors.last_name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* email */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>Email address:</label>
                <div className='col-lg-6'>
                  <input
                    type='email'
                    id="email"
                    placeholder='Enter email'
                    {...formIk.getFieldProps('email')}
                    className='form-control'
                  />
                  {formIk.touched.email && formIk.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formIk.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* organization */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>Employee Organization:</label>
                <div className='col-lg-6'>
                  <Select
                    isMulti={true}
                    id="emp_org"
                    options={organizationsNames.map((org) => {
                      return {label: org.name, value: org.code}
                    })}
                    value={formIk.values.organisation.map((item: string) => ({
                      label: organizationsNames.find((org) => org.code === item)?.name || '',
                      value: item,
                    }))}
                    onBlur={() => formIk.setTouched({...formIk.touched, organisation: []}, true)}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : []
                      formIk.setFieldValue('organisation', selectedValues)
                    }}
                    isClearable={true}
                  />
                  {formIk.touched.organisation && formIk.errors.organisation && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formIk.errors.organisation}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* employee code */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>Employee Code:</label>
                <div className='col-lg-6'>
                  <input
                    type='text'
                    id="emp_code"
                    placeholder='Enter Employee code'
                    {...formIk.getFieldProps('emp_code')}
                    className='form-control'
                  />
                  {formIk.touched.emp_code && formIk.errors.emp_code && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formIk.errors.emp_code}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* evaluator_capacity */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>Evaluate Capacity:</label>
                <div className='col-lg-6'>
                  <input
                    type='number'
                    id="eval_capacity"
                    placeholder='0'
                    {...formIk.getFieldProps('evaluator_capacity')}
                    className='form-control'
                  />
                  {formIk.touched.evaluator_capacity && formIk.errors.evaluator_capacity && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formIk.errors.evaluator_capacity}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* reviewer_capacity */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>Review Capacity:</label>
                <div className='col-lg-6'>
                  <input
                    type='number'
                    id="review_capacity"
                    placeholder='0'
                    {...formIk.getFieldProps('reviewer_capacity')}
                    className='form-control'
                  />
                  {formIk.touched.reviewer_capacity && formIk.errors.reviewer_capacity && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formIk.errors.reviewer_capacity}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* role */}
              <div className='form-group row mb-2'>
                <label className='col-lg-3 col-form-label'>Employee Role:</label>
                <div className='col-lg-6'>
                  <Select
                    options={employeeRoleDropdown}
                    isClearable={true}
                    id="emp_role"
                    isSearchable={true}
                    value={{label: formIk.values.role || 'Select Role', value: formIk.values.role}}
                    name='role'
                    required={true}
                    onBlur={() => formIk.setTouched({...formIk.touched, role: true}, true)}
                    onChange={(e) => {
                      formIk.setFieldValue('role', e?.value || '')
                    }}
                  />
                  {formIk.touched.role && formIk.errors.role && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formIk.errors.role}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-center text-center'>
            <button
              type='button'
              className='btn btn-success'
              disabled={formIk.isSubmitting || !formIk.isValid || isLoading}
              onClick={() => formIk.handleSubmit()}
            >
              {!isLoading && <span className='indicator-label'>Save Changes</span>}
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              type='reset'
              className='btn btn-secondary ms-3'
              disabled={!formIk.isValid || formIk.isSubmitting}
              onClick={handleReset}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
