 // english
 export const enMessages = {
  "TRANSLATOR.SELECT": "Select your language",
  "MENU.NEW": "new",
  "MENU.ACTIONS": "Actions",
  "MENU.CREATE_POST": "Create New Post",
  "MENU.PAGES": "Pages",
  "MENU.FEATURES": "Features",
  "MENU.APPS": "Apps",
  "MENU.DASHBOARD": "Dashboard",
  "AUTH.GENERAL.OR": "Or",
  "AUTH.GENERAL.SUBMIT_BUTTON": "Submit",
  "AUTH.GENERAL.NO_ACCOUNT": "Don't have an account?",
  "AUTH.GENERAL.SIGNUP_BUTTON": "Sign Up",
  "AUTH.GENERAL.FORGOT_BUTTON": "Forgot Password",
  "AUTH.GENERAL.BACK_BUTTON": "Back",
  "AUTH.GENERAL.PRIVACY": "Privacy",
  "AUTH.GENERAL.LEGAL": "Legal",
  "AUTH.GENERAL.CONTACT": "Contact",
  "AUTH.LOGIN.TITLE": "Login Account",
  "AUTH.LOGIN.BUTTON": "Sign In",
  "AUTH.FORGOT.TITLE": "Forgotten Password?",
  "AUTH.FORGOT.DESC": "Enter your email to reset your password",
  "AUTH.FORGOT.SUCCESS": "Your account has been successfully reset.",
  "AUTH.REGISTER.TITLE": "Sign Up",
  "AUTH.REGISTER.DESC": "Enter your details to create your account",
  "AUTH.REGISTER.SUCCESS": "Your account has been successfuly registered.",
  "AUTH.INPUT.EMAIL": "Email",
  "AUTH.INPUT.FULLNAME": "Fullname",
  "AUTH.INPUT.PASSWORD": "Password",
  "AUTH.INPUT.CONFIRM_PASSWORD": "Confirm Password",
  "AUTH.INPUT.USERNAME": "Username",
  "AUTH.VALIDATION.INVALID": "{name} is not valid",
  "AUTH.VALIDATION.REQUIRED": "{name} is required",
  "AUTH.VALIDATION.MIN_LENGTH": "{name}} minimum length is {{min}",
  "AUTH.VALIDATION.AGREEMENT_REQUIRED": "Accepting terms & conditions are required",
  "AUTH.VALIDATION.NOT_FOUND": "The requested {name} is not found",
  "AUTH.VALIDATION.INVALID_LOGIN": "The login detail is incorrect",
  "AUTH.VALIDATION.REQUIRED_FIELD": "Required field",
  "AUTH.VALIDATION.MIN_LENGTH_FIELD": "Minimum field length:",
  "AUTH.VALIDATION.MAX_LENGTH_FIELD": "Maximum field length:",
  "AUTH.VALIDATION.INVALID_FIELD": "Field is not valid",
  "ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT": "Selected records count: ",
  "ECOMMERCE.COMMON.ALL": "All",
  "ECOMMERCE.COMMON.SUSPENDED": "Suspended",
  "ECOMMERCE.COMMON.ACTIVE": "Active",
  "ECOMMERCE.COMMON.FILTER": "Filter",
  "ECOMMERCE.COMMON.BY_STATUS": "by Status",
  "ECOMMERCE.COMMON.BY_TYPE": "by Type",
  "ECOMMERCE.COMMON.BUSINESS": "Business",
  "ECOMMERCE.COMMON.INDIVIDUAL": "Individual",
  "ECOMMERCE.COMMON.SEARCH": "Search",
  "ECOMMERCE.COMMON.IN_ALL_FIELDS": "in all fields",
  "ECOMMERCE.ECOMMERCE": "eCommerce",
  "ECOMMERCE.CUSTOMERS.CUSTOMERS": "Customers",
  "ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST": "Customers list",
  "ECOMMERCE.CUSTOMERS.NEW_CUSTOMER": "New Customer",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE": "Customer Delete",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION": "Are you sure to permanently delete this customer?",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION": "Customer is deleting...",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE": "Customer has been deleted",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE": "Customers Delete",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION": "Are you sure to permanently delete selected customers?",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION": "Customers are deleting...",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE": "Selected customers have been deleted",
  "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE": "Status has been updated for selected customers",
  "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE": "Selected customers status have successfully been updated",
  // My Work Module
  "MY_WORK.EVALUATION": "Evaluation",
  "MY_WORK.REVIEW": "Review",
  "MY_WORK.MY_WORK_TITLE": "My Work",
  "MY_WORK.UNIVERSITY_CARDS.NEW_APPLICATIONS": "New Applications: ",
  "MY_WORK.UNIVERSITY_CARDS.IN_EVALUATION_APPLICATIONS": "& In Evaluation Applications: ",
  "MY_WORK.DATA_TABLE.SEARCH_BY_APP_ID": "Search By AppId",
  "MY_WORK.DATA_TABLE.APP_ID": "App Id",
  "MY_WORK.DATA_TABLE.DATE_OF_ASSIGNMENT": "Date of Assignment",
  "MY_WORK.DATA_TABLE.PRIORITY": "Priority",
  "MY_WORK.DATA_TABLE.REMARKS": "Remarks",
  "MY_WORK.DATA_TABLE.GO_TO_APPLICATION":  "Go to Application",
  "MY_WORK.EVAL_NEW": "Evaluation New",
  "MY_WORK.EVAL_IN_PROGRESS": "Evaluation in Progress",
  "MY_WORK.EVAL_COMPLETE": "Evaluation Complete",                               
  "MY_WORK.REVIEW_NEW": "Review New",
  "MY_WORK.REVIEW_IN_PROGRESS": "Review in Progress",               
  "MY_WORK.REVIEW_COMPLETE": "Review Complete",             
  "MY_WORK.RE_EVAL_NEW": "Re-Evaluation New",
  "MY_WORK.RE_EVAL_IN_PROGRESS": "Re-Evaluation in Progress",                           
  "MY_WORK.RE_EVAL_COMPLETE": "Re-Evaluation Complete",                   
  "MY_WORK.RE_REVIEW_NEW": "Re-Review New",
  "MY_WORK.RE_REVIEW_IN_PROGRESS": "Re-Review in Progress",                 
  "MY_WORK.RE_REVIEW_COMPLETE": "Re-Review Complete",
  // distribution
  "DISTRIBUTION.REASSIGN_APP_IDS_TO_USERS": "Reassign Appid's to Users",
  "DISTRIBUTION.TOTAL_APPLICATION_COUNT": "Total Applications count",
  "DISTRIBUTION.RE_DISTRIBUTE_APP_IDS": "Redistribute AppId's",
  "DISTRIBUTION.PLEASE_SELECT_APP_IDS_AND_USED_IDS": "Please select appIds and user id",
  "DISTRIBUTION.ASSIGN_APP_IDS_TO_SPECIFIC_USER": "Assign App ID to Specific User",
  "DISTRIBUTION.SELECT_APP_ID_AND_EMAIL_OF_USER": "Select AppId's and email of user",
  "DISTRIBUTION.SELECT_APP_IDS": "Select App Id",
  "DISTRIBUTION.SELECT_EMAIL_OF_USER_TO_ASSIGN": "Select email of user to assign",
  "DISTRIBUTION.SELECTED_DATA": "Selected Data",
  //Types of Evaluation
  "TYPES_OF_EVALUATION.DEGREE_OF_EVALUATION": "Degree of Evaluation",
  "TYPES_OF_EVALUATION.CHANGE_EVALUATION_LEVEL": "Change Evaluation level",
  "TYPES_OF_EVALUATION.PREVIOUS_APPLICATIONS_WITH_SAME_PERSONAL_ID_FOUND": "Previous applications with same personal Id Found",
   //General evaluation
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.YOU_EVALUATING_ONE_DEGREE": "You are evaluating only 1 Degree!",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.UNDER_GRADUATE_EVALUATION": "Undergraduate Evaluation",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.POST_GRADUATE_EVALUATION": "Postgraduate Evaluation",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.HIGH_SCHOOL_EVALUATION": "High School Evaluation",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.COURSE_BY_COURSE_EVALUATION": "Course By Course Evaluation",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.NAME_OF_THE_COURSE": "Name Of The Course",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_CREDIT": "US CREDIT",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_GPA": "US GPA",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.LETTER_GRADE": "Letter Grade",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TOTAL_US_CREDIT": "Total US credits",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_BACHELORS_DEGREE": "US bachelor's degree",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ONE_YEAR_OF_UNDER_GRADUATE_STUDY": "One year of undergraduate study",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TWO_YEAR_OF_UNDER_GRADUATE_STUDY": "Two year of undergraduate study",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.THREE_YEAR_OF_UNDER_GRADUATE_STUDY": "Three year of undergraduate study",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.FOUR_YEAR_OF_UNDER_GRADUATE_STUDY": "Four year of undergraduate study",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_BACHELORS_DEGREE_PLUS_ONE_YEAR_OF_POSTGRADUATE_STUDY": "US bachelor's degree + 1 year of postgraduate study",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ONE_YEAR_OF_POSTGRADUATE_STUDY": "1 year of postgraduate study",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TWO_YEARS_OF_POSTGRADUATE_STUDY": "2 years of postgraduate study",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_MASTERS_DEGREE": "US master's degree",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.DOCTORATE_DEGREE": "Doctorate degree",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.FIRST_NAME_IS_REQUIRED": "First Name is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.LAST_NAME_IS_REQUIRED": "Last Name is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.UNIVERSITY_IS_REQUIRED": "University is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TYPE_OF_EVALUATION_IS_REQUIRED": "Type of Evaluation is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.SCALE_IS_REQUIRED": "Scale is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.NUMBER_OF_REPEATS_IS_REQUIRED": "Number of Repeats is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ACCREDITATION_IS_REQUIRED": "Accreditation is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.GRADUATION_YEAR_IS_REQUIRED": "Graduation Year is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.GRADUATION_YEAR_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ADMISSION_YEAR": "Graduation year must be greater than or equal to admission year",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.COUNTRY_IS_REQUIRED": "Country is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.CREDENTIAL_NAME_IS_REQUIRED": "Credential Name is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_EQUIVALENCY_IS_REQUIRED": "US Equivalency is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.MAJOR_IS_REQUIRED": "Major is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ADMISSION_YEAR_IS_REQUIRED": "Admission Year is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TOTAL_CGPA_IS_REQUIRED": "Total CGPA is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ADDITIONAL_COMMENT_IS_REQUIRED": "Additional comment is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.STATE_IS_REQUIRED": "State is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.CITY_IS_REQUIRED": "City is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.PROGRAM_DURATION_IS_REQUIRED": "Program Duration is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.OUT_OF_IS_REQUIRED": "Out Of is required",
  "GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.SCHOOL_IS_REQUIRED": "School is required",
  "GENERAL_EVALUATION.NONE" : "None",
  "GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_90_OUT_120_CRED_TILL_3RD_YEAR" : "The candidate has completed 90 out of 120 credits till 3rd year of undergraduate studies. The candidate will complete the rest of the 30 credits in the upcoming semester/year.",
  "GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_105_OUT_120_CRED_TILL_7TH_SEM" : "The candidate has completed 105 out of 120 credits till 7th semester of undergraduate studies. The candidate will complete the rest of the 15 credits in the upcoming semester.",
  "GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_100_OUT_120_CRED_TILL_5TH_SEM" : "The candidate has completed 100 out of 120 credits till 5th semester of undergraduate studies. The candidate will complete the rest of the 20 credits in the upcoming semester.",
  "GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_80_OUT_120_CRED_TILL_2ND_YEAR" : "The candidate has completed 80 out of 120 credits till 2nd year of undergraduate studies. The candidate will complete the rest of the 40 credits in the upcoming semester/year.",
  "GENERAL_EVALUATION.THE_CANDIDATE_GOT_A_LATERAL_ENTRY_IN_2ND_YEAR_OF_BACHELOR" : "The candidate has got a lateral entry in the 2nd year of Bachelor's Degree after completion of a diploma.",
  "GENERAL_EVALUATION.THE_CANDIDATE_GOT_A_LATERAL_ENTRY_IN_3RD_YEAR_OF_BACHELOR" : "The candidate has got a lateral entry in the 3rd year of Bachelor's Degree after completion of a diploma.",
  "GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_A_INTERNSHIP_AS PART_OF_FINAL_YEAR" : "The candidate has completed a compulsory Internship as a part of the final year of Doctor of Pharmacy degree.",
  "COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.UG" : "Ug Course By Course",
  "COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.PG" : "Pg Course By Course",
  "COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.HS1" : "HS1 Course By Course",
  "COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.HS2" : "HS2 Course By Course",
  "COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.AUTO_SAVE" : "Auto save",
  "COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.FETCH_PREV_DATA" : "Fetch Prev. Data",
  // Profile Section
  "PROFILE.PROFILE_HEADER.REVIEWER_CAPACITY": "Reviewer Capacity",
  "PROFILE.PROFILE_HEADER.EVALUATOR_CAPACITY": "Evaluator Capacity",
  "PROFILE.PROFILE_HEADER.LEAVES": "Leaves",
  "PROFILE.ACTION.INSERT_APP_IDS_DB_TO_DRIVE": "Insert appid's to database from Organization drive",
  "PROFILE.ACTION.DISTRIBUTE_APP_IDS_AMONG_USERS": "Distribute app ids among users",
  "PROFILE.ACTION.MASTER_UPLOAD": "Master Upload",
  "PROFILE.ACTION.REDO_MASTER_UPLOAD": "Redo Master Upload",
  "PROFILE.ACTION.INCOMPLETE_TRANSCRIPT_CHECKBOX_LABEL": "Generate separate report for incomplete transcript",
  "PROFILE.ACTION.PLEASE_INPUT_COMMA_SEPARATED_APP_IDS_LABEL": "Please input comma-separated App IDs (e.g., App#slu345352, App#slu345353). PDF files are generated for each ID, along with an entry in a CSV file accessible in the respective organization's drive.",
  // Common Input
  "INPUT.UNDER_GRADUATE" : "Under Graduate",
  "INPUT.POST_GRADUATE": "Post Graduate",
  "INPUT.DOCTORATE":  "Doctorate",
  "INPUT.TYPES_OF_EVALUATION" : "Type of Evaluation",
  "INPUT.GENERAL_EVALUATION" : "General Evaluation",
  "INPUT.COURSE_BY_COURSE" : "Course by Course",
  "INPUT.UNDER_GRADUATE_COUNTRY": "Ug Country",
  "INPUT.POST_GRADUATE_COUNTRY": "Pg Country",
  "INPUT.UG_SCALE" : "Ug Scale",
  "INPUT.PG_SCALE" : "Pg Scale",
  "INPUT.UG" : "UG",
  "INPUT.PG" : "PG",
  "INPUT.ADDITION_COMMENTS" : "Additional Comments",
  "INPUT.COMPLETE_TRANSCRIPT_AVAILABLE": "Complete Transcript Available",
  "INPUT.CONDITION_FOR_IN_COMPLETE_TRANSCRIPT": "Conditions for incomplete transcript",
  "INPUT.YES": "Yes",
  "INPUT.NO": "No",
  "INPUT.DEGREE_AWARDED": "Degree Awarded",
  "INPUT.ANY_ADDITIONAL_CRED_FOR_EVAL": "Any additional credentials for evaluation?",
  "INPUT.HIGH_SCHOOL_1" : "High School 1",
  "INPUT.HIGH_SCHOOL_2" : "High School 2",
  "INPUT.ORGANIZATION" : "Organization",
  "INPUT.APPLICATION_IDS" : "Application Id",
  "INPUT.EVALUATION_LEVEL" : "Evaluation Level",
  "INPUT.AUTO_SAVE": "Auto save",
  "INPUT.CHOOSE_CSV_FILE": "Choose Csv file",
  "INPUT.APP_IDS": "AppIds",
  "INPUT.USER_EMAILS": "User Emails",
  "INPUT.IS_VERIFIED": "Is Verified",
  "INPUT.STATUS": "Status",
  "INPUT.EMAIL": "Email",
  "INPUT.LAST_MODIFIED_DATE": "last modified date",
  "INPUT.FIRST_NAME": "First Name",
  "INPUT.LAST_NAME": "Last Name",
  "INPUT.COLLAGE": "College",
  "INPUT.SCHOOL" : "School", 
  "INPUT.SELECT_SCHOOL" : "Select School", 
  "INPUT.SELECT_COLLAGE": "Select College",
  "INPUT.UNIVERSITY": "University",
  "INPUT.SELECT_UNIVERSITY": "select university",
  "INPUT.CITY": "City", 
  "INPUT.STATE": "State", 
  "INPUT.COUNTRY": "Country", 
  "INPUT.SELECT_CITY": "Select City", 
  "INPUT.SELECT_STATE": "Select State",
  "INPUT.CREDENTIAL_NAME": "Credential name",
  "INPUT.SELECT_CREDENTIAL_NAME" : "select credential name",
  "INPUT.MAJOR" : "Major",
  "INPUT.ADMISSION_YEAR" : "Admission Year",
  "INPUT.ADMISSION_YEAR_FORMAT" : "Admission Year(YYYY)",
  "INPUT.GRADUATING_YEAR" : "Graduating Year",
  "INPUT.GRADUATING_YEAR_FORMAT" : "Graduating Year (YYYY)",
  "INPUT.DURATION_OF_PROGRAM" : "Duration Of Program",
  "INPUT.TOTAL_CGPA" : "Total CGPA",
  "INPUT.NO_OF_REPEATS" : "Number Of Repeats",
  "INPUT.US_DEGREE_EQUIVALENCY" : "US Degree Equivalency",
  "INPUT.ACCREDITATION" : "Accreditation",
  "INPUT.ENTER_COMMENTS" : "Enter Comments",
  "INPUT.GPA" : "GPA",
  "INPUT.GPA_LETTER_GRADE" : "GPA Letter Grade",
  "INPUT.ADDITIONAL_REQUIREMENTS" : "Admission Requirements",
  "INPUT.EVALUATION_DATE" : "Evaluation Date",
  "INPUT.EVALUATOR_NAME" : "Evaluator Name",
  "INPUT.ENTER_REMARKS" : "Enter Remarks",
  "INPUT.REMARKS" : "Remarks:",
  "INPUT.COMMENTS" : "Comments: ", 
  "INPUT.ENTER_GRADE" : "Enter Grade",
  "INPUT.ROW_REQUIRED" :"Rows required",
  "INPUT.ADD_ROWS" : "Add Rows",
  "INPUT.TOTAL_N_CREDITS" : "Total NCredits",
  "INPUT.TOTAL_OM" : "Total OM",
  "INPUT.TOTAL_MM" : "Total MM",
  "INPUT.TOTAL_OBTAINED_MARKS" : "Total Obtained Marks",
  "INPUT.TOTAL_MAXIMUM_MARKS" : "Total Maximum Marks",
  "INPUT.SEMESTER" : "Semester",
  "INPUT.COURSE_NAME" : "Course Name",
  "INPUT.GRADE" : "Grade",
  "INPUT.OM" : "OM",
  "INPUT.MM" : "MM",
  "INPUT.CREDITS" : "Credits",
  "INPUT.ACTIONS" : "Actions",
  "INPUT.EVALUATION" : "Evaluation",
  "INPUT.UNDERGRADUATE_2" : "Undergraduate 2",
  "INPUT.POST_GRADUATE_2" : "Postgraduate 2",
  "INPUT.DOCTORATE_2":"Doctorate 2",
  "INPUT.ORGANIZATION_CODE" : "Org Code",
  "INPUT.MODIFIED_AT" : " Modified At",
  "INPUT.FAST_TRACK_APPLICATION" : "Fast Track Application",
  // Common Validations
  "VALIDATIONS.REQUIRED_FIELDS": "All fields are mandatory.",
  "VALIDATIONS.EDUCATION_LEVEL_WILL_CHANGED_DO_YOU_WANT_CONTINUE" : "With this action education level will be changed? from {education_level} to new selected do you want to continue?",
  "VALIDATIONS.EDUCATION_LEVEL_REQUIRED_FIELDS" : "Education Level field is required.",
  "VALIDATIONS.TYPES_OF_EVALUATION_REQUIRED_FIELDS" : "Type Of Evaluation field is required.",
  "VALIDATIONS.DEGREE_OF_EVALUATION_REQUIRED_FIELDS" : "Degree of Evaluation field is required.",
  "VALIDATIONS.PG_TRANSCRIPT_COMPLETE_REQUIRED_FIELDS" : "PG transcript Complete field is required.",
  "VALIDATIONS.UG_TRANSCRIPT_COMPLETE_REQUIRED_FIELDS" : "UG transcript Complete field is required.",
  "VALIDATIONS.DEGREE_AWARD_FOR_UG_REQUIRED_FIELDS" : "Degree Awarded for UG field is required.",
  "VALIDATIONS.DEGREE_AWARD_FOR_PG_REQUIRED_FIELDS" : "Degree Awarded for PG field is required.",
  "VALIDATIONS.UG_SCALE_REQUIRED_FIELDS" : "Ug scale is required.",
  "VALIDATIONS.PG_SCALE_REQUIRED_FIELDS": "PG Scale is Required",
  "VALIDATIONS.UG_COUNTRY_REQUIRED_FIELDS": "UG Country is required",
  "VALIDATIONS.PG_COUNTRY_REQUIRED_FIELDS": "PG Country is required",
  "VALIDATIONS.APP_ID_IS_REQUIRED" : "AppID is required",
  "VALIDATIONS.INDEX_IS_REQUIRED" : "Index is required",
  "VALIDATIONS.SEMESTER_IS_REQUIRED" : "Semester is required",
  "VALIDATIONS.COURSE_NAME_IS_REQUIRED" : "Course name is required",
  "VALIDATIONS.NATIVE_CREDITS_IS_REQUIRED" : "Native credits is required",
  "VALIDATIONS.US_CREDITS_IS_REQUIRED" : "US Credits is required",
  "VALIDATIONS.US_GPA_IS_REQUIRED" : "US GPA is required",
  "VALIDATIONS.MA_IS_REQUIRED" : "MA is required",
  "VALIDATIONS.MB_IS_REQUIRED" : "MB is required",
  "VALIDATIONS.ADDITIONAL_CREDENTIAL_FOR_UG_REQUIRED_FIELDS" : "Additional Credentials for UG field is required.",
  "VALIDATIONS.ADDITIONAL_CREDENTIAL_FOR_PG_REQUIRED_FIELDS" : "Additional Credentials for PG field is required.",
  "VALIDATIONS.TRANSCRIPT_INCOMPLETE_REASON_IF_TRANSCRIPT_INCOMPLETE_REQUIRED_FIELDS": "Transcript incomplete reason is required when transcript is incomplete",
  "VALIDATIONS.ADDITIONAL_COMMENTS_IF_TRANSCRIPT_INCOMPLETE_REQUIRED_FIELDS": "Additional comments is required when transcript is incomplete",
  // Common Reusable Buttons
  "BUTTONS.ASSIGN_APP_IDS_TO_USERS":"Assign App IDs to Users",
  "BUTTONS.NEXT": "Next",
  "BUTTONS.SAVE": "Save",
  "BUTTONS.PREVIOUS": "Pervious",
  "BUTTONS.PREVIEW": "Preview",
  "BUTTONS.SUBMIT": "Submit",
  "BUTTONS.MASTER_UPLOAD": "Master Upload",
  "BUTTONS.APP_IDS_MASTER_UPLOAD" : "App Ids Master Upload",
  "BUTTONS.SENT_TO_NEXT_LEVEL" : "Send To Next Level",
  "BUTTONS.EVALUATE_PG" : "Evaluate PG",
  "BUTTONS.EVALUATE_UG" : "Evaluate UG",
  "BUTTONS.EVALUATE_HS2": "Evaluate HS2",
  "BUTTONS.EVALUATE_HS1": "Evaluate HS1",
  "BUTTONS.COURSE_BY_COURSE" : "Course By Course",
  "BUTTONS.CLOSE" : "Close",
  "BUTTONS.SEND_BACK_TO_EVALUATOR" : "Send Back To Evaluator",
  "BUTTONS.CLEAR_COURSE_BY_COURSE_DATA" : "Clear Course by Course Data",
  // Common Reusable Text
  "COMMON.LOADING": "Loading...",
  "COMMON.PLEASE_WAIT": "Please wait...",
  "COMMON.STATUS": "Status",
  "COMMON.ACTION": "Action",
  "COMMON.VIEW": "View",
  "COMMON.RESTORE": "Restore",
  "COMMON.DATA_NOT_FOUND": "Data Not Found",
  "COMMON.NO_APP_IDS_FOUND": "No appId found.",
  "COMMON.SELECT_ORGANIZATION": "Select Organization",
  "COMMON.APP_IDS_EG": "eg: App#slu345352, App#slu345353",
  "COMMON.NO_CHANGES_TO_SAVE": "No changes to save",
  "COMMON.SAMPLE": "Sample",
  "COMMON.OR": "OR",
  "COMMON.DOWNLOAD_CSV_FILE": "Download sample csv",
  "COMMON.UNSUCCESSFUL": "Unsuccessful",
  "COMMON.SUCCESSFUL": "Successful",
  "COMMON.PLEASE_UPLOAD_CVS_FILE": "Please upload csv file",
  "COMMON.ACTIONS": "Actions",
  "COMMON.PLEASE_SELECT_USER_EMAIL": "Please select user email",
  "COMMON.INVALID_SCALE": "Invalid Scale",
  "COMMON.INVALID_COUNTRY_SCALE": "Invalid Country/Scale",
  "COMMON.ENTER_VALID_NUMBER":"Please enter a valid number",
  "COMMON.PRIORITY_UPDATED_SUCCESSFULLY":"Priority updated successfully",
  "COMMON.PRIORITY_UPDATED_SUCCESSFULLY_AND_ASSIGNED" : "Application Priority has Increased and Assigned to user.",
  "COMMON.WRONG_FILE_SELECTED" : "Wrong file type selected",
  "COMMON.PLEASE_SELECT_A_FILE_TO_UPLOAD" : "Please select a file to upload",
  "COMMON.APPIDS_NOT_ASSIGNED" : "AppIds not assigned",
  
  // Common Reusable Messages
  "COMMON.MESSAGE.SUCCESSFULLY_UPDATED_DATA_IN_DB": "Successfully updated database with current csv data!!",
  "COMMON.MESSAGE.SOMETHING_WENT_WRONG": "Something went wrong, Please try again",
  "COMMON.MESSAGE.TRANSCRIPT_NOT_AVAILABLE": "Transcript not available!!",
  "COMMON.MESSAGE.SUCCESSFULLY_ASSIGNED_APP_IDS_OF_POOL_TO_USER": "Successfully assigned app ids of pool to users",
  "COMMON.MESSAGE.REQUEST_SENT_FOR_MASTER_UPLOAD": "Request sent for master upload",
  "COMMON.MESSAGE.APP_IDS_INVALID_INPUT": "AppIds are not entered!",
  "COMMON.MESSAGE.REQUEST_SENT_FOR_APP_IDS_MASTER_UPLOAD": "Request sent for AppIds master upload.",
  "COMMON.MESSAGE.PLEASE_FILL_REQUIRED_FIELDS": "Please fill all required fields",
  "COMMON.MESSAGE.DATA_IS_NOT_EXIST_IN_DB": "Data does not exist in the database!",
  "COMMON.MESSAGE.SOMETHING_WENT_WRONG_REFRESH_PAGE": "Something went wrong, Please try again later/ refresh page",
  "COMMON.MESSAGE.SUCCESSFULLY_SAVED_THE_DATA": "Successfully saved the data !!",
  "COMMON.MESSAGE.SUCCESSFULLY_UPDATED_DATA": "Successfully updated data !!",
  "COMMON.MESSAGE.SUCCESSFULLY_DELETED_DATA": "Data deleted Successfully!!",
  "COMMON.MESSAGE.FAILED_TO_SAVE_DATA": "Failed to save the data!",
  "COMMON.MESSAGE.SUCCESSFULLY_SENT_TO_NEXT_LEVEL": "Successfully sent to next level!",
  "COMMON.MESSAGE.ACTION_WILL_CLEAR_DATA_DO_YOU_WANT_CONTINUE" : "By this Action All the data related to this Degree of Evaluation will be cleared from System. Do you want to continue ?",
  "COMMON.MESSAGE.SUCCESSFULLY_ASSIGN_SELECTED_APP_IDS_TO_USER" : "Successfully assign selected appIds to the selected users",
  "COMMON.MESSAGE.SOME_APPLICATION_ARE_IN_WRONG_POOL" : "Some Application are in wrong pool or wrong status!!",
  "COMMON.MESSAGE.APPID_IN_WRONG_POOL" : "AppId is in wrong pool or wrong status",
  "COMMON.MESSAGE.SUCCESSFULLY_DISTRIBUTED_SELECTED_USERS_APPLICATIONS": "Successfully redistributed selected user's applications",
  "COMMON.MESSAGE.NO_APPLICATIONS_DATA_FOUND": "No Applications Data found",
  "COMMON.MESSAGE.SUCCESSFULLY_SENT_BACK_TO_EVALUATOR": "Successfully sent back to evaluator!",
  "COMMON.MESSAGE.ARE_YOU_SURE_YOU_WANT_TO_SAVE_ANY_COMMENTS": "Are you sure you want to save? Any comments?",
  "COMMON.MESSAGE.WANT_TO_ADD_REMARK_FOR_RE_EVALUATION": "Do you want to add any remarks for Re-Evaluation? Any Remarks ?",
  "COMMON.MESSAGE.FAILED_TO_FETCH_ORG_DATA": "Failed to fetch organization data!",
  "COMMON.MESSAGE.PLEASE_CORRECT_THE_INVALID_DATA": "Please correct the invalid data",
  "COMMON.MESSAGE.CAN_NOT_REMOVE_SINGLE_ROW": "Cannot remove single row",
  "COMMON.MESSAGE.NO_DATA_FOUND": "No data found",
  "COMMON.MESSAGE.PLEASE_ENTER_VALID_US_CREDITS": "Please enter valid U.S credits",
  "COMMON.MESSAGE.BY_THIS_ACTION_CBE_DATA_REMOVED_CONTINUE": "By this Action all cbc data will be removed for {gradType}. Do you want to continue ?",
  "COMMON.MESSAGE.INVALID_DATA_FOUND_IN_CSV_FILE": "Invalid data found in csv file",
  "COMMON.MESSAGE.CURRENT_APPLICATION_DATA_UPDATED_SUCCESSFULLY": "Current application data updated successfully",
  "COMMON.CONFIRM_RESTORE_DATA_PREVIOUSLY_FILLED_APP_TO_CURRENT_APP": "Are you sure you want to restore data of previously filled application to current application"
}