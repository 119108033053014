export const GRADE_SCALE_CONST = {
    column_range: ' column_range',
    country: 'country',
    grade_scale_type: 'grade_scale_type',
    grade_description: 'grade_description',
    high_range: 'high_range',
    low_range: 'low_range',
    scale: 'scale',
  }
  
  export const DYNAMODB_DB_CONDITIONS: {[key: string]: string} = {
    'Equal To': '=',
    'Greater than equal to': '>=',
    'Greater than': '>',
    'Less than': '<',
    'Less than equal to': '<=',
    Exists: 'exists',
    'Not Exists': 'not exists',
    Contains: 'contains',
    'Not contains': 'not contains',
  }
