import React, {useEffect, useState, memo} from 'react';
import {Worker, Viewer, SpecialZoomLevel} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import type {ToolbarProps, ToolbarSlot, TransformToolbarSlot} from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useIntl} from 'react-intl';
import {streamTranscript} from '../../../utils/common-services';
import {useDebounce} from '@uidotdev/usehooks';
import {ITranscript} from '../../modules/evaluation/evaluation-interfaces';
import {useLocation} from 'react-router-dom';
import './transcript.scss';

const transcriptURLCache: Record<string, string> = {};

const Transcript: React.FC<ITranscript> = memo(({appId, organizationCode}) => {
  const [isTranscriptLoading, setIsTranscriptLoading] = useState<boolean>(true);
  const [transcriptURL, setTranscriptURL] = useState<string>('');
  const debouncedAppId = useDebounce(appId, 2000);
  const [error, setError] = useState<boolean>(false);
  const translator = useIntl();
  const location = useLocation();
  const heightClass = location.pathname.includes('evalMain') ? 'max-h-1250px' : 'mh-1000px';

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
    SwitchScrollMode: () => <></>,
    SwitchScrollModeMenuItem: () => <></>,
    ShowProperties: () => <></>,
    ShowPropertiesMenuItem: () => <></>,
  });

  const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  });

  const {renderDefaultToolbar} = defaultLayoutPluginInstance.toolbarPluginInstance;
  const streamTranscriptFile = async (appid: string, university: string) => {
    try {
      const cacheKey = `${appid}_${university}`;
      if (transcriptURLCache[cacheKey]) {
        setError(false);
        return transcriptURLCache[cacheKey] || '';
      }
      const url = `${appid}/${university}`;
      const response = await streamTranscript(url);
      transcriptURLCache[cacheKey] = response;
      setError(false);
      return response || '';
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    if (!appId || !organizationCode) {
      return;
    }
    const fetchTranscriptFromDatabase = async () => {
      try {
        setIsTranscriptLoading(true);
        const fetchedTranscriptURL = await streamTranscriptFile(debouncedAppId, organizationCode);
        setTranscriptURL(fetchedTranscriptURL || '');
      } catch (error) {
      } finally {
        setIsTranscriptLoading(false);
      }
    };
    fetchTranscriptFromDatabase();
  }, [debouncedAppId, organizationCode]);

  return (
    <div className={`d-flex flex-row-fluid flex-start rounded m-1 bg-body ${heightClass}`}>
      {isTranscriptLoading ? (
        <span className='indicator-progress d-flex flex-center w-100 h-100 text-primary fs-3'>
          {translator.formatMessage({id: 'COMMON.LOADING'})}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      ) : (
        <>
          {error ? (
            <span className='indicator-progress d-block fs-5 ms-5 mt-5'>
              {translator.formatMessage({id: 'COMMON.MESSAGE.TRANSCRIPT_NOT_AVAILABLE'})}
            </span>
          ) : (
            <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
              <Viewer
                fileUrl={transcriptURL}
                defaultScale={SpecialZoomLevel.PageWidth}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          )}
        </>
      )}
    </div>
  );
});

export default Transcript;
