import {toast} from 'react-toastify'
import {post} from '../core/_requests'
import { IResponse } from '../../../../utils/common-interface'
import {jwtDecode} from 'jwt-decode'
import { IAuthModel } from '../auth-model'

const REFRESH_TOKEN = 'refreshToken'
const AUTH_KEY = 'cred-eval-auth'
const getAuth = (): IAuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: IAuthModel = JSON.parse(lsValue) as IAuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: IAuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

export const setRefreshToken = (refreshToken: string) => {
  if (!refreshToken) return
  try {
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
  } catch (error) {
    console.error('error occurred in setting refresh token', error)
  }
}
export const getRefreshToken = () => {
  if (!localStorage) {
    return
  }

  const token: string | null = localStorage.getItem(REFRESH_TOKEN)
  if (!token) {
    return null
  }
  return token
}

export const removeRefreshToken = () => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.removeItem(REFRESH_TOKEN)
  } catch (error) {
    console.log('error occurred in removing refresh token', error)
  }
}
const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export const refreshAccessToken = () => {
  const refreshToken = getRefreshToken()
  const oldAccessToken = getAuth()?.api_token
  if (oldAccessToken && refreshToken) {
    localStorage.setItem('isTokenApiTriggered', 'true')
    const decodedToken = jwtDecode(oldAccessToken)
    if (decodedToken?.exp && decodedToken?.iat) {
      const timeTillRefreshAccessToken: number =
        (decodedToken?.exp - decodedToken?.iat - 5 * 60) * 1000
      const interval = setInterval(async () => {
        try {
          const res = await post<IResponse<{accessToken: string}>>(
            '/auth/getAccessTokenFromRefreshToken',
            {
              refreshToken: refreshToken,
            }
          )

          if (res?.data?.success) {
            const authRes: IAuthModel = {
              api_token: res?.data?.success?.accessToken,
            }
            setAuth(authRes)
          }
        } catch (error) {
          clearInterval(interval)
          localStorage.setItem('isTokenApiTriggered', 'false')
          toast.error('Error while refreshing the access token', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }, timeTillRefreshAccessToken)
    }
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string; refreshtoken: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => {
      if (response?.headers && response.headers.get('access_token')) {
        setAuth({
          api_token: response.headers.get('access_token'),
        })
      }
      // Handle successful responses (status codes 2xx)
      return response
    },
    (error: any) => {
      // Handle error responses (status codes other than 2xx)
      const {response} = error
      if (response) {
        if (response.status === 400) {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else if (response.status === 401) {
          toast.error(response?.data?.message || 'Unauthorized request!', {
            position: toast.POSITION.TOP_RIGHT,
          })
          if (getAuth()?.api_token) {
            removeAuth()
            window.location.reload()
          }
        } else if (response.status === 404) {
          toast.error(response?.data?.message || 'Route not found!', {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else if (response.status > 500) {
          toast.error(response?.data?.message || 'Internal server error!', {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth}
