import {useRef, useState} from 'react';
import Papa from 'papaparse';
import {toast} from 'react-toastify';
import {saveAs} from 'file-saver';
import {checkCsvFileApps, checkUploadedAppIds} from './_service/application-priority-services';
import {IUploadNewApplication} from './application-priority-interface';
import {IErrorResponse} from '../auth';
import {useIntl} from 'react-intl';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../utils/helpers';

const UploadApplications: React.FC<IUploadNewApplication> = ({setCsvFileAppData, onClose}) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const translator = useIntl();

  // on file change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.[0].type !== 'text/csv') {
      toast.error(translator.formatMessage({id: 'COMMON.WRONG_FILE_SELECTED'}));
      if (fileInputRef.current && fileInputRef.current.value) {
        fileInputRef.current.value = '';
      }
      return;
    }
    setFile(() => e?.target?.files?.[0] || null);
    handleFileUpload(e?.target?.files?.[0]);
  };

  // handle file upload
  const handleFileUpload = (file: File) => {
    if (!file) {
      toast.error(translator.formatMessage({id: 'COMMON.PLEASE_SELECT_A_FILE_TO_UPLOAD'}));
      return;
    }
    Papa.parse(file, {
      header: true,
      complete: async (results: {data: {AppIds: string}[]}) => {
        const {data} = results;
        data.pop();
        const validationErrors = [];
        const seen = new Set();
        let rowIndex = 1;
        for (const item of data) {
          rowIndex += 1;
          try {
            if (seen.has(item.AppIds)) {
              const duplicateIndex = data.findIndex((ele) => ele.AppIds === item.AppIds);
              validationErrors.push(
                `row no: ${rowIndex} and row no: ${duplicateIndex + 2} are duplicate`
              );
            }
            seen.add(item.AppIds);
          } catch (err: unknown) {
            validationErrors.push('row no: ' + rowIndex + ': ' + (err as IErrorResponse)?.errors);
          }
        }
        setErrors(validationErrors);
        if (data && data.length > 0 && validationErrors.length === 0) {
          setFileData(() => data.map((e) => e.AppIds));
          await checkCsvFileApplications(data.map((e) => e.AppIds));
        }
      },
    });
  };

  // check validations and set file data
  const checkCsvFileApplications = async (data: string[]) => {
    try {
      setLoader(() => true);
      const res = await checkCsvFileApps(data.filter((item) => item && item.length > 0));
      setCsvFileAppData(() => res?.data || []);
      if (res && res.errors.length > 0) {
        setFileData(() => []);
        setCsvFileAppData(() => []);
        setErrors(res.errors.map((e) => `AppId: ${e.appid}, reason: ${e.reason}`));
      }
      setFileData(() => res?.data?.map((item) => item?.id) || []);
    } catch (error) {
    } finally {
      setLoader(() => false);
    }
  };

  // handle upload on submit
  const handleUpload = async () => {
    try {
      setLoader(() => true);
      const res = await checkUploadedAppIds(fileData);
      if (res) {
        toast.success(
          translator.formatMessage({id: 'COMMON.PRIORITY_UPDATED_SUCCESSFULLY_AND_ASSIGNED'})
        );
        setFile(() => null);
        setFileData([]);
        setCsvFileAppData(() => []);
        if (fileInputRef.current && fileInputRef.current.value) {
          fileInputRef.current.value = '';
        }
        const unAssignedIds = res.filter((item) => item != null);
        if (unAssignedIds.length > 0) {
          setErrors([
            unAssignedIds.join(', ') + translator.formatMessage({id: 'COMMON.APPIDS_NOT_ASSIGNED'}),
          ]);
        }
      }
      setLoader(false);
    } catch (error) {} 
    finally {
      setLoader(false);
    }
  };

  // download sample csv file 
  const downloadSampleCSV = () => {
    const sampleCSVData = `AppIds\n"App#slu123"\n"App#sluhs12313"\n"App#cua124"`;
    const blob = new Blob([sampleCSVData], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, `${translator.formatMessage({id: 'COMMON.SAMPLE'})}.csv`);
  };

  return (
    <Modal show={true} onHide={onClose} dialogClassName="mw-800px" backdrop="static">
    <Modal.Header closeButton>
     <Modal.Title>Upload New Applications</Modal.Title>
   </Modal.Header>
   <Modal.Body className='h-400px'>
    <div className='container'>
      <div className='row justify-content-start'>
        <div className='col'>
          <div className='mb-3'>
            <div className='input-group'>
              <input
                type='file'
                accept='.csv'
                ref={fileInputRef}
                onChange={handleFileChange}
                className='form-control p-3 bg-gray-100i w-400px'
                aria-describedby='file-remove-btn'
              />
              {file && (
                <button
                  className='btn btn-danger'
                  type='button'
                  onClick={() => {
                    setFile(() => null);
                    setFileData(() => []);
                    setCsvFileAppData(() => []);
                    if (fileInputRef.current && fileInputRef.current.value) {
                      fileInputRef.current.value = '';
                    }
                    setErrors([]);
                  }}
                  id='file-remove-btn'
                >
                  <div className='fa fa-close fa-1x'></div>
                </button>
              )}
               <OverlayTrigger
                  placement="right"
                  overlay={
                  <Tooltip id="tooltip-right">
                      { translator.formatMessage({ id: 'COMMON.DOWNLOAD_CSV_FILE' })}
                  </Tooltip>
                 }
                >
                <div className="btn btn-linkedin z-index-0"  onClick={downloadSampleCSV}>              
                  <SVG src={toAbsoluteUrl("./media/icons/duotune/arrows/arr044.svg")} />
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <button
            onClick={handleUpload}
            className='btn btn-primary'
            disabled={loader || !file || !fileInputRef || errors.length > 0 ? true : false}
          >
            {loader ? (
              <>
                <span className='spinner-border spinner-border-sm'></span>
                Please Wait...
              </>
            ) : (
              'Upload'
            )}
          </button>
        </div>
        {errors.length > 0 && (
          <div className='col-md-12 mt-3'>
            <div className='alert alert-danger'>
              <h3>Errors:</h3>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
    </Modal.Body>
    </Modal>
  );
};

export default UploadApplications;
