import {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {toast} from 'react-toastify';
import {IAttributeAndConditions, IGradeScaleData} from './grade-scales-interface';
import {
  archiveGradeScale,
  fetchAllGradeScalesData,
  searchGradeScaleData,
} from './_service/grade-scales-service';
import UploadNewScales from './upload-new-grade-scales';
import EditGradeScales from './grade-scales-edit';
import SearchGradeScale from './search-grade-scales';
import GenericDialogueBox from '../../components/generic-dialogue-box/generic-dialogue-box';
import {IErrorResponse} from '../auth';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from '../../../utils/helpers';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const GradeScales = () => {
  const [allGradeScaleData, setAllGradeScaleData] = useState<IGradeScaleData[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isAddNewScalesOpen, setIsAddNewScalesOpen] = useState(false);
  const [isAllSelected, setAllSelected] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isArchiveGradeScalesSubmitting, setIsArchiveGradesScaleSubmitting] = useState(false);
  const [selectedGradeScales, setSelectedGradeScales] = useState<string[]>([]);
  const [isSearchAccordionOpen, setIsSearchAccordionOpen] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [prevLastEvaluatedKey, setPrevLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoreGradeScalesLoading, setIsMoreGradeScalesLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [editableIndex, setEditableIndex] = useState<null | number>(null);
  const [filterConditions, setFilterConditions] = useState<JSX.Element[]>([]);
  const [attributeAndCondition, setAttributeAndCondition] = useState<IAttributeAndConditions[]>([
    {
      attribute: 'country',
      condition: 'Equal To',
      searchValue: '',
      operation: 'AND',
    },
  ]);
  const [searchLastEvaluatedKey, setSearchLastEvaluatedKey] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setIsLoading(true);
    const loadAllGradeScales = async () => {
      try {
        await loadAllGradeScaleData();
      } catch (error) {}
      setIsLoading(false);
    };
    loadAllGradeScales();
  }, []);

  // handle grade scale data for initial time
  const loadAllGradeScaleData = async () => {
    try {
      setIsMoreGradeScalesLoading(true);
      const data = await fetchAllGradeScalesData(undefined);
      if (data) {
        setAllGradeScaleData(data.gradeScales);
        setLastEvaluatedKey(data.lastEvaluatedKey);
        setHasMore(!!data.lastEvaluatedKey);
      }
      setIsMoreGradeScalesLoading(false);
    } catch (error) {}
    setIsLoading(false);
  };

  // handle search
  const handleFinalSearch = async () => {
    try {
      setSelectedGradeScales([]);
      if (!searchLastEvaluatedKey) setAllGradeScaleData([]);
      setIsMoreGradeScalesLoading(true);
      setIsSearching(true);
      const res = await searchGradeScaleData(searchLastEvaluatedKey, transformData(attributeAndCondition));
      setSearchLastEvaluatedKey(() => res.lastEvaluatedKey);
      setHasMore(!!res.lastEvaluatedKey);
      setIsMoreGradeScalesLoading(false);
      setIsSearching(false);
      if (searchLastEvaluatedKey) {
        setAllGradeScaleData(allGradeScaleData.concat(res.gradeScales));
      } else {
        setAllGradeScaleData(() => res.gradeScales);
      }
    } catch (error) {
      setIsMoreGradeScalesLoading(false);
      setIsSearching(false);
    }
  };

  // grade scale submit format for body
  const transformData = (rowData: IAttributeAndConditions[]) => {
    return rowData?.map((row, index) => {
      let isAnd = false;
      let isOr = false;
      if (index === 0) {
        isAnd = false;
        isOr = false;
      } else if (row.operation === "AND") {
        isAnd = true;
      } else if (row.operation === "OR") {
        isOr = true;
      }
      return {
        attribute: row.attribute,
        condition: row.condition,
        searchValue: row.searchValue,
        isAnd,
        isOr
      };
    });
  }

  // handle load more data
  const loadMoreGradeScales = async () => {
    try {
      if (searchLastEvaluatedKey) {
        await handleFinalSearch();
        return;
      }
      if (!isMoreGradeScalesLoading && !isLoading && !isSearching) {
        setIsMoreGradeScalesLoading(true);
        if (prevLastEvaluatedKey !== lastEvaluatedKey) {
          setPrevLastEvaluatedKey(() => lastEvaluatedKey);
          const data = await fetchAllGradeScalesData(lastEvaluatedKey);
          if (data) {
            setAllGradeScaleData(allGradeScaleData.concat(data.gradeScales));
            setLastEvaluatedKey(() => data.lastEvaluatedKey);
            setHasMore(!!data.lastEvaluatedKey);
          }
        }
      }
    } catch (error: unknown) {
      toast.error((error as IErrorResponse)?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setIsMoreGradeScalesLoading(false);
  };

  // This is for archive confirmation dialog box open 
  const openDialog = (name: string) => {
    setSelectedName(name);
    setDialogOpen(true);
  };

  // handle archive yes
  const handleYes = () => {
    handleArchiveGradeScales();
    handleClose();
  };

  // This is for archive confirmation dialog box close 
  const handleClose = () => {
    setDialogOpen(false);
  };

  // close dialog for upload csv file
  const handleCloseDialog = () => {
    setIsAddNewScalesOpen(!isAddNewScalesOpen);
  };

  // clear search
  const clearSearchValue = async () => {
    setAttributeAndCondition([
      {
        attribute: 'country',
        condition: 'Equal To',
        searchValue: '',
        operation: 'AND',
      },
    ]);
    setFilterConditions([]);
    setSearchLastEvaluatedKey(undefined);
    loadAllGradeScaleData();
  };

  // archive grade scale
  const handleArchiveGradeScales = async () => {
    try {
      setIsArchiveGradesScaleSubmitting(true);
      const data = await archiveGradeScale(selectedGradeScales);
      if (data) {
        toast.success('Request sent to approver for archiving grade scales');
        setSelectedGradeScales([]);
        setAllSelected(false);
      }
    } catch (error) {
      setIsArchiveGradesScaleSubmitting(false);
    }
    setIsArchiveGradesScaleSubmitting(false);
  };

  // This is for render all the table data
  function DataTable(): JSX.Element {
    const renderTableRows = () => {
      if (allGradeScaleData.length === 0) {
        return isMoreGradeScalesLoading || isSearching ? (
          <tr>
            <td colSpan={10}>
              <div className='text-center mb-5'>
                <span className='ms-2'>Please wait...</span>
                <span className='spinner-border spinner-border-sm'></span>
              </div>
            </td>
          </tr>
        ) : (
          <tr>
            <td colSpan={10}>
              <div className='text-center my-auto'>
                <span className='fs-1 fw-bold'>DATA NOT FOUND</span>
              </div>
            </td>
          </tr>
        );
      }
      return allGradeScaleData.map((row, i) => (
        <tr key={i}>
          <td className='p-4'>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='cursor-pointer widget-9-check w-20px h-20px'
                type='checkbox'
                checked={isAllSelected || selectedGradeScales.includes(row.id)}
                onChange={() => {
                  if (selectedGradeScales.includes(row.id)) {
                    setSelectedGradeScales((prev) =>
                      prev.filter((gradeScaleSk) => row.id !== gradeScaleSk)
                    );
                    setAllSelected(false);
                  } else {
                    if (selectedGradeScales.length === allGradeScaleData.length - 1) {
                      setAllSelected(true);
                    }
                    setSelectedGradeScales((prev) => [...prev, row.id]);
                  }
                }}
              />
            </div>
          </td>
          <td className='p-4'>
            <div className='text-dark fw-bold d-block fs-6'>{row.country}</div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.grade_scale_type}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.column_range}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>{row.low_range}</div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.high_range}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>
              {row.grade_description}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold d-block fs-6'>{row.scale}</div>
          </td>

          <td>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setEditableIndex(i)}
            >
              {editableIndex === i ? (
                <i className='ki-duotone ki-switch fs-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              ) : (
                <i className='ki-duotone ki-pencil fs-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              )}
            </button>
          </td>
        </tr>
      ));
    };

    return (
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='align-middle fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='cursor-pointer form-check-success widget-9-check w-20px h-20px'
                      type='checkbox'
                      checked={isAllSelected}
                      onChange={() => {
                        if (isAllSelected) {
                          setSelectedGradeScales([]);
                        } else {
                          setSelectedGradeScales(
                            allGradeScaleData.map((gradeScale) => gradeScale.id)
                          );
                        }
                        setAllSelected(!isAllSelected);
                      }}
                    />
                  </div>
                </th>
                <th className='min-w-125px' >COUNTRY</th>
                <th className='min-w-100px'>GRADE SCALE TYPE</th>
                <th className='min-w-125px'>COLUMN RANGE</th>
                <th className='min-w-80px'>LOW RANGE</th>
                <th className='min-w-80px'>HIGH RANGE</th>
                <th className='min-w-125px'>GRADE DESCRIPTION</th>
                <th className='min-w-80px'>SCALE</th>
                <th className='min-w-80px rounded-end'>ACTIONS</th>
              </tr>
            </thead>

            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='card mb-xl-8'>
        <div className='card-header justify-content-center border-0 pt-5'>
          <h2 className='border-0 card-header pt-5 text-center'>
            <span className='card-label fw-bold fs-3 mb-1'>Grade Scale Data</span>
          </h2>
        </div>

        {isLoading ? (
          <div className='align-content-around text-center h-400px'>
            <div className='spinner-border h-75px w-75px text-primary mt-10' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
          {isAddNewScalesOpen && (
                <UploadNewScales onClose={ handleCloseDialog }/>
            )}
            <div className='border-0 d-flex justify-content-between flex-wrap px-10'>
              {isSearchAccordionOpen && (
                <SearchGradeScale
                  attributeAndCondition={attributeAndCondition}
                  filterConditions={filterConditions}
                  setAttributeAndCondition={setAttributeAndCondition}
                  setFilterConditions={setFilterConditions}
                  isSearching={isSearching}
                  setSearchLastEvaluatedKey={setSearchLastEvaluatedKey}
                  handleSearchParent={handleFinalSearch}
                />
              )}
              <div className='d-flex gap-3 align-items-start ms-auto'>
                {/* filter */}
                <div>
                  <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip id='tooltip-top'>Filters</Tooltip>}
                  >
                    <div
                      className={`cursor-pointer fs-6 mt-2 ${isSearchAccordionOpen ? 'text-info' : 'text-gray-500'}`}
                      onClick={() => {
                        isSearching ? <></> : setIsSearchAccordionOpen(!isSearchAccordionOpen);
                      }}
                    >
                    <SVG src={toAbsoluteUrl('./media/icons/duotune/general/gen031.svg')} />
                    </div>
                  </OverlayTrigger>
                </div>
                {/* Clear Filters */}
                {isSearchAccordionOpen ? (
                  <div>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip id='tooltip-top'>Clear Filters</Tooltip>}
                    >
                      <button
                        className='btn btn-google btn-sm'
                        onClick={(e) => {
                          e.preventDefault();
                          clearSearchValue();
                        }}
                        disabled={isMoreGradeScalesLoading || isSearching}
                      >
                        <i className='fa fa-times fs-2'></i>
                      </button>
                    </OverlayTrigger>
                  </div>
                ) : (
                  <></>
                )}
                {/* Reload */}
                <div>
                  <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip id='tooltip-top'>Reload</Tooltip>}
                  >
                    <button
                      className='btn btn-linkedin btn-sm'
                      onClick={(e) => {
                        e.preventDefault();
                        loadAllGradeScaleData();
                      }}
                      disabled={isMoreGradeScalesLoading || isSearching}
                    >
                      <i className='fa fa-refresh fs-2'></i>
                    </button>
                  </OverlayTrigger>
                </div>
                {/* Archive Selected */}
                <div className='d-flex align-items-center'>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-top'>
                        {selectedGradeScales.length} Archive Selected
                      </Tooltip>
                    }
                  >
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      disabled={selectedGradeScales.length === 0 || isArchiveGradeScalesSubmitting}
                      onClick={(e) => {
                        e.preventDefault();
                        openDialog('Are you sure you want to archive selected grade scales');
                      }}
                    >
                      {isArchiveGradeScalesSubmitting ? (
                        <>
                          Please Wait...
                          <span className='spinner-border spinner-border-sm'></span>
                        </>
                      ) : (
                        <span>
                          <i className='fa fa-trash fs-2'></i>
                        </span>
                      )}
                    </button>
                  </OverlayTrigger>
                </div>
                {/* Upload New Grade Scales */}
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id='tooltip-top'>Upload New Grade Scales</Tooltip>}
                >
                  <div
                    className='btn btn-primary btn-sm'
                    onClick={(e) => {
                      isSearching ? <></> : setIsAddNewScalesOpen(!isAddNewScalesOpen);
                    }}
                  >
                    <i className='fa fa-upload fs-2'></i>
                  </div>
                </OverlayTrigger>
              </div>
            </div>   
            <GenericDialogueBox
              name={selectedName}
              open={dialogOpen}
              onYes={handleYes}
              onNo={handleClose}
              onClose={handleClose}
            />
            <div className='h-auto'>
              <EditGradeScales
                show={editableIndex !== null && editableIndex >= 0 ? true : false}
                editableIndex={editableIndex != null ? editableIndex : 0}
                onHide={() => setEditableIndex(null)}
                data={
                  editableIndex !== null
                    ? {
                        id: allGradeScaleData[editableIndex].id || '',
                        created_at: allGradeScaleData[editableIndex].created_at || 0,
                        modified_at: allGradeScaleData[editableIndex].modified_at || 0,
                        country: allGradeScaleData[editableIndex].country || '',
                        grade_scale_type: allGradeScaleData[editableIndex].grade_scale_type || '',
                        column_range: allGradeScaleData[editableIndex].column_range || '',
                        grade_description: allGradeScaleData[editableIndex].grade_description || '',
                        high_range: allGradeScaleData[editableIndex].high_range || 0,
                        low_range: allGradeScaleData[editableIndex].low_range || 0,
                        out_of: allGradeScaleData[editableIndex].out_of || '',
                        scale: allGradeScaleData[editableIndex].scale || 0,
                      }
                    : {
                        id: '',
                        created_at: 0,
                        modified_at: 0,
                        country: '',
                        grade_scale_type: '',
                        column_range: '',
                        grade_description: '',
                        high_range: 0,
                        low_range: 0,
                        out_of: '',
                        scale: 0,
                      }
                }
              />
              <InfiniteScroll
                dataLength={allGradeScaleData.length}
                next={loadMoreGradeScales}
                hasMore={hasMore}
                refreshFunction={() => {}}
                scrollThreshold={0.8}
                loader={
                  allGradeScaleData.length > 0 &&
                  isMoreGradeScalesLoading && (
                    <div className='text-center mb-5'>
                      <span className='ms-2'>Please wait...</span>
                      <span className='spinner-border spinner-border-sm'></span>
                    </div>
                  )
                }
                endMessage={
                  isMoreGradeScalesLoading && !isSearching ? (
                    <div className='text-center mb-5'>
                      <span className='ms-2'>Please wait...</span>
                      <span className='spinner-border spinner-border-sm'></span>
                    </div>
                  ) : (
                    <p className='text-center'>
                      <b>All Grade scale data is loaded</b>
                    </p>
                  )
                }
              >
                <DataTable />
              </InfiniteScroll>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GradeScales;
