import {IUniversity, IUserAppRef} from '../my-work-interface'
import {get} from '../../auth/core/_requests'
import {GET_ALL_UNIVERSITIES, GET_ALL_WORK_ITEMS, GET_REVIEW_AND_EVAL_COUNT, GET_ALL_SEARCH_ITEMS,} from '../../../../utils/service-constants'
import { IResponse } from '../../../../utils/common-interface'
export const fetchAllUniversitiesData = async (activeTabName: string) => {
  try {
    const response = await get<IResponse<IUniversity[]>>(
      GET_ALL_UNIVERSITIES + `?activeTabName=${activeTabName}`
    )
    const data = response.data?.success
    return data || []
  } catch (error) {
    throw error
  }
}

export const fetchAllWorkItemData = async (
  activeTabName: string,
  universityName: string,
  currPageNumber: number
) => {
  try {
    const response = await get<
      IResponse<{
        statusData: IUserAppRef[]
        statusDataCount: number
      }>
    >(
      GET_ALL_WORK_ITEMS +
        `?activeTabName=${activeTabName}&university=${universityName}&pageNumber=${currPageNumber}&itemsPerPage=10`
    )
    const data = response.data?.success
    return (
      data || {
        statusData: [],
        statusDataCount: 0,
      }
    )
  } catch (error) {
    throw error
  }
}

export const fetchReviewAndEvalCountData = async () => {
  try {
    const response = await get<
      IResponse<{
        reviewCount: number
        evaluationCount: number
      }>
    >(GET_REVIEW_AND_EVAL_COUNT)
    const data = response.data?.success
    return (
      data || {
        reviewCount: 0,
        evaluationCount: 0,
      }
    )
  } catch (error) {
    throw error
  }
}

export const fetchAllSearchItemData = async (
  searchQuery: string,
  organisationCode:String,
  activeTabName: string
) => {
  try {
    const response = await get<
      IResponse<{
        statusData: IUserAppRef[]
        statusDataCount: number
      }>
    >(
      GET_ALL_SEARCH_ITEMS +
        `?searchQuery=${searchQuery}&organisationCode=${organisationCode}&activeTabName=${activeTabName}`
    );
    const data = response.data?.success;

    return (
      data || {
        statusData: [],
        statusDataCount: 0,
      }
    );
  } catch (error) {
    throw error
  }
};
