import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import { IEvalUg, IEvalPg, IEvalHsUg, IEvalHsPg, IEvaluatorName } from '../evaluation-interfaces';

export const PG_COLLEGE = 'pg_college';
export const UG_COLLEGE = 'ug_college';
export const PG_UNIVERSITY = 'pg_university';
export const UG_UNIVERSITY = 'ug_university';
export const PG_CITY = 'pg_city';
export const UG_CITY = 'ug_city';
export const PG_STATE = 'pg_state';
export const UG_STATE = 'ug_state';
export const PG_CREDENTIAL_NAME = 'pg_credential_name';
export const UG_CREDENTIAL_NAME = 'ug_credential_name';
export const OTHER = 'other';
export const CBC = 'cbc';
export const GENERAL_EVAL = 'ge';
export const PG_COUNTRY = 'pg_country';
export const UG_COUNTRY = 'ug_country';

export const US_DEGREE_EQU_OPTIONS_LIST  = (translator: IntlShape) => {
  return [
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_BACHELORS_DEGREE' }), value: "US bachelor's degree" },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ONE_YEAR_OF_UNDER_GRADUATE_STUDY' }), value: 'One year of undergraduate study' },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TWO_YEAR_OF_UNDER_GRADUATE_STUDY' }), value: 'Two years of undergraduate study' },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.THREE_YEAR_OF_UNDER_GRADUATE_STUDY' }), value: 'Three years of undergraduate study' },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.FOUR_YEAR_OF_UNDER_GRADUATE_STUDY' }), value: 'Four years of undergraduate study' },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_BACHELORS_DEGREE_PLUS_ONE_YEAR_OF_POSTGRADUATE_STUDY' }), value: "US bachelor's degree + 1 year of postgraduate study" },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ONE_YEAR_OF_POSTGRADUATE_STUDY' }), value: '1 year of postgraduate study' },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TWO_YEARS_OF_POSTGRADUATE_STUDY' }), value: '2 years of postgraduate study' },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_MASTERS_DEGREE' }), value: "US master's degree" },
    { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.DOCTORATE_DEGREE' }), value: "Doctorate degree" },
  ];
}

export const GEN_EVAL_REQUIRED_FIELDS = [
  'university',
  'type_of_eval',
  'first_name',
  'last_name',
  'no_of_repeats',
  'accreditation',
  'graduation_year',
  'credential_name',
  'admission_req',
  'college',
  'us_equivalency',
  'major',
  'admission_year',
  'total_cgpa',
  'additional_comment',
  'state',
  'city',
  'program_duration',
];

export const GEN_EVAL_APPEND_UG_PG = [
  'university',
  'country',
  'no_of_repeats',
  'accreditation',
  'graduation_year',
  'gpa',
  'gpa_letter_grade',
  'credential_name',
  'admission_req',
  'college',
  'us_equivalency',
  'major',
  'admission_year',
  'total_cgpa',
  'additional_comment',
  'state',
  'city',
  'program_duration',
  'temp_comments',
];

export const DEFAULT_COMMENTS = (translator: IntlShape) => {
 return [
  { label: translator.formatMessage({ id: 'GENERAL_EVALUATION.NONE' }), value: 'None'},
  {
    label: translator.formatMessage({ id: 'GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_90_OUT_120_CRED_TILL_3RD_YEAR' }),
    value:
      'The candidate has completed 90 out of 120 credits till 3rd year of undergraduate studies. The candidate will complete the rest of the 30 credits in the upcoming semester/year.',
  },
  {
    label: translator.formatMessage({ id: 'GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_105_OUT_120_CRED_TILL_7TH_SEM' }),
    value:
      'The candidate has completed 105 out of 120 credits till 7th semester of undergraduate studies. The candidate will complete the rest of the 15 credits in the upcoming semester.',
  },
  {
    label: translator.formatMessage({ id: 'GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_100_OUT_120_CRED_TILL_5TH_SEM' }),
    value:
      'The candidate has completed 100 out of 120 credits till 5th semester of undergraduate studies. The candidate will complete the rest of the 20 credits in the upcoming semester.',
  },
  {
    label: translator.formatMessage({ id: 'GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_80_OUT_120_CRED_TILL_2ND_YEAR' }),
    value:
      'The candidate has completed 80 out of 120 credits till 2nd year of undergraduate studies. The candidate will complete the rest of the 40 credits in the upcoming semester/year.',
  },
  {
    label: translator.formatMessage({ id: 'GENERAL_EVALUATION.THE_CANDIDATE_GOT_A_LATERAL_ENTRY_IN_2ND_YEAR_OF_BACHELOR' }),
    value:
      "The candidate has got a lateral entry in the 2nd year of Bachelor's Degree after completion of a diploma.",
  },
  {
    label: translator.formatMessage({ id: 'GENERAL_EVALUATION.THE_CANDIDATE_GOT_A_LATERAL_ENTRY_IN_3RD_YEAR_OF_BACHELOR' }),
    value:
      "The candidate has got a lateral entry in the 3rd year of Bachelor's Degree after completion of a diploma.",
  },
  {
    label: translator.formatMessage({ id: 'GENERAL_EVALUATION.THE_CANDIDATE_COMPLETED_A_INTERNSHIP_AS PART_OF_FINAL_YEAR' }),
    value:
      'The candidate has completed a compulsory Internship as a part of the final year of Doctor of Pharmacy degree.',
  },
];
}

export const initialEvalUgValues: IEvalUg = {
  status: 0,
  first_name: '',
  last_name: '',
  ug_university: '',
  type_of_eval: '',
  ug_scale: '',
  ug_no_of_repeats: 0,
  ug_accreditation: '',
  ug_graduation_year: '',
  ug_gpa: 0,
  ug_n_gpa: 0,
  ug_n_gpa_letter_grade: '',
  ug_country: '',
  ug_gpa_letter_grade: '',
  ug_credential_name: '',
  ug_admission_req: '',
  ug_college: '',
  ug_us_equivalency: '',
  ug_major: '',
  ug_admission_year: '',
  ug_total_cgpa: '',
  ug_additional_comment: '',
  ug_state: '',
  ug_city: '',
  ug_program_duration: 0,
  eval_date: 0,
  ug_temp_comments: '',
  eval_by: '',
  review_by: '',
  ug_out_of: 0,
};

export const initialEvalPgValues: IEvalPg = {
  status: 0,
  pg_university: '',
  type_of_eval: '',
  pg_scale: '',
  pg_no_of_repeats: 0,
  pg_accreditation: '',
  pg_graduation_year: '',
  pg_gpa: 0,
  pg_n_gpa: 0,
  pg_country: '',
  pg_gpa_letter_grade: '',
  pg_n_gpa_letter_grade: '',
  pg_credential_name: '',
  pg_admission_req: '',
  pg_college: '',
  pg_us_equivalency: '',
  pg_major: '',
  pg_admission_year: '',
  pg_total_cgpa: '',
  pg_additional_comment: '',
  pg_state: '',
  pg_city: '',
  pg_program_duration: 0,
  eval_date: 0,
  pg_temp_comments: '',
  pg_out_of: 0,
  eval_by: '',
  review_by: '',
};

export const initialEvalHsUgValues: IEvalHsUg = {
  status:0,
  first_name: '',
  last_name: '',
  type_of_eval: '',
  ug_school: '',
  ug_gpa: 0,
  ug_scale: '',
  ug_country: '',
  ug_gpa_letter_grade: '',
  ug_additional_comment: '',
  ug_state: '',
  ug_city: '',
  ug_program_duration: 0,
  eval_date: 0,
  ug_temp_comments: '',
  ug_out_of: 0,
  eval_by: '',
  review_by: '',
};

export const initialEvalHsPgValues: IEvalHsPg = {
  first_name: '',
  last_name: '',
  type_of_eval: '',
  pg_school: '',
  pg_gpa: 0,
  pg_scale: '',
  pg_country: '',
  pg_gpa_letter_grade: '',
  pg_additional_comment: '',
  pg_state: '',
  pg_city: '',
  pg_program_duration: 0,
  eval_date: 0,
  pg_temp_comments: '',
  eval_by: '',
  review_by: '',
  pg_out_of: 0,
};

export const ORG_CODES = {
  slu: 'slu',
  dpu: 'dpu',
  cua: 'cua',
  sluhs: 'sluhs',
};

export const initialEvaluatorName: IEvaluatorName = {
  givenName: '',
  familyName: ''
};

export const EvalUgSchema = (translator: IntlShape) => {
return yup.object().shape({
  status: yup.number(),
  first_name: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.FIRST_NAME_IS_REQUIRED' })),
  last_name: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.LAST_NAME_IS_REQUIRED' })),
  ug_university: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.UNIVERSITY_IS_REQUIRED' })),
  type_of_eval: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TYPE_OF_EVALUATION_IS_REQUIRED' })),
  ug_scale: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.SCALE_IS_REQUIRED' })),
  ug_no_of_repeats: yup.number().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.NUMBER_OF_REPEATS_IS_REQUIRED' })),
  ug_accreditation: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ACCREDITATION_IS_REQUIRED' })),
  ug_graduation_year: yup
    .string()
    .required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.GRADUATION_YEAR_IS_REQUIRED' }))
    .test(
      'is-greater-than-admission',
      `${ translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.GRADUATION_YEAR_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ADMISSION_YEAR' })}`,
      function (value) {
        const admissionYearAsNumber = parseInt(this.resolve(yup.ref('ug_admission_year')), 10);
        const graduationYearAsNumber = parseInt(value, 10);
        return graduationYearAsNumber >= admissionYearAsNumber;
      }
    ),
  ug_gpa: yup.number().optional(),
  ug_country: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.COUNTRY_IS_REQUIRED' })),
  ug_gpa_letter_grade: yup.string().optional(),
  ug_credential_name: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.CREDENTIAL_NAME_IS_REQUIRED' })),
  ug_admission_req: yup.string().optional(),
  ug_college: yup.string().optional(),
  ug_us_equivalency: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_EQUIVALENCY_IS_REQUIRED' })),
  ug_major: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.MAJOR_IS_REQUIRED' })),
  ug_admission_year: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ADMISSION_YEAR_IS_REQUIRED' })),
  ug_total_cgpa: yup.string().when(['type_of_eval'], ([type_of_eval], schema) => {
    return type_of_eval === CBC ? schema.required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TOTAL_CGPA_IS_REQUIRED' })) : schema;
  }),
  ug_additional_comment: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ADDITIONAL_COMMENT_IS_REQUIRED' })),
  ug_state: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.STATE_IS_REQUIRED' })),
  ug_city: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.CITY_IS_REQUIRED' })),
  ug_program_duration: yup.number().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.PROGRAM_DURATION_IS_REQUIRED' })),
  eval_date: yup.number().optional(),
  ug_temp_comments: yup.string(),
  eval_by: yup.string().optional(),
  review_by: yup.string().optional(),
  ug_out_of: yup.number().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.OUT_OF_IS_REQUIRED' })),
});
}

export const EvalPgSchema  = (translator: IntlShape) => {
  return yup.object().shape({
  status: yup.number().optional(),
  pg_university: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.UNIVERSITY_IS_REQUIRED' })),
  type_of_eval: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TYPE_OF_EVALUATION_IS_REQUIRED' })),
  pg_scale: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.SCALE_IS_REQUIRED' })),
  pg_no_of_repeats: yup.number().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.NUMBER_OF_REPEATS_IS_REQUIRED' })),
  pg_accreditation: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ACCREDITATION_IS_REQUIRED' })),
  pg_graduation_year: yup
    .string()
    .required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.GRADUATION_YEAR_IS_REQUIRED' }))
    .test(
      'is-greater-than-admission',
      `${ translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.GRADUATION_YEAR_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ADMISSION_YEAR' })}`,
      function (value) {
        const admissionYearAsNumber = parseInt(this.resolve(yup.ref('pg_admission_year')), 10);
        const graduationYearAsNumber = parseInt(value, 10);
        return graduationYearAsNumber >= admissionYearAsNumber;
      }
    ),
  pg_gpa: yup.number().optional(),
  pg_country: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.COUNTRY_IS_REQUIRED' })),
  pg_gpa_letter_grade: yup.string().optional(),
  pg_credential_name: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.CREDENTIAL_NAME_IS_REQUIRED' })),
  pg_admission_req: yup.string().optional(),
  pg_college: yup.string().optional(),
  pg_us_equivalency: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.US_EQUIVALENCY_IS_REQUIRED' })),
  pg_major: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.MAJOR_IS_REQUIRED' })),
  pg_admission_year: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ADMISSION_YEAR_IS_REQUIRED' })),
  pg_total_cgpa: yup.string().when(['type_of_eval'], ([type_of_eval], schema) => {
    return type_of_eval === CBC ? schema.required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TOTAL_CGPA_IS_REQUIRED' })) : schema;
  }),
  pg_additional_comment: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ADDITIONAL_COMMENT_IS_REQUIRED' })),
  pg_state: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.STATE_IS_REQUIRED' })),
  pg_city: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.CITY_IS_REQUIRED' })),
  pg_program_duration: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.PROGRAM_DURATION_IS_REQUIRED' })),
  eval_date: yup.number().optional(),
  pg_temp_comments: yup.string(),
  eval_by: yup.string().optional(),
  review_by: yup.string().optional(),
  pg_out_of: yup.number().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.OUT_OF_IS_REQUIRED' })),
});
}

export const EvalHsUgSchema = (translator: IntlShape) => {
  return yup.object().shape({
  first_name: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.FIRST_NAME_IS_REQUIRED' })),
  last_name: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.LAST_NAME_IS_REQUIRED' })),
  type_of_eval: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TYPE_OF_EVALUATION_IS_REQUIRED' })),
  ug_school: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.SCHOOL_IS_REQUIRED' })),
  ug_gpa: yup.number().optional(),
  ug_gpa_letter_grade: yup.string().optional(),
  ug_additional_comment: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ADDITIONAL_COMMENT_IS_REQUIRED' })),
  ug_state: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.STATE_IS_REQUIRED' })),
  ug_city: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.CITY_IS_REQUIRED' })),
  ug_program_duration: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.PROGRAM_DURATION_IS_REQUIRED' })),
  eval_date: yup.number().optional(),
  ug_temp_comments: yup.string(),
  eval_by: yup.string().optional(),
  ug_review_by: yup.string().optional(),
  ug_out_of: yup.number().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.OUT_OF_IS_REQUIRED' })),
});
}

export const EvalHsPgSchema = (translator: IntlShape) => {
  return yup.object().shape({
  first_name: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.FIRST_NAME_IS_REQUIRED' })),
  last_name: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.LAST_NAME_IS_REQUIRED' })),
  pg_school: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.SCHOOL_IS_REQUIRED' })),
  type_of_eval: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.TYPE_OF_EVALUATION_IS_REQUIRED' })),
  pg_gpa: yup.number().optional(),
  pg_gpa_letter_grade: yup.string().optional(),
  pg_additional_comment: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.ADDITIONAL_COMMENT_IS_REQUIRED' })),
  pg_state: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.STATE_IS_REQUIRED' })),
  pg_city: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.CITY_IS_REQUIRED' })),
  pg_program_duration: yup.string().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.PROGRAM_DURATION_IS_REQUIRED' })),
  eval_date: yup.number().optional(),
  pg_temp_comments: yup.string(),
  eval_by: yup.string().optional(),
  review_by: yup.string().optional(),
  pg_out_of: yup.number().required(translator.formatMessage({ id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.OUT_OF_IS_REQUIRED' })),
});
}

// This will return data if there is any changes in previous data and current data
export const getChanges = <T>(dataFromApi: Partial<T>, formIkValues: Partial<T>): Partial<T> => {
  const changes: Partial<T> = {};
  Object.keys(dataFromApi).forEach((key) => {
    const formValue = formIkValues[key as keyof T];
    const apiValue = dataFromApi[key as keyof T];
    if (formValue !== apiValue) {
      if (typeof formValue === 'string') {
        changes[key as keyof T] = (formValue as string).trim() as unknown as T[keyof T];
      } else {
        changes[key as keyof T] = formValue;
      }
    }
  });
  return changes;
};
