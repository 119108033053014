import React from 'react';
import Modal from 'react-modal';

import {IDialogueBox} from '../component-interface';

const DialogBox: React.FC<IDialogueBox> = ({isOpen, onClose, children, zIndex}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      contentLabel='A4 Size Dialog'
      style={{overlay: {zIndex: zIndex ?? 0}}}
    >
      <button
        className='btn btn-danger'
        onClick={onClose}
        style={{position: 'absolute', top: '10px', right: '10px', alignContent: 'center'}}
      >
        Close <div className='fa fa-close fa-1x'></div>
      </button>
      <div className='d-flex justify-content-center align-align-items-center mt-10 p-2'>
        {children}
      </div>
    </Modal>
  );
};

export default DialogBox;
