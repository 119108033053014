export const TOTAL_US_CREDITS_DROPDOWN = [
  {value: 150, label: 150},
  {value: 120, label: 120},
  {value: 105, label: 105},
  {value: 100, label: 100},
  {value: 90, label: 90},
  {value: 80, label: 80},
  {value: 75, label: 75},
  {value: 60, label: 60},
  {value: 30, label: 30},
  {value: 15, label: 15},
]

export const TOTAL_US_CREDITS_DEFAULT_VALUE: {[key: string]: number} = {
    "US bachelor's degree": 120,
    "One year of undergraduate study": 30,
    "Two years of undergraduate study": 60,
    "Three years of undergraduate study": 90,
    "Four years of undergraduate study": 120,
    "US bachelor's degree + 1 year of postgraduate study": 150,
    "1 year of postgraduate study": 15,
    "2 years of postgraduate study": 30,
    "US master's degree": 30,
    "Doctorate degree":150
}

export const ROLES = {
  Team_Leader: "Team Leader",
  Admin: "Admin",
  Moderator: "Moderator",
  User: "User",
};

export const REGEX = /^[0-9\b]+$/