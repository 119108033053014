import {useState, useEffect, useCallback, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {getValueLocalStorage, setValueLocalStorage} from '../../auth/core/_requests';
import {toast} from 'react-toastify';
import {CBC, EvalHsUgSchema, getChanges, initialEvalHsUgValues, initialEvaluatorName} from './general-evaluation-constants';
import {autoSaveCredentialEvalData} from '../../../../utils/common-services';
import {DEFAULT_COMMENTS} from './general-evaluation-constants';
import Transcript from '../../../components/transcript/transcript';
import DialogBoxWithInput from '../../../components/dialog-box-with-input/dialog-box-with-input';
import {useAuth} from '../../auth';
import EditableSelect from '../../../components/editable-select/editable-select';
import {ICredEvalLocalStorageData, IKeyValues} from '../../../../utils/common-interface';
import { ILableValue } from '../../page-common-interface';
import { useIntl } from 'react-intl';
import { MY_WORK_STATUS } from '../../my-work/my-work-constants';
import { getFormattedDate } from '../../../../utils/utils';
import { commonConstant } from '../../../../utils/common-constant';
import { dropdownData, fetchGeneralEvalDbData, sendBackToEvaluator, updatePgData } from '../_service/type-of-evaluation-services';
import { IAppEvalUgPgData, IEvalHighSchoolInDifferentComponent, IEvalHsUg , IEvaluatorName} from '../evaluation-interfaces';
import GenericDialogueBox from '../../../components/generic-dialogue-box/generic-dialogue-box';

const EvalHighSchoolUnderGraduate: React.FC<IEvalHighSchoolInDifferentComponent> = ({
  isAutoSave,
  setIsAutoSavingMain,
  sendToNextLevelApp,
  isSendToNextLevelLoading,
  setCbcData,
  goToPreview,
  setPreviewData,
}) => {
  const translator = useIntl();
  const formIk = useFormik({
    initialValues: initialEvalHsUgValues,
    validationSchema: EvalHsUgSchema(translator),
    onSubmit: () => {},
  });
  const navigate = useNavigate();
  const {currentUser} = useAuth();
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const localStorageData = getValueLocalStorage(commonConstant.credEvalData) || '';
  const credEvalData: ICredEvalLocalStorageData | undefined =
    localStorageData.length > 0 ? JSON.parse(localStorageData) : undefined;
  const [dataFromApi, setDataFromApi] = useState<IEvalHsUg>({} as IEvalHsUg);
  const [isLoading, setIsLoading] = useState(false);
  const [schools, setSchools] = useState<ILableValue[]>([]);
  const [cities, setCities] = useState<ILableValue[]>([]);
  const [states, setStates] = useState<ILableValue[]>([]);
  const [additionalComments, setAdditionalComments] =
    useState<ILableValue[]>(DEFAULT_COMMENTS(translator));
  const [isPgEvaluation, setIsPgEvaluation] = useState<boolean>(
    credEvalData?.isPgEvaluation ? true : false
  );
  const [isDropDownLoading, setIsDropDownLoading] = useState<boolean>(false);
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
  const [isReEvalDialogOpen, setIsReEvalDialogOpen] = useState(false);
  const [isSendBackToEvaluator, setIsSendBackToEvaluator] = useState(false);
  const [isSendToNextLevelDialogOpen, setSendToNextLevelDialogOpen] = useState(false);
  const [displayEvaluatorName, setDisplayEvaluatorName] = useState(false);
  const [evaluatorName, setEvaluatorName] = useState<IEvaluatorName>(initialEvaluatorName);
   
  const autoSaveData = async (dataToUpdate: IEvalHsUg) => {
    try {
      if (isAutoSaving || !formIk.values.type_of_eval || !isAutoSave) {
        return;
      }
      const localStorage = getValueLocalStorage(commonConstant.credEvalData) || '';
      const credEval: ICredEvalLocalStorageData | undefined =
      localStorage.length > 0 ? JSON.parse(localStorage) : undefined;
      setIsAutoSaving(() => true);
      const changes: IKeyValues = {};
      Object.entries(dataToUpdate).forEach(([key, value]) => {
        if (dataFromApi[key as keyof IEvalHsUg] !== value)
          changes[key] = typeof value === 'string' ? value.trim() : value;
      });
      if (Object.keys(changes).length === 0) {
        setIsAutoSaving(false);
        return;
      }
      const response = await autoSaveCredentialEvalData(
        credEval?.appIdWithOrgCode || '',
        changes,
        commonConstant.typeOfEvaluation
      );
      if (response) {
        setDataFromApi((prevData) => ({
          ...prevData,
          ...response,
        }));
        formIk.setValues((prev) => ({
          ...prev,
          ...response,
        }));
      }
    } catch (err) { } 
    finally {
      setIsAutoSaving(() => false);
    }
  };

  const useDebounce = (callback: (args: any) => void, delay: number) => {
    const timeoutRef = useRef<any>(null);
    const debounceTimeout = useCallback(
      (args: any) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          callback(args);
        }, delay);
      },
      [callback, delay]
    );
    return debounceTimeout;
  };

  const debounceValue = useDebounce(autoSaveData, 3000);
  // Effect to handle auto-saving when the queue changes

  useEffect(() => {
    if (!isAutoSaving && formIk.values.type_of_eval && isAutoSave) {
      debounceValue(formIk.values);
    }
  // eslint-disable-next-line
  }, [debounceValue, isAutoSave]);

  // patch value to form Ik fields
  useEffect(() => {
    const localStorage = getValueLocalStorage(commonConstant.credEvalData) || '';
    const credEval: ICredEvalLocalStorageData | undefined =
      localStorage.length > 0 ? JSON.parse(localStorage) : undefined;
    const fetchDataFromAPI = async () => {
      try {
        const data = await fetchGeneralEvalDbData(credEval?.appIdWithOrgCode || '');
        if (data) {
          const resultData: IEvalHsUg = {
            status: data.status,
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            type_of_eval: data.type_of_eval || '',
            ug_scale: data.ug_scale || '',
            ug_country: data.ug_country || '',
            ug_school: data.ug_school || '',
            ug_gpa: data.ug_gpa || 0,
            ug_gpa_letter_grade: data.ug_gpa_letter_grade || '',
            ug_additional_comment: data.ug_additional_comment || '',
            ug_state: data.ug_state || '',
            ug_city: data.ug_city || '',
            ug_program_duration: data.ug_program_duration,
            eval_date: data.eval_date,
            ug_temp_comments: data.ug_temp_comments || '',
            eval_by: data.eval_by || currentUser?.user_code || '',
            review_by: data.review_by || currentUser?.user_code || '',
            ug_out_of: data.ug_out_of || 0,
          };
          formIk.setValues({
            ...resultData,
            ug_program_duration: !data.ug_program_duration ? 0 : data.ug_program_duration,
          });
          setDataFromApi(resultData);
          setPreviewData(data);
          setCbcData(data.cbc);
          if (data.eval_family_Name && data.eval_given_Name) {
            setEvaluatorName({
              givenName: data.eval_given_Name,
              familyName: data.eval_family_Name,
            });
          } else {
            setEvaluatorName({
              givenName: '',
              familyName: '',
            });
          }
        }
      } catch (error) {}
    };
    const fetchAllDropDownData = async () => {
      try {
        setIsDropDownLoading(true);
        const responseData: IAppEvalUgPgData[] = await dropdownData();   
        const processResponse = (key: string): ILableValue[] => {
          const uniqueItems = new Set<string>();
          responseData?.forEach(item => {
            const resValue = (item as IKeyValues)[key];
            if (resValue) {
              uniqueItems.add(resValue!);
            }
          });
          return Array.from(uniqueItems).map(item => ({ label: item, value: item }));
        };
        setCities(processResponse('ug_city'));
        setStates(processResponse('ug_state'));
        setSchools(processResponse('ug_school'));
        setIsDropDownLoading(false);
      } catch (error) {
        setIsDropDownLoading(false);
      }
    };
    fetchDataFromAPI();
    fetchAllDropDownData();
  // eslint-disable-next-line
  }, []);

  // handle navigate
  const handleChangeNavigation = async (navigateTo: string) => {
    await onSaveClick();
    navigate(navigateTo);
  };

  // on save click handle data
  const onSaveClick = async (temp_comments?: string) => {
    setIsLoading(true);
    const changes = getChanges<IEvalHsUg>(dataFromApi, formIk.values);
    //add temp_comments
    if (temp_comments && dataFromApi.ug_temp_comments !== temp_comments)
      changes['ug_temp_comments'] = temp_comments;
    if (!formIk.values.eval_date) changes['eval_date'] = new Date().getTime();
    if (Object.keys(changes).length === 0) {
      if (!toast.isActive('save-success'))
        toast.success(translator.formatMessage({ id: 'COMMON.NO_CHANGES_TO_SAVE' }), {toastId: 'save-success'});
      setIsLoading(false);
      return;
    }
    try {
      const response = await updatePgData(credEvalData?.appIdWithOrgCode || '', changes);
      if (response) {
        if (!toast.isActive('save-success'))
          toast.success(translator.formatMessage({ id: 'COMMON.MESSAGE.SUCCESSFULLY_UPDATED_DATA' }), {toastId: 'save-success'});
        setDataFromApi((prevData) => {
          return {
            ...prevData,
            ...response,
          };
        });
        formIk.setValues((prev) => {
          return {
            ...prev,
            ...response,
          };
        });
        setPreviewData(response);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG' }));
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // set the data in formIk
  const handleInputChange = (event: {name: string; value: any}) => {
    const {name, value} = event;
    formIk.setFieldValue(name, value);
  };

  // on click of save button in popup
  function handleCommentDialogueClose() {
    setIsCommentDialogOpen(false);
  }

  // on click of close button in re eval popup
  async function handleCommentDialogueSave(inputData?: string) {
    await onSaveClick(inputData);
  }

  // handle re eval remark dialog close
  function handleReEvalRemarkDialogClose() {
    setIsReEvalDialogOpen(false);
  }

  // Shows evaluator details on mouse hover.
  const handleMouseOver = () => {
    if (evaluatorName.givenName && evaluatorName.familyName) {
      setDisplayEvaluatorName(true);
    }
  };

  // Hides evaluator details on mouse out.
  const handleMouseOut = () => {
    setDisplayEvaluatorName(false);
  };

  // handle send back to evaluator
  const handleSendBackToEvaluator = async (inputData?: string) => {
    try {
      setIsSendBackToEvaluator(true);
      const response = await sendBackToEvaluator(
        credEvalData?.appIdWithOrgCode || '',
        inputData || ''
      );
      if (response) {
        toast.success(translator.formatMessage({ id: 'COMMON.MESSAGE.SUCCESSFULLY_SENT_BACK_TO_EVALUATOR' }));
        navigate('/my-work');
      } else {
        toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG' }));
      }
    } catch (error) {
    } finally {
      setIsReEvalDialogOpen(false);
      setIsSendBackToEvaluator(false);
    }
  };

  // handle routing changes
  const routeChange = async () => {
    setValueLocalStorage(
      commonConstant.credEvalData,
      JSON.stringify({
        ...credEvalData,
        isPgEvaluation: !isPgEvaluation,
      })
    );
    setIsPgEvaluation(!isPgEvaluation);
    await onSaveClick();
    navigate('/type-of-evaluation/evalMain?page=evalHsPg');
  };

  // on click of send to next level button
  const handleSendToNextLevel = async () => {
    await onSaveClick();
    if (formIk.isValid) {
      sendToNextLevelApp();
    } else {
      toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.PLEASE_FILL_REQUIRED_FIELDS' }));
    }
  };

  // on click of send Next Level popup
  const handleYes = async () => {
    try {
      await handleSendToNextLevel();
      setSendToNextLevelDialogOpen(false);
    } catch (error) {}
  };
  
  // on click of send Next Level popup
  const handleNo = async () => {
    setSendToNextLevelDialogOpen(false);
  };
  
  // on click of send Next Level popup
  const handleClose = async () => {
    setSendToNextLevelDialogOpen(false);
  };

  return (
    <div>
      <div className='d-flex flex-column  flex-xl-row flex-row-fluid mt-3'>
        <div className='card col-12 col-sm-12 col-xl-4 p-10 m-1'>
          <div className='form-group row mt-2'>
            <label htmlFor='appId' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.APPLICATION_IDS' })}:
            </label>
            <div className=''>
              <input
                id='appId'
                readOnly
                value={credEvalData?.appId}
                placeholder={ translator.formatMessage({ id: 'INPUT.APPLICATION_IDS' })}
                className='w-100 ps-xl-3 form-control'
                disabled
              />
            </div>
          </div>

          <div className='form-group row mt-2'>
            <label htmlFor='first_name' className=' ps-3 form-check-label'>
              { translator.formatMessage({ id: 'INPUT.FIRST_NAME' })}:
            </label>
            <div className=''>
              <input
                id='first_name'
                name='first_name'
                disabled={isDropDownLoading}
                value={formIk.values.first_name || ''}
                onChange={(e) => handleInputChange({name: 'first_name', value: e.target.value})}
                onBlur={(e) => {
                  formIk.handleBlur(e);
                }}
                placeholder={ translator.formatMessage({ id: 'INPUT.FIRST_NAME' })}
                className='w-100 ps-xl-3 form-control'
              />
              {formIk.touched.first_name && formIk.errors.first_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.first_name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='form-group row mt-2'>
            <label htmlFor='last_name' className=' ps-3 form-check-label'>
              { translator.formatMessage({ id: 'INPUT.LAST_NAME' })} :
            </label>
            <div className=''>
              <input
                id='last_name'
                name='last_name'
                placeholder={ translator.formatMessage({ id: 'INPUT.LAST_NAME' })} 
                disabled={isDropDownLoading}
                value={formIk.values.last_name || ''}
                onChange={(e) => handleInputChange({name: 'last_name', value: e.target.value})}
                onBlur={(e) => {
                  formIk.handleBlur(e);
                }}
                className='ps-xl-3 w-100 form-control'
              />
              {formIk.touched.last_name && formIk.errors.last_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.last_name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='form-group row mt-2'>
            <label className='ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.SCHOOL' })} :</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='ug_school'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_SCHOOL' })}
              formIkData={formIk.values.ug_school}
              option={schools}
              setOptions={setSchools}
              formik={formIk}
              alertData={formIk.touched.ug_school}
              alertMessage={formIk.errors.ug_school}
            />
          </div>
          <div className='form-group row mt-2'>
            <label className='ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.CITY' })} :</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='ug_city'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_CITY' })}
              formIkData={formIk.values.ug_city}
              option={cities}
              setOptions={setCities}
              formik={formIk}
              alertData={formIk.touched.ug_city}
              alertMessage={formIk.errors.ug_city}
            />
          </div>
          <div className='form-group row mt-2'>
            <label className='ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.STATE' })}:</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='ug_state'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_STATE' })}
              formIkData={formIk.values.ug_state}
              option={states}
              setOptions={setStates}
              formik={formIk}
              alertData={formIk.touched.ug_state}
              alertMessage={formIk.errors.ug_state}
            />
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='program_duration' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.DURATION_OF_PROGRAM' })} :
            </label>
            <div className='w-100'>
              <input
                id='ug_program_duration'
                type='number'
                min='0'
                name='ug_program_duration'
                disabled={isDropDownLoading}
                placeholder={ translator.formatMessage({ id: 'INPUT.DURATION_OF_PROGRAM' })}
                value={formIk.values.ug_program_duration}
                onChange={(e) =>
                  handleInputChange({
                    name: 'ug_program_duration',
                    value: parseInt('' + e.target.value),
                  })
                }
                onBlur={(e) => {
                  formIk.handleBlur(e);
                }}
                className='w-100 ps-3 form-control'
              />
              {formIk.touched.ug_program_duration && formIk.errors.ug_program_duration && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.ug_program_duration}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='additional_comment' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.ADDITION_COMMENTS' })} :
            </label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='ug_additional_comment'
              placeholder={ translator.formatMessage({ id: 'INPUT.ADDITION_COMMENTS' })}
              formIkData={formIk.values.ug_additional_comment}
              option={additionalComments}
              setOptions={setAdditionalComments}
              formik={formIk}
              alertData={formIk.touched.ug_additional_comment}
              alertMessage={formIk.errors.ug_additional_comment}
            />
          </div>
        </div>
        <DialogBoxWithInput
          dynamicData={{
            title: translator.formatMessage({ id: 'COMMON.MESSAGE.ARE_YOU_SURE_YOU_WANT_TO_SAVE_ANY_COMMENTS' }),
            inputData: formIk.values.ug_temp_comments,
            inputLabel: translator.formatMessage({ id: 'INPUT.COMMENTS' }),
            inputPlaceHolder: translator.formatMessage({ id: 'INPUT.ENTER_COMMENTS' }),
            isLoading: isLoading,
            leftButton: translator.formatMessage({ id: 'BUTTONS.CLOSE' }),
            rightButton: translator.formatMessage({ id: 'BUTTONS.SAVE' }),
          }}
          onClose={handleCommentDialogueClose}
          onSubmit={handleCommentDialogueSave}
          isOpen={isCommentDialogOpen}
        ></DialogBoxWithInput>

        <Transcript
          appId={credEvalData?.appId || ''}
          organizationCode={credEvalData?.organizationCode || ''}
        />

        <div className='card p-10 m-1'>
          <div className='ps-xl-3 pe-xl-3'>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='ug_country'
                  readOnly
                  value={formIk.values.ug_country}
                  placeholder={ translator.formatMessage({ id: 'INPUT.COUNTRY' })}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='ug_gpa'
                  placeholder={ translator.formatMessage({ id: 'INPUT.GPA' })}
                  readOnly
                  type='number'
                  value={formIk.values.ug_gpa || 0}
                  disabled
                  className='w-100 form-control'
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='ug_gpa_letter_grade'
                  readOnly
                  type='string'
                  placeholder={ translator.formatMessage({ id: 'INPUT.GPA_LETTER_GRADE' })}
                  value={formIk.values.ug_gpa_letter_grade}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  type='text'
                  id='ug_eval_date'
                  placeholder={ translator.formatMessage({ id: 'INPUT.EVALUATION_DATE' })}
                  value={getFormattedDate(formIk.values.eval_date || Date.now())}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='eval_by'
                  placeholder={ translator.formatMessage({ id: 'INPUT.EVALUATOR_NAME' })}
                  value={`${formIk.values.eval_by}`}
                  className='w-100 form-control cursor-pointer'
                  disabled
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                />
                  {displayEvaluatorName &&  (
                   <div className='m-2'>
                    Evaluator: {evaluatorName.givenName} {evaluatorName.familyName}
                   </div>
                  )}
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-center flex-column flex-md-row flex-row-fluid flex-sm-column flex-wrap flex-xl-row mt-10 rounded'>
        <button
          type='button'
          id='go_to_type_of_eval'
          onClick={() => {
            handleChangeNavigation('/type-of-evaluation');
          }}
          className='btn btn-primary m-5'
        >
          { translator.formatMessage({ id: 'INPUT.TYPES_OF_EVALUATION' })}
        </button>

        <button
          type='button'
          onClick={(e) => goToPreview(formIk.values)}
          className='btn btn-primary m-5'
        >
          <i className='bi bi-eye'></i>
          { translator.formatMessage({ id: 'BUTTONS.PREVIEW' })}
        </button>

        <button
          type='button'
          onClick={() => setSendToNextLevelDialogOpen(true)}
          disabled={isSendToNextLevelLoading}
          className='btn btn-primary m-5'
        >
          {isSendToNextLevelLoading ? (
            <>
              <span className='spinner-border spinner-border-sm'></span>
              { translator.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
            </>
          ) : (
            <span>{ translator.formatMessage({ id: 'BUTTONS.SENT_TO_NEXT_LEVEL' }) }</span>
          )}
        </button>
        {isSendToNextLevelDialogOpen && (
          <GenericDialogueBox
            key={'is-Send-Next-Level'}
            name={translator.formatMessage({
              id: 'Are_sure_you_want_send_to_next_Level',
              defaultMessage: 'Are you sure you want to send to the next level?',
            })}
            open={isSendToNextLevelDialogOpen}
            onYes={handleYes}
            onNo={handleNo}
            onClose={handleClose}
          />
        )}
        {(formIk.values.status === Number(MY_WORK_STATUS.REVIEW_NEW) ||
          formIk.values.status === Number(MY_WORK_STATUS.REVIEW_IN_PROGRESS)) && (
          <button
            className='btn btn-primary m-5'
            onClick={() => {
              setIsReEvalDialogOpen(true);
            }}
          >
            <span>{ translator.formatMessage({ id: 'BUTTONS.SEND_BACK_TO_EVALUATOR' }) }</span>
          </button>
        )}

        {isReEvalDialogOpen && (
          <DialogBoxWithInput
            dynamicData={{
              title: translator.formatMessage({ id: 'COMMON.MESSAGE.WANT_TO_ADD_REMARK_FOR_RE_EVALUATION' }),
              inputData: '',
              inputLabel: translator.formatMessage({ id: 'INPUT.REMARKS' }),
              inputPlaceHolder: translator.formatMessage({ id: 'INPUT.ENTER_REMARKS' }),
              isLoading: isSendBackToEvaluator,
              leftButton: translator.formatMessage({ id: 'BUTTONS.CLOSE' }),
              rightButton: translator.formatMessage({ id: 'BUTTONS.SEND_BACK_TO_EVALUATOR' }),
            }}
            onClose={handleReEvalRemarkDialogClose}
            onSubmit={handleSendBackToEvaluator}
            isOpen={isReEvalDialogOpen}
          ></DialogBoxWithInput>
        )}
        <button
          type='button'
          className='btn btn-primary m-5'
          disabled={isLoading}
          onClick={() => {
            formIk.handleSubmit();
            if (formIk.isValid) {
              const changes = getChanges<IEvalHsUg>(dataFromApi, formIk.values);
              if (Object.keys(changes).length === 0) {
                setIsCommentDialogOpen(false);
                onSaveClick();
              } else {
                setIsCommentDialogOpen(true);
              }
            } else {
              toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.PLEASE_FILL_REQUIRED_FIELDS' }));
            }
          }}
        >
          {isLoading ? (
            <>
              <span className='spinner-border spinner-border-sm'></span>
              { translator.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
            </>
          ) : (
            <span>{ translator.formatMessage({ id: 'BUTTONS.SAVE' })}</span>
          )}
        </button>
        {credEvalData?.degreeOfEval === 2 && (
          <button type='button' onClick={routeChange} className='btn btn-primary m-5'>
            { translator.formatMessage({ id: 'BUTTONS.EVALUATE_HS2' }) }
          </button>
        )}

        {(credEvalData?.typeOfEval || '') === CBC && (
          <button
            type='button'
            className='btn btn-primary m-5'
            onClick={() => {
              handleChangeNavigation('/type-of-evaluation/course-by-course-eval?page=evalHsUg');
            }}
          >
            { translator.formatMessage({ id: 'BUTTONS.COURSE_BY_COURSE' })}
          </button>
        )}
      </div>
    </div>
  );
};

export default EvalHighSchoolUnderGraduate;
