import {useState, useEffect} from 'react';
import ToggleSwitch from '../../../components/toggle-switch/toggle-switch';
import {getValueLocalStorage} from '../../auth/core/_requests';
import {toast} from 'react-toastify';
import DialogBox from '../../../components/dialogue-box/dialogue-box';
import EvalUnderGraduate from './eval-under-graduate';
import {fetchOrganizationData} from '../../../../utils/common-services';
import {useNavigate} from 'react-router-dom';
import EvalPostGraduate from './eval-post-graduate';
import {ICredEvalLocalStorageData, IKeyValues} from '../../../../utils/common-interface';
import {IOrganization, IEvalHsPg, IEvalHsUg, IEvalPg, IEvalUg, ICbcData} from '../evaluation-interfaces';
import EvalHighSchoolUnderGraduate from './eval-high-school-under-graduate';
import EvalHighSchoolPostGraduate from './eval-high-school-post-graduate';
import { getHtml } from '../html-output';
import { useIntl } from 'react-intl';
import { commonConstant } from '../../../../utils/common-constant';
import { sendToNextLevel } from '../_service/type-of-evaluation-services';

const EvalMainWrapper = () => {
  const navigate = useNavigate();
  const localStorageData = getValueLocalStorage(commonConstant.credEvalData) || '';
  const credEvalData: ICredEvalLocalStorageData | undefined =
    localStorageData.length > 0 ? JSON.parse(localStorageData) : undefined;
  const [cbcData, setCbcData] = useState<ICbcData>({} as ICbcData);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isAutoSaveEnabled, setIsAutoSaveEnabled] = useState<boolean>(
    sessionStorage.getItem('autoSave')
      ? sessionStorage.getItem('autoSave') === 'true'
      : process.env.REACT_APP_IS_AUTO_SAVE === 'true'
  );
  const [organizationData, setOrganizationFetchData] = useState<IOrganization>({} as IOrganization);
  const [previewData, setPreviewData] = useState<IKeyValues>();
  const [isSendToNextLevelLoading, setIsSendToNextLevelLoading] = useState(false);
  const [pdfHtml, setPdfHtml] = useState<string>('');
  const translator = useIntl();
  // handle auto save button for boolean
  const handleAutoSaveToggle = () => {
    setIsAutoSaveEnabled(!isAutoSaveEnabled);
  };
  // handle auto save button is for set value
  const setIsAutoSavingInMain = (isAutoSaving: boolean): void => {
    setIsAutoSaving(isAutoSaving);
  };
  useEffect(() => {
    if (
      !credEvalData?.organizationName ||
      !credEvalData?.appId ||
      !credEvalData?.appIdWithOrgCode
    ) {
      navigate('/type-of-evaluation');
    }
    if (
      credEvalData?.gradeScaleData?.[credEvalData?.isPgEvaluation ? 'pgData' : 'ugData']?.length ===
      0
    ) {
      toast.error(translator.formatMessage({id: 'COMMON.INVALID_SCALE'}));
      navigate('/type-of-evaluation');
    }
    if (window.location.href.includes('evalHsPg') && credEvalData?.degreeOfEval !== 2) {
      navigate('/evalMain?page=evalHsUg');
      toast.error(
        translator.formatMessage({
          id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.YOU_EVALUATING_ONE_DEGREE',
        })
      );
    }
    if (window.location.href.includes('evalPg') && credEvalData?.degreeOfEval !== 2) {
      navigate('/evalMain?page=evalUg');
      toast.error(
        translator.formatMessage({
          id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.YOU_EVALUATING_ONE_DEGREE',
        })
      );
    }
    const fetchOrgData = async () => {
      try {
        const responseData = await fetchOrganizationData(
          'evalUgPg',
          credEvalData?.organizationCode || ''
        );
        const orgData = responseData.find((org) => org.name === credEvalData?.organizationName);
        if (!orgData) {
          toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.FAILED_TO_FETCH_ORG_DATA'}), {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/type-of-evaluation');
          return;
        }
        setOrganizationFetchData(orgData);
      } catch (error) {
        toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG'}), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    fetchOrgData();
  // eslint-disable-next-line
  }, []);

  const renderChildComponent = () => {
    const page = window.location.href.split('page=')?.[1];
    switch (page) {
      case 'evalUg':
        return (
          <EvalUnderGraduate
            isAutoSave={isAutoSaveEnabled}
            sendToNextLevelApp={sendToNextLevelApp}
            isSendToNextLevelLoading={isSendToNextLevelLoading}
            setCbcData={setCbcData}
            goToPreview={goToPreview}
            setPreviewData={handleSetPreviewData}
          />
        );
      case 'evalPg':
        if (credEvalData?.degreeOfEval === 2) {
          return (
            <EvalPostGraduate
              isAutoSave={isAutoSaveEnabled}
              setCbcData={setCbcData}
              goToPreview={goToPreview}
              setPreviewData={handleSetPreviewData}
            />
          );
        } else {
          navigate('/error/404');
          return;
        }
      case 'evalHsUg':
        return (
          <EvalHighSchoolUnderGraduate
            isAutoSave={isAutoSaveEnabled}
            setIsAutoSavingMain={setIsAutoSavingInMain}
            sendToNextLevelApp={sendToNextLevelApp}
            isSendToNextLevelLoading={isSendToNextLevelLoading}
            setCbcData={setCbcData}
            goToPreview={goToPreview}
            setPreviewData={(e) => handleSetPreviewData(e)}
          />
        );
      case 'evalHsPg':
        if (credEvalData?.degreeOfEval === 2) {
          return (
            <EvalHighSchoolPostGraduate
              isAutoSave={isAutoSaveEnabled}
              setCbcData={setCbcData}
              goToPreview={goToPreview}
              setPreviewData={handleSetPreviewData}
            />
          );
        } else {
          navigate('/error/404');
          return;
        }
      default:
        navigate('/error/404');
        return null;
    }
  };

  const getPageTitle = () => {
    const page = window.location.href.split('page=')?.[1];
    switch (page) {
      case 'evalUg':
        return translator.formatMessage({
          id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.UNDER_GRADUATE_EVALUATION',
        });
      case 'evalPg':
        if (credEvalData?.degreeOfEval === 2) {
          return translator.formatMessage({
            id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.POST_GRADUATE_EVALUATION',
          });
        } else {
          return '';
        }
      case 'evalHsUg':
        return translator.formatMessage({
          id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.HIGH_SCHOOL_EVALUATION',
        });
      case 'evalHsPg':
        return translator.formatMessage({
          id: 'GENERAL_EVALUATION.EVAL_MAIN_WRAPPER.HIGH_SCHOOL_EVALUATION',
        });
      default:
        return '';
    }
  };

  // preview click button
  const goToPreview = async (data: IEvalUg | IEvalPg | IEvalHsUg | IEvalHsPg) => {
    const htmlToRender = await getHtml(
      organizationData,
      cbcData,
      {...previewData, ...data},
      credEvalData
    );
    setPdfHtml(htmlToRender);
    setDialogOpen(true);
  };

  // send to next level button click
  const sendToNextLevelApp = async () => {
    setIsSendToNextLevelLoading(true);
    try {
      const response = await sendToNextLevel(credEvalData?.appIdWithOrgCode || '');
      if (response) {
        toast.success(translator.formatMessage({ id: 'COMMON.MESSAGE.SUCCESSFULLY_SENT_TO_NEXT_LEVEL' }));
        navigate('/my-work');
      } else {
        toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG' }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch {}
    finally { 
      setIsSendToNextLevelLoading(false) 
    };
  };

  // close dialog
  const closeDialog = () => {
    setDialogOpen(false);
  };

  // set html to show
  const handleSetPreviewData = (data: IEvalUg | IEvalPg | IEvalHsUg | IEvalHsPg) => {
    setPreviewData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  return (
    <>
      <div className='bg-body d-flex justify-content-between p-3 rounded'>
        <div>
          <h1 className='d-flex flex-center mt-2'>{getPageTitle()}</h1>
        </div>
        <div className='btn-group-toggle mt-2 flex-end' data-toggle='buttons'>
          <ToggleSwitch
            label={ translator.formatMessage({ id: 'COURSE_BY_COURSE_EVALUATION.COURSE_BY_COURSE_EVAL.AUTO_SAVE'}) }
            toggleHandler={handleAutoSaveToggle}
            isAutoSaving={isAutoSaving}
            value={isAutoSaveEnabled}
          ></ToggleSwitch>
        </div>
      </div>
      {renderChildComponent()}
      <DialogBox isOpen={isDialogOpen} onClose={closeDialog}>
        <div
          className='p-6 border border-dark-subtle'
          dangerouslySetInnerHTML={{__html: pdfHtml || ''}}
        />
      </DialogBox>
    </>
  );
};

export default EvalMainWrapper;
