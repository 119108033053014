import {get, post} from '../../../../auth/core/_requests'
import {
  CREATE_USER,
  FETCH_ORGANIZATION_NAMES,
  IS_USER_CONFIRMED,
  SEND_CODE_ON_EMAIL,
  VERIFY_CODE,
  VERIFY_EMP_CODE,
} from '../../../../../../utils/service-constants'
import { IResponse } from '../../../../../../utils/common-interface'

export const verifyEmpCode = async (empCode: string): Promise<boolean> => {
  try {
    const response = await get<IResponse<boolean>>(VERIFY_EMP_CODE + `/${empCode}`)
    return response.data?.success || false
  } catch (error) {
    throw error
  }
}

export const fetchOrganizationNames = async (): Promise<Array<{code: string; name: string}>> => {
  try {
    const response =
      await get<IResponse<Array<{code: string; name: string}>>>(FETCH_ORGANIZATION_NAMES)
    return response.data?.success || []
  } catch (error) {
    throw error
  }
}

export const createUser = async (formData: FormData): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(CREATE_USER, formData)
    return response.data?.success || false
  } catch (error) {
    throw error
  }
}

export const isUserConfirmed = async (email: string): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(IS_USER_CONFIRMED, {email})
    return response.data?.success || false
  } catch (error) {
    throw error
  }
}

export const sendCodeOnEmail = async (email: string): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(SEND_CODE_ON_EMAIL, {email})
    return response.data?.success || false
  } catch (error) {
    throw error
  }
}

export const verifyCode = async (code: string, email: string): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(VERIFY_CODE, {code, email})
    return response.data?.success || false
  } catch (error) {
    throw error
  }
}
