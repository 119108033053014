import React, {useState, useEffect} from 'react';
import Select, {MultiValue} from 'react-select';
import {saveAs} from 'file-saver';
import Papa from 'papaparse';
import {toast} from 'react-toastify';
import {
  getAllAppId,
  getUserData,
  submitAssignSpecificUsers,
  verifyCsvChecks,
} from './_service/admin-services';
import {fetchOrganizationData} from '../../../utils/common-services';
import { IUserProfileInfo } from '../auth/auth-model';
import {useIntl} from 'react-intl';
import {IAppIdAndUserId, ICsvFileData, IUniversityOptions, IVerifyCSVCheck} from './distribution.interface';
import { IKeyValues, ILabelValue, INameAndCode } from '../../../utils/common-interface';
import { commonConstant } from '../../../utils/common-constant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../utils/helpers';

const AssignAppToSpecific: React.FC = () => {
  const [appIds, setAppIds] = useState<string[]>([]);
  const [users, setUsers] = useState<IUserProfileInfo[]>([]);
  const [selectedAppIds, setSelectedAppIds] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUserProfileInfo | null>(null);
  const [verifiedCsvChecks, setVerifiedCsvChecks] = useState<IVerifyCSVCheck[]>([]); // selected store data 
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false); // loading data for dropdown if org select
  const [isDataSubmittingCsv, setIsDataSubmittingCSv] = useState<boolean>(false); // disable submit and loader for csv upload
  const [isDataSubmitting, setIsDataSubmitting] = useState<boolean>(false); // disable submit and loader for specific user
  const [isCsvVerified, setIsCsvVerified] = useState<boolean>(false); // is csv verified after upload
  const [unprocessedData, setUnprocessedData] = useState<string[]>([]); // show unprocessed data status
  const [organization, setOrganization] = useState<INameAndCode>({
    name: '',
    code: '',
  });
  const [universityOptions, setUniversityOptions] = useState<IUniversityOptions[]>([]);
  const [organizationForCsvUpload, setOrganizationForCsvUpload] = useState<INameAndCode>({
    name: '',
    code: '',
  });
  const [universityOptionsForCsvUpload, setUniversityOptionsForCsvUpload] = useState<IUniversityOptions[]>([]);
  const [formData, setFormData] = useState<{
    csvFileData: ICsvFileData[];
    selectedAppIds: string[];
    selectedUser: IUserProfileInfo | null;
  }>({
    csvFileData: [],
    selectedAppIds: [],
    selectedUser: null,
  });
  const translator = useIntl();
  // fetch dropdown data
  useEffect(() => {
    setIsDataLoading(true);
    const fetchOrganizationsData = async () => {
      try {
        const data = await fetchOrganizationData(commonConstant.typeOfEval);
        if (data) {
          const universities = data.map((organization) => {
            return {
              label: organization.name || '',
              value: organization.name || '',
              code: organization.code.toLowerCase() || '',
            };
          });
          setUniversityOptions(universities);
          setUniversityOptionsForCsvUpload(universities);
        }
      } catch(error) {} 
       finally {
        setIsDataLoading(false);
      }
    };
    fetchOrganizationsData();
  }, []);

  const handleOrgChange = async (orgCode: string) => {
    try {
      setIsDataLoading(true);
      const appIds = await getAllAppId(orgCode);
      setAppIds(appIds);
      const userData = await getUserData(orgCode);
      setUsers(userData);
    } finally {
      setIsDataLoading(false);
    }
  };

  // download sample scv file for upload
  const downloadSampleCSV = () => {
    const sampleCSVData = `appIds,userEmails\nApp#slu123,john@example.com\nApp#cua1234,jane@example.com`;
    const blob = new Blob([sampleCSVData], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, `${translator.formatMessage({ id: 'COMMON.SAMPLE' })}.csv`);
  };

  // Handle selection of app IDs in the multi-select dropdown
  const handleAppIdSelect = (selected: MultiValue<ILabelValue>) => {
    setFormData({
      ...formData,
      selectedAppIds: selected.map((item) => item.value),
    });
    setSelectedAppIds(selected.map((item) => item.value));
    setUnprocessedData([]);
  };

  // Handle selection of a user in the single-select dropdown
  const handleUserSelect = (user: IUserProfileInfo | null) => {
    setFormData({
      ...formData,
      selectedUser: user,
    });
    setSelectedUser(user);
    setUnprocessedData([]);
  };

  // Handle CSV file upload
  const handleFileUpload = async (file: File | null) => {
    if (!file) return;
    let fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = (event: any) => {
      let csvData = event.target.result;
      let uploadResultData: IKeyValues[] = [];
      Papa.parse(csvData, {
        skipEmptyLines: true,
        header: true,
        complete: function (results: {data: ICsvFileData[]}) {
          uploadResultData = results.data;
       },
      });
      if(uploadResultData.length > 0) {
      uploadResultData.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if(typeof row[key] === 'string') {
            row[key] = row[key]?.trim();
          }
        });
      });
      uploadResultData = uploadResultData.filter((row) => row.appIds !== '' && row.userEmails !== '');
      const tableTitle = Object.keys(uploadResultData[0]);
      if (
        tableTitle[0] === 'appIds' &&
        tableTitle[1] === 'userEmails' &&
        tableTitle.length === 2
      ) {
        setFormData({
          ...formData,
          csvFileData: uploadResultData as ICsvFileData[],
        });
        handleVerifyCsvChecks(uploadResultData as ICsvFileData[]);
        setUnprocessedData([]);
      } else {
        toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.INVALID_DATA_FOUND_IN_CSV_FILE'}));
        return;
      }
    } else {
      toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.INVALID_DATA_FOUND_IN_CSV_FILE'}));
    }
    }
  };

  // Handle the click event when the user clicks the upload button
  const handleVerifyCsvChecks = async (
    csvData: ICsvFileData[]
  ) => {
    try {
      const data = await verifyCsvChecks({
        orgCode: organizationForCsvUpload.code,
        assignMentData: csvData.map((item) => {
          return {
            appId: item.appIds,
            userEmail: item.userEmails,
          };
        }),
      });
      setVerifiedCsvChecks((previous)=> [...data, ...previous]);

      if (data.filter((item) => item.result !== 'success').length === 0) {
        setIsCsvVerified(true);
      }
    } catch (e) {
      toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.INVALID_DATA_FOUND_IN_CSV_FILE'}));
    }
  };
 
  // On submit click of appId's and email of user
  const handleSubmitSpecificUserClick = async () => {
    try {
      setIsDataSubmitting(true);
      const appAndUserData: IAppIdAndUserId[] = [];
      if (selectedUser && selectedAppIds.length > 0) {
        const data = selectedAppIds.map((id) => {
          return {
            appId: id,
            userId: selectedUser.email,
          };
        });
        appAndUserData.push(...data);
      } else {
        toast.error(translator.formatMessage({ id: 'DISTRIBUTION.PLEASE_SELECT_APP_IDS_AND_USED_IDS'}));
        return;
      }
      const response = await submitAssignSpecificUsers(appAndUserData);
      if (response && response.length === 0) {
        toast.success(translator.formatMessage({ id: 'COMMON.MESSAGE.SUCCESSFULLY_ASSIGN_SELECTED_APP_IDS_TO_USER'}));
        resetFields();
      } else {
        toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.SOME_APPLICATION_ARE_IN_WRONG_POOL'}));
      }
      setUnprocessedData(response);
    } catch (e) {
      setUnprocessedData(selectedAppIds);
    } finally {
      setIsDataSubmitting(false);
    }
  };

  // selected csv file submit handle
  const handleSubmitClickCsv = async () => {
    try {
      setIsDataSubmittingCSv(true);
      const appAndUserData: IAppIdAndUserId[] = [];
      if (formData.csvFileData.length > 0) {
        formData.csvFileData.forEach((item) => {
          appAndUserData.push({appId: item.appIds, userId: item.userEmails});
        });
      } else {
        toast.error(translator.formatMessage({ id: 'COMMON.PLEASE_UPLOAD_CVS_FILE'}));
        return;
      }
      const response = await submitAssignSpecificUsers(appAndUserData);
      if (response && response.length === 0) {
        toast.success(translator.formatMessage({ id: 'COMMON.MESSAGE.SUCCESSFULLY_ASSIGN_SELECTED_APP_IDS_TO_USER'}));
        resetFields();
      }
      setUnprocessedData(response);
    } catch (e) {
      setUnprocessedData(selectedAppIds);
    } finally {
      setIsDataSubmitting(false);
      setIsDataSubmittingCSv(false);
    }
  };

  const resetFile = (event: React.MouseEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).value = '';
  }

  const resetFields = () => {
    setOrganization({name: '', code: ''});
    setSelectedUser(null);
    setSelectedAppIds([]);
    setUnprocessedData([]);
  };

  return (
    <div className='container card mb-10'>
      <h1 className='text-center p-10 m-3'>{ translator.formatMessage({ id: 'DISTRIBUTION.ASSIGN_APP_IDS_TO_SPECIFIC_USER' })}</h1>
      <div className='row pb-4'>
        <div className='col-md-5'>
          <div className='card border border-2'>
            <div className='card-header mt-2 justify-content-center align-content-center'>
              <h3 className='text-center mt-2'>{ translator.formatMessage({ id: 'DISTRIBUTION.SELECT_APP_ID_AND_EMAIL_OF_USER' })}</h3>
            </div>
            <div className='card-body'>
              <div className='mb-3'>
                <label className='required-field form-check-label'>{ translator.formatMessage({ id: 'INPUT.ORGANIZATION' })}</label>
                <Select
                  options={universityOptions}
                  value={{label: organization.name || '', value: organization.name || ''}}
                  name='organization'
                  inputId='organization'
                  required={true}
                  onChange={(e) => {
                    setAppIds([]);
                    setUsers([]);
                    setSelectedAppIds([]);
                    setSelectedUser(null);
                    setOrganization({
                      name: e?.value || '',
                      code: universityOptions.find((item) => item.label === e?.label)?.code || '',
                    });
                    handleOrgChange(
                      universityOptions.find((item) => item.label === e?.label)?.code || ''
                    );
                  }}
                  isSearchable={true}
                />
              </div>
              <div className='mb-3'>
                <label className='required-field form-label form-check-label'>{ translator.formatMessage({ id: 'DISTRIBUTION.SELECT_APP_IDS' })}</label>
                <Select
                  isMulti={true}
                  name='appIds'
                  inputId='appIds'
                  isLoading={isDataLoading}
                  options={appIds.map((item) => {
                    return {label: item, value: item};
                  })}
                  value={selectedAppIds.map((item) => {
                    return {label: item, value: item};
                  })}
                  onChange={handleAppIdSelect}
                />
              </div>
              <div className='mb-2'>
                <label className='required-field form-label form-check-label'>
                { translator.formatMessage({ id: 'DISTRIBUTION.SELECT_EMAIL_OF_USER_TO_ASSIGN' })}
                </label>
                <Select
                  options={users.map((user) => {
                    return {
                      label: user.email + ', ' + user.user_code,
                      value: user.email,
                    };
                  })}
                  name='userEmail'
                  inputId='userEmail'
                  isClearable={true}
                  isSearchable={true}
                  value={{
                    label: selectedUser ? selectedUser.email + ', ' + selectedUser?.user_code : '',
                    value: selectedUser ? selectedUser.email + ', ' + selectedUser?.user_code : '',
                  }}
                  isLoading={isDataLoading}
                  onChange={(e) =>
                    handleUserSelect(users.find((user) => user.email === e?.value) || null)
                  }
                />
              </div>
              <button
                type='submit'
                className='btn btn-primary my-2'
                disabled={isDataSubmitting || selectedAppIds.length === 0 || !selectedUser}
                onClick={handleSubmitSpecificUserClick}
              >
                {!isDataSubmitting && <span className='indicator-label'>{ translator.formatMessage({ id: 'BUTTONS.SUBMIT' })}</span>}
                {isDataSubmitting && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    { translator.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-2 d-flex justify-content-center'>
          <div className='d-flex strong align-items-center'>{ translator.formatMessage({ id: 'COMMON.OR' })}</div>
        </div>
        <div className='col-md-5'>
          <div className='card border border-2'>
            <div className='card-header mt-2 justify-content-center align-content-center'>
              <h3 className='text-center mt-2'>{ translator.formatMessage({ id: 'INPUT.CHOOSE_CSV_FILE' })}</h3>
            </div>
            <div className='card-body'>
              <div className='mb-3'>
                <label className='required-field form-check-label'>{ translator.formatMessage({ id: 'INPUT.ORGANIZATION' })}</label>
                <Select
                  options={universityOptionsForCsvUpload}
                  value={{label: organizationForCsvUpload.name || '', value: organizationForCsvUpload.name || ''}}
                  name='university'
                  inputId='universityId'
                  required={true}
                  onChange={(e) => {
                    setOrganizationForCsvUpload({
                      name: e?.value || '',
                      code: universityOptionsForCsvUpload.find((item) => item.label === e?.label)?.code || '',
                    });
                  }}
                  isSearchable={true}
                />
              </div>
              <div className='form-group my-5'>
                <label className='form-label form-check-label'>{ translator.formatMessage({ id: 'INPUT.CHOOSE_CSV_FILE' })} :</label>
                <div className='input-group d-flex justify-content-between'>
                  <input
                    type='file'
                    accept='.csv'
                    onClick={(event) => resetFile(event)}
                    onChange={(event) => handleFileUpload(event?.target?.files?.[0] || null)}
                    className='form-control p-3 bg-gray-100i'
                  />
                   <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        { translator.formatMessage({ id: 'COMMON.DOWNLOAD_CSV_FILE' })}
                      </Tooltip>
                    }
                  >
                 <div className="btn btn-linkedin z-index-0"  onClick={downloadSampleCSV}>              
                    <SVG src={toAbsoluteUrl("./media/icons/duotune/arrows/arr044.svg")} />
                  </div>
                </OverlayTrigger>
                </div>
              </div>
              <div className='d-flex justify-content-between my-2'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={
                    isDataSubmittingCsv ||
                    formData.csvFileData.length === 0 ||
                    !organizationForCsvUpload.code ||
                    !isCsvVerified
                  }
                  onClick={handleSubmitClickCsv}
                >
                  {!isDataSubmittingCsv && <span className='indicator-label'>{ translator.formatMessage({ id: 'BUTTONS.SUBMIT' })}</span>}
                  {isDataSubmittingCsv && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      { translator.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        {(formData.csvFileData.length > 0 ||
          (formData.selectedAppIds.length > 0 && formData.selectedUser)) && (
          <div className='col border-top-dashed border-1'>
            <h1 className='text-center m-4 justify-content-center'>
              <label>{ translator.formatMessage({ id: 'DISTRIBUTION.SELECTED_DATA' })}</label>
            </h1>
            <div className='table-responsive py-10'>
              <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer border border-2'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border border-2'>
                    <th className='fs-5 fw-bold text-center'>{ translator.formatMessage({ id: 'INPUT.APP_IDS' })}</th>
                    <th className='fs-5 fw-bold text-center'>{ translator.formatMessage({ id: 'INPUT.USER_EMAILS' })}</th>
                    <th className='fs-5 fw-bold text-center'>{ translator.formatMessage({ id: 'INPUT.IS_VERIFIED' })}</th>
                    <th className='fs-5 fw-bold text-center'>{ translator.formatMessage({ id: 'INPUT.STATUS' })}</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold text-center border border-2'>
                <>
                    { formData.csvFileData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.appIds}</td>
                          <td>{item.userEmails}</td>
                          <td>
                            {verifiedCsvChecks.find((ele) => ele.appId === item.appIds)?.result !==
                            'success' ? (
                              <span>
                                {verifiedCsvChecks.find((ele) => ele.appId === item.appIds)?.result}
                                <i className="fa fa-close fs-3 ms-2 text-danger"></i>
                              </span>
                            ) : (
                              <i className="fa fa-check-circle fs-1"></i>
                            )}
                          </td>
                        
                          <td
                            className={`${
                              unprocessedData.length > 0
                              ? unprocessedData.find((unprocessed) => unprocessed === item.appIds)
                                  ? 'text-danger'
                                  : 'text-success'
                                : ''                                
                            }`}
                          >
                            { unprocessedData.length > 0
                            ? unprocessedData.find((unprocessed) => unprocessed === item.appIds)
                                ? <>{ translator.formatMessage({ id: 'COMMON.UNSUCCESSFUL' })}</>
                                : <>{ translator.formatMessage({ id: 'COMMON.SUCCESSFUL' })}</>
                               : ''}
                          </td>
                        </tr>
                       ))
                        }
                         { formData.selectedAppIds.map((item, index) => (
                          <tr key={index}>
                            <td>{item}</td>
                            <td>{formData.selectedUser?.email}</td>
                            <td>
                              {formData.selectedAppIds.length > 0 && formData.selectedUser ? (
                                <i className="fa fa-check-circle fs-1"></i>
                              ) : (
                                <i className="fa fa-close fs-3 ms-2 text-danger"></i>
                              )}
                            </td>
                            <td
                              className={`${
                                unprocessedData.length > 0
                               ? unprocessedData.find((unprocessed) => unprocessed === item)
                                    ? 'text-danger'
                                    : 'text-success' 
                                  : ''
                              }`}
                            >
                              {unprocessedData.length > 0
                              ? unprocessedData.find((unprocessed) => unprocessed === item)
                                ? <>{ translator.formatMessage({ id: 'COMMON.UNSUCCESSFUL' })}</>
                                : <>{ translator.formatMessage({ id: 'COMMON.SUCCESSFUL' })}</>
                              : ''}
                            </td>
                          </tr>
                        ))
                      }
                    </>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignAppToSpecific;
