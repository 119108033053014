export const WORK_ITEM = {
    pk: "pk",
    sk: "sk",
    work_item_name: "work_item_name",
    dynamic_data: "dynamic_data",
    internal_data: "internal_data",
    status: "status",
    log: "log",
    created_at: "created_at",
    modified_at: "modified_at",
  };
  
  export const WORK_ITEM_DECISION = {
    APPROVE: "APPROVE",
    REJECT: "REJECT",
    
  };
  
  export type WORK_ITEM_DECISION_TYPE = keyof typeof WORK_ITEM_DECISION;
  
  export const WORK_ITEM_LOG_ACTIONS = {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
  };
  
  export const WORK_ITEM_NAMES = {
    UserArchive: "UserArchive",
    GradeScaleArchive: "GradeScaleArchive",
    GradeScaleEdit: "GradeScaleEdit",
    AddGradeScale: "AddGradeScale",
  };
  
  export enum LOG_ACTION {
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
  }
  
  export enum WORK_ITEM_STATUS {
    PENDING = 1,
    ARCHIVED = 2,
    REQUEST_CHANGES = 3,
  }

  export const orderOfKeys= ['country', 'grade_scale_type', 'grade_description', 'high_range', 'low_range', 'out_of', 'scale'];
  
  export type WORK_ITEM_TYPE = keyof typeof WORK_ITEM_NAMES;
   
export const approverIcons = [
  "./media/icons/duotune/communication/com014.svg",
  "./media/icons/duotune/general/gen064.svg",
  "./media/icons/duotune/coding/cod009.svg",
  "./media/icons/duotune/graphs/gra007.svg",
 ];
 