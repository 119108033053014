import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { customStylesSelect } from '../../../utils/common-constant';
import { IEditableSelect } from '../component-interface';

const EditableSelect: React.FC<IEditableSelect> = ({ name, formIkData, disabled, placeholder, handleInputChange, option, setOptions, formik, alertData, alertMessage }) => {
    return (
        <div className='w-100'>
            {formIkData ? (
                <input
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={formIkData || ''}
                    onChange={(e) => handleInputChange({ name, value: e.target.value })}
                    onBlur={(e) => {
                        formik.handleBlur(e);
                    }}
                    className='ps-xl-3 w-100 form-control'
                />
            ) : (
                <CreatableSelect
                    placeholder={placeholder}
                    inputId={name}
                    name={name}
                    isClearable={true}
                    styles={customStylesSelect}
                    isSearchable={true}
                    isDisabled={disabled}
                    value={formIkData ? { label: formIkData.toString(), value: formIkData.toString() } : null}
                    onChange={(e) => handleInputChange({ name, value: e?.value || '' })}
                    onBlur={formik.handleBlur}
                    options={option}
                    onCreateOption={(inputValue: string) => {
                        handleInputChange({
                            name: name,
                            value: inputValue,
                        });
                        setOptions([...option, { value: inputValue, label: inputValue }]);
                    }}
                    isLoading={disabled}
                />
            )}
            {alertData && alertMessage && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{alertMessage}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditableSelect;
