import React from 'react';
import {DYNAMODB_DB_CONDITIONS, GRADE_SCALE_CONST} from './grade-scales-constant';
import Select from 'react-select';
import {ISearchGradeScale} from './grade-scales-interface';
import {toAbsoluteUrl} from '../../../utils/helpers';
import SVG from 'react-inlinesvg';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const SearchGradeScale: React.FC<ISearchGradeScale> = ({
  isSearching,
  filterConditions,
  attributeAndCondition,
  setFilterConditions,
  setSearchLastEvaluatedKey,
  setAttributeAndCondition,
  handleSearchParent,
}) => {
  const handleFinalSearch = async () => {
    handleSearchParent();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, index = 0) => {
    e.preventDefault();

    setAttributeAndCondition((prev) =>
      prev.map((values, i) => {
        if (i === index) {
          values.searchValue = e?.target.value || '';
        }
        return values;
      })
    );
    setSearchLastEvaluatedKey(undefined);
  };

  const filterRow = (index = 0) => {
    return (
      <>
       {/* AND OR dropdown */}
       {index !== 0 && (
          <div>
            <Select
              options={[
                {
                  label: 'AND',
                  value: 'AND',
                },
                {
                  label: 'OR',
                  value: 'OR',
                },
              ]}
              placeholder='Operation Name'
              inputId='operation-name'
              className='my-2 w-150px mw-150px'
              value={{
                label: attributeAndCondition[index].operation,
                value: attributeAndCondition[index].operation,
              }}
              onChange={(e) => {
                setAttributeAndCondition((prev) =>
                  prev.map((values, i) => {
                    if (i === index) {
                      values.operation = e?.value || '';
                    }
                    return values;
                  })
                );
              }}
            />
          </div>
          )}
        <div className='d-flex gap-3 align-items-center flex-wrap'>
          {/* Attribute Name dropdown*/}
          <div>
            {index === 0 ? (
              <label htmlFor='filter' className='form-check-label'>
                Filter By:
              </label>
            ) : (
              <></>
            )}
            <Select
              options={Object.keys(GRADE_SCALE_CONST).map((key) => ({
                label: key,
                value: key,
              }))}
              placeholder='Attribute Name'
              className='w-150px mw-150px'
              inputId='attribute-name'
              value={{
                label: attributeAndCondition[index].attribute,
                value: attributeAndCondition[index].attribute,
              }}
              onChange={(e) => {
                setAttributeAndCondition((prev) =>
                  prev.map((values, i) => {
                    if (i === index) {
                      values.attribute = e?.value || '';
                    }
                    return values;
                  })
                );
              }}
            />
          </div>
          {/* Operation Dropdown*/}
          <div>
          {index === 0 ? (
              <label htmlFor='operation' className='form-check-label'>
               Operation:
              </label>
            ) : (
              <></>
            )}
            <Select
              options={Object.keys(DYNAMODB_DB_CONDITIONS).map((key) => ({
                label: key,
                value: key,
              }))}
              placeholder='Operation'
              className='w-150px mw-150px'
              inputId='operation'
              value={{
                label: attributeAndCondition[index].condition,
                value: attributeAndCondition[index].condition,
              }}
              onChange={(e) => {
                setAttributeAndCondition((prev) =>
                  prev.map((values, i) => {
                    if (i === index) {
                      values.condition = e?.value || '';
                    }
                    return values;
                  })
                );
              }}
            />
          </div>
          {/*  Search input */}          
          <div>
            {index === 0 ? (
              <label htmlFor='values' className='form-check-label'>
               Values:
              </label>
            ) : (
              <></>
            )}
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px'
                placeholder={`Enter ${attributeAndCondition[index].attribute}`}
                onChange={(e) => handleSearch(e, index)}
                value={attributeAndCondition[index].searchValue || ''}
              />
          </div>
          {/* Add Button */}
          {index === 0 && (
            <div>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-top'>Add New Filter</Tooltip>}
              >
                <div
                  className='cursor-pointer text-hover-primary text-info mt-5'
                  onClick={() => {
                    isSearching ? <></> :  setAttributeAndCondition((prev) => [
                      ...prev,
                      {
                        attribute: 'country',
                        condition: 'Equal To',
                        searchValue: '',
                        operation: 'AND',
                      },
                    ]);
                    setFilterConditions((prev) => [...prev, filterRow(index)]);
                  }}
                  id='add-btn'
                >
                  <SVG src={toAbsoluteUrl('./media/icons/duotune/general/gen035.svg')} />
                </div>
              </OverlayTrigger>
            </div>
          )}
          {/* Delete Button */}
          {index !== 0 && (
            <div>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-top'>Remove Filter</Tooltip>}
              >
                <div
                  className='cursor-pointer text-hover-danger text-danger-emphasis'
                  onClick={() => {
                    isSearching ? <></> : setAttributeAndCondition((prev) => prev.filter((item, i, self) => i !== index));
                  }}
                  id='remove-btn'
                >
                  <SVG src={toAbsoluteUrl('./media/icons/duotune/general/gen036.svg')} />
                </div>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className='d-flex justify-content-center flex-column'>
      {attributeAndCondition.map((item, index) =>
        filterConditions.length === 1 ? (
          <div key={index}>{filterRow(index)}</div>
        ) : (
          <div key={index}>{filterRow(index)}</div>
        )
      )}
      <div>
        <button
          className='btn btn-sm btn-active-primary btn-primary border-1 my-3'
          onClick={handleFinalSearch}
          disabled={!attributeAndCondition.every((item) => item.searchValue)}
        >
          {isSearching ? (
            <>
              <span className='spinner-border spinner-border-sm'></span>
              Please Wait...
            </>
          ) : (
            'Search'
          )}
        </button>
      </div>
    </div>
  );
};

export default SearchGradeScale;
