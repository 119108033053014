// Japanese
export const jaMessages = {
  "TRANSLATOR.SELECT": "あなたが使う言語を選んでください",
  "MENU.NEW": "新しい",
  "MENU.ACTIONS": "行動",
  "MENU.CREATE_POST": "新しい投稿を作成",
  "MENU.PAGES": "Pages",
  "MENU.FEATURES": "特徴",
  "MENU.APPS": "アプリ",
  "MENU.DASHBOARD": "ダッシュボード",
  "AUTH.GENERAL.OR": "または",
  "AUTH.GENERAL.SUBMIT_BUTTON": "提出する",
  "AUTH.GENERAL.NO_ACCOUNT": "アカウントを持っていない？",
  "AUTH.GENERAL.SIGNUP_BUTTON": "サインアップ",
  "AUTH.GENERAL.FORGOT_BUTTON": "パスワードをお忘れですか",
  "AUTH.GENERAL.BACK_BUTTON": "バック",
  "AUTH.GENERAL.PRIVACY": "プライバシー",
  "AUTH.GENERAL.LEGAL": "法的",
  "AUTH.GENERAL.CONTACT": "接触",
  "AUTH.LOGIN.TITLE": "Create Account",
  "AUTH.LOGIN.BUTTON": "Sign In",
  "AUTH.FORGOT.TITLE": "Forgotten Password?",
  "AUTH.FORGOT.DESC": "Enter your email to reset your password",
  "AUTH.FORGOT.SUCCESS": "Your account has been successfully reset.",
  "AUTH.REGISTER.TITLE": "Sign Up",
  "AUTH.REGISTER.DESC": "Enter your details to create your account",
  "AUTH.REGISTER.SUCCESS": "Your account has been successfuly registered.",
  "AUTH.INPUT.EMAIL": "Email",
  "AUTH.INPUT.FULLNAME": "Fullname",
  "AUTH.INPUT.PASSWORD": "Password",
  "AUTH.INPUT.CONFIRM_PASSWORD": "Confirm Password",
  "AUTH.INPUT.USERNAME": "ユーザー名",
  "AUTH.VALIDATION.INVALID": "{name} is not valid",
  "AUTH.VALIDATION.REQUIRED": "{name} is required",
  "AUTH.VALIDATION.MIN_LENGTH": "{name}} minimum length is {{min}",
  "AUTH.VALIDATION.AGREEMENT_REQUIRED": "Accepting terms & conditions are required",
  "AUTH.VALIDATION.NOT_FOUND": "The requested {name} is not found",
  "AUTH.VALIDATION.INVALID_LOGIN": "The login detail is incorrect",
  "AUTH.VALIDATION.REQUIRED_FIELD": "Required field",
  "AUTH.VALIDATION.MIN_LENGTH_FIELD": "Minimum field length:",
  "AUTH.VALIDATION.MAX_LENGTH_FIELD": "Maximum field length:",
  "AUTH.VALIDATION.INVALID_FIELD": "Field is not valid",
  "ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT": "Selected records count: ",
  "ECOMMERCE.COMMON.ALL": "All",
  "ECOMMERCE.COMMON.SUSPENDED": "Suspended",
  "ECOMMERCE.COMMON.ACTIVE": "Active",
  "ECOMMERCE.COMMON.FILTER": "Filter",
  "ECOMMERCE.COMMON.BY_STATUS": "by Status",
  "ECOMMERCE.COMMON.BY_TYPE": "by Type",
  "ECOMMERCE.COMMON.BUSINESS": "Business",
  "ECOMMERCE.COMMON.INDIVIDUAL": "Individual",
  "ECOMMERCE.COMMON.SEARCH": "Search",
  "ECOMMERCE.COMMON.IN_ALL_FIELDS": "in all fields",
  "ECOMMERCE.ECOMMERCE": "eCommerce",
  "ECOMMERCE.CUSTOMERS.CUSTOMERS": "Customers",
  "ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST": "Customers list",
  "ECOMMERCE.CUSTOMERS.NEW_CUSTOMER": "New Customer",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE": "Customer Delete",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION": "Are you sure to permanently delete this customer?",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION": "Customer is deleting...",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE": "Customer has been deleted",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE": "Customers Delete",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION": "Are you sure to permanently delete selected customers?",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION": "Customers are deleting...",
  "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE": "Selected customers have been deleted",
  "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE": "Status has been updated for selected customers",
  "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE": "Selected customers status have successfully been updated",
  "ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE": "Customer has been updated",
  "ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE": "Customer has been created",
  // My work Module
  "MY_WORK.EVALUATION": "Evaluation",
  "MY_WORK.REVIEW": "Review",
  "MY_WORK.MY_WORK_TITLE": "My Work",
  "MY_WORK.UNIVERSITY_CARDS.NEW_APPLICATIONS": "New Applications: ",
  "MY_WORK.UNIVERSITY_CARDS.IN_EVALUATION_APPLICATIONS": "& In Evaluation Applications: ",
  "MY_WORK.DATA_TABLE.SEARCH_BY_APP_ID": "Search By AppId",
  "MY_WORK.DATA_TABLE.APP_ID": "App Id",
  "MY_WORK.DATA_TABLE.DATE_OF_ASSIGNMENT": "Date of Assignment",
  "MY_WORK.DATA_TABLE.PRIORITY": "Priority",
  "MY_WORK.DATA_TABLE.REMARKS": "Remarks",
  "MY_WORK.DATA_TABLE.GO_TO_APPLICATION":  "Go to Application",
  "MY_WORK.EVAL_NEW": "Evaluation New",
  "MY_WORK.EVAL_IN_PROGRESS": "Evaluation in Progress",
  "MY_WORK.EVAL_COMPLETE": "Evaluation Complete",                               
  "MY_WORK.REVIEW_NEW": "Review New",
  "MY_WORK.REVIEW_IN_PROGRESS": "Review in Progress",               
  "MY_WORK.REVIEW_COMPLETE": "Review Complete",             
  "MY_WORK.RE_EVAL_NEW": "Re-Evaluation New",
  "MY_WORK.RE_EVAL_IN_PROGRESS": "Re-Evaluation in Progress",                           
  "MY_WORK.RE_EVAL_COMPLETE": "Re-Evaluation Complete",                   
  "MY_WORK.RE_REVIEW_NEW": "Re-Review New",
  "MY_WORK.RE_REVIEW_IN_PROGRESS": "Re-Review in Progress",                 
  "MY_WORK.RE_REVIEW_COMPLETE": "Re-Review Complete",    
  // Profile Section
  "PROFILE.PROFILE_HEADER.REVIEWER_CAPACITY": "Reviewer Capacity",
  "PROFILE.PROFILE_HEADER.EVALUATOR_CAPACITY": "Evaluator Capacity",
  "PROFILE.PROFILE_HEADER.LEAVES": "Leaves",
  "PROFILE.ACTION.INSERT_APP_IDS_DB_TO_DRIVE": "Insert appid's to database from Organization drive",
  "PROFILE.ACTION.DISTRIBUTE_APP_IDS_AMONG_USERS": "Distribute app ids among users",
  "PROFILE.ACTION.MASTER_UPLOAD": "Master Upload",
  "PROFILE.ACTION.REDO_MASTER_UPLOAD": "Redo Master Upload",
  "PROFILE.ACTION.INCOMPLETE_TRANSCRIPT_CHECKBOX_LABEL": "Generate separate report for incomplete transcript",
  "PROFILE.ACTION.PLEASE_INPUT_COMMA_SEPARATED_APP_IDS_LABEL": "Please input comma-separated App IDs (e.g., App#slu345352, App#slu345353). PDF files are generated for each ID, along with an entry in a CSV file accessible in the respective organization's drive.",
  // Common reusable buttons
  "BUTTONS.ASSIGN_APP_IDS_TO_USERS":"Assign App IDs to Users",
  "BUTTONS.NEXT": "Next",
  "BUTTONS.PREVIOUS": "Pervious",
  "BUTTONS.SUBMIT": "Submit",
  "BUTTONS.MASTER_UPLOAD": "Master Upload",
  "BUTTONS.APP_IDS_MASTER_UPLOAD" : "App Ids Master Upload",
  // Common reusable text
  "COMMON.LOADING": "Loading...",
  "COMMON.PLEASE_WAIT": "Please wait...",
  "COMMON.STATUS": "Status",
  "COMMON.ACTION": "Action",
  "COMMON.DATA_NOT_FOUND": "Data Not Found",
  "COMMON.NO_APP_IDS_FOUND": "No appId found.",
  "COMMON.SELECT_ORGANIZATION": "Select Organization",
  "COMMON.APP_IDS_EG": "eg: App#slu345352, App#slu345353",
  "COMMON.ACTIONS": "Actions",
  //Common reusable messages
  "COMMON.MESSAGE.SUCCESSFULLY_UPDATED_DATA_IN_DB": "Successfully updated database with current csv data!!",
  "COMMON.MESSAGE.SOMETHING_WENT_WRONG": "Something went wrong, Please try again",
  "COMMON.MESSAGE.SUCCESSFULLY_ASSIGNED_APP_IDS_OF_POOL_TO_USER": "Successfully assigned app ids of pool to users",
  "COMMON.MESSAGE.REQUEST_SENT_FOR_MASTER_UPLOAD": "Request sent for master upload",
  "COMMON.MESSAGE.APP_IDS_INVALID_INPUT": "AppIds are not entered!",
  "COMMON.MESSAGE.REQUEST_SENT_FOR_APP_IDS_MASTER_UPLOAD": "Request sent for AppIds master upload."
}
