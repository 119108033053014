import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IKeyAndColumnName,ITimeBasedUsersData } from './my-team-interface';
import { fetchAllOrgNames, fetchTimeBasedUsersData } from './_service/my-teams-services';
import { ILableValue } from '../page-common-interface';
import { toast } from 'react-toastify';
import { INameAndCode } from '../../../utils/common-interface';

const TimeBasedData: React.FC = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState(date.toISOString());
  date.setHours(23, 59, 59, 999);
  const [endDate, setEndDate] = useState(date.toISOString());
  const [organization, setOrganization] = useState<ILableValue>({
    label: 'All',
    value: 'All',
  });
  const [allOrgs, setAllOrgs] = useState<ILableValue[]>([]);
  const [timeBasedUserData, setTimedBasedUsersData] = useState<ITimeBasedUsersData[]>([]);
  const [isMoreUsersLoading, setIsMoreUsersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMoreIndex, setShowMoreIndex] = useState<IKeyAndColumnName>();
  const [showMoreValue, setShowMoreValue] = useState<string[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [prevLastEvaluatedKey, setPrevLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    setIsMoreUsersLoading(true);
    const fetchData = async () => {
      try {
        const data = await fetchAllOrgNames();
        if (data) {
          setAllOrgs([
            {
              label: 'All',
              value: 'All',
            },
            ...data.map((org: INameAndCode) => ({label: org.name, value: org.code})),
          ]);
          await initialLoadData(organization.value);
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false);
        setIsMoreUsersLoading(false);
      }
    };
    fetchData();
  // eslint-disable-next-line
  }, []);

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = getFormattedDateEvent(event);
    date.setHours(0, 0, 0, 0);
    setStartDate(() => date.toISOString());
    setTimedBasedUsersData(() => []);
    initialLoadData(organization.value, date.toISOString());
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = getFormattedDateEvent(event);
    date.setHours(23, 59, 59, 999);
    setEndDate(() => date.toISOString());
    setTimedBasedUsersData(() => []);
    initialLoadData(organization.value, startDate, date.toISOString());
  };

  const getFormattedDateEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parts = event.target.value.split('-');
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1;
    const day = parseInt(parts[2]);
    return new Date(year, month, day);
  };

  function DataTable(): JSX.Element {
    const renderTableRows = () => {
      if (timeBasedUserData.length === 0 && !isMoreUsersLoading) {
        return (
          <tr>
            <td colSpan={10}>
              <div className='text-center my-auto'>
                <span className='fs-1 fw-bold'>DATA NOT FOUND</span>
              </div>
            </td>
          </tr>
        );
      }
      return timeBasedUserData.map((row, i) => (
        <tr key={i}>
          <td className='p-4'>
            <div className='text-dark fw-bold  d-block fs-6'>
              {row.given_name + ' ' + row.family_name}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold  d-block fs-6'>{row.email}</div>
          </td>
          <td>
            <div className='text-dark fw-bold  d-block fs-6'>{row.user_code}</div>
          </td>
          <td>
            <div className='text-dark fw-bold  d-block fs-6'>{row.evaluation_completed}</div>
          </td>
          <td>
            <div className={`text-dark fw-bold d-flex flex-wrap fs-6`}>
              {row.eval_apps.length > 0 ? (
                <>
                  {showMoreIndex?.key === i && showMoreIndex.columnName === 'eval_apps' ? (
                    showMoreValue?.join(', ')
                  ) : (
                    <>
                      <div>{row.eval_apps.slice(0, 5).join(', ')}</div>
                      {row.eval_apps.length > 5 && (
                        <div
                          onClick={() => {
                            setShowMoreIndex({key: i, columnName: 'eval_apps'});
                            setShowMoreValue(row.eval_apps);
                          }}
                          className='active cursor-pointer text-primary'
                        >
                          Show More...
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                'No apps found'
              )}
            </div>
          </td>
          <td>
            <div className='text-dark fw-bold  d-block fs-6'>{row.review_completed}</div>
          </td>
          <td>
            <div className={`text-dark fw-bold d-flex flex-wrap fs-6`}>
              {row.review_apps.length > 0 ? (
                <>
                  {showMoreIndex?.key === i && showMoreIndex.columnName === 'review_apps' ? (
                    showMoreValue.join(', ')
                  ) : (
                    <>
                      <div>{row.review_apps.slice(0, 5).join(', ')}</div>
                      {row.review_apps.length > 5 && (
                        <div
                          onClick={() => {
                            setShowMoreIndex({key: i, columnName: 'review_apps'});
                            setShowMoreValue(row.review_apps);
                          }}
                          className='active cursor-pointer text-primary'
                        >
                          Show More...
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                'No apps found'
              )}
            </div>
          </td>
        </tr>
      ));
    };

    return (
      <div className='py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='align-middle fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px'>NAME</th>
                <th className='min-w-125px'>EMAIL ID</th>
                <th className='min-w-100px'>USER CODE</th>
                <th className='min-w-80px'>EVALUATION COMPLETED</th>
                <th className='min-w-80px'>APPLICATIONS EVALUATED</th>
                <th className='min-w-80px'>REVIEW COMPLETED</th>
                <th className='min-w-80px'>APPLICATIONS REVIEWED</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
      </div>
    );
  }

  const initialLoadData = async (code: string, start?: string, end?: string) => {
    try {
      if (isLoading || isMoreUsersLoading) {
        return;
      }
      const sDate = start ? new Date(start).getTime() : new Date(startDate).getTime();
      const eDate = end ? new Date(end).getTime() : new Date(endDate).getTime();
      if (eDate < sDate) {
        toast.success('Start date should be less than end date.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      setLastEvaluatedKey(undefined);
      setPrevLastEvaluatedKey(undefined);
      setIsMoreUsersLoading(true);
      const fetchedData = await fetchTimeBasedUsersData(
        start ? sDate : sDate,
        end ? eDate : eDate,
        code !== 'All' ? code?.toLowerCase() : undefined,
        undefined
      );
      if (fetchedData) {
        setTimedBasedUsersData(fetchedData?.data);
      }
      setIsMoreUsersLoading(false);
      if (fetchedData?.lastEvaluatedKey) {
        setLastEvaluatedKey(fetchedData?.lastEvaluatedKey);
        setHasMore(!!fetchedData.lastEvaluatedKey);
      }
    } catch (error) {
    } finally {
      setIsMoreUsersLoading(false);
    }
  };

  const onScrollLoadMoreData = async () => {
    try {
      if (!isMoreUsersLoading && !isLoading) {
        setIsMoreUsersLoading(true);

        if (prevLastEvaluatedKey !== lastEvaluatedKey) {
          setPrevLastEvaluatedKey(() => lastEvaluatedKey);
          const data = await fetchTimeBasedUsersData(
            new Date(startDate).getTime(),
            new Date(endDate).getTime(),
            organization.value === 'All' ? undefined : organization.value,
            lastEvaluatedKey ? lastEvaluatedKey : undefined
          );
          if (data) {
            const dataToSet = timeBasedUserData.concat(data.data);
            setTimedBasedUsersData(() => dataToSet);
            setLastEvaluatedKey(() => data?.lastEvaluatedKey);
            setHasMore(!!data.lastEvaluatedKey);
          }
        }
      }
    } catch (error) {
    } finally {
      setIsMoreUsersLoading(false);
    }
  };

  return (
    <div className='container'>
      <div className='row mt-4'>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='cursor-pointer'>
                <label htmlFor='Organization' className='form-label'>
                  Organization:
                </label>
                <br />
                <Select
                  options={allOrgs}
                  placeholder='Organization'
                  inputId='Organization'
                  value={organization}
                  isDisabled={isMoreUsersLoading || isLoading}
                  onChange={(selectedOption) => {
                    const {label, value} = selectedOption as {label: string; value: string};
                    setOrganization(() => ({label, value}));
                    initialLoadData(value);
                  }}
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='cursor-pointer'>
                <label htmlFor='startDate' className='form-label'>
                  Start Date:
                </label>
                <br />
                <input
                  type='date'
                  id='startDate'
                  max={new Date().toISOString().split('T')[0]} // Prevent future dates
                  disabled={isLoading || isMoreUsersLoading}
                  value={
                    new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000)
                      .toISOString()
                      .split('T')[0]
                  }
                  onChange={handleStartDateChange}
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='cursor-pointer'>
                <label htmlFor='endDate' className='form-label'>
                  End Date:
                </label>
                <br />
                <input
                  type='date'
                  id='endDate'
                  value={new Date(endDate).toISOString().substring(0, 10)}
                  min={ new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]}
                  disabled={isMoreUsersLoading || isLoading}
                  onChange={handleEndDateChange}
                  className='form-control'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 d-flex align-items-center justify-content-end mt-6'>
          <button
            className='btn btn-primary'
            onClick={() => initialLoadData(organization.value)}
            disabled={isLoading}
          >
            {isMoreUsersLoading ? (
              <>
                {' '}
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
                <span className='ml-2'>Please wait...</span>
              </>
            ) : (
              'Reload / Refresh'
            )}
          </button>
        </div>
      </div>

      <div className='d-flex mt-4'>
        <h4 className='text-gray-400 me-5'>
          Applications Evaluated:
          <span className='text-dark ms-2'>
            {timeBasedUserData.reduce((acc, item) => (acc += item.evaluation_completed), 0)}
          </span>
        </h4>
        <h4 className='text-gray-400'>
          Applications Reviewed :
          <span className='text-dark ms-2'>
            {timeBasedUserData.reduce((acc, item) => (acc += item.review_completed), 0)}
          </span>
        </h4>
      </div>

      <div className='h-auto'>
        <InfiniteScroll
          dataLength={timeBasedUserData.length}
          next={onScrollLoadMoreData}
          hasMore={hasMore}
          refreshFunction={() => {}}
          loader={<></>}
          endMessage={
            timeBasedUserData.length !== 0 && (
              <p className='text-center'>
                <b>All users data is loaded</b>
              </p>
            )
          }
        >
          <DataTable />
          {isMoreUsersLoading && (
            <div className='text-center mb-5'>
              <span className='ml-2'>Please wait...</span>
              <span className='spinner-border spinner-border-sm'></span>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default TimeBasedData;
