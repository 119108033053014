import React, {useEffect, useState} from 'react';
import {ITaskApproverResponse, IWorkItemArchiveUserResponse, IWorkItemCounts} from './approver-list-interface';
import {
  getWorkItems,
  getWorkItemCounts,
  acceptOrRejectWorkItem,
} from './_service/task-approver-service';
import {WORK_ITEM_NAMES, approverIcons} from './approver-list-constants';
import DialogBoxWithInput from '../../components/dialog-box-with-input/dialog-box-with-input';
import {toast} from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import {saveAs} from 'file-saver';
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../utils/helpers';
import { convertToSentenceCase, getFormattedDateAndTime } from '../../../utils/utils';
import { IGradeScale } from '../evaluation/evaluation-interfaces';

const TaskApproverMain: React.FC = () => {
  const [workItemCount, setWorkItemCount] = useState<IWorkItemCounts>({
    UserArchive: 0,
    GradeScaleArchive: 0,
    GradeScaleEdit: 0,
    NewGradeScale: 0,
  });
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [prevLastEvaluatedKey, setPrevLastEvaluatedKey] = useState<string | undefined>(
    undefined
  );
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [workItemSk, setWorkItemSk] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>(WORK_ITEM_NAMES.UserArchive);
  const [workItems, setWorkItems] = useState<IWorkItemArchiveUserResponse[]>([]);
  const [isExpanded, setIsExpanded] = useState<null | number>(null);
  const [isDialogueBoxOpen, setIsDialogueBoxOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cacheTaskApproverData, setCacheTaskApproverData] = useState<{[key: string]: ITaskApproverResponse}>({});

  useEffect(() => {
    const fetchWorkItemCounts = async () => {
      try {
        setWorkItemCount(await getWorkItemCounts());
      } catch (error) {}
    };
    const fetchWorkItems = async () => {
      await fetchWorkItemWithActiveTab();
    };

    fetchWorkItemCounts();
    fetchWorkItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWorkItemWithActiveTab = async (key = activeTab) => {
    try {
      setIsLoading(true);
      setLastEvaluatedKey(undefined);
      // Check if data is already cached
      if (cacheTaskApproverData[key]) {
        setWorkItems(()=>cacheTaskApproverData[key]?.result);
        setLastEvaluatedKey(()=>cacheTaskApproverData[key]?.lastEvaluatedKey);
      } else {
        const data = await getWorkItems(key, cacheTaskApproverData[key] ? lastEvaluatedKey : undefined);
        setWorkItems(data.result);
        setLastEvaluatedKey(data.lastEvaluatedKey);
        setCacheTaskApproverData(prevCache => ({
          ...prevCache,
          [key]: data
        }));
      }
    } catch (error) {}
    finally {
      setIsLoading(false);
    }
  };

  const handleExpandedItem = (index: number | null) => {
    setIsExpanded(index);
  };

  // Load more data
  const loadMoreWorkItems = async () => {
    try {
      if (!isLoadMore) {
        setIsLoadMore(true);
        if (lastEvaluatedKey && prevLastEvaluatedKey !== lastEvaluatedKey) {
          setPrevLastEvaluatedKey(lastEvaluatedKey);
          const data = await getWorkItems(activeTab, lastEvaluatedKey);
          setWorkItems(workItems.concat(data.result));
          setLastEvaluatedKey(data.lastEvaluatedKey);
          setHasMore(() => !!data.lastEvaluatedKey);
        }
      }
    } catch (error) {}
    setIsLoadMore(false);
  };
  
  // Download sample file
  const downloadUploadedCSV = (data: IGradeScale[]) => {
    let archiveGradeScaleCSVData = `country,grade_scale_type,grade_description,high_range,low_range,scale`;
    data.forEach((item) => {
      archiveGradeScaleCSVData += `\n"${item.country}","${item.grade_scale_type}",${
        item.grade_description || ''
      }, ${item.high_range || ''},${item.low_range || ''},${item.scale || ''}`;
    });
    const blob = new Blob([archiveGradeScaleCSVData], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, 'addNewGradeScaleData.csv');
  };

  // handle submit
  const handleSubmit = async (inputData: string) => {
    setIsSubmitting(true);
    try {
      const response = await acceptOrRejectWorkItem(workItemSk, activeTab, isAccept, inputData);
      if (response) {
        isAccept
          ? toast.success('Request accepted successfully!')
          : toast.success('Request rejected successfully!');
        setWorkItemCount(await getWorkItemCounts());
        await fetchWorkItemWithActiveTab();
      }
    } catch (error) {}
    finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const workItemsData = (): JSX.Element => {
    switch (activeTab) {
      case WORK_ITEM_NAMES.UserArchive: {
        return (
          <>
            {' '}
            <p>Users: </p>
            <ul>
              {workItems[isExpanded || 0].dynamic_data.usersSk.map((userSk: string, i: number) => (
                <li key={i}>{userSk}</li>
              ))}
            </ul>
          </>
        );
      }
      case WORK_ITEM_NAMES.GradeScaleArchive: {
        return (
          <>
            {' '}
            <p>Grade Scales: </p>
            <ul>
              {workItems[isExpanded || 0].dynamic_data?.gradeScalesSk?.map(
                (gradeScale: string, i: number) => <li key={i}>{gradeScale}</li>
              )}
            </ul>
          </>
        );
      }
      case WORK_ITEM_NAMES.GradeScaleEdit: {
        return (
          <>
            {' '}
            <h3>
              <p>
                <b>Old grade scale data:</b>{' '}
              </p>
            </h3>
            <ul>
              {Object.keys(workItems?.[isExpanded || 0]?.dynamic_data?.editData).map(
                (key: string, i: number) =>
                  key.includes('old') && (
                    <li key={i}>
                      <b>{key.substring(4)} : </b>{' '}
                      {workItems?.[isExpanded || 0]?.dynamic_data?.editData?.[key]}
                    </li>
                  )
              )}
            </ul>
            <h3>
              <p>
                <b>Edit grade scale data: </b>
              </p>
            </h3>
            <ul>
              {Object.keys(workItems[isExpanded || 0].dynamic_data?.editData).map(
                (key: string, i: number) =>
                  key.includes('edit') && (
                    <li key={i}>
                      <b>{key.substring(5)} : </b>{' '}
                      {workItems[isExpanded || 0].dynamic_data?.editData?.[key]}
                    </li>
                  )
              )}
            </ul>
          </>
        );
      }
      case WORK_ITEM_NAMES.AddGradeScale: {
        return (
          <>
            <p>Download grade scales csv: </p>
            <div className='m-5'>
              <b>
                <p>
                  {' '}
                  <label htmlFor=''>
                    {workItems[isExpanded || 0].dynamic_data?.isHighSchoolScales
                      ? 'High school scales data'
                      : 'Graduate courses grade scale data'}
                  </label>
                </p>
              </b>
              <button
                className='btn btn-primary btn-sm'
                onClick={() =>
                  downloadUploadedCSV(workItems[isExpanded || 0].dynamic_data?.addData)
                }
              >
                Download file
              </button>
            </div>
          </>
        );
      }
      default: {
        return <>No Data Found</>;
      }
    }
  };
  return (
    <>
    <div className='row mx-7 mb-5'>
      {workItemCount &&
        Object.keys(workItemCount).map((key, index) => (
          <a className='col-xl-3 mb-2' key={key} href="#card-scroll-on-click">
            <div
              className={`card card-xl-stretch mb-xl-8 cursor-pointer shadow-lg ${key === activeTab ? "bg-light text-primary" : "bg-gray-700 text-light"}`}
              onClick={() => {
                if (!isLoading) {
                  setActiveTab(() => key);
                  setHasMore(()=>true);
                  fetchWorkItemWithActiveTab(key);
                }
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between'>
                  <SVG className='w-50px h-50px' src={toAbsoluteUrl(approverIcons[index] || "./media/icons/duotune/graphs/gra004.svg")} />
                  <div className='fw-bold fs-1 mb-2 mt-5 me-10'>
                    {workItemCount?.[key as keyof IWorkItemCounts]}
                  </div>
                </div>
                <div className='fw-bolder fs-3'>{convertToSentenceCase(key)}</div>
              </div>
            </div>
          </a>
        ))
      }
    </div>

     {/* accordion portion */}
      <div className='d-flex flex-column flex-lg-row mb-2' id="card-scroll-on-click">
        <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
          <div
            className='card'
            id='kt_chat_messenger'
            style={{overflow: isLoadMore ? 'hidden' : 'auto'}}
          >
            <div className='card-header' id='kt_chat_messenger_header'>
              <div className='card-title'>
                <div className='symbol-group symbol-hover' />
                <div className='d-flex justify-content-center flex-column me-3'>
                  <span className='fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1'>
                    {convertToSentenceCase(activeTab)}
                  </span>
                  <div className='mb-0 lh-1'>
                    <span className='badge badge-success badge-circle w-10px h-10px me-1' />
                    <span className='fs-7 fw-bold text-gray-400'>Active</span>
                  </div>
                </div>
              </div>
            </div>
            <DialogBoxWithInput
              dynamicData={{
                title: 'Are you sure you want to proceed with this action?',
                inputData: '',
                inputPlaceHolder: 'Accept/Reject Reason',
                inputLabel: 'Accept/Reject Reason',
                leftButton: 'Cancel',
                rightButton: 'Submit',
                isLoading: isSubmitting,
              }}
              isOpen={isDialogueBoxOpen}
              onClose={() => setIsDialogueBoxOpen(false)}
              onSubmit={handleSubmit}
              key={isExpanded}
            ></DialogBoxWithInput>
            {isLoading ? (
              <div className='text-center my-auto mb-5'>
                <div className='spinner-border h-75px w-75px text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <div className='card-body' id='kt_chat_messenger_body'>
                {workItems.length === 0 ? (
                  <>No Data found</>
                ) : (
                  <div className='accordion' id='kt_accordion_1'>
                    <InfiniteScroll
                      dataLength={workItems.length}
                      next={loadMoreWorkItems}
                      hasMore={hasMore}
                      refreshFunction={() => {}}
                      loader={
                        isLoadMore && (
                          <div className='text-center mb-5'>
                            <span className='ms-2'>Please wait...</span>
                            <span className='spinner-border spinner-border-sm'></span>
                          </div>
                        )
                      }
                      endMessage={
                        <p style={{textAlign: 'center'}}>
                          <b>All task loaded</b>
                        </p>
                      }
                    >
                      {workItems.sort((a, b) => b.created_at - a.created_at).map((ele, index) => (
                        <div className='accordion-item m-2 border-1' key={index}>
                          <h2 className='accordion-header text-center' id='kt_accordion_1_header_1'>
                            <div
                              style={{backgroundColor: 'white'}}
                              key={ele.id}
                              className={`d-flex justify-content-between accordion-button fs-4 fw-semibold ${
                                isExpanded === index ? '' : 'collapsed'
                              }`}
                              data-bs-toggle='collapse'
                              data-bs-target='#kt_accordion_1_body_1'
                              aria-expanded={isExpanded === index ? true : false}
                              aria-controls='kt_accordion_1_body_1'
                              onClick={() => {
                                isExpanded === index
                                  ? handleExpandedItem(null)
                                  : handleExpandedItem(index);
                              }}
                            >
                              <div className='d-flex flex-column'>
                                <h3 className='fw-bolder text-black mb-2'>
                                  New Request Raised By {ele.internal_data?.request_raised_by_email}
                                </h3>
                                <span className='fs-6 text-muted fw-semibold'>
                                  Req Raised at: {getFormattedDateAndTime(new Date(ele.created_at))}
                                </span>
                              </div>
                            </div>
                          </h2>

                          {isExpanded === index ? (
                            <div
                              className='accordion-collapse collapse show'
                              id='kt_accordion_1_body_1'
                              aria-labelledby='kt_accordion_1_header_1'
                              data-bs-parent='#kt_accordion_1'
                            >
                              <div className='accordion-body'>
                                <div className='card-body py-0'>
                                  <div>{workItemsData()}</div>
                                </div>
                                <div className='card-footer'>
                                  <div className='d-flex justify-content-between '>
                                    <button
                                      className='btn btn-primary'
                                      onClick={() => {
                                        setIsAccept(() => true);
                                        setWorkItemSk(workItems[isExpanded || 0].id);
                                        setIsDialogueBoxOpen(true);
                                      }}
                                    >
                                      Accept
                                    </button>
                                    <button
                                      className='btn btn-danger'
                                      onClick={() => {
                                        setIsAccept(() => false);
                                        setWorkItemSk(workItems[isExpanded || 0].id);
                                        setIsDialogueBoxOpen(true);
                                      }}
                                    >
                                      Reject
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskApproverMain;