import {FC, useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {Footer} from './components/footer'
import {HeaderWrapper} from './components/header/header-wrapper'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {PageDataProvider, useLayout} from './core'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {ThemeModeProvider, themeModeSwitchHelper, useThemeMode,} from '../partials/layout/theme-mode/theme-mode-provider'
import clsx from 'clsx'
import { MenuComponent } from '../../../assets/ts/components'
import { WithChildren } from '../../../utils/helpers'

const MasterLayout: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const {mode} = useThemeMode()
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              {/* <Toolbar /> */}
              <div
                className={clsx(
                  'd-flex flex-column-fluid align-items-start px-sm-5 px-xl-20 px-md-20',
                  classes.contentContainer.filter(item=> item !=='container').join(' ')
                )}
                id='kt_post'
              >
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
