import * as Yup from 'yup';
import {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {Dispatch, SetStateAction} from 'react';
import * as authHelper from './core/auth-helpers';

export const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;
export const passwordLabelMessage =
  'Must Contain at least 1 capital letter, 1 small letter, a number, and a special character like !@#$%*';

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .matches(passwordRegex, passwordLabelMessage),
  keep_me_signed_in: Yup.boolean().optional().default(false),
});

export const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  change_password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});

export const verifyCodeSchema = Yup.object().shape({
  code: Yup.string().min(6, 'Minimum 6 symbols').required('Verification code is required'),
  newPassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .matches(passwordRegex, passwordLabelMessage),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
    .required('Confirm password is required'),
});

export type AuthContextProps = {
  auth: IAuthModel | undefined;
  saveAuth: (auth: IAuthModel | undefined) => void;
  currentUser: IUserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<IUserModel | undefined>>;
  logout: () => void;
};

export const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

export interface IUserProfileInfo {
  user_code: string;
  email: string;
  given_name: string;
  family_name: string;
  role: 'Team Leader' | 'Admin' | 'Moderator' | '"User';
  picture?: string;
  reviewer_capacity?: number;
  evaluator_capacity?: number;
  organisation_name?: string[];
}

export interface IResponseSignIn {
  access_token: string;
  refresh_token: string;
  expire_in: number;
}

export interface IAuthModel {
  api_token: string;
}

export interface IUserAddressModel {
  addressLine: string;
  city: string;
  state: string;
  postCode: string;
}

export interface IUserCommunicationModel {
  email: boolean;
  sms: boolean;
  phone: boolean;
}

export interface IUserEmailSettingsModel {
  emailNotification?: boolean;
  sendCopyToPersonalEmail?: boolean;
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean;
    youAreSentADirectMessage?: boolean;
    someoneAddsYouAsAsAConnection?: boolean;
    uponNewOrder?: boolean;
    newMembershipApproval?: boolean;
    memberRegistration?: boolean;
  };
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean;
    tipsOnGettingMoreOutOfKeen?: boolean;
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean;
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean;
    tipsOnStartBusinessProducts?: boolean;
  };
}

export interface IUserSocialNetworksModel {
  linkedIn: string;
  facebook: string;
  twitter: string;
  instagram: string;
}

export interface IErrorResponse {
  status?: number | 0;
  message?: string;
  code?: string;
  config?: InternalAxiosRequestConfig;
  response?: AxiosResponse;
  data?: {
    message?: string;
  };
  errors: string;
}

export interface IUserModel {
  user_code?: string;
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  fullname?: string;
  occupation?: string;
  companyName?: string;
  phone?: string;
  roles?: Array<number>;
  role?: string;
  reviewer_capacity?: number;
  evaluator_capacity?: number;
  organisation_name?: string[];
  pic?: string;
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru';
  timeZone?: string;
  website?: 'https://keenthemes.com';
  emailSettings?: IUserEmailSettingsModel;
  auth?: IAuthModel;
  communication?: IUserCommunicationModel;
  address?: IUserAddressModel;
  socialNetworks?: IUserSocialNetworksModel;
}
