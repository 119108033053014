//======================== AUTH ROUTES ===============================================
export const GET_ALL_USER_PROFILE_DATA = '/auth/getAllUsersData';
export const VERIFY_CODE_PAGE = '/auth/verify-code';

//======================== TYPE OF EVAL/ EVAL MAIN /CBC ROUTES ==============================
export const STREAM_TRANSCRIPT = '/credentialEvaluation/streamTranscript/';
export const GET_ORGANIZATION = '/credentialEvaluation/getAllOrganizations';
export const AUTO_SAVE_CRED_EVAL_DATA = '/credentialEvaluation/autoSave/';
export const GET_PERCENTAGE_TO_CGPA = '/credentialEvaluation/fetchPercentageToCgpa';
export const UPDATE_PG_UG = '/credentialEvaluation/updateAppData/';
export const PG_UG_COURSE_EVAL = '/credentialEvaluation/fetchData/';
export const GET_PERCENT_TO_CGPA = '/credentialEvaluation/fetchData/percent_to_cgpa';
export const GET_TYPE_OF_EVALUATION = '/credentialEvaluation/fetchData/';
export const UPDATE_CBC_DATA = '/credentialEvaluation/updateCBCData';
export const DELETE_ONE_CBC_ROW = '/credentialEvaluation/deleteOneCourse/'
export const CLEAR_CBC_DATA = '/credentialEvaluation/clearCbc/';
export const GET_DROPDOWN = '/credentialEvaluation/generalEval/fetch';
export const GENERAL_EVAL_API = '/credentialEvaluation/fetchData/';
export const UPLOAD_PDF = '/credentialEvaluation/generatePdf';
export const SEND_TO_NEXT_LEVEL = '/credentialEvaluation/sendToNextLevel';
export const SEND_BACK_TO_EVALUATOR = '/credentialEvaluation/sendBackToEvaluator'
export const SAVE_CRED_EVAL_DATA = '/credentialEvaluation/updateAppData/';
export const GET_GRADE_SCALE_DATA = '/credentialEvaluation/gradeScaleData';
export const GET_APP_IDS_WITH_ORG = '/credentialEvaluation/fetchAppIdWithOrgCode';
//======================== APPLICATION PRIORITY ROUTES  ==============================
export const GET_ALL_APP_LIST = '/applicationPriority/fetchAllAppList'
export const UPDATE_PRIO_APP = '/applicationPriority/updatePriority/'
export const SEARCH_APP_IDS = '/applicationPriority/search/'
export const APPLICATIONS_CHECK = '/applicationPriority/checkCsvApps'
export const UPDATE_APP_PRIO_CSV = '/applicationPriority/updateCsvPrio'
export const GET_USER_EMAIL_WITH_ORG = '/credentialEvaluation/fetchEmailWithOrgCode';
export const GET_PERSONAL_ID_DATA_WITH_APPID = '/credentialEvaluation/fetchDataByPersonalId'
export const UPDATEDATA_BY_PERSONALID = '/credentialEvaluation/updateDataByPersonalId'

//======================== GRADE SCALE ROUTES  ==============================
export const GET_ALL_GRADE_SCALES = '/gradeScales/getAll'
export const SEARCH_GRADE_SCALES = '/gradeScales/search'
export const ARCHIVE_GRADE_SCALES = '/gradeScales/Archive'
export const EDIT_GRADE_SCALES = '/gradeScales/edit'
export const ADD_NEW_GRADE_SCALES = '/gradeScales/addNew'

//======================== FEEDBACK ROUTES  ==============================
export const SEND_FEEDBACK = '/feedback/sendFeedback';

//======================== MY TEAMS ROUTES  ==============================
export const GET_ALL_USERS_DATA = '/myTeam/getAllUsersData';
export const UPDATE_USER_DATA = '/myTeam/updateUserData';
export const UPDATE_ORG_CODES = '/myTeam/updateOrgCodes';
export const SEARCH_USER_DATA = '/myTeam/searchUserData';

//======================== TIME BASED DATA ROUTES  ==============================
export const GET_ALL_USERS_TIME_BASED_DATA = '/myTeam/getTimeBasedUserData'
export const GET_ORG_NAMES = '/credentialEvaluation/getOrganizationsName'

//======================== MY WORK ROUTES  ==============================
export const GET_ALL_UNIVERSITIES = '/myWork/getAllOrganisations';
export const GET_ALL_WORK_ITEMS = '/myWork/getAllWorkItems';
export const GET_REVIEW_AND_EVAL_COUNT = '/myWork/getReviewAndEvalCount';
export const GET_ALL_SEARCH_ITEMS = '/myWork/searchWorkItems';

//======================== LEAVE ROUTES  ==============================
export const APPLY_LEAVE = '/leave/applyLeave';
export const ACCEPT_REJECT_LEAVE = '/leave/acceptRejectLeave';
export const GET_APPLIED_LEAVES = '/leave/getAppliedLeaves';
export const GET_LEAVES_REQUESTS = '/leave/getLeavesRequests';
export const GET_ALL_USER_EMAILS = '/leave/getAllUserEmails';

//======================== USERS ROUTES ==============================
export const VERIFY_EMP_CODE = '/user/verifyEmpCode';
export const FETCH_ORGANIZATION_NAMES = '/user/fetchOrganisationNames';
export const CREATE_USER = '/user/createUser';
export const ARCHIVE_USERS = '/user/archiveUsers';
export const IS_USER_CONFIRMED = '/user/isUserConfirmed';
export const SEND_CODE_ON_EMAIL = '/user/sendCodeOnEmail';
export const VERIFY_CODE = '/user/verifyCode';

//======================== DISTRIBUTION ROUTES  ==============================
export const GET_USER_APP_REF_DATA = '/distribution/getAllAppIdsOfUser';
export const SUBMIT_REASSIGNMENT = '/distribution/redistributeApps';
export const UPLOAD_APP_DATA = '/distribution/getCsvfileData/';
export const ASSIGN_APPIDS_TO_USERS = '/distribution/assignAppIdToUser';
export const MASTER_UPLOAD = '/distribution/masterUpload';
export const APPIDS_MASTER_UPLOAD = '/distribution/appIdsMasterUpload';
export const VERIFY_CSV_FILE_CHECKS = '/distribution/assignToSpecific/verifyChecks';
export const ASSIGN_APPIDS_TO_SPECIFIC_USERS = '/distribution/assignToSpecific';

//======================== TASK APPROVER ROUTES  ==============================
export const GET_ALL_WORKITEM_COUNTS = '/workitem/getAllWorkItemsCount';
export const GET_WORK_ITEMS_DATA = '/workitem/getWorkItems';
export const ACCEPT_REJECT_WORKITEM = '/workitem/acceptRejectWorkItem/';

// ======================== REASSIGN USER ID =======================================
export const GET_ALL_USER_WITH_EMAILS = '/user/getUsers';