import { IResponse } from "../../../../utils/common-interface"
import { SEND_FEEDBACK } from "../../../../utils/service-constants"
import { post } from "../../auth/core/_requests"

export const sendFeedback = async (formData: FormData): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(SEND_FEEDBACK, formData)
    return response.data?.success ? true : false
  } catch (error) {
    throw error
  }
}
