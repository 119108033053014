
  export const USER_ATTRIBUTES = {
    email:'email',
    name: 'name',
    user_code: 'user_code'
  }
  
  export const customStylesSelect = {
    option: (styles: any) => ({
      ...styles,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: 'max-width 0.3s ease-in-out',
      '&:hover': {
        whiteSpace: 'wrap',
        height: 'auto',
        overflow: 'visible',
        textOverflow: 'clip',
      },
    }),
  }

  export const onlyNumbersAndDotRegEx = /^[0-9]*(\.[0-9]+)?$/;
  export const commonConstant = {
    credEvalData: "credEvalData",
    typeOfEvaluation: "typeOfEvaluation",
    typeOfEval: "typeOfEval",
    globalGradCourses: 'Global Graduate Courses',
    highSchool: 'High School',
  }

  export const currentYear = new Date().getFullYear() + 5;
  export const yearOptions = Array.from({length: 51}, (_, index) => ({
    value: (currentYear - index).toString(),
    label: (currentYear - index).toString(),
  }));
