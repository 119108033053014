import {useRef, useState} from 'react';
import Papa from 'papaparse';
import {toast} from 'react-toastify';
import {saveAs} from 'file-saver';
import Select from 'react-select';
import {IUploadNewScale, ParseResult, csvFilesSchema} from './grade-scales-interface';
import {addNewGradeScale} from './_service/grade-scales-service';
import { COUNTRIES_DATA } from '../../../utils/helpers/crud-helper/country-model';
import { useIntl } from 'react-intl';
import { IGradeScale } from '../evaluation/evaluation-interfaces';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../utils/helpers';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

const UploadNewScales: React.FC<IUploadNewScale> = ({
  onClose,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<IGradeScale[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [isHighSchoolScales, setIsHighSchoolScales] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isNewScalesSubmitting, setIsNewScalesSubmitting] = useState<boolean>(false);
  const translator = useIntl();
  
  //handle file upload
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.[0]?.type !== 'text/csv') {
      toast.error('Wrong file type selected');
      if (fileInputRef.current && fileInputRef.current.value) {
        fileInputRef.current.value = '';
      }
      return;
    }
    setFile(() => e?.target?.files?.[0] || null);
    handleFileUpload(e?.target?.files?.[0]);
  };

  // File upload csv
  const handleFileUpload = async (file: File | undefined) => {
    if (!file) {
      toast.error('Please select a file to upload');
      return;
    }

    try {
      let {data, meta} = await new Promise<ParseResult<IGradeScale>>((resolve, reject) => {
        Papa.parse<IGradeScale>(file, {
          header: true,
          complete: (results) => resolve(results),
          error: reject,
        });
      });
      let validationErrors: string[] = [];
      const seen = new Set<string>();

      const requiredHeaders = [
        'country',
        'grade_scale_type',
        'high_range',
        'low_range',
        'grade_description',
        'scale',
      ];
      const missingHeaders = requiredHeaders.filter((header) => !meta.fields?.includes(header));
      if (missingHeaders.length > 0)
        validationErrors.push(`Missing headers: ${missingHeaders.join(', ')}`);
      data = data.filter((item) => item.country && item.grade_scale_type);

      for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
        const row = data[rowIndex];

        try {
          await csvFilesSchema.validate(row);

          const duplicateCheck = JSON.stringify(row);
          if (seen.has(duplicateCheck)) {
            const duplicateIndex = data.findIndex(
              (ele, index) => index !== rowIndex && JSON.stringify(ele) === duplicateCheck
            );
            validationErrors.push(
              `Row no: ${rowIndex + 1} and row no: ${duplicateIndex + 1} are duplicate`
            );
          }

          if (row.grade_scale_type.includes('4.0') || row.grade_scale_type.includes('4.0 US')) {
            validationErrors.push(`Row no: ${rowIndex + 1}, has 4.0 US scale grade_scale_type`);
          }

          if (row.high_range && row.low_range !== undefined && parseFloat(row.high_range + "") < parseFloat(row.low_range + "")) {
            validationErrors.push(
              `Row no: ${rowIndex + 1}, high range cannot be less than low range`
            );
          } else if (!row.grade_description && !row.high_range && !row.low_range) {
            validationErrors.push(`Row no: ${rowIndex + 1}, grade description cannot be empty`);
          } else if (row.high_range && row.low_range !== undefined && row.grade_description) {
            validationErrors.push(
              `Row no: ${
                rowIndex + 1
              }, row cannot contain both grade description and high range and low range`
            );
          }

          const isFound = COUNTRIES_DATA.some((item) => item.name === row.country);
          if (!isFound) {
            validationErrors.push(`Row no: ${rowIndex + 1}, Invalid country found`);
          }

          if (Object.keys(row).length !== 6) {
            throw new Error(`Invalid columns found: ${Object.keys(row).join(', ')}`);
          } else {
            seen.add(duplicateCheck);
          }
        } catch (err) {
          validationErrors.push(`Row no: ${rowIndex + 1}: ${err}`);
        }
      }

      setErrors(() => validationErrors);

      if (validationErrors.length === 0) {
        setFileData(data);
      }
    } catch (error) {
      toast.error('Error parsing CSV file. Please try again.');
    }
  };
  const handleUpload = async () => {
    try {
      setIsNewScalesSubmitting(true);
      const res = await addNewGradeScale(fileData, isHighSchoolScales);
      if (res) {
        toast.success('Request for adding new grade scale has been sent!');
        setFile(() => null);
        setFileData([]);
        if (fileInputRef.current && fileInputRef.current.value) {
          fileInputRef.current.value = '';
        }
        setErrors(() => []);
      }
      setIsNewScalesSubmitting(false);
    } catch (error) {
      setIsNewScalesSubmitting(false);
    }
  };
  
  // download sample csv file
  const downloadSampleCSV = () => {
    const sampleCSVData =
      `country,grade_description,grade_scale_type,high_range,low_range,scale` +
      `\n"India","Fail","Grade Description Scale (Excellent, Very Good, Good, ......, Fail)","","",0` +
      `\n"India","Excellent","Grade Description Scale (Excellent, Very Good, Good, ......, Fail)","","",0` +
      `\n"India","","Percentage Scale (90-100 A, 80-89 B+, 70-79 B, 60-69 C, 55-59 D, 0-54 F)",60,40,0` +
      `\n"India","","Percentage Scale (90-100 A, 80-89 B+, 70-79 B, 60-69 C, 55-59 D, 0-54 F)",54.99,0,0`;
    const blob = new Blob([sampleCSVData], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, `${translator.formatMessage({id: 'COMMON.SAMPLE'})}.csv`);
  };

  return (
    <Modal show={true} onHide={onClose} dialogClassName="mw-800px" backdrop="static">
       <Modal.Header closeButton>
        <Modal.Title>Upload New Grade Scales</Modal.Title>
      </Modal.Header>
      <Modal.Body className='min-h-400px h-auto'>
      <div className='container'>
      <div className='d-flex justify-content-center'>
        <div>
            <div className='form-check ms-2 me-5 mb-5'>
              <input
                type='radio'
                id='high-school-scales'
                name='high-school-scales'
                className='form-check-input'
                checked={isHighSchoolScales}
                onChange={() => setIsHighSchoolScales(true)}
              />
              <label htmlFor='high-school-scales' className='form-check-label'>
              High School Scale Data
            </label>
            </div>
            <div className='form-check ms-2 me-5 mb-5'>
              <input
                type='radio'
                id='grad-scales'
                name='grad-scales'
                className='form-check-input'
                checked={!isHighSchoolScales}
                onChange={() => setIsHighSchoolScales(false)}
              />
              <label htmlFor='grad-scales' className='form-check-label'>
              Grad Scale Data
             </label>
            </div>
          <div className='mb-3 mw-400px'>
            <Select
              options={[
                {
                  label: 'USA',
                  value: 'USA',
                },
              ]}
              placeholder='Attribute Name'
              inputId='destination_country'
              className='mb-5'
              value={{
                label: 'Destination Country: USA',
                value: 'Destination Country: USA',
              }}
              isDisabled={true}
              onChange={() => {}}
            />
            <div className='input-group mb-3'>
              <input
                type='file'
                accept='.csv'
                ref={fileInputRef}
                onChange={handleFileChange}
                className='form-control p-3 bg-gray-100i'
                aria-describedby='file-remove-btn'
              />
              {file && (
                <button
                  className='btn btn-danger'
                  type='button'
                  onClick={() => {
                    setFile(null);
                    setFileData([]);
                    if (fileInputRef.current && fileInputRef.current.value) {
                      fileInputRef.current.value = '';
                    }
                    setErrors([]);
                  }}
                  id='file-remove-btn'
                >
                  <i className='fa fa-close'></i>
                </button>
              )}
               <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                         { translator.formatMessage({ id: 'COMMON.DOWNLOAD_CSV_FILE' })}
                      </Tooltip>
                    }
                  >
                  <div className="btn btn-linkedin z-index-0"  onClick={downloadSampleCSV}>              
                    <SVG src={toAbsoluteUrl("./media/icons/duotune/arrows/arr044.svg")} />
                  </div>
                </OverlayTrigger>
            </div>
          </div>
          <button
            onClick={handleUpload}
            className='btn btn-primary mw-400px'
            disabled={(errors.length > 0 ? true : false) || isNewScalesSubmitting || !file}
          >
            {isNewScalesSubmitting ? (
              <>
                <span className='spinner-border spinner-border-sm'></span>
                Please Wait...
              </>
            ) : (
              'Upload'
            )}
          </button>
          </div>
        </div>
        {errors.length > 0 && (
          <div className='col-md-12 mt-3'>
            <div className='alert alert-danger'>
              <h3>Errors:</h3>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      </Modal.Body>
      </Modal>
  );
};

export default UploadNewScales;