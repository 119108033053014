import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import { IAuthModel, IUserProfileInfo } from '../auth-model'
import { IResponse } from '../../../../utils/common-interface'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/getUserProfileInfo`
export const LOGOUT_USER = `${API_URL}/auth/logout`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<IAuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

//set value in local storage
export function setValueLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value)
}

//get value in local storage
export function getValueLocalStorage(key: string) {
  return localStorage.getItem(key)
}
//remove value from local storage
export function removeValueLocalStorage(key: string) {
  localStorage.removeItem(key)
}
// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function getUserByToken(token: string): Promise<IResponse<IUserProfileInfo>> {
  try {
    const response = await axios.get<IResponse<IUserProfileInfo>>(GET_USER_BY_ACCESSTOKEN_URL)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function logoutUser(): Promise<IResponse<any>> {
  try {
    const response = await axios.post<IResponse<any>>(LOGOUT_USER)
    return response.data
  } catch (error) {
    throw error
  }
}

export const get = async <T>(
  url: string,
  params: AxiosRequestConfig['params'] = {},
  config: AxiosRequestConfig = {}
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios.get(API_URL + url, {params, ...config})
    return response.data
  } catch (error) {
    throw error
  }
}

export const post = async <T>(url: string, data: AxiosRequestConfig['data'] = {}): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios.post(API_URL + url, data)
    return response.data
  } catch (error) {
    throw error
  }
}
