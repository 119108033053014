import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {sendCodeOnEmail, verifyCode, isUserConfirmed} from '../../apps/user-management/create-user/_service/create-user-service'
import {
  getUserByToken,
  post,
  removeValueLocalStorage,
} from '../core/_requests'
import {useAuth} from '../core/auth'
import { IResponse } from '../../../../utils/common-interface'
import { removeRefreshToken } from '../core/auth-helpers'
import { emailSchema, verifyCodeSchema } from '../auth-model'
const style = {
  passwordInvalid: {
    backgroundPosition: 'right calc(2.375em + 0.3875rem) center',
  },
}

export function VerifyCode() {
  const {saveAuth, setCurrentUser} = useAuth()
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [searchParams] = useSearchParams()
  const [showPassword, setShowPassword] = useState(false)
  const [confirmShowPassword, setConfirmShowPassword] = useState(false)
  const [step, setStep] = useState(1)
  const navigate = useNavigate()
  const tokenName = process.env.REACT_APP_CRED_EVAL_AUTH as string;
  const initialValues = {
    email: '',
    code: '',
    newPassword: '',
    confirmPassword: '',
  }
  useEffect(() => {
    removeValueLocalStorage(tokenName)
    localStorage.removeItem('isTokenApiTriggered')
    removeRefreshToken()
    if (searchParams.get('email')) {
      setStep(2)
      formik.setFieldValue('email', searchParams.get('email'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getValidationSchema = () => {
    switch (step) {
      case 1:
        return emailSchema
      case 2:
        return verifyCodeSchema
      default:
        return emailSchema
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: async () => {
      if (step === 1) {
        submitHandler()
      } else {
        saveNewPassword()
      }
    },
  })

  useEffect(() => {
    removeValueLocalStorage(tokenName)
    localStorage.removeItem('isTokenApiTriggered')
    removeRefreshToken()
    if (searchParams.get('email')) {
      setStep(2)
      formik.setFieldValue('email', searchParams.get('email'))
    }
  // eslint-disable-next-line
  }, [])


  const handleCancelVerify = () => {
    navigate('/auth')
  }

  const submitHandler = async () => {
    try {
      setLoading1(true)
      await isUserConfirmed(formik.values.email)
      if (step !== 1) {
        const isCodeSent = await sendCodeOnEmail(formik.values.email)
        if (isCodeSent) {
          toast.success('Verification code has been sent', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }
      setStep(2)
      setLoading1(false)
    } catch (error) {
      setLoading1(false)
    }
  }

  const VerifyCodeHandler = async () => {
    try {
      const isCodeSent = await verifyCode(formik.values.code, formik.values.email)
      if (isCodeSent) {
        return true
      } else return false
    } catch (error) {
      setLoading2(false)
    }
  }

  const saveNewPassword = async () => {
    try {
      setLoading2(true)
      const isUserConfirmed = await VerifyCodeHandler()
      if (!isUserConfirmed) return
      const response = await post<
        IResponse<{
          access_token: string
        }>
      >('/user/setUserPasswordAndLogIn', {
        password: formik.values.newPassword,
        email: formik.values.email,
      })
      if (response?.data?.success?.access_token) {
        saveAuth({
          api_token: response.data.success?.access_token || '',
        })

        const data = await getUserByToken(response.data.success?.access_token)
        setCurrentUser({
          user_code: data.data.success?.user_code || '',
          username: data.data.success?.email || '',
          role: data.data.success?.role || '',
          reviewer_capacity: data.data.success?.reviewer_capacity || 0,
          evaluator_capacity: data.data.success?.evaluator_capacity || 0,
          email: data.data.success?.email || '',
          first_name: data.data.success?.given_name || '',
          last_name: data.data.success?.family_name || '',
          pic: data.data.success?.picture || '',
        })
        //set token to local storage
        // toast.success('Logged in successfully !', {
        //   position: toast.POSITION.TOP_RIGHT,
        // })
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    } catch (error) {
      saveAuth(undefined)
      setLoading2(false)
    }
  }

  return (
    <div>
      <form
        noValidate
        className='form w-100'
        id='kt_login_signin_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center'>
          <h1 className='text-dark fw-bolder mb-3'>
            {step === 1 && 'Want to Verify Your Email ID?'}
            {step === 2 && 'Verify your account and Create a strong password'}
          </h1>
          {step === 1 && (
            <p className='text-muted font-weight-bold font-size-h4'>
              Please enter the email you want to verify
            </p>
          )}
          {step === 2 && (
            <p className='text-muted font-weight-bold font-size-h4'>
              Please verify your account using the authentication code sent to your email
            </p>
          )}
        </div>
        {step === 1 && (
          <div className='row g-3 mb-9'>
            <div className='col-md-6'></div>
            <div className='col-md-6'></div>
          </div>
        )}

        {/* begin::Form group */}
        {step === 1 && (
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        )}

        {step === 2 && (
          <div>
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
              <div className='input-group'>
                <div className='input-group-append'>
                  <span className='input-group-text'>@</span>
                </div>
                <input
                  type='text'
                  className='form-control'
                  disabled={true}
                  value={formik.values.email}
                />
              </div>
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Verification Code</label>
              <div className='input-group'>
                <div className='input-group-append'>
                  <span className='input-group-text cursor-pointer text-primary'>
                    <div onClick={submitHandler}>Resend</div>
                  </span>
                </div>
                <input
                  placeholder='Please enter code here'
                  {...formik.getFieldProps('code')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.code && formik.errors.code},
                    {
                      'is-valid': formik.touched.code && !formik.errors.code,
                    }
                  )}
                  type='text'
                  name='code'
                  autoComplete='off'
                />
              </div>
              {formik.touched.code && formik.errors.code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.code}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {/* end::Form group */}

        {step === 1 && (
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'></div>
        )}

        {/* begin::Action */}
        {step === 1 && (
          <div className='d-flex justify-content-center text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={!formik.isValid || formik.isSubmitting || loading1}
            >
              {!loading1 && <span className='indicator-label'>Submit</span>}
              {loading1 && (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <button type='reset' id='kt_sign_in_submit' className='btn btn-secondary ms-3'>
              <span className='indicator-label' onClick={handleCancelVerify}>
                Cancel
              </span>
            </button>
          </div>
        )}

        {step === 2 && (
          <div>
            <div>
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Create password</label>
                <div className='position-relative'>
                  <input
                    style={style.passwordInvalid}
                    placeholder='Create new password'
                    type={showPassword ? 'text' : 'password'}
                    {...formik.getFieldProps('newPassword')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                      {
                        'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                      }
                    )}
                    name='newPassword'
                    autoComplete='off'
                  />
                  {showPassword ? (
                    <i
                      className='fas fa-eye position-absolute top-50 end-0 me-2 translate-middle-y cursor-pointer fs-2'
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  ) : (
                    <i
                      className='fas fa-eye-slash position-absolute top-50 end-0 me-2 translate-middle-y cursor-pointer fs-2'
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  )}
                </div>
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.newPassword}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-8 position-relative'>
                <label className='form-label fs-6 fw-bolder text-dark'>Confirm password</label>
                <input
                  placeholder='Confirm Password'
                  style={style.passwordInvalid}
                  type={confirmShowPassword ? 'text' : 'password'}
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                    {
                      'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                    }
                  )}
                  name='confirmPassword'
                  autoComplete='off'
                />
                {confirmShowPassword ? (
                  <i
                    className='fas fa-eye position-absolute end-0 me-2 translate-middle-y cursor-pointer fs-2 mt-n7'
                    onClick={() => setConfirmShowPassword(!confirmShowPassword)}
                  ></i>
                ) : (
                  <i
                    className='fas fa-eye-slash position-absolute end-0 me-2 translate-middle-y cursor-pointer fs-2 mt-n7'
                    onClick={() => setConfirmShowPassword(!confirmShowPassword)}
                  ></i>
                )}
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirmPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex justify-content-center text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid || loading2}
              >
                {!loading2 && <span className='indicator-label'>Continue</span>}
                {loading2 && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        )}
        {/* end::Action */}
      </form>
    </div>
  )
}
