import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import Select from 'react-select';
import {
  fetchAllAppList,
  searchAppIds,
  updateData,
} from './_service/application-priority-services';
import {IAppPriorityRes} from './application-priority-interface';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDebounce} from '@uidotdev/usehooks';
import UploadApplications from './upload-applications';
import {APPLICATION_PRIORITIES, priorities} from './application-priority-constants';
import {fetchOrganizationData} from '../../../utils/common-services';
import {MY_WORK_STATUS, getStatusString} from '../my-work/my-work-constants';
import { useIntl} from 'react-intl';
import { INameAndCode } from '../../../utils/common-interface';
import { getFormattedDate } from '../../../utils/utils';

const ApplicationPriorityPage = () => {
  const [appData, setAppData] = useState<IAppPriorityRes[]>([]);
  const [csvFileAppData, setCsvFileAppData] = useState<IAppPriorityRes[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [inputFieldError, setInputFieldError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isPriorityUpdating, setIsPriorityUpdating] = useState(false);
  const [isMoreAppsLoading, setIsMoreAppsLoading] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [searchLastEvaluatedKey, setSearchLastEvaluatedKey] = useState<string | undefined>(
    undefined
  );
  const [organization, setOrganization] = useState<INameAndCode>({
    name: '',
    code: '',
  });
  const [universityOptions, setUniversityOptions] = useState<
    {label: string; value: string; code: string}[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchValue, 1000);
  const translator = useIntl();

  // fetch initial data
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const data = await fetchOrganizationData('typeOfEval');
        if (data) {
          const universities = data.map((organization) => {
            return {
              label: organization.name || '',
              value: organization.name || '',
              code: organization.code.toLowerCase() || '',
            };
          });
          setUniversityOptions(universities);
          setOrganization(() => ({name: universities[0].label, code: universities[0].code}));
          await fetchAppData(universities[0].code);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load more data
  const loadMoreData = async () => {
    try {
      if (!searchLastEvaluatedKey && !searchValue) {
        await fetchAppData();
      } else if (searchValue && !isMoreAppsLoading) {
        await searchAppIdData();
      }
    } catch (error) {}
  };

  // fetch app data
  const fetchAppData = async (code?: string) => {
    try {
      setIsMoreAppsLoading(() => true);
      const data = await fetchAllAppList(
        code ? undefined : lastEvaluatedKey,
        code || organization.code.toLowerCase() || ''
      );
      if (data) {
        setAppData((prev) => (code ? data.appData : prev.concat(data.appData)));
        setHasMore(!!data.lastEvaluatedKey);
        setLastEvaluatedKey(() => data.lastEvaluatedKey);
      }
    } catch (error: any) {
    } finally {
      setIsMoreAppsLoading(() => false);
    }
  };

  // search debounce
  useEffect(() => {
    const searchAppData = async () => {
      try {
        await searchAppIdData();
      } catch (error) {}
    };
    if (searchValue && !isMoreAppsLoading) {
      setAppData(() => []);
      searchAppData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  // API call for search
  const searchAppIdData = async () => {
    try {
      setIsMoreAppsLoading(() => true);
      const res = await searchAppIds(
        (organization.code.toLowerCase() || universityOptions[0]?.code.toLowerCase()) +
          searchValue.trim(),
        searchLastEvaluatedKey
      );
      setAppData((prev) => prev.concat(res.appData));
      setSearchLastEvaluatedKey(() => res.lastEvaluatedKey);
      setHasMore(!!res.lastEvaluatedKey);
    } catch (error) {
    } finally {
      setIsMoreAppsLoading(() => false);
    }
  };

  // handle search
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const isValidNumber = /^\d+$/.test(inputValue);
    if (isValidNumber || inputValue === '') {
      setSearchValue(() => e.target.value);
      setSearchLastEvaluatedKey(() => undefined);
      if (e.target.value === '') {
        fetchAppData();
      }
    } else {
      setInputFieldError(() => translator.formatMessage({id: 'COMMON.ENTER_VALID_NUMBER'}));
    }
  };

  // table data
  function DataTable(): JSX.Element {
    const saveData = async (index: number) => {
      try {
        setIsPriorityUpdating(true);
        const response = await updateData(
          appData[index].id,
          APPLICATION_PRIORITIES[appData[index].priority]
        );
        if (response && response.length === 0) {
          toast.success(
            translator.formatMessage({id: 'COMMON.PRIORITY_UPDATED_SUCCESSFULLY_AND_ASSIGNED'}),
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          renderAllAppsTableRows();
        } else if (response && response.length > 0) {
          toast.error(translator.formatMessage({id: 'COMMON.MESSAGE.APPID_IN_WRONG_POOL'}), {
            position: toast.POSITION.TOP_RIGHT,
          });
          renderAllAppsTableRows();
        }
      } catch (error) { } 
      finally {
        setIsPriorityUpdating(false);
      }
    };

    // For dropdown
    const handleSelectChange = async (
      event: {name: string; value: string | undefined},
      index: number
    ) => {
      const {value} = event;
      if (value === undefined) {
        return;
      }
      appData[index].priority = +value;
      await saveData(index);
    };

    // table data row
    const renderAllAppsTableRows = () => {
      const rows = [];
      const data = csvFileAppData.length > 0 ? csvFileAppData : appData;
      for (let i = 0; i < (data ? data.length : 0); i++) {
        rows.push(
          <tr key={i}>
            <td className='p-4'>
              <div className='text-dark fw-bold fs-6'>{data[i]?.id}</div>
            </td>
            <td>
              <div className='text-dark fw-bold fs-6'>
                {getFormattedDate(data[i]?.created_at)}
              </div>
            </td>
            <td>
              <div className='text-dark fw-bold fs-6'>
                {data[i]?.eval_by ? 'Email: ' + data[i]?.eval_by : '-'}
              </div>
              {data[i]?.eval_assgined_on && (
                <div className='text-dark fw-bold fs-6'>
                  Assigned on: {getFormattedDate(data[i]?.eval_assgined_on || 0) || '-'}
                </div>
              )}
            </td>
            <td>
              <div className='text-dark fw-bold fs-6'>
                {data[i]?.review_by ? 'Email: ' + data[i]?.review_by : '-'}
              </div>
              {data[i]?.review_assigned_on && (
                <div className='text-dark fw-bold fs-6'>
                  Assigned on: {getFormattedDate(data[i]?.review_assigned_on) || '-'}
                </div>
              )}
            </td>
            <td>
              <div className='text-dark fw-bold'>
                <div className={`badge fw-bolder ${getStatusString(data[i]?.status)?.bgColor}`}>
                  {translator.formatMessage({
                    id: `MY_WORK.${MY_WORK_STATUS[data[i]?.status]}`,
                  })}
                </div>
              </div>
            </td>
            <td>
              <div className='text-dark fw-bold fs-6'>
                <Select
                  placeholder='Select Priority'
                  inputId='priority'
                  name='priority'
                  isDisabled={
                    isPriorityUpdating ||
                    data[i]?.status === MY_WORK_STATUS.REVIEW_COMPLETE ||
                    csvFileAppData.length > 0
                  }
                  isLoading={isPriorityUpdating}
                  isSearchable={true}
                  value={{
                    label:
                      priorities.find((item) => item.value === data[i].priority)?.label ||
                      'Select Priority',
                    value:
                      priorities.find((item) => item.value === data[i].priority)?.value ||
                      'Select Priority',
                  }}
                  onChange={(e) =>
                    handleSelectChange({name: 'priority', value: e?.value.toString()}, i)
                  }
                  options={priorities}
                  menuPortalTarget={document.body}
                />
              </div>
            </td>
          </tr>
        );
      }
      return rows;
    };

    return (
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4 mb-100px'>
            <thead>
              <tr className='align-middle fw-bold text-muted bg-light'>
                <th className='min-w-125px px-4'>APP ID</th>
                <th className='min-w-125px'>CREATED ON</th>
                <th className='min-w-125px'>EVALUATOR</th>
                <th className='min-w-100px'>REVIEWER</th>
                <th className='min-w-50px'>STATUS</th>
                <th className='min-w-125px'>PRIORITY</th>
              </tr>
            </thead>
            <tbody>{renderAllAppsTableRows()}</tbody>
          </table>
        </div>
      </div>
    );
  }
  // close dialog for upload csv file
  function handleCloseDialog(): void {
    setIsOpen(!isOpen);
  }
  
  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header justify-content-center border-0 pt-5'>
        <div>
          <h1 className='border-0 card-header pt-5 text-center'>
            <span className='fw-bold fs-1 mb-1'>Applications</span>
          </h1>
        </div>
      </div>

      {isOpen && (
        <div className='card-body'>
          <UploadApplications setCsvFileAppData={setCsvFileAppData} onClose={ handleCloseDialog }/>
        </div>
      )}
      
      {csvFileAppData.length === 0 && (
        <div className='mx-9 d-flex flex-wrap justify-content-between align-items-end gap-5'>
          <div className='d-flex flex-wrap'>
            <div className='mw-360px me-4 mt-2'>
            <label
              htmlFor='filter-by'
              className='required-field form-check-label mt-3'
            >
              Select Organization
            </label>
              <Select
                options={universityOptions}
                value={{
                  label: organization.name || universityOptions[0]?.label || '',
                  value: organization.name || universityOptions[0]?.label || '',
                }}
                name='organization'
                inputId='filter-by'
                required={true}
                isLoading={isLoading || isMoreAppsLoading}
                onChange={(e) => {
                  setOrganization({
                    name: e?.value || '',
                    code: universityOptions.find((item) => item.label === e?.label)?.code || '',
                  });
                  fetchAppData(universityOptions.find((item) => item.label === e?.label)?.code);
                }}
              />
            </div>
            <div className='form-group mt-2'>
            <label
              htmlFor='filter-by'
              className='required-field form-check-label mt-3'
            >
              Search by app id
            </label>
            <div className='d-flex align-items-end position-relative'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-3 mb-3'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='number'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-12 h-40px'
                placeholder='Search value'
                onChange={handleSearch}
                value={searchValue}
                onWheel={(e: any) => {
                  e.target.blur();
                  e.stopPropagation();
                  setTimeout(() => {
                    e.target.focus();
                  }, 0);
                }}
              />
              {inputFieldError && <span className='text-danger'>{inputFieldError}</span>}
              {searchValue && (
                <button
                  className='btn btn-danger'
                  type='button'
                  onClick={() => {
                    setSearchValue(() => '');
                    setSearchLastEvaluatedKey(() => undefined);
                    fetchAppData();
                  }}
                  id='file-remove-btn'
                >
                  <i className='fa fa-close fa-1x'></i>
                </button>
              )}
            </div>
            </div>
          </div>
          <button className='btn btn-primary btn-sm' onClick={(e) => {
            setIsOpen(!isOpen);
            }}>
            <div className="fa fa-add"></div> Add new
          </button>
        </div>
      )}
      {isLoading ? (
        <div className='text-center align-content-around h-400px'>
          <div className='spinner-border h-75px w-75px text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <div className='h-auto'>
          <InfiniteScroll
            dataLength={appData.length}
            next={loadMoreData}
            hasMore={csvFileAppData.length > 0 ? false : hasMore}
            refreshFunction={() => {}}
            loader={<></>}
            endMessage={
              appData.length !== 0 &&
              !isMoreAppsLoading &&
              !isLoading && (
                <div className='mt-2'>
                  <p className='text-center'>
                    <b>All apps data is loaded</b>
                  </p>
                </div>
              )
            }
          >
            <DataTable />
            {isMoreAppsLoading ? (
              <div className='text-center mb-5'>
                <span className='ml-2'>Please wait...</span>
                <span className='spinner-border spinner-border-sm'></span>
              </div>
            ) : (
              appData.length === 0 && (
                <div className='text-center my-auto'>
                  <span className='fs-1 fw-bold'>DATA NOT FOUND</span>
                </div>
              )
            )}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
export default ApplicationPriorityPage;
