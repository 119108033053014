import { IKeyValues, IResponse } from '../../../../utils/common-interface';
import { ITimeBasedUserResponse, IUserMyTeamResponse } from '../my-team-interface';
import { get, post } from '../../auth/core/_requests';
import { ARCHIVE_USERS, GET_ALL_USERS_DATA, GET_ALL_USERS_TIME_BASED_DATA, GET_ORG_NAMES, SEARCH_USER_DATA, UPDATE_USER_DATA } from '../../../../utils/service-constants';

export const fetchAllUsersData = async (lastEvaluatedKey: string | undefined) => {
  try {
    const response = await post<
      IResponse<{
        userData: IUserMyTeamResponse[];
        allOrgCodes: string[];
        lastEvaluatedKey: string;
      }>
    >(GET_ALL_USERS_DATA, lastEvaluatedKey ? {lastEvaluatedKey: lastEvaluatedKey} : {});
    return response.data?.success || {userData: [], allOrgCodes: [], lastEvaluatedKey: undefined};
  } catch (error) {
    throw error;
  }
};

export const searchUsersData = async (attribute: string, searchString: string) => {
  try {
    const response = await post<
      IResponse<{
        userData: IUserMyTeamResponse[];
        lastEvaluatedKey: string;
      }>
    >(SEARCH_USER_DATA, {attribute, searchString});
    const data = response.data?.success;
    return (
      data || {
        userData: [],
        lastEvaluatedKey: undefined,
      }
    );
  } catch (error) {
    throw error;
  }
};

export const updateUserData = async (data: IKeyValues): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(UPDATE_USER_DATA, {
      data: data,
    });
    return response.data?.success ? true : false;
  } catch (error) {
    throw error;
  }
};

export const archiveUsers = async (usersSk: string[]): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(ARCHIVE_USERS, {usersSk});
    return response.data?.success ? true : false;
  } catch (error) {
    throw error;
  }
};

export const fetchTimeBasedUsersData = async (
  startDate: number,
  endDate: number,
  orgCode: string | undefined,
  lastEvaluatedKey: string | undefined,
) => {
  try {
    const response = await post<IResponse<ITimeBasedUserResponse>>(
      GET_ALL_USERS_TIME_BASED_DATA,
      {startDate, endDate, orgCode: orgCode, lastEvaluatedKey: lastEvaluatedKey}
    );
    return { data: response.data?.success?.data || [],
       lastEvaluatedKey: response.data?.success?.lastEvaluatedKey
    };
  } catch (error) {
    throw error;
  }
};

export const fetchAllOrgNames = async () => {
  try {
    const response = await get<IResponse<{name: string; code: string}[]>>(GET_ORG_NAMES);
    return response.data?.success || [];
  } catch (error) {
    throw error;
  }
};
