import { IKeyValues, IResponse } from "../../../../utils/common-interface";
import {
  CLEAR_CBC_DATA,
  GET_PERCENT_TO_CGPA,
  UPDATE_CBC_DATA,
  GET_PERSONAL_ID_DATA_WITH_APPID,
  GET_TYPE_OF_EVALUATION,
  SAVE_CRED_EVAL_DATA,
  UPDATEDATA_BY_PERSONALID,
  GET_DROPDOWN,
  UPLOAD_PDF,
  SEND_TO_NEXT_LEVEL,
  SEND_BACK_TO_EVALUATOR,
  UPDATE_PG_UG,
  GENERAL_EVAL_API,
  DELETE_ONE_CBC_ROW,
} from '../../../../utils/service-constants';
import { get, post } from "../../auth/core/_requests";
import { IAppData, IAppEvalUgPgData, ICbcData, ICbcUgPg, IEvalPage, IEvalPg, IEvalUg, IGradeScale, ITypeOfEvaluation } from "../evaluation-interfaces";


export const saveCredentialEvalData = async (
  appIdWithOrgCode: string,
  data: IKeyValues,
  pageName: string
): Promise<ITypeOfEvaluation> => {
  try {
    const response = await post<IResponse<any>>(SAVE_CRED_EVAL_DATA + `?appId=${appIdWithOrgCode}&page=${pageName}`, {
      data: data,
    });
    return response.data?.success || ({} as ITypeOfEvaluation);
  } catch (error) {
    throw error;
  }
};

export const fetchTypeOfEvaluationData = async (
  appIdWithOrgCode: string
): Promise<ITypeOfEvaluation> => {
  try {
    const response = await get<IResponse<ITypeOfEvaluation>>(
      GET_TYPE_OF_EVALUATION + appIdWithOrgCode + '/typeOfEvaluation'
    );
    return response.data?.success || ({} as ITypeOfEvaluation);
  } catch (error) {
    throw error;
  }
};


export const fetchApplicationByPersonalId= async (appid: string): Promise<ITypeOfEvaluation[]> => {
  try {
    const response = await get<IResponse<any>>(GET_PERSONAL_ID_DATA_WITH_APPID + '/' + appid);
    return response.data?.success as ITypeOfEvaluation[];
  } catch (error) {
    throw error;
  }
};

export const updateApplicationByPersonalId = async (
  appidToFetchDataFrom: string,
  appidToRestoreData: string
): Promise<boolean> => {
  try {
    const response = await post<IResponse<any>>(UPDATEDATA_BY_PERSONALID, {
      appidToFetchDataFrom,
      appidToRestoreData,
    });
    return response.data?.success as boolean;
  } catch (error) {
    throw error;
  }
};


export const fetchPercentToCgpa = async (
  gradCountry: string,
  gradeScaleType: string
): Promise<{gradeScaleData: IGradeScale[]}> => {
  try {
    const response = await get<IResponse<{gradeScaleData: IGradeScale[]}>>(GET_PERCENT_TO_CGPA, {
      country: gradCountry,
      scale: gradeScaleType,
    });
    return response.data?.success || {gradeScaleData: []};
  } catch (error) {
    throw error;
  }
};

export const fetchCByCData = async (appId: string, gradType: string): Promise<IAppData> => {
  try {
    const response = await get<IResponse<IAppData>>(
      GET_TYPE_OF_EVALUATION + `${appId}/${gradType}CourseByCourseEvaluation`
    );
    return response.data.success || ({} as IAppData);
  } catch (error) {
    throw error;
  }
};

export const updateCByCData = async (
  appId: string,
  gradType: string,
  gradeScaleData: IGradeScale[],
  gradeScaleType: string,
  data: IKeyValues,
  isHighSchoolEval: boolean
): Promise<{cbc: ICbcData}> => {
  try {
    data.gradeScaleData = gradeScaleData;
    data.gradeScaleType = gradeScaleType;
    data['isHighSchoolEval'] = isHighSchoolEval;
    const response = await post<IResponse<{cbc: ICbcData}>>(
      UPDATE_CBC_DATA + `?appId=${appId}&page=${gradType.toLowerCase()}`,
      {data}
    );
    return response.data.success || ({} as {cbc: ICbcData});
  } catch (error) {
    throw error;
  }
};

export const deleteRowCBC = async (
  appId: string,
  gradType: string,
  gradeScaleData: IGradeScale[],
  gradeScaleType: string,
  data: IKeyValues,
  isHighSchoolEval: boolean
): Promise<{cbc: ICbcData}> => {
  try {
    data.gradeScaleData = gradeScaleData;
    data.gradeScaleType = gradeScaleType;
    data['isHighSchoolEval'] = isHighSchoolEval;
    const response = await post<IResponse<Promise<{cbc: ICbcData}>>>(
      DELETE_ONE_CBC_ROW + data.deletedObject + `?appId=${appId}&page=${gradType.toLowerCase()}`,
      {data}
    );
    return response.data.success || ({} as {cbc: ICbcData});
  } catch (error) {
    throw error;
  }
};

export const clearCbcData = async (appId: string, gradType: string): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(
      CLEAR_CBC_DATA + `${appId}/${gradType.toLowerCase()}`
    );
    return response.data.success || false;
  } catch (error) {
    throw error;
  }
};

export const fetchUGGeneralEvalData = async (appId: string): Promise<IEvalUg> => {
  try {
    const response = await get<IResponse<IEvalUg>>(
      GET_TYPE_OF_EVALUATION + `${appId}/generalEvaluation`
    );
    return response.data.success || ({} as IEvalUg);
  } catch (error) {
    throw error;
  }
};

export const fetchPGGeneralEvalData = async (appId: string): Promise<IEvalPg> => {
  try {
    const response = await get<IResponse<IEvalPg>>(
      GET_TYPE_OF_EVALUATION + `${appId}/generalEvaluation`
    );
    return response.data.success || ({} as IEvalPg);
  } catch (error) {
    throw error;
  }
};

export const fetchPrevCByCData = async (
  appid: string,
  gradType: string,
  university: string,
  major: string
): Promise<{
  data: ICbcUgPg[];
  totalRequiredCredits: number;
}> => {
  try {
    const encodedAppId = encodeURIComponent(appid);
    const encodedGradType = encodeURIComponent(gradType);
    const encodedUniversity = encodeURIComponent(university);
    const encodedMajor = encodeURIComponent(major);

    const response = await get<
      IResponse<{
        data: ICbcUgPg[];
        totalRequiredCredits: number;
      }>
    >(
      `/credentialEvaluation/fetch/${encodedAppId}/${encodedGradType}/${encodedUniversity}/${encodedMajor}`
    );

    return (
      response.data?.success ||
      ({} as {
        data: ICbcUgPg[];
        totalRequiredCredits: number;
      })
    );
  } catch (error) {
    throw error;
  }
};

export const dropdownData = async (): Promise<IAppEvalUgPgData[]> => {
  try {
    const responseData = await get<IResponse<IAppEvalUgPgData[]>>(GET_DROPDOWN)
    return responseData.data.success || []
  } catch (error) {
    throw error
  }
}

export const fetchGeneralEvalDbData = async (appid: string): Promise<IEvalPage> => {
  try {
    const response = await get<IResponse<IEvalPage>>(GENERAL_EVAL_API + appid + '/generalEvaluation')
    return response?.data?.success || ({} as IEvalPage)
  } catch (error) {
    throw error
  }
}

export const generateAndUploadPdf = async (
  pdfData: {fileName: string; html: string},
  university: string
) => {
  try {
    const response = await post<IResponse<boolean>>(UPLOAD_PDF + '/' + university, {data: pdfData})
    return response.data?.success ? true : false
  } catch (error) {
    throw error
  }
}

export const sendToNextLevel = async (appid: string): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(SEND_TO_NEXT_LEVEL + '/' + appid)
    return response.data?.success ? true : false
  } catch (error) {
    throw error
  }
}

export const sendBackToEvaluator = async (appid: string, remarks: string): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(SEND_BACK_TO_EVALUATOR + '/' + appid, {
      remarks: remarks,
    })
    return response.data?.success ? true : false
  } catch (error) {
    throw error
  }
}

export const updatePgData = async (
  appid: string,
  data: IKeyValues
): Promise<IEvalPage> => {
  try {
    const response = await post<IResponse<IEvalPage>>(UPDATE_PG_UG + `?appId=${appid}&page=generalEvaluation`, {
      data: data,
    })
    return response?.data?.success || ({} as IEvalPage)
  } catch (error) {
    throw error
  }
}
