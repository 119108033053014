export const COUNTRIES_DATA = [
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
      icon: "assets/media/svg/flags/111-afghanistan.svg",
    },
    {
      name: "Aland Islands",
      dial_code: "+358",
      code: "AX",
      icon: "assets/media/svg/flags/087-aland-islands.svg",
    },
    {
      name: "Albania",
      dial_code: "+355",
      code: "AL",
      icon: "assets/media/svg/flags/099-albania.svg",
    },
    {
      name: "Algeria",
      dial_code: "+213",
      code: "DZ",
      icon: "assets/media/svg/flags/144-algeria.svg",
    },
    {
      name: "American Samoa",
      dial_code: "+1",
      code: "AS",
      icon: "assets/media/svg/flags/027-american-samoa.svg",
    },
    {
      name: "Andorra",
      dial_code: "+376",
      code: "AD",
      icon: "assets/media/svg/flags/045-andorra.svg",
    },
    {
      name: "Angola",
      dial_code: "+244",
      code: "AO",
      icon: "assets/media/svg/flags/117-angola.svg",
    },
    {
      name: "Anguilla",
      dial_code: "+1",
      code: "AI",
      icon: "assets/media/svg/flags/025-anguilla.svg",
    },
    {
      name: "Antarctica",
      dial_code: "+672",
      code: "AQ",
      icon: "assets/media/svg/flags/aq.svg",
    },
    {
      name: "Antigua and Barbuda",
      dial_code: "+1268",
      code: "AG",
      icon: "assets/media/svg/flags/075-antigua-and-barbuda.svg",
    },
    {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
      icon: "assets/media/svg/flags/198-argentina.svg",
    },
    {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
      icon: "assets/media/svg/flags/108-armenia.svg",
    },
    {
      name: "Aruba",
      dial_code: "+297",
      code: "AW",
      icon: "assets/media/svg/flags/042-aruba.svg",
    },
    {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
      icon: "assets/media/svg/flags/009-australia.svg",
    },
    {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
      icon: "assets/media/svg/flags/001-austria.svg",
    },
    {
      name: "Azerbaijan",
      dial_code: "+994",
      code: "AZ",
      icon: "assets/media/svg/flags/141-azerbaijan.svg",
    },
    {
      name: "Bahamas",
      dial_code: "+1",
      code: "BS",
      icon: "assets/media/svg/flags/120-bahamas.svg",
    },
    {
      name: "Bahrain",
      dial_code: "+973",
      code: "BH",
      icon: "assets/media/svg/flags/138-bahrain.svg",
    },
    {
      name: "Bangladesh",
      dial_code: "+880",
      code: "BD",
      icon: "assets/media/svg/flags/147-bangladesh.svg",
    },
    {
      name: "Barbados",
      dial_code: "+1",
      code: "BB",
      icon: "assets/media/svg/flags/084-barbados.svg",
    },
    {
      name: "Belarus",
      dial_code: "+375",
      code: "BY",
      icon: "assets/media/svg/flags/135-belarus.svg",
    },
    {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
      icon: "assets/media/svg/flags/002-belgium.svg",
    },
    {
      name: "Belize",
      dial_code: "+501",
      code: "BZ",
      icon: "assets/media/svg/flags/078-belize.svg",
    },
    {
      name: "Benin",
      dial_code: "+229",
      code: "BJ",
      icon: "assets/media/svg/flags/060-benin.svg",
    },
    {
      name: "Bermuda",
      dial_code: "+1",
      code: "BM",
      icon: "assets/media/svg/flags/081-bermuda.svg",
    },
    {
      name: "Bhutan",
      dial_code: "+975",
      code: "BT",
      icon: "assets/media/svg/flags/040-bhutan.svg",
    },
    {
      name: "Bolivia, Plurinational State of",
      dial_code: "+591",
      code: "BO",
      icon: "assets/media/svg/flags/150-bolivia.svg", 
    },
    {
      name: "Bosnia and Herzegovina",
      dial_code: "+387",
      code: "BA",
      icon: "assets/media/svg/flags/132-bosnia-and-herzegovina.svg",
    },
    {
      name: "Botswana",
      dial_code: "+267",
      code: "BW",
      icon: "assets/media/svg/flags/126-botswana.svg",
    },
    {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
      icon: "assets/media/svg/flags/011-brazil.svg",
    },
    {
      name: "British Indian Ocean Territory",
      dial_code: "+246",
      code: "IO",
      icon: "assets/media/svg/flags/069-british-indian-ocean-territory.svg",
    },
    {
      name: "Brunei Darussalam",
      dial_code: "+673",
      code: "BN",
      icon: "assets/media/svg/flags/119-brunei.svg",
    },
    {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
      icon: "assets/media/svg/flags/168-bulgaria.svg",
    },
    {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
      icon: "assets/media/svg/flags/090-burkina-faso.svg",
    },
    {
      name: "Burundi",
      dial_code: "+257",
      code: "BI",
      icon: "assets/media/svg/flags/057-burundi.svg",
    },
    {
      name: "Cambodia",
      dial_code: "+855",
      code: "KH",
      icon: "assets/media/svg/flags/159-cambodia.svg",
    },
    {
      name: "Cameroon",
      dial_code: "+237",
      code: "CM",
      icon: "assets/media/svg/flags/105-cameroon.svg",
    },
    {
      name: "Canada",
      dial_code: "+1",
      code: "CA",
      icon: "assets/media/svg/flags/243-canada.svg",
    },
    {
      name: "Cape Verde",
      dial_code: "+238",
      code: "CV",
      icon: "assets/media/svg/flags/038-cape-verde.svg",
    },
    {
      name: "Cayman Islands",
      dial_code: "+1",
      code: "KY",
      icon: "assets/media/svg/flags/051-cayman-islands.svg",
    },
    {
      name: "Central African Republic",
      dial_code: "+236",
      code: "CF",
      icon: "assets/media/svg/flags/036-central-african-republic.svg",
    },
    {
      name: "Chad",
      dial_code: "+235",
      code: "TD",
      icon: "assets/media/svg/flags/066-chad.svg",
    },
    {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
      icon: "assets/media/svg/flags/131-chile.svg",
    },
    {
      name: "China",
      dial_code: "+86",
      code: "CN",
      icon: "assets/media/svg/flags/015-china.svg",
    },
    {
      name: "Christmas Island",
      dial_code: "+61",
      code: "CX",
      icon: "assets/media/svg/flags/017-christmas-island.svg",
    },
    {
      name: "Cocos (Keeling) Islands",
      dial_code: "+61",
      code: "CC",
      icon: "assets/media/svg/flags/023-cocos-island.svg",
    },
    {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
      icon: "assets/media/svg/flags/177-colombia.svg",
    },
    {
      name: "Comoros",
      dial_code: "+269",
      code: "KM",
      icon: "assets/media/svg/flags/029-comoros.svg",
    },
    {
      name: "Congo",
      dial_code: "+242",
      code: "CG",
      icon: "assets/media/svg/flags/157-republic-of-the-congo.svg",
    },
    {
      name: "Democratic Republic of Congo",
      dial_code: "+243",
      code: "CD",
      icon: "assets/media/svg/flags/249-democratic-republic-of-congo.svg",
    },
    {
      name: "Cook Islands",
      dial_code: "+682",
      code: "CK",
      icon: "assets/media/svg/flags/021-cook-islands.svg",
    },
    {
      name: "Costa Rica",
      dial_code: "+506",
      code: "CR",
      icon: "assets/media/svg/flags/156-costa-rica.svg",
    },
    {
      name: "Cote d'Ivoire",
      dial_code: "+225",
      code: "CI",
      icon: "assets/media/svg/flags/ci.svg",
    },
    {
      name: "Croatia",
      dial_code: "+385",
      code: "HR",
      icon: "assets/media/svg/flags/164-croatia.svg",
    },
    {
      name: "Cuba",
      dial_code: "+53",
      code: "CU",
      icon: "assets/media/svg/flags/153-cuba.svg",
    },
    {
      name: "Cyprus",
      dial_code: "+357",
      code: "CY",
      icon: "assets/media/svg/flags/101-northern-cyprus.svg",
    },
    {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
      icon: "assets/media/svg/flags/149-czech-republic.svg",
    },
    {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
      icon: "assets/media/svg/flags/174-denmark.svg",
    },
    {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
      icon: "assets/media/svg/flags/068-djibouti.svg",
    },
    {
      name: "Dominica",
      dial_code: "+1",
      code: "DM",
      icon: "assets/media/svg/flags/186-dominica.svg",
    },
    {
      name: "Dominican Republic",
      dial_code: "+1",
      code: "DO",
      icon: "assets/media/svg/flags/047-dominican-republic.svg",
    },
    {
      name: "Ecuador",
      dial_code: "+593",
      code: "EC",
      icon: "assets/media/svg/flags/104-ecuador.svg",
    },
    {
      name: "Egypt",
      dial_code: "+20",
      code: "EG",
      icon: "assets/media/svg/flags/158-egypt.svg",
    },
    {
      name: "El Salvador",
      dial_code: "+503",
      code: "SV",
      icon: "assets/media/svg/flags/015-el-salvador.svg",
    },
    {
      name: "Equatorial Guinea",
      dial_code: "+240",
      code: "GQ",
      icon: "assets/media/svg/flags/189-equatorial-guinea.svg",
    },
    {
      name: "Eritrea",
      dial_code: "+291",
      code: "ER",
      icon: "assets/media/svg/flags/065-eritrea.svg",
    },
    {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
      icon: "assets/media/svg/flags/008-estonia.svg",
    },
    {
      name: "Ethiopia",
      dial_code: "+251",
      code: "ET",
      icon: "assets/media/svg/flags/005-ethiopia.svg",
    },
    {
      name: "Falkland Islands (Malvinas)",
      dial_code: "+500",
      code: "FK",
      icon: "assets/media/svg/flags/215-falkland-islands.svg",
    },
    {
      name: "Faroe Islands",
      dial_code: "+298",
      code: "FO",
      icon: "assets/media/svg/flags/fo.svg",
    },
    {
      name: "Fiji",
      dial_code: "+679",
      code: "FJ",
      icon: "assets/media/svg/flags/137-fiji.svg",
    },
    {
      name: "Finland",
      dial_code: "+358",
      code: "FI",
      icon: "assets/media/svg/flags/125-finland.svg",
    },
    {
      name: "France",
      dial_code: "+33",
      code: "FR",
      icon: "assets/media/svg/flags/019-france.svg",
    },
    {
      name: "French Guiana",
      dial_code: "+594",
      code: "GF",
      icon: "assets/media/svg/flags/French_Guiana.png",
    },
    {
      name: "French Polynesia",
      dial_code: "+689",
      code: "PF",
      icon: "assets/media/svg/flags/180-french-polynesia.svg",
    },
    {
      name: "Gabon",
      dial_code: "+241",
      code: "GA",
      icon: "assets/media/svg/flags/059-gabon.svg",
    },
    {
      name: "Gambia",
      dial_code: "+220",
      code: "GM",
      icon: "assets/media/svg/flags/146-gambia.svg",
    },
    {
      name: "Georgia",
      dial_code: "+995",
      code: "GE",
      icon: "assets/media/svg/flags/256-georgia.svg",
    },
    {
      name: "Germany",
      dial_code: "+49",
      code: "DE",
      icon: "assets/media/svg/flags/017-germany.svg",
    },
    {
      name: "Ghana",
      dial_code: "+233",
      code: "GH",
      icon: "assets/media/svg/flags/053-ghana.svg",
    },
    {
      name: "Gibraltar",
      dial_code: "+350",
      code: "GI",
      icon: "assets/media/svg/flags/213-gibraltar.svg",
    },
    {
      name: "Greece",
      dial_code: "+30",
      code: "GR",
      icon: "assets/media/svg/flags/170-greece.svg",
    },
    {
      name: "Greenland",
      dial_code: "+299",
      code: "GL",
      icon: "assets/media/svg/flags/113-greenland.svg",
    },
    {
      name: "Grenada",
      dial_code: "+1",
      code: "GD",
      icon: "assets/media/svg/flags/210-grenada.svg",
    },
    {
      name: "Guadeloupe",
      dial_code: "+590",
      code: "GP",
      icon: "assets/media/svg/flags/guadeloupe.png",
    },
    {
      name: "Guam",
      dial_code: "+1",
      code: "GU",
      icon: "assets/media/svg/flags/207-guam.svg",
    },
    {
      name: "Guatemala",
      dial_code: "+502",
      code: "GT",
      icon: "assets/media/svg/flags/098-guatemala.svg",
    },
    {
      name: "Guernsey",
      dial_code: "+44",
      code: "GG",
      icon: "assets/media/svg/flags/204-guernsey.svg",
    },
    {
      name: "Guinea",
      dial_code: "+224",
      code: "GN",
      icon: "assets/media/svg/flags/110-guinea.svg",
    },
    {
      name: "Guinea-Bissau",
      dial_code: "+245",
      code: "GW",
      icon: "assets/media/svg/flags/056-guinea-bissau.svg",
    },
    {
      name: "Guyana",
      dial_code: "+595",
      code: "GY",
      icon: "assets/media/svg/flags/gy.svg",
    },
    {
      name: "Haiti",
      dial_code: "+509",
      code: "HT",
      icon: "assets/media/svg/flags/185-haiti.svg",
    },
    {
      name: "Holy See (Vatican City State)",
      dial_code: "+379",
      code: "VA",
      icon: "assets/media/svg/flags/124-vatican-city.svg",
    },
    {
      name: "Honduras",
      dial_code: "+504",
      code: "HN",
      icon: "assets/media/svg/flags/024-honduras.svg",
    },
    {
      name: "Hong Kong",
      dial_code: "+852",
      code: "HK",
      icon: "assets/media/svg/flags/183-hong-kong.svg",
    },
    {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
      icon: "assets/media/svg/flags/115-hungary.svg",
    },
    {
      name: "Iceland",
      dial_code: "+354",
      code: "IS",
      icon: "assets/media/svg/flags/080-iceland.svg",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
      icon: "assets/media/svg/flags/010-india.svg",
    },
    {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
      icon: "assets/media/svg/flags/004-indonesia.svg",
    },
    {
      name: "Iran",
      dial_code: "+98",
      code: "IR",
      icon: "assets/media/svg/flags/136-iran.svg",
    },
    {
      name: "Iraq",
      dial_code: "+964",
      code: "IQ",
      icon: "assets/media/svg/flags/020-iraq.svg",
    },
    {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
      icon: "assets/media/svg/flags/179-ireland.svg",
    },
    {
      name: "Isle of Man",
      dial_code: "+44",
      code: "IM",
      icon: "assets/media/svg/flags/219-isle-of-man.svg",
    },
    {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
      icon: "assets/media/svg/flags/155-israel.svg",
    },
    {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
      icon: "assets/media/svg/flags/013-italy.svg",
    },
    {
      name: "Jamaica",
      dial_code: "+1",
      code: "JM",
      icon: "assets/media/svg/flags/037-jamaica.svg",
    },
    {
      name: "Japan",
      dial_code: "+81",
      code: "JP",
      icon: "assets/media/svg/flags/014-japan.svg",
    },
    {
      name: "Jersey",
      dial_code: "+44",
      code: "JE",
      icon: "assets/media/svg/flags/245-jersey.svg",
    },
    {
      name: "Jordan",
      dial_code: "+962",
      code: "JO",
      icon: "assets/media/svg/flags/077-jordan.svg",
    },
    {
      name: "Kazaksthan",
      dial_code: "+7",
      code: "KZ",
      icon: "assets/media/svg/flags/074-kazakhstan.svg",
    },
    {
      name: "Kenya",
      dial_code: "+254",
      code: "KE",
      icon: "assets/media/svg/flags/067-kenya.svg",
    },
    {
      name: "Kiribati",
      dial_code: "+686",
      code: "KI",
      icon: "assets/media/svg/flags/261-kiribati.svg",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "+850",
      code: "KP",
      icon: "assets/media/svg/flags/030-north-korea.svg",
    },
    {
      name: "Kosovo",
      dial_code: "+383",
      code: "XK",
      icon: "assets/media/svg/flags/kosovo.svg",
    },
    {
      name: "South Korea",
      dial_code: "+82",
      code: "KR",
      icon: "assets/media/svg/flags/018-south-korea.svg",
    },
    {
      name: "South Sudan",
      dial_code: "+211",
      code: "SS",
      icon: "assets/media/svg/flags/south-sudan.svg",
    },
    {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
      icon: "assets/media/svg/flags/107-kuwait.svg",
    },
    {
      name: "Kyrgyzstan",
      dial_code: "+996",
      code: "KG",
      icon: "assets/media/svg/flags/152-kyrgyzstan.svg",
    },
    {
      name: "Laos",
      dial_code: "+856",
      code: "LA",
      icon: "assets/media/svg/flags/112-laos.svg",
    },
    {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
      icon: "assets/media/svg/flags/044-latvia.svg",
    },
    {
      name: "Lebanon",
      dial_code: "+961",
      code: "LB",
      icon: "assets/media/svg/flags/018-lebanon.svg",
    },
    {
      name: "Lesotho",
      dial_code: "+266",
      code: "LS",
      icon: "assets/media/svg/flags/176-lesotho.svg",
    },
    {
      name: "Liberia",
      dial_code: "+231",
      code: "LR",
      icon: "assets/media/svg/flags/169-liberia.svg",
    },
    {
      name: "Libya",
      dial_code: "+218",
      code: "LY",
      icon: "assets/media/svg/flags/ly.svg",
    },
    {
      name: "Liechtenstein",
      dial_code: "+423",
      code: "LI",
      icon: "assets/media/svg/flags/134-liechtenstein.svg",
    },
    {
      name: "Lithuania",
      dial_code: "+370",
      code: "LT",
      icon: "assets/media/svg/flags/064-lithuania.svg",
    },
    {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
      icon: "assets/media/svg/flags/035-luxembourg.svg",
    },
    {
      name: "Macao",
      dial_code: "+853",
      code: "MO",
      icon: "assets/media/svg/flags/167-macao.svg",
    },
    {
      name: "Macedonia",
      dial_code: "+389",
      code: "MK",
      icon: "assets/media/svg/flags/236-republic-of-macedonia.svg",
    },
    {
      name: "Madagascar",
      dial_code: "+261",
      code: "MG",
      icon: "assets/media/svg/flags/242-madagascar.svg",
    },
    {
      name: "Malawi",
      dial_code: "+265",
      code: "MW",
      icon: "assets/media/svg/flags/214-malawi.svg",
    },
    {
      name: "Malaysia",
      dial_code: "+60",
      code: "MY",
      icon: "assets/media/svg/flags/007-malasya.svg",
    },
    {
      name: "Maldives",
      dial_code: "+960",
      code: "MV",
      icon: "assets/media/svg/flags/225-maldives.svg",
    },
    {
      name: "Mali",
      dial_code: "+223",
      code: "ML",
      icon: "assets/media/svg/flags/173-mali.svg",
    },
    {
      name: "Malta",
      dial_code: "+356",
      code: "MT",
      icon: "assets/media/svg/flags/194-malta.svg",
    },
    {
      name: "Marshall Islands",
      dial_code: "+692",
      code: "MH",
      icon: "assets/media/svg/flags/103-marshall-island.svg",
    },
    {
      name: "Martinique",
      dial_code: "+596",
      code: "MQ",
      icon: "assets/media/svg/flags/201-martinique.svg",
    },
    {
      name: "Mauritania",
      dial_code: "+222",
      code: "MR",
      icon: "assets/media/svg/flags/050-mauritania.svg",
    },
    {
      name: "Mauritius",
      dial_code: "+230",
      code: "MU",
      icon: "assets/media/svg/flags/001-mauritius.svg",
    },
    {
      name: "Mayotte",
      dial_code: "+262",
      code: "YT",
      icon: "assets/media/svg/flags/mayotte.png",
    },
    {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
      icon: "assets/media/svg/flags/021-mexico.svg",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      dial_code: "+691",
      code: "FM",
      icon: "assets/media/svg/flags/046-micronesia.svg",
    },
    {
      name: "Moldova",
      dial_code: "+373",
      code: "MD",
      icon: "assets/media/svg/flags/212-moldova.svg",
    },
    {
      name: "Monaco",
      dial_code: "+377",
      code: "MC",
      icon: "assets/media/svg/flags/039-monaco.svg",
    },
    {
      name: "Mongolia",
      dial_code: "+976",
      code: "MN",
      icon: "assets/media/svg/flags/258-mongolia.svg",
    },
    {
      name: "Montenegro",
      dial_code: "+382",
      code: "ME",
      icon: "assets/media/svg/flags/239-montenegro.svg",
    },
    {
      name: "Montserrat",
      dial_code: "+1664",
      code: "MS",
      icon: "assets/media/svg/flags/043-montserrat.svg",
    },
    {
      name: "Morocco",
      dial_code: "+212",
      code: "MA",
      icon: "assets/media/svg/flags/166-morocco.svg",
    },
    {
      name: "Mozambique",
      dial_code: "+258",
      code: "MZ",
      icon: "assets/media/svg/flags/096-mozambique.svg",
    },
    {
      name: "Myanmar",
      dial_code: "+95",
      code: "MM",
      icon: "assets/media/svg/flags/058-myanmar.svg",
    },
    {
      name: "Namibia",
      dial_code: "+264",
      code: "NA",
      icon: "assets/media/svg/flags/062-namibia.svg",
    },
    {
      name: "Nauru",
      dial_code: "+674",
      code: "NR",
      icon: "assets/media/svg/flags/228-nauru.svg",
    },
    {
      name: "Nepal",
      dial_code: "+977",
      code: "NP",
      icon: "assets/media/svg/flags/016-nepal.svg",
    },
    {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
      icon: "assets/media/svg/flags/237-netherlands.svg",
    },
    {
      name: "Netherlands Antilles",
      dial_code: "+599",
      code: "AN",
      icon: "assets/media/svg/flags/netherlands_antilles.png",
    },
    {
      name: "New Caledonia",
      dial_code: "+687",
      code: "NC",
      icon: "assets/media/svg/flags/nc.svg",
    },
    {
      name: "New Zealand",
      dial_code: "+64",
      code: "NZ",
      icon: "assets/media/svg/flags/121-new-zealand.svg",
    },
    {
      name: "Nicaragua",
      dial_code: "+505",
      code: "NI",
      icon: "assets/media/svg/flags/007-nicaragua.svg",
    },
    {
      name: "Niger",
      dial_code: "+227",
      code: "NE",
      icon: "assets/media/svg/flags/222-niger.svg",
    },
    {
      name: "Nigeria",
      dial_code: "+234",
      code: "NG",
      icon: "assets/media/svg/flags/086-nigeria.svg",
    },
    {
      name: "Niue",
      dial_code: "+683",
      code: "NU",
      icon: "assets/media/svg/flags/182-niue.svg",
    },
    {
      name: "Norfolk Island",
      dial_code: "+672",
      code: "NF",
      icon: "assets/media/svg/flags/193-norfolk-island.svg",
    },
    {
      name: "Northern Mariana Islands",
      dial_code: "+1",
      code: "MP",
      icon: "assets/media/svg/flags/160-northern-marianas-islands.svg",
    },
    {
      name: "Norway",
      dial_code: "+47",
      code: "NO",
      icon: "assets/media/svg/flags/143-norway.svg",
    },
    {
      name: "Oman",
      dial_code: "+968",
      code: "OM",
      icon: "assets/media/svg/flags/004-oman.svg",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
      icon: "assets/media/svg/flags/100-pakistan.svg",
    },
    {
      name: "Palau",
      dial_code: "+680",
      code: "PW",
      icon: "assets/media/svg/flags/178-palau.svg",
    },
    {
      name: "Palestine",
      dial_code: "+970",
      code: "PS",
      icon: "assets/media/svg/flags/ps.svg",
    },
    {
      name: "Panama",
      dial_code: "+507",
      code: "PA",
      icon: "assets/media/svg/flags/106-panama.svg",
    },
    {
      name: "Papua New Guinea",
      dial_code: "+675",
      code: "PG",
      icon: "assets/media/svg/flags/163-papua-new-guinea.svg",
    },
    {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
      icon: "assets/media/svg/flags/041-paraguay.svg",
    },
    {
      name: "Peru",
      dial_code: "+51",
      code: "PE",
      icon: "assets/media/svg/flags/188-peru.svg",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
      icon: "assets/media/svg/flags/192-philippines.svg",
    },
    {
      name: "Pitcairn",
      dial_code: "+872",
      code: "PN",
      icon: "assets/media/svg/flags/095-pitcairn-islands.svg",
    },
    {
      name: "Poland",
      dial_code: "+48",
      code: "PL",
      icon: "assets/media/svg/flags/211-poland.svg",
    },
    {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
      icon: "assets/media/svg/flags/005-portugal.svg",
    },
    {
      name: "Puerto Rico",
      dial_code: "+1",
      code: "PR",
      icon: "assets/media/svg/flags/028-puerto-rico.svg",
    },
    {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
      icon: "assets/media/svg/flags/026-qatar.svg",
    },
    {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
      icon: "assets/media/svg/flags/109-romania.svg",
    },
    {
      name: "Russia",
      dial_code: "+7",
      code: "RU",
      icon: "assets/media/svg/flags/013-russia.svg",
    },
    {
      name: "Rwanda",
      dial_code: "+250",
      code: "RW",
      icon: "assets/media/svg/flags/206-rwanda.svg",
    },
    {
      name: "Reunion",
      dial_code: "+262",
      code: "RE",
      icon: "assets/media/svg/flags/reunion.png",
    },
    {
      name: "Saint Barthelemy",
      dial_code: "+590",
      code: "BL",
      icon: "assets/media/svg/flags/saint_barthelemy.png",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "+290",
      code: "SH",
      icon: "assets/media/svg/flags/sh.svg",
    },
    {
      name: "Saint Kitts and Nevis",
      dial_code: "+1",
      code: "KN",
      icon: "assets/media/svg/flags/033-saint-kitts-and-nevis.svg",
    },
    {
      name: "Saint Lucia",
      dial_code: "+1",
      code: "LC",
      icon: "assets/media/svg/flags/172-st-lucia.svg",
    },
    {
      name: "Saint Martin",
      dial_code: "+590",
      code: "MF",
      icon: "assets/media/svg/flags/saint_martin.png",
    },
    {
      name: "Saint Pierre and Miquelon",
      dial_code: "+508",
      code: "PM",
      icon: "assets/media/svg/flags/saint_pierre_and_miquelon.png",
    },
    {
      name: "St. Vincent and Grenadines",
      dial_code: "+1",
      code: "VC",
      icon: "assets/media/svg/flags/241-st-vincent-and-the-grenadines.svg",
    },
    {
      name: "Samoa",
      dial_code: "+685",
      code: "WS",
      icon: "assets/media/svg/flags/251-samoa.svg",
    },
    {
      name: "San Marino",
      dial_code: "+378",
      code: "SM",
      icon: "assets/media/svg/flags/097-san-marino.svg",
    },
    {
      name: "Sao Tome and Principe",
      dial_code: "+239",
      code: "ST",
      icon: "assets/media/svg/flags/012-sao-tome-and-prince.svg",
    },
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
      icon: "assets/media/svg/flags/008-saudi-arabia.svg",
    },
    {
      name: "Scotland",
      dial_code: "+44",
      code: "GB",
      icon: "assets/media/svg/flags/008-scotland.svg",
    },
    {
      name: "Senegal",
      dial_code: "+221",
      code: "SN",
      icon: "assets/media/svg/flags/227-senegal.svg",
    },
    {
      name: "Serbia",
      dial_code: "+381",
      code: "RS",
      icon: "assets/media/svg/flags/071-serbia.svg",
    },
    {
      name: "Seychelles",
      dial_code: "+248",
      code: "SC",
      icon: "assets/media/svg/flags/253-seychelles.svg",
    },
    {
      name: "Sierra Leone",
      dial_code: "+232",
      code: "SL",
      icon: "assets/media/svg/flags/092-sierra-leone.svg",
    },
    {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
      icon: "assets/media/svg/flags/230-singapore.svg",
    },
    {
      name: "Slovakia",
      dial_code: "+421",
      code: "SK",
      icon: "assets/media/svg/flags/091-slovakia.svg",
    },
    {
      name: "Slovenia",
      dial_code: "+386",
      code: "SI",
      icon: "assets/media/svg/flags/010-slovenia.svg",
    },
    {
      name: "Solomon Islands",
      dial_code: "+677",
      code: "SB",
      icon: "assets/media/svg/flags/085-solomon-islands.svg",
    },
    {
      name: "Somalia",
      dial_code: "+252",
      code: "SO",
      icon: "assets/media/svg/flags/083-somalia.svg",
    },
    {
      name: "South Africa",
      dial_code: "+27",
      code: "ZA",
      icon: "assets/media/svg/flags/200-south-africa.svg",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      dial_code: "+500",
      code: "GS",
      icon: "assets/media/svg/flags/gs.svg",
    },
    {
      name: "Spain",
      dial_code: "+34",
      code: "ES",
      icon: "assets/media/svg/flags/016-spain.svg",
    },
    {
      name: "Sri Lanka",
      dial_code: "+94",
      code: "LK",
      icon: "assets/media/svg/flags/127-sri-lanka.svg",
    },
    {
      name: "Sudan",
      dial_code: "+249",
      code: "SD",
      icon: "assets/media/svg/flags/199-sudan.svg",
    },
    {
      name: "Suriname",
      dial_code: "+597",
      code: "SR",
      icon: "assets/media/svg/flags/076-suriname.svg",
    },
    {
      name: "Svalbard and Jan Mayen",
      dial_code: "+47",
      code: "SJ",
      icon: "assets/media/svg/flags/sj.svg",
    },
    {
      name: "Swaziland",
      dial_code: "+268",
      code: "SZ",
      icon: "assets/media/svg/flags/154-swaziland.svg",
    },
    {
      name: "Sweden",
      dial_code: "+46",
      code: "SE",
      icon: "assets/media/svg/flags/184-sweden.svg",
    },
    {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
      icon: "assets/media/svg/flags/003-switzerland.svg",
    },
    {
      name: "Syria",
      dial_code: "+963",
      code: "SY",
      icon: "assets/media/svg/flags/sy.svg",
    },
    {
      name: "Taiwan",
      dial_code: "+886",
      code: "TW",
      icon: "assets/media/svg/flags/202-taiwan.svg",
    },
    {
      name: "Tajikistan",
      dial_code: "+992",
      code: "TJ",
      icon: "assets/media/svg/flags/196-tajikistan.svg",
    },
    {
      name: "Tanzania",
      dial_code: "+255",
      code: "TZ",
      icon: "assets/media/svg/flags/006-tanzania.svg",
    },
    {
      name: "Thailand",
      dial_code: "+66",
      code: "TH",
      icon: "assets/media/svg/flags/238-thailand.svg",
    },
    {
      name: "Timor-Leste",
      dial_code: "+670",
      code: "TL",
      icon: "assets/media/svg/flags/140-east-timor.svg",
    },
    {
      name: "Togo",
      dial_code: "+228",
      code: "TG",
      icon: "assets/media/svg/flags/073-togo.svg",
    },
    {
      name: "Tokelau",
      dial_code: "+690",
      code: "TK",
      icon: "assets/media/svg/flags/235-tokelau.svg",
    },
    {
      name: "Tonga",
      dial_code: "+676",
      code: "TO",
      icon: "assets/media/svg/flags/191-tonga.svg",
    },
    {
      name: "Trinidad and Tobago",
      dial_code: "+1",
      code: "TT",
      icon: "assets/media/svg/flags/181-trinidad-and-tobago.svg",
    },
    {
      name: "Tunisia",
      dial_code: "+216",
      code: "TN",
      icon: "assets/media/svg/flags/049-tunisia.svg",
    },
    {
      name: "Turkey",
      dial_code: "+90",
      code: "TR",
      icon: "assets/media/svg/flags/006-turkey.svg",
    },
    {
      name: "Turkmenistan",
      dial_code: "+993",
      code: "TM",
      icon: "assets/media/svg/flags/229-turkmenistan.svg",
    },
    {
      name: "Turks and Caicos Islands",
      dial_code: "+1",
      code: "TC",
      icon: "assets/media/svg/flags/223-turks-and-caicos.svg",
    },
    {
      name: "Tuvalu",
      dial_code: "+688",
      code: "TV",
      icon: "assets/media/svg/flags/221-tuvalu.svg",
    },
    {
      name: "Uganda",
      dial_code: "+256",
      code: "UG",
      icon: "assets/media/svg/flags/009-uganda.svg",
    },
    {
      name: "Ukraine",
      dial_code: "+380",
      code: "UA",
      icon: "assets/media/svg/flags/145-ukraine.svg",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
      icon: "assets/media/svg/flags/151-united-arab-emirates.svg",
    },
    {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
      icon: "assets/media/svg/flags/260-united-kingdom.svg",
    },
    {
      name: "United States",
      dial_code: "+1",
      code: "US",
      icon: "assets/media/svg/flags/226-united-states.svg",
    },
    {
      name: "Uruguay",
      dial_code: "+598",
      code: "UY",
      icon: "assets/media/svg/flags/088-uruguay.svg",
    },
    {
      name: "Uzbekistan",
      dial_code: "+998",
      code: "UZ",
      icon: "assets/media/svg/flags/190-uzbekistn.svg",
    },
    {
      name: "Vanuatu",
      dial_code: "+678",
      code: "VU",
      icon: "assets/media/svg/flags/187-vanuatu.svg",
    },
    {
      name: "Venezuela",
      dial_code: "+58",
      code: "VE",
      icon: "assets/media/svg/flags/139-venezuela.svg",
    },
    {
      name: "Vietnam",
      dial_code: "+84",
      code: "VN",
      icon: "assets/media/svg/flags/220-vietnam.svg",
    },
    {
      name: "Virgin Islands, British",
      dial_code: "+1",
      code: "VG",
      icon: "assets/media/svg/flags/114-british-virgin-islands.svg",
    },
    {
      name: "Virgin Islands, U.S.",
      dial_code: "+1",
      code: "VI",
      icon: "assets/media/svg/flags/217-virgin-islands.svg",
    },
    {
      name: "Wallis and Futuna",
      dial_code: "+681",
      code: "WF",
      icon: "assets/media/svg/flags/wallis_and_futuna.png",
    },
    {
      name: "Wales",
      dial_code: "+44",
      code: "GB-WLS",
      icon: "assets/media/svg/flags/Wales.png",
    },
    {
      name: "Yemen",
      dial_code: "+967",
      code: "YE",
      icon: "assets/media/svg/flags/232-yemen.svg",
    },
    {
      name: "Zambia",
      dial_code: "+260",
      code: "ZM",
      icon: "assets/media/svg/flags/032-zambia.svg",
    },
    {
      name: "Zimbabwe",
      dial_code: "+263",
      code: "ZW",
      icon: "assets/media/svg/flags/011-zimbabwe.svg",
    },
  ];