import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {getUserAppIdData, submitReassignment} from './_service/reassignment-service';
import {ILableValue} from '../../page-common-interface';
import {useIntl} from 'react-intl';
import {MY_WORK_STATUS, getStatusString} from '../../my-work/my-work-constants';
import {getAllUserEmails} from '../../../../utils/common-services';
import {IUserAppDistributionResponse} from '../distribution.interface';
import {useDebounce} from '../../../../utils/helpers';
import { ILabelValue } from '../../../../utils/common-interface';

const ReassignApp: React.FC = () => {
  const [alreadyAssignedUser, setAlreadyAssignedUser] = useState<ILableValue>({
    label: '',
    value: '',
  });
  const [usersAppIdData, setUsersAppIdData] = useState<IUserAppDistributionResponse[]>([]);
  const [allUserEmails, setAllUserEmails] = useState<ILableValue[]>([]);
  const [isRedistributing, setIsRedistributing] = useState<boolean>(false);
  const translator = useIntl();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [allSearchedUserEmails, setSearchedAllUserEmails] = useState<ILableValue[]>([]);
  const [isMoreAppsLoading, setIsMoreAppsLoading] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined);
  const [searchLastEvaluatedKey, setSearchLastEvaluatedKey] = useState<string | undefined>(
    undefined
  );
  const debouncedSearchTerm = useDebounce(searchValue, 1000);
  // fetch all users email_id and id for dropdown
  useEffect(() => {
    fetchUserAppIdData();
  }, []);

  const fetchUserAppIdData = async () => {
    try {
      const userData = await getAllUserEmails();
      if (userData) {
        setAllUserEmails(
          userData?.data?.map((user) => {
            return {label: user.email, value: user.id};
          })
        );
        setLastEvaluatedKey(() => userData?.lastEvaluatedKey);
      }
    } catch (error) {}
  };

  // click on redistribute apps
  const redistributeApps = async () => {
    if (alreadyAssignedUser.value) {
      try {
        setIsRedistributing(true);
        const response = await submitReassignment(
          alreadyAssignedUser.value
        );
        if (response) {
          toast.success(
            translator.formatMessage({
              id: 'COMMON.MESSAGE.SUCCESSFULLY_DISTRIBUTED_SELECTED_USERS_APPLICATIONS',
            })
          );
          const userAppData = await getUserAppIdData(alreadyAssignedUser.value);
          setUsersAppIdData(userAppData);
        }
      } catch (error) {
      } finally {
        setIsRedistributing(false);
      }
    }
  };

  // if lastEvaluatedKey is there and searchValue not there
  const loadMoreData = async () => {
    if (!searchLastEvaluatedKey && !searchValue && lastEvaluatedKey) {
      await fetchUsersEmailsData();
    } else if (searchValue && searchLastEvaluatedKey) {
      setLastEvaluatedKey(() => undefined);
      setAllUserEmails(() => []);
      await searchUsersData();
    }
  };

  // fetch users data with last evaluated key
  const fetchUsersEmailsData = async () => {
    try {
      setIsMoreAppsLoading(() => true);
      const resData = await getAllUserEmails(lastEvaluatedKey);
      if (resData) {
        const userData = resData?.data?.map((user) => {
          return {label: user.email, value: user.id};
        });
        setAllUserEmails((prev) => {
          const combinedData = [...prev, ...userData];
          const uniqueUsersMap = new Map(combinedData.map(user => [user?.value, user]));
          return Array.from(uniqueUsersMap.values());
        });
        setLastEvaluatedKey(() => resData.lastEvaluatedKey);
        setSearchedAllUserEmails(() => []);
      }
    } catch (error) {
    } finally {
      setIsMoreAppsLoading(() => false);
    }
  };

  // searched data with last evaluated key
  const searchUsersData = async () => {
    try {
      setAllUserEmails(() => []);
      setIsMoreAppsLoading(() => true);
      const res = await getAllUserEmails(
        searchLastEvaluatedKey,
        searchValue?.toLowerCase()?.trim()
      );
      if (res) {
        const userData = res?.data?.map((user) => {
          return {label: user.email, value: user.id};
        });
        setSearchedAllUserEmails((prev) => {
          const combinedData = [...prev, ...userData];
          const uniqueUsersMap = new Map(combinedData.map(user => [user?.value, user]));
          return Array.from(uniqueUsersMap.values());
        });
        setSearchLastEvaluatedKey(() => res?.lastEvaluatedKey);
        setLastEvaluatedKey(() => undefined);
        setIsMoreAppsLoading(() => false);
      }
    } catch (error) {
    } finally {
      setIsMoreAppsLoading(() => false);
    }
  };

  // on change user email call data of users
  const handleUserEmailSelect = async (event: ILabelValue) => {
    setAlreadyAssignedUser({
      label: event?.label || '',
      value: event?.value || '',
    });
    if (event?.label) {
      try {
        setIsLoading(true);
        const userAppData = await getUserAppIdData(event?.value);
        setUsersAppIdData(userAppData);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else {
      setSearchValue(() => '');
      setSearchLastEvaluatedKey(() => undefined);
      setLastEvaluatedKey(() => undefined);
      setUsersAppIdData([]);
    }
  };

  // on X clear click call all
  const handleSearch = (searchStr: string) => {
    setSearchValue(() => searchStr);
    if (searchStr === '') {
      onReloadClick();
    }
  };

  // on reload click call api
  const onReloadClick = async () => {
    setSearchValue(() => '');
    setSearchLastEvaluatedKey(() => undefined);
    setLastEvaluatedKey(() => undefined);
    setUsersAppIdData([]);
    setAlreadyAssignedUser({ label: '', value: '' });
    setAllUserEmails([]);
    if(!lastEvaluatedKey) {
     await fetchUsersEmailsData();
    }
  };  

  // This is for search if length is greater than 3
  useEffect(() => {
    if (searchValue && searchValue?.length > 3) {
      searchUsersData();
    }
  // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <div id='kt_content_container' className='content flex-row-fluid mb-10'>
      <div className='card'>
        <h1 className='text-center p-3 m-3'>
          {translator.formatMessage({id: 'DISTRIBUTION.REASSIGN_APP_IDS_TO_USERS'})}
        </h1>
        <div className='card-body py-4'>
          <>
            <div className='d-flex justify-content-between gap-4'>
              <div className='w-100 d-flex flex-wrap gap-4 align-items-center'>
                <div className='mw-400px w-100'>
                  <label htmlFor='select_user' className='text-gray-500'>
                    {translator.formatMessage({id: 'COMMON.PLEASE_SELECT_USER_EMAIL'})}
                  </label>
                  <Select
                    options={allUserEmails?.length ? allUserEmails : allSearchedUserEmails}
                    value={alreadyAssignedUser}
                    inputId='userEmail'
                    name='alreadyAssignedUser'
                    required={true}
                    styles={{
                      menuList: (styles) => {
                        return {
                          ...styles,
                          maxHeight: 146,
                        };
                      },
                    }}
                    onChange={(event) => {
                      handleUserEmailSelect({
                        label: event?.label || '',
                        value: event?.value || '',
                      });
                    }}
                    onInputChange={(value, {action}) => {
                      if (action === 'input-change') {
                        handleSearch(value);
                      } 
                    }}
                    isLoading={isMoreAppsLoading}
                    isSearchable={true}
                    isClearable={true}
                    onMenuScrollToBottom={loadMoreData}
                  />
                </div>
                <div className='btn btn-sm btn-light-twitter mt-6' onClick={onReloadClick}>
                  <i className='fa fa-refresh'></i>
                </div>
                {alreadyAssignedUser && alreadyAssignedUser.label && (
                  <div className='mt-3 text-gray-500'>
                    {translator.formatMessage({id: 'DISTRIBUTION.TOTAL_APPLICATION_COUNT'})} :{' '}
                    <span className='ms-2'>{usersAppIdData?.length}</span>
                  </div>
                )}
              </div>

              <div className='text-end min-w-175px'>
                <div
                  className='btn btn-light btn-active-light-primary btn-sm'
                  aria-disabled={isRedistributing}
                  onClick={() => redistributeApps()}
                >
                  {!isRedistributing && (
                    <span className='indicator-label'>
                      {translator.formatMessage({id: 'DISTRIBUTION.RE_DISTRIBUTE_APP_IDS'})}
                    </span>
                  )}
                  {isRedistributing && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {translator.formatMessage({id: 'COMMON.PLEASE_WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className='table-responsive min-h-400px'>
              <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th colSpan={1} role='columnheader' className='min-w-125px'>
                      {translator.formatMessage({id: 'INPUT.APPLICATION_IDS'})}
                    </th>
                    <th colSpan={1} role='columnheader' className='min-w-125px'>
                      {translator.formatMessage({id: 'INPUT.STATUS'})}
                    </th>
                    <th colSpan={1} role='columnheader' className='min-w-125px'>
                      {translator.formatMessage({id: 'INPUT.LAST_MODIFIED_DATE'})}
                    </th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tbody className='position-absolute end-50 top-50 min-h-400px'>
                    <tr>
                      <td className='spinner-border h-75px w-75px text-primary' role='status'>
                        <span className='visually-hidden'>
                          {translator.formatMessage({id: 'COMMON.LOADING'})}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className='text-gray-600 fw-bold'>
                    {usersAppIdData?.length === 0 ? (
                      <>
                        {alreadyAssignedUser.label ? (
                          <tr>
                            <td className='py-10'>
                              {translator.formatMessage({
                                id: 'COMMON.MESSAGE.NO_APPLICATIONS_DATA_FOUND',
                              })}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td className='py-10'>
                              {translator.formatMessage({id: 'COMMON.PLEASE_SELECT_USER_EMAIL'})}
                            </td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <>
                        {usersAppIdData?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.app_id}</td>
                              <td>
                                <div
                                  className={`badge fw-bolder ${getStatusString(item?.status)
                                    ?.bgColor}`}
                                >
                                  {translator.formatMessage({
                                    id: `MY_WORK.${MY_WORK_STATUS[item?.status]}`,
                                  })}
                                </div>
                              </td>
                              <td>{new Date(item.modified_at).toDateString()}</td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ReassignApp;
