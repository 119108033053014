import {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {getValueLocalStorage, setValueLocalStorage} from '../../auth/core/_requests';
import {toast} from 'react-toastify';
import {CBC, EvalHsPgSchema, getChanges, initialEvalHsPgValues} from './general-evaluation-constants';
import {autoSaveCredentialEvalData} from '../../../../utils/common-services';
import {DEFAULT_COMMENTS} from './general-evaluation-constants';
import Transcript from '../../../components/transcript/transcript';
import DialogBoxWithInput from '../../../components/dialog-box-with-input/dialog-box-with-input';
import {useAuth} from '../../auth';
import EditableSelect from '../../../components/editable-select/editable-select';
import {ILableValue} from '../../page-common-interface';
import {ICredEvalLocalStorageData, IKeyValues} from '../../../../utils/common-interface';
import { useIntl } from 'react-intl';
import { getFormattedDate } from '../../../../utils/utils';
import { commonConstant } from '../../../../utils/common-constant';
import { IAppEvalUgPgData, IEvalHsComponent, IEvalHsPg } from '../evaluation-interfaces';
import { dropdownData, fetchGeneralEvalDbData, updatePgData } from '../_service/type-of-evaluation-services';

const EvalHighSchoolPostGraduate: React.FC<IEvalHsComponent> = ({
  isAutoSave,
  setCbcData,
  goToPreview,
  setPreviewData,
}) => {
  const translator = useIntl();
  const [additionalComments, setAdditionalComments] = useState<ILableValue[]>(DEFAULT_COMMENTS(translator));
  const formIk = useFormik({
    initialValues: initialEvalHsPgValues,
    validationSchema: EvalHsPgSchema(translator),
    onSubmit: () => {},
  });
  const navigate = useNavigate();
  const {currentUser} = useAuth();
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const localStorageData = getValueLocalStorage(commonConstant.credEvalData) || '';
  const credEvalData: ICredEvalLocalStorageData | undefined =
    localStorageData.length > 0 ? JSON.parse(localStorageData) : undefined;
  const [dataFromApi, setDataFromApi] = useState<IEvalHsPg>({} as IEvalHsPg);
  const [isLoading, setIsLoading] = useState(false);
  const [schools, setSchools] = useState<ILableValue[]>([]);
  const [cities, setCities] = useState<ILableValue[]>([]);
  const [states, setStates] = useState<ILableValue[]>([]);
  const [isPgEvaluation, setIsPgEvaluation] = useState<boolean>(
    credEvalData?.isPgEvaluation ? true : false
  );
  const [isDropDownLoading, setIsDropDownLoading] = useState<boolean>(false);
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);

  // Effect to handle auto-saving when the queue changes
  const autoSaveData = async (dataToUpdate: IEvalHsPg) => {
    try {
      if (isAutoSaving || !formIk.values.type_of_eval || !isAutoSave) {
        return;
      }
      const localStorage = getValueLocalStorage(commonConstant.credEvalData) || '';
      const credEval: ICredEvalLocalStorageData | undefined =
      localStorage.length > 0 ? JSON.parse(localStorage) : undefined;
      setIsAutoSaving(() => true);
      const changes: IKeyValues = {};
      Object.entries(dataToUpdate).forEach(([key, value]) => {
        if (dataFromApi[key as keyof IEvalHsPg] !== value)
          changes[key] = typeof value === 'string' ? value.trim() : value;
      });
      if (Object.keys(changes).length === 0) {
        setIsAutoSaving(false);
        return;
      }
      const response = await autoSaveCredentialEvalData(
        credEval?.appIdWithOrgCode || '',
        changes,
        commonConstant.typeOfEvaluation
      );
      if (response) {
        setDataFromApi((prevData) => ({
          ...prevData,
          ...response,
        }));
        formIk.setValues((prev) => ({
          ...prev,
          ...response,
        }));
      }
    } catch (err) { } 
    finally {
      setIsAutoSaving(() => false);
    }
  };

  const useDebounce = (callback: (args: any) => void, delay: number) => {
    const timeoutRef = useRef<any>(null);
    const debounceTimeout = useCallback(
      (args: any) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          callback(args);
        }, delay);
      },
      [callback, delay]
    );
    return debounceTimeout;
  };

  const debounceValue = useDebounce(autoSaveData, 3000);
  // Effect to handle auto-saving when the queue changes

  useEffect(() => {
    if (!isAutoSaving && formIk.values.type_of_eval && isAutoSave) {
      debounceValue(formIk.values);
    }
  // eslint-disable-next-line
  }, [debounceValue, isAutoSave]);

  // patch value to the formIk
  useEffect(() => {
    const localStorage = getValueLocalStorage(commonConstant.credEvalData) || '';
    const credEval: ICredEvalLocalStorageData | undefined =
      localStorage.length > 0 ? JSON.parse(localStorage) : undefined;
    const fetchDataFromAPI = async () => {
      try {
        const data = await fetchGeneralEvalDbData(credEval?.appIdWithOrgCode || '');
        if (data) {
          const resultData: IEvalHsPg = {
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            type_of_eval: data.type_of_eval || '',
            pg_scale: data.pg_scale || '',
            pg_country: data.pg_country || '',
            pg_school: data.pg_school || '',
            pg_gpa: data.pg_gpa || 0,
            pg_gpa_letter_grade: data.pg_gpa_letter_grade || '',
            pg_additional_comment: data.pg_additional_comment || '',
            pg_state: data.pg_state || '',
            pg_city: data.pg_city || '',
            pg_program_duration: data.pg_program_duration,
            eval_date: data.eval_date,
            pg_temp_comments: data.pg_temp_comments || '',
            eval_by: data.eval_by || currentUser?.user_code || '',
            review_by: data.review_by || currentUser?.user_code || '',
            pg_out_of: data.pg_out_of || 0,
          };
          formIk.setValues({
            ...resultData,
            pg_program_duration: !data.pg_program_duration ? 0 : data.pg_program_duration,
          });
          setDataFromApi(resultData);
          setPreviewData(data);
          setCbcData(data.cbc);
        }
      } catch (error) {}
    };
    const fetchAllDropDownData = async () => {
      try {
        setIsDropDownLoading(true);
        const responseData: IAppEvalUgPgData[] = await dropdownData();   
        const processResponse = (key: string): ILableValue[] => {
          const uniqueItems = new Set<string>();
          responseData?.forEach(item => {
            const resValue = (item as IKeyValues)[key];
            if (resValue) {
              uniqueItems.add(resValue!);
            }
          });
          return Array.from(uniqueItems).map(item => ({ label: item, value: item }));
        };
        setCities(processResponse('pg_city'));
        setStates(processResponse('pg_state'));
        setSchools(processResponse('pg_school'));
        setIsDropDownLoading(false);
      } catch (error) {
        setIsDropDownLoading(false);
      }
    };
    fetchDataFromAPI();
    fetchAllDropDownData();
  // eslint-disable-next-line
  }, []);

  // handle routing changes
  const handleChangeNavigation = async (navigateTo: string) => {
    await onSaveClick();
    navigate(navigateTo);
  };

  // on save click handle data
  const onSaveClick = async (temp_comments?: string) => {
    setIsLoading(true);
    const changes = getChanges<IEvalHsPg>(dataFromApi, formIk.values);
    //add temp_comments
    if (temp_comments && dataFromApi.pg_temp_comments !== temp_comments)
      changes['pg_temp_comments'] = temp_comments;
    if (!formIk.values.eval_date) changes['eval_date'] = new Date().getTime();
    if (Object.keys(changes).length === 0) {
      if (!toast.isActive('save-success'))
        toast.success(translator.formatMessage({ id: 'COMMON.NO_CHANGES_TO_SAVE' }), {toastId: 'save-success'});
      setIsLoading(false);
      return;
    }
    try {
      const response = await updatePgData(credEvalData?.appIdWithOrgCode || '', changes);
      if (response) {
        if (!toast.isActive('save-success'))
        toast.success(translator.formatMessage({ id: 'COMMON.MESSAGE.SUCCESSFULLY_UPDATED_DATA' }), {toastId: 'save-success'});
        setDataFromApi((prevData) => {
          return {
            ...prevData,
            ...response,
          };
        });
        formIk.setValues((prev) => {
          return {
            ...prev,
            ...response,
          };
        });
        setPreviewData(response);
      } else {
        toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.SOMETHING_WENT_WRONG' }));
      }
    } catch (error) {}
    setIsLoading(false);
  };

  // set the data in formIk
  const handleInputChange = (event: {name: string; value: any}) => {
    const {name, value} = event;
    formIk.setFieldValue(name, value);
  };

  // on click of save button in popup
  function handleCommentDialogueClose() {
    setIsCommentDialogOpen(false);
  }

  // on click of close button in re eval popup
  async function handleCommentDialogueSave(inputData?: string) {
    await onSaveClick(inputData);
  }

  // handle routing changes
  const routeChange = async () => {
    setValueLocalStorage(
      commonConstant.credEvalData,
      JSON.stringify({
        ...credEvalData,
        isPgEvaluation: !isPgEvaluation,
      })
    );
    await onSaveClick();
    setIsPgEvaluation(!isPgEvaluation);
    navigate('/type-of-evaluation/evalMain?page=evalHsUg');
  };


  return (
    <div>
      <div className='d-flex flex-column  flex-xl-row flex-row-fluid mt-3'>
        <div className='card col-12 col-sm-12 col-xl-4 p-10 m-1'>
          <div className='form-group row mt-2'>
            <label htmlFor='appId' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.APPLICATION_IDS' })} :
            </label>
            <div className=''>
              <input
                id='appId'
                readOnly
                value={credEvalData?.appId}
                placeholder={ translator.formatMessage({ id: 'INPUT.APPLICATION_IDS' })}
                className='w-100 ps-xl-3 form-control'
                disabled
              />
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label className='ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.SCHOOL' })} :</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_school'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_SCHOOL' })}
              formIkData={formIk.values.pg_school}
              option={schools}
              setOptions={setSchools}
              formik={formIk}
              alertData={formIk.touched.pg_school}
              alertMessage={formIk.errors.pg_school}
            />
          </div>
          <div className='form-group row mt-2'>
            <label className='ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.CITY' })}:</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_city'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_CITY' })}
              formIkData={formIk.values.pg_city}
              option={cities}
              setOptions={setCities}
              formik={formIk}
              alertData={formIk.touched.pg_city}
              alertMessage={formIk.errors.pg_city}
            />
          </div>
          <div className='form-group row mt-2'>
            <label className='ps-3 form-check-label required-field'>{ translator.formatMessage({ id: 'INPUT.STATE' })}:</label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_state'
              placeholder={ translator.formatMessage({ id: 'INPUT.SELECT_STATE' })}
              formIkData={formIk.values.pg_state}
              option={states}
              setOptions={setStates}
              formik={formIk}
              alertData={formIk.touched.pg_state}
              alertMessage={formIk.errors.pg_state}
            />
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='pg_program_duration' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.DURATION_OF_PROGRAM' })} :
            </label>
            <div className='w-100'>
              <input
                id='pg_program_duration'
                type='number'
                min='0'
                name='pg_program_duration'
                disabled={isDropDownLoading}
                placeholder={ translator.formatMessage({ id: 'INPUT.DURATION_OF_PROGRAM' })}
                value={formIk.values.pg_program_duration}
                onChange={(e) =>
                  handleInputChange({
                    name: 'pg_program_duration',
                    value: parseInt('' + e.target.value),
                  })
                }
                onBlur={(e) => {
                  formIk.handleBlur(e);
                }}
                className='w-100 ps-3 form-control'
              />
              {formIk.touched.pg_program_duration && formIk.errors.pg_program_duration && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formIk.errors.pg_program_duration}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='form-group row mt-2'>
            <label htmlFor='pg_additional_comment' className=' ps-3 form-check-label'>
            { translator.formatMessage({ id: 'INPUT.ADDITION_COMMENTS' })} :
            </label>

            <EditableSelect
              handleInputChange={handleInputChange}
              disabled={isDropDownLoading}
              name='pg_additional_comment'
              placeholder={ translator.formatMessage({ id: 'INPUT.ADDITION_COMMENTS' })}
              formIkData={formIk.values.pg_additional_comment}
              option={additionalComments}
              setOptions={setAdditionalComments}
              formik={formIk}
              alertData={formIk.touched.pg_additional_comment}
              alertMessage={formIk.errors.pg_additional_comment}
            />
          </div>
        </div>
        <DialogBoxWithInput
          dynamicData={{
            title: translator.formatMessage({ id: 'COMMON.MESSAGE.ARE_YOU_SURE_YOU_WANT_TO_SAVE_ANY_COMMENTS' }),
            inputData: formIk.values.pg_temp_comments,
            inputLabel: translator.formatMessage({ id: 'INPUT.COMMENTS' }),
            inputPlaceHolder: translator.formatMessage({ id: 'INPUT.ENTER_COMMENTS' }),
            isLoading: isLoading,
            leftButton: translator.formatMessage({ id: 'BUTTONS.CLOSE' }),
            rightButton: translator.formatMessage({ id: 'BUTTONS.SAVE' }),
          }}
          onClose={handleCommentDialogueClose}
          onSubmit={handleCommentDialogueSave}
          isOpen={isCommentDialogOpen}
        ></DialogBoxWithInput>

        <Transcript
          appId={credEvalData?.appId || ''}
          organizationCode={credEvalData?.organizationCode || ''}
        />

        <div className='card p-10 m-1'>
          <div className=' ps-xl-3 pe-xl-3'>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='country'
                  readOnly
                  value={formIk.values.pg_country}
                  placeholder={ translator.formatMessage({ id: 'INPUT.COUNTRY' })}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='ug_gpa'
                  placeholder={ translator.formatMessage({ id: 'INPUT.GPA' })}
                  type='number'
                  readOnly
                  value={formIk.values.pg_gpa || 0}
                  disabled
                  className='w-100 form-control'
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='pg_gpa_letter_grade'
                  type='string'
                  placeholder={ translator.formatMessage({ id: 'INPUT.GPA_LETTER_GRADE' })}
                  value={formIk.values.pg_gpa_letter_grade || ''}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  type='text'
                  id='pg_eval_date'
                  placeholder={ translator.formatMessage({ id: 'INPUT.EVALUATION_DATE' })}
                  value={getFormattedDate(formIk.values.eval_date || Date.now())}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
            <div className='form-group row mt-2'>
              <div className='w-100'>
                <input
                  id='eval_by'
                  placeholder={ translator.formatMessage({ id: 'INPUT.EVALUATOR_NAME' })}
                  value={`${formIk.values.eval_by}`}
                  className='w-100 form-control'
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-center flex-column flex-md-row flex-row-fluid flex-sm-column flex-wrap flex-xl-row mt-10 rounded'>
        <button
          type='button'
          onClick={() => {
            handleChangeNavigation('/type-of-evaluation');
          }}
          className='btn btn-primary m-5'
        >
          { translator.formatMessage({ id: 'INPUT.TYPES_OF_EVALUATION' })}
        </button>

        <button
          type='button'
          onClick={(e) => goToPreview(formIk.values)}
          className='btn btn-primary m-5'
        >
          <i className='bi bi-eye'></i>
          { translator.formatMessage({ id: 'BUTTONS.PREVIEW' })}
        </button>

        <button
          type='button'
          className='btn btn-primary m-5'
          disabled={isLoading}
          onClick={() => {
            formIk.handleSubmit();
            if (formIk.isValid) {
              const changes = getChanges<IEvalHsPg>(dataFromApi, formIk.values);
              if (Object.keys(changes).length === 0) {
                setIsCommentDialogOpen(false);
                onSaveClick();
              } else {
                setIsCommentDialogOpen(true);
              }
            } else {
              toast.error(translator.formatMessage({ id: 'COMMON.MESSAGE.PLEASE_FILL_REQUIRED_FIELDS' }));
            }
          }}
        >
          {isLoading ? (
            <>
              <span className='spinner-border spinner-border-sm'></span>
              { translator.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
            </>
          ) : (
            <span>{ translator.formatMessage({ id: 'BUTTONS.SAVE' })}</span>
          )}
        </button>
        {credEvalData?.degreeOfEval === 2 && (
          <button type='button' onClick={routeChange} className='btn btn-primary m-5'>
            { translator.formatMessage({ id: 'BUTTONS.EVALUATE_HS1' }) }
          </button>
        )}

        {(credEvalData?.typeOfEval || '') === CBC && (
          <button
            type='button'
            className='btn btn-primary m-5'
            onClick={() => {
              handleChangeNavigation('/type-of-evaluation/course-by-course-eval?page=evalHsPg');
            }}
          >
            { translator.formatMessage({ id: 'BUTTONS.COURSE_BY_COURSE' })}
          </button>
        )}
      </div>
    </div>
  );
};

export default EvalHighSchoolPostGraduate;
