import React, { useState, useEffect } from 'react';
import { IGradeScaleData, IGradeScaleEditDialog } from './grade-scales-interface';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { editGradeScale } from './_service/grade-scales-service';
import { IKeyValues } from '../../../utils/common-interface';
import { getFormattedDate } from '../../../utils/utils';
import { onlyNumbersAndDotRegEx } from '../../../utils/common-constant';

const EditGradeScale: React.FC<IGradeScaleEditDialog> = ({ show, onHide, editableIndex, data }) => {
  const [formData, setFormData] = useState<IGradeScaleData>(data);
  const [isEditScaleReqSubmitting, setIsEditScaleReqSubmitting] = useState(false);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleSave = () => {
    if (formData.id && formData.created_at) {
      const changes: IKeyValues = {};
      const numericFields = ['high_range', 'low_range', 'scale'];
      let isValid = true;
      Object.keys(formData).forEach((key) => {
        const value = formData[key as keyof IGradeScaleData];
        if (numericFields.includes(key)) {
          if (!onlyNumbersAndDotRegEx.test(value.toString())) {
            toast.error(`Invalid input for ${mapColumn(key).label}. Only non-negative integers are allowed.`);
            isValid = false;
            return;
          }
        }
        if (data[key as keyof IGradeScaleData] !== value) {
          changes[key] = value;
        }
      });  
      if (isValid) {
        editFields(changes);
      }
    } else {
      toast.error('Invalid form data!!');
    }
  };

  // edit fields
  const editFields = async (fields: Partial<IGradeScaleData>) => {
    try {
      if (fields && Object.keys(fields).length > 0 && editableIndex !== null) {
        setIsEditScaleReqSubmitting(true);
        const res = await editGradeScale({
          ...fields,
          id: data.id,
        });
        if (res) {
          toast.success('Request sent to approver for editing grade data');
        }
        setIsEditScaleReqSubmitting(false);
      }
      onHide();
    } catch (error) {
      setIsEditScaleReqSubmitting(false);
      onHide();
    }
  };

  // map column for input
  const mapColumn = (value: string): {label: string; inputType: string} => {
    switch (value) {
      case 'id':
        return {label: 'id', inputType: 'text'};
      case 'created_at':
        return {label: 'Created At', inputType: 'text'};
      case 'modified_at':
        return {label: 'Modified At', inputType: 'text'};
      case 'column_range':
        return {label: 'Column Range', inputType: 'text'};
      case 'country':
        return {label: 'Country', inputType: 'text'};
      case 'grade_scale_type':
        return {label: 'Grade Scale Type', inputType: 'text'};
      case 'grade_description':
        return {label: 'Grade Description', inputType: 'text'};
      case 'high_range':
        return {label: 'High Range', inputType: 'number'};
      case 'low_range':
        return {label: 'Low Range', inputType: 'number'};
      case 'out_of':
        return {label: 'Out of', inputType: 'text'};
      case 'scale':
        return {label: 'Scale', inputType: 'number'};
      default:
        return {label: value, inputType: 'text'};
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit / Add Data</Modal.Title>
      </Modal.Header>
      <Modal.Body className='overflow-y-auto h-400px'>
        <Form>
          {Object.keys(formData)
            .filter((key) => key !== 'id')
            .map((key, index) => (
              <Form.Group controlId={key} key={index}>
                <Form.Label>{mapColumn(key).label}</Form.Label>
                <Form.Control
                  type={mapColumn(key).inputType}
                  disabled={['id', 'created_at', 'modified_at',"column_range"].includes(key)}
                  value={
                    ['created_at', 'modified_at'].includes(key)
                      ? getFormattedDate(
                        formData[key as keyof IGradeScaleData]
                      )
                      : formData[key as keyof IGradeScaleData]
                  }
                  onChange={(e) => setFormData({...formData, [key]: e.target.value})}
                />
              </Form.Group>
            ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Close
        </Button>
        <Button variant='primary' onClick={handleSave}>
          {isEditScaleReqSubmitting ? (
            <>
              <span className='spinner-border spinner-border-sm'></span>
              Please Wait...
            </>
          ) : (
            <span> Save</span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGradeScale;
