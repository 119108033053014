import { IntlShape } from 'react-intl';
import { ITypeOfEvalGrad, ITypeOfEvalHs } from './evaluation-interfaces';
import * as Yup from 'yup';
import { commonConstant } from '../../../utils/common-constant';

export const initialFormDataGrad: ITypeOfEvalGrad = {
  education_level: '',
  type_of_eval: '',
  degree_of_eval: 0,
  ug_no_process_reason: '',
  pg_no_process_reason: '',
  ug_txt: '',
  pg_txt: '',
  ug_is_transcript_complete: false,
  pg_is_transcript_complete: false,
  ug_is_degree_awarded: false,
  pg_is_degree_awarded: false,
  ug_is_additional_credentials: false,
  pg_is_additional_credentials: false,
  pg_transcript_incomplete_reason: '',
  ug_transcript_incomplete_reason: '',
  pg_additional_comment: '',
  ug_additional_comment: '',
  ug_scale: '',
  ug_country: '',
  pg_scale: '',
  pg_country: '',
  ug_out_of: 0,
  pg_out_of: 0,
};

export const initialFormDataHs: ITypeOfEvalHs = {
  education_level: '',
  type_of_eval: '',
  degree_of_eval: 0,
  ug_txt: '',
  pg_txt: '',
  ug_scale: '',
  ug_country: '',
  pg_scale: '',
  pg_country: '',
  ug_is_transcript_complete: false,
  pg_is_transcript_complete: false,
  ug_no_process_reason: '',
  pg_no_process_reason: '',
  pg_transcript_incomplete_reason: '',
  ug_transcript_incomplete_reason: '',
  pg_additional_comment: '',
  ug_additional_comment: '',
  ug_out_of: 0,
  pg_out_of: 0,
};

export const initialFormData = {
  ...initialFormDataGrad,
  ...initialFormDataHs,
};

export const useTypeOfEvalGradSchema = (translator: IntlShape) => {
  return Yup.object().shape({
    education_level: Yup.string().required(translator.formatMessage(
      { id: 'VALIDATIONS.EDUCATION_LEVEL_REQUIRED_FIELDS' },
    )),
    type_of_eval: Yup.string().when(
      ['ug_no_process_reason', 'pg_no_process_reason'],
      ([ug_no_process_reason, pg_no_process_reason], schema) => {
        return !ug_no_process_reason && !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.TYPES_OF_EVALUATION_REQUIRED_FIELDS' },
          ))
          : schema.optional();
      }
    ),
    degree_of_eval: Yup.number().when(
      ['ug_no_process_reason', 'pg_no_process_reason'],
      ([ug_no_process_reason, pg_no_process_reason], schema) => {
        return !ug_no_process_reason && !pg_no_process_reason ? schema.required() : schema.optional();
      }
    ),
    ug_txt: Yup.string().when(
      ['ug_no_process_reason', 'pg_no_process_reason'],
      ([ug_no_process_reason, pg_no_process_reason], schema) => {
        return !ug_no_process_reason && !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.DEGREE_OF_EVALUATION_REQUIRED_FIELDS' },
          ))
          : schema.optional();
      }
    ),
    pg_txt: Yup.string().optional(),
    ug_is_transcript_complete: Yup.boolean().required(
      translator.formatMessage({id: 'VALIDATIONS.UG_TRANSCRIPT_COMPLETE_REQUIRED_FIELDS'})
    ),
    pg_is_transcript_complete: Yup.boolean().when(
      ['degree_of_eval'],
      ([degree_of_eval], schema) => {
      return degree_of_eval === 2
        ? schema.required(translator.formatMessage(
          { id: 'VALIDATIONS.PG_TRANSCRIPT_COMPLETE_REQUIRED_FIELDS' },
        ))
        : schema;
    }),
    ug_is_degree_awarded: Yup.boolean().when(
      ['education_level', 'ug_no_process_reason', 'pg_no_process_reason'],
      ([education_level, ug_no_process_reason, pg_no_process_reason], schema) => {
        return education_level === commonConstant.globalGradCourses &&
          !ug_no_process_reason &&
          !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.DEGREE_AWARD_FOR_UG_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    pg_is_degree_awarded: Yup.boolean().when(
      ['degree_of_eval', 'education_level', 'ug_no_process_reason', 'pg_no_process_reason'],
      ([degree_of_eval, education_level, ug_no_process_reason, pg_no_process_reason], schema) => {
        return education_level === commonConstant.globalGradCourses &&
          degree_of_eval === 2 &&
          !ug_no_process_reason &&
          !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.DEGREE_AWARD_FOR_PG_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    ug_is_additional_credentials: Yup.boolean().when(
      ['education_level', 'ug_no_process_reason', 'pg_no_process_reason'],
      ([education_level, ug_no_process_reason, pg_no_process_reason], schema) => {
        return education_level === commonConstant.globalGradCourses &&
          !ug_no_process_reason &&
          !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.ADDITIONAL_CREDENTIAL_FOR_UG_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    pg_is_additional_credentials: Yup.boolean().when(
      ['degree_of_eval', 'education_level', 'ug_no_process_reason', 'pg_no_process_reason'],
      ([degree_of_eval, education_level, ug_no_process_reason, pg_no_process_reason], schema) => {
        return education_level === commonConstant.globalGradCourses &&
          degree_of_eval === 2 &&
          !ug_no_process_reason &&
          !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.ADDITIONAL_CREDENTIAL_FOR_PG_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    pg_transcript_incomplete_reason: Yup.string().when(
      ['pg_is_transcript_complete', 'degree_of_eval', 'education_level'],
      ([pg_is_transcript_complete, degree_of_eval, education_level], schema) => {
        return education_level === commonConstant.globalGradCourses &&
          degree_of_eval === 2 &&
          pg_is_transcript_complete === false
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.TRANSCRIPT_INCOMPLETE_REASON_IF_TRANSCRIPT_INCOMPLETE_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    ug_transcript_incomplete_reason: Yup.string().when(
      ['ug_is_transcript_complete', 'education_level'],
      ([ug_is_transcript_complete, education_level], schema) => {
        return education_level === commonConstant.globalGradCourses &&
          ug_is_transcript_complete === false
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.TRANSCRIPT_INCOMPLETE_REASON_IF_TRANSCRIPT_INCOMPLETE_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    ug_additional_comment: Yup.string().when(
      ['ug_no_process_reason', 'education_level'],
      ([ug_no_process_reason, education_level], schema) => {
        return education_level === commonConstant.globalGradCourses && ug_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.ADDITIONAL_COMMENTS_IF_TRANSCRIPT_INCOMPLETE_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    pg_additional_comment: Yup.string().when(
      ['pg_no_process_reason', 'education_level'],
      ([pg_no_process_reason, education_level], schema) => {
        return education_level === commonConstant.globalGradCourses && pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.ADDITIONAL_COMMENTS_IF_TRANSCRIPT_INCOMPLETE_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    ug_scale: Yup.string().when(
      ['ug_no_process_reason', 'pg_no_process_reason'],
      ([ug_no_process_reason, pg_no_process_reason], schema) => {
        return !ug_no_process_reason && !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.UG_SCALE_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    ug_country: Yup.string().when(
      ['ug_no_process_reason', 'pg_no_process_reason'],
      ([ug_no_process_reason, pg_no_process_reason], schema) => {
        return !ug_no_process_reason && !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.UG_COUNTRY_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    pg_scale: Yup.string().when(
      ['degree_of_eval', 'ug_no_process_reason', 'pg_no_process_reason'],
      ([degree_of_eval, ug_no_process_reason, pg_no_process_reason], schema) => {
        return degree_of_eval === 2 && !ug_no_process_reason && !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.PG_SCALE_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
    pg_country: Yup.string().when(
      ['degree_of_eval', 'ug_no_process_reason', 'pg_no_process_reason'],
      ([degree_of_eval, ug_no_process_reason, pg_no_process_reason], schema) => {
        return degree_of_eval === 2 && !ug_no_process_reason && !pg_no_process_reason
          ? schema.required(translator.formatMessage(
            { id: 'VALIDATIONS.PG_COUNTRY_REQUIRED_FIELDS' },
          ))
          : schema;
      }
    ),
  });
}