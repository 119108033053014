import { IUserProfileInfo } from '../../auth/auth-model'
import { get, post } from '../../auth/core/_requests'
import {
  ASSIGN_APPIDS_TO_SPECIFIC_USERS,
  GET_APP_IDS_WITH_ORG,
  GET_USER_EMAIL_WITH_ORG,
  VERIFY_CSV_FILE_CHECKS,
} from '../../../../utils/service-constants'
import { IResponse } from '../../../../utils/common-interface'

export const getAllAppId = async (orgCode: string): Promise<string[]> => {
  try {
    const response = await get<IResponse<string[]>>(GET_APP_IDS_WITH_ORG + '/' + orgCode)
    return response.data?.success || []
  } catch (error) {
    throw error
  }
}

export const getUserData = async (orgCode: string): Promise<IUserProfileInfo[]> => {
  try {
    const response = await get<IResponse<IUserProfileInfo[]>>(
      GET_USER_EMAIL_WITH_ORG + '/' + orgCode
    )
    return response.data?.success || []
  } catch (error) {
    throw error
  }
}

export const verifyCsvChecks = async (data: {
  orgCode: string
  assignMentData: {appId: string; userEmail: string}[]
}): Promise<{appId: string; result: string}[]> => {
  try {
    const response = await post<IResponse<{appId: string; result: string}[]>>(
      VERIFY_CSV_FILE_CHECKS,
      {data}
    )
    return response.data?.success || []
  } catch (error) {
    throw error
  }
}

export const submitAssignSpecificUsers = async (
  appAndUserData: {appId: string; userId: string}[]
): Promise<string[]> => {
  try {
    const response = await post<IResponse<string[]>>(ASSIGN_APPIDS_TO_SPECIFIC_USERS, {
      appAndUserData,
    })
    return response.data?.success || []
  } catch (error) {
    throw error
  }
}
