import { commonConstant } from "../../../utils/common-constant";
import { ICredEvalLocalStorageData, IKeyValues } from "../../../utils/common-interface";
import { getFormattedDate } from "../../../utils/utils";
import { CBC, GENERAL_EVAL, ORG_CODES } from "./general-evaluation/general-evaluation-constants";
import { ICbcData, ICbcUgPg, IOrganization } from "./evaluation-interfaces";


export function fetchCbCData(organisationData: IOrganization, cbcData: ICbcData, previewData: IKeyValues, credEvalData: ICredEvalLocalStorageData | undefined): string {
  const isDpu = organisationData?.code.toLowerCase() === ORG_CODES.dpu;
  const isCua = organisationData?.code.toLowerCase() === ORG_CODES.cua;
  const hs_ = credEvalData?.educationLevel === commonConstant.highSchool ? 'hs_' : 'gr_';
  const isHighSchoolEval = credEvalData?.educationLevel === commonConstant.highSchool;
  if (credEvalData?.degreeOfEval === 1) {
    let temphtml = organisationData[`cbc_${hs_}ug_html`]?.split('</body></html>')[0];
    const semesterMapper: { [key: string]: ICbcUgPg[] } = {};
    cbcData?.ug?.forEach((key) => {
      semesterMapper[key['sem']] = [];
      semesterMapper[key['sem']] = cbcData?.ug?.filter((item) => item.sem === key.sem);
    });
    temphtml += `<div class="course-by-course">
          <span class="heading-texts">Course By Course Evaluation</span>`;
    Object.entries(semesterMapper).forEach((semester) => {
      temphtml += ` <table class="course-by-course-table">
             <tr>
                <th colspan=${isDpu ? '4' : '3'}>
                ${semester[0]}
                </th>
            </tr> 
            <tr>
                <td>
                    <b>Name Of The Course</b>
                </td>
              ${!isHighSchoolEval ? '<td><b>US CREDIT</b> </td>' : ''}
                <td>
                    <b>US GPA</b>
                </td>
            ${isDpu ? `<td><b>Letter Grade</b></td>` : ''}</tr>`;
      semester[1].forEach((courses) => {
        temphtml += `<tr class="${isDpu && GPAtoGrade(courses.us_gpa)?.toLowerCase() === 'f' ? 'f-grade' : ''}">
              <td class="text-left-align">${courses.course}
              </td>
              ${!isHighSchoolEval ? `<td>${courses.us_credit}</td>` : ''}
              <td>${courses.us_gpa}</td>
              ${isDpu ? `<td>${GPAtoGrade(courses.us_gpa)}</td>` : ''}
              </tr>`;
      });
      temphtml += `</table>`;
    });
    temphtml =
      temphtml +
      (isHighSchoolEval
        ? `<div class="totals">
             <span class="total-gpa-credit"> <b>US GPA:</b> ${previewData?.ug_gpa}</span></div></div></body></html>`
        : `<div class="totals">
             <span class="total-gpa-credit"><b>Total US Credits: </b>${cbcData?.ug_required_credit}</span>
               <span class="total-gpa-credit"> <b>US GPA: </b>${isCua
          ? ' ' + previewData?.ug_gpa + '/' + previewData?.ug_out_of
          : previewData?.ug_gpa_letter_grade +
          '(' +
          previewData?.ug_gpa +
          '/' +
          previewData?.ug_out_of +
          ')'
        }</span></div></div></body></html>`);
    return temphtml;
  } else {
    let temphtml = organisationData[`cbc_${hs_}ugpg_html`]?.split('</body></html>')?.[0];
    const semesterMapperUG: { [key: string]: ICbcUgPg[] } = {};
    const semesterMapperPG: { [key: string]: ICbcUgPg[] } = {};
    cbcData?.ug?.forEach((key) => {
      semesterMapperUG[key['sem']] = [];
      semesterMapperUG[key['sem']] = cbcData?.ug?.filter((item) => item.sem === key.sem);
    });
    cbcData?.pg?.forEach((key) => {
      semesterMapperPG[key['sem']] = [];
      semesterMapperPG[key['sem']] = cbcData?.pg?.filter((item) => item.sem === key.sem);
    });
    temphtml += `<div class="course-by-course">
        <span class="heading-texts">Course By Course Evaluation ${previewData?.ug_txt || ''
      }</span>`;
    Object.entries(semesterMapperUG).forEach((semester) => {
      temphtml += ` <table class="course-by-course-table">
           <tr>
              <th colspan=${isDpu ? '4' : '3'}>
              ${semester[0]}
              </th>
          </tr> 
          <tr>
              <td>
                  <b>Name Of The Course</b>
              </td>
              ${!isHighSchoolEval ? '<td><b>US CREDIT</b> </td>' : ''}
              <td>
                  <b>US GPA</b>
              </td>
          ${isDpu ? `<td><b>Letter Grade</b></td>` : ''}</tr>`;
      semester[1].forEach((courses) => {
        temphtml += `<tr class="${isDpu && GPAtoGrade(courses.us_gpa)?.toLowerCase() === 'f' ? 'f-grade' : ''}">
            <td class="text-left-align">${courses.course}
            </td>
            ${!isHighSchoolEval ? `<td>${courses.us_credit}</td>` : ''}
            <td>${courses.us_gpa}</td>
            ${organisationData?.name === 'DePaul University' ? `<td>${GPAtoGrade(courses.us_gpa)}</td>` : ''}
            </tr>`;
      });
      temphtml += `</table>`;
    });
    temphtml =
      temphtml +
      (isHighSchoolEval
        ? `<div class="totals">
             <span class="total-gpa-credit"> <b>US GPA:</b> ${previewData?.ug_gpa}</span></div></div></body></html>`
        : `<div class="totals">
             <span class="total-gpa-credit"><b>Total US Credits: </b>${cbcData?.ug_required_credit}</span>
               <span class="total-gpa-credit"> <b>US GPA: </b>${isCua
          ? '' + previewData?.ug_gpa + '/' + previewData?.ug_out_of
          : previewData?.ug_gpa_letter_grade +
          '(' +
          previewData?.ug_gpa +
          '/' +
          previewData?.ug_out_of +
          ')'
        }</span></div></div></body></html>`);
    temphtml += `<div class="course-by-course">
        <span class="heading-texts">Course By Course Evaluation ${previewData?.pg_txt || ''
      } </span>`;
    Object.entries(semesterMapperPG).forEach((semester) => {
      temphtml += ` <table class="course-by-course-table">
           <tr>
              <th colspan=${isDpu ? '4' : '3'}>
              ${semester[0]}
              </th>
          </tr> 
          <tr>
              <td>
                  <b>Name Of The Course</b>
              </td>
              ${!isHighSchoolEval ? '<td><b>US CREDIT</b> </td>' : ''}
              <td>
                  <b>US GPA</b>
              </td>
          ${isDpu ? `<td><b>Letter Grade</b></td>` : ''}</tr>`;
      semester[1].forEach((courses) => {
        temphtml += `<tr class="${isDpu && GPAtoGrade(courses.us_gpa)?.toLowerCase() === 'f' ? 'f-grade' : ''}">
            <td class="text-left-align">${courses.course}
            </td>
            ${!isHighSchoolEval ? `<td>${courses.us_credit}</td>` : ''}
            <td>${courses.us_gpa}</td>
            ${organisationData?.name === 'DePaul University' ? `<td>${GPAtoGrade(courses.us_gpa)}</td>` : ''}
            </tr>`;
      });
      temphtml += `</table>`;
    });
    temphtml =
      temphtml +
      (isHighSchoolEval
        ? `<div class="totals">
          <span class="total-gpa-credit"> <b>US GPA:</b>${previewData?.pg_gpa}</span></div></div></body></html>`
        : `<div class="totals">
          <span class="total-gpa-credit"><b>Total US Credits: </b>${cbcData?.pg_required_credit}</span>
          <span class="total-gpa-credit"> <b>US GPA: </b>${isCua
          ? ' ' + previewData?.pg_gpa + '/' + previewData?.pg_out_of
          : previewData?.pg_gpa_letter_grade +
          '' +
          previewData?.pg_gpa +
          '/' +
          previewData?.pg_out_of +
          ')'
        }</span></div></div></body></html>`);
    return temphtml;
  }
}

function GPAtoGrade(gpa: number) {
  if (gpa == null || isNaN(gpa)) {
    throw new Error(`Wrong GPA value, GPA:${gpa}`);
  }
  if (gpa >= 4.0) {
    return "A+";
  } else if (gpa < 4.0 && gpa >= 3.84) {
    return "A";
  } else if (gpa <= 3.83 && gpa >= 3.5) {
    return "A-";
  } else if (gpa <= 3.49 && gpa >= 3.17) {
    return "B+";
  } else if (gpa <= 3.16 && gpa >= 2.84) {
    return "B";
  } else if (gpa <= 2.83 && gpa >= 2.5) {
    return "B-";
  } else if (gpa <= 2.49 && gpa >= 2.17) {
    return "C+";
  } else if (gpa <= 2.16 && gpa >= 1.84) {
    return "C";
  } else if (gpa <= 1.83 && gpa >= 1.5) {
    return "C-";
  } else if (gpa <= 1.49 && gpa >= 1.17) {
    return "D+";
  } else if (gpa <= 1.16 && gpa >= 1.0) {
    return "D";
  } else if (gpa <= 0.99 && gpa > 0.0) {
    return "D-";
  } else if (gpa <= 0) {
    return "F";
  }
}

export async function getHtml(organisationData: IOrganization, cbcData: ICbcData, variablesMapper: IKeyValues, credEvalData: ICredEvalLocalStorageData | undefined) {
  const hs_ = credEvalData?.educationLevel === commonConstant.highSchool ? 'hs_' : 'gr_';
  let html = '';
  if (credEvalData?.typeOfEval === CBC) {
    html = fetchCbCData(organisationData, cbcData, variablesMapper, credEvalData);
  } else if (credEvalData?.typeOfEval === GENERAL_EVAL && credEvalData?.degreeOfEval === 1) {
    html = organisationData?.[`ge_${hs_}ug_html`] || '';
  } else if (credEvalData?.typeOfEval === GENERAL_EVAL && credEvalData?.degreeOfEval === 2) {
    html = organisationData?.[`ge_${hs_}ugpg_html`] || '';
  }
  const regexp = new RegExp(/\{\[[^\]]+]\}/g);
  const variables = html.match(regexp);
  if (variables) {
    variablesMapper['appid'] = credEvalData?.appId;
    variablesMapper['ug_txt'] = credEvalData?.ugTxt;
    variablesMapper['pg_txt'] = credEvalData?.pgTxt;
    variablesMapper['ug_grade_scale_out_of'] = credEvalData?.gradeScaleData?.ugData[0]?.out_of;
    variablesMapper['pg_grade_scale_out_of'] = credEvalData?.gradeScaleData?.pgData[0]?.out_of;
    variables.forEach((dynConst) => {
      let key;
      if (credEvalData?.degreeOfEval === 2) {
        key = dynConst.substring(2, dynConst.length - 2);
        if (key === 'eval_date') {
          html = html.replace(
            dynConst,
            variablesMapper[key]
              ? getFormattedDate(new Date(variablesMapper[key] as number).getTime())
              : getFormattedDate(new Date().getTime())
          );
        } else if ((key === 'ug_college' || key === 'pg_college') && !variablesMapper[key]) {
          html = html.replace(dynConst + ',', '');
        } else if ((key === 'ug_grade_scale_out_of' || key === 'pg_grade_scale_out_of') && variablesMapper[key]){
          html = html.replace(dynConst, '/' + variablesMapper[key]);
        } else if (variablesMapper[key] != null) {
          html = html.replace(dynConst, '' + variablesMapper[key]);
        } else {
          html = html.replace(dynConst, '');
        }
      } else {
        key = dynConst.substring(2, dynConst.length - 2);
        if (key === 'eval_date') {
          html = html.replace(
            dynConst,
            variablesMapper[key]
              ? getFormattedDate((new Date(variablesMapper[key] as number).getTime()))
              : getFormattedDate(new Date().getTime())
          );
        } else if (key === 'ug_college' && !variablesMapper[key]) {
          html = html.replace(dynConst + ',', '');
        } else if (key === 'ug_grade_scale_out_of' && variablesMapper[key]) {
          html = html.replace(dynConst, '/' + variablesMapper[key]);
        } else if (variablesMapper[key] != null) {
          html = html.replace(dynConst, '' + variablesMapper[key]);
        } else {
          html = html.replace(dynConst, '');
        }
      }
    });
  }
  return html;
}