import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {getUserByToken, post} from '../core/_requests'
import {useAuth} from '../core/auth'
import {toast} from 'react-toastify'
import {VERIFY_CODE_PAGE} from '../../../../utils/service-constants'
import {useNavigate} from 'react-router-dom'
import {IResponse} from '../../../../utils/common-interface'
import {setRefreshToken} from '../core/auth-helpers'
import { IResponseSignIn, loginSchema } from '../auth-model'
const style = {
  passwordInvalid: {
    backgroundPosition: 'right calc(2.375em + 0.3875rem) center',
  },
}
export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const initialValues = {
    email: '',
    password: '',
    keep_me_signed_in: false,
  };
  useEffect(() => {
    localStorage.removeItem('isTokenApiTriggered')
  })
  
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const response = await post<IResponse<IResponseSignIn>>('/auth/signin', {
          signInValues: {
            email: values.email,
            password: values.password,
            keep_me_signed_in: values.keep_me_signed_in,
          },
        })
        if (response?.data?.success?.access_token) {
          saveAuth({
            api_token: response.data.success?.access_token || '',
          })
          setRefreshToken(response.data.success?.refresh_token)
          //set token to local storage
          const data = await getUserByToken(response.data.success?.access_token)
          setCurrentUser({
            user_code: data.data.success?.user_code || '',
            username: data.data.success?.email || '',
            role: data.data.success?.role || '',
            reviewer_capacity: data.data.success?.reviewer_capacity || 0,
            evaluator_capacity: data.data.success?.evaluator_capacity || 0,
            email: data.data.success?.email || '',
            first_name: data.data.success?.given_name || '',
            last_name: data.data.success?.family_name || '',
            pic: data.data.success?.picture || '',
            organisation_name: data.data.success?.organisation_name || []
          })
          // toast.success('Logged in successfully !', {
          //   position: toast.POSITION.TOP_RIGHT,
          // })
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      } catch (error: any) {
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleVerifyCodePage = () => {
    navigate(`${VERIFY_CODE_PAGE}`)
  }

  return (
    <div>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
          {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
        </div>
        {/* begin::Heading */}

        {/* begin::Login options */}
        <div className='row g-3 mb-9'>
          {/* begin::Col */}
          <div className='col-md-6'>
            {/* begin::Google link */}
            {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}
            {/* end::Google link */}
          </div>
          {/* end::Col */}

          {/* begin::Col */}
          <div className='col-md-6'>
            {/* begin::Google link */}
            {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a> */}
            {/* end::Google link */}
          </div>
          {/* end::Col */}
        </div>
        {/* end::Login options */}

        {/* begin::Separator */}
        {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}
        {/* end::Separator */}

        {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <div className='position-relative'>
            <input
              style={style.passwordInvalid}
              type={showPassword ? 'text' : 'password'}
              autoComplete='off'
              placeholder='password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent password-invalid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {/* <button
            className='btn btn-outline-secondary'
            type='button'
            onClick={() => setShowPassword(!showPassword)}
          > */}
            {showPassword ? (
              <i
                className='fas fa-eye position-absolute top-50 end-0 me-2 translate-middle-y cursor-pointer fs-2'
                onClick={() => setShowPassword(!showPassword)}
              ></i>
            ) : (
              <i
                className='fas fa-eye-slash position-absolute top-50 end-0 me-2 translate-middle-y cursor-pointer fs-2'
                onClick={() => setShowPassword(!showPassword)}
              ></i>
            )}
            {/* </button> */}
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-start flex-wrap fs-base fw-semibold my-5'>
          <div />
          <div className='form-check'>
            <input
              type='checkbox'
              {...formik.getFieldProps('keep_me_signed_in')}
              className='form-check-input cursor-pointer'
              id='keep_me_signed_in'
            />
            <label htmlFor='keep_me_signed_in' className='form-check-label cursor-pointer text-hover-primary'> 
              Keep me signed in
            </label>
          </div>
          {/* begin::Link */}
          {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid || loading}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress d-block'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}

        {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
      </form>
      <div className='d-flex justify-content-center'>
        <div
          onClick={handleVerifyCodePage}
          className='font-weight-bolder text-primary font-size-h6 mt-5 cursor-pointer'
        >
          Want to Verify Your Email ID?
        </div>
      </div>
    </div>
  )
}
