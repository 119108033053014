import React from 'react';
import './toggle-switch-style.scss';
import { ToggleSwitchProps } from '../component-interface';

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({label, isAutoSaving, toggleHandler, value}) => {
  const handleToggle = () => {
    sessionStorage.setItem('autoSave', ('' + !value).toLowerCase());
    toggleHandler();
  };
  return (
    <div className='d-flex align-items-center text-center'>
      {isAutoSaving && (
        <>
          Auto Saving data...
          <span className='spinner-border spinner-border-sm m-5'></span>
        </>
      )}
      <h3>
        <label>{label}</label>
      </h3>
      <div className={`position-relative w-60px h-30px d-inline-block text-start ms-10px cursor-pointer rounded-pill ms-2 ${value ? 'bg-success' : 'bg-dark-subtle'}`} onClick={handleToggle}>
        <input
          type='checkbox'
          className='checkbox visually-hidden'
          name={label}
          id={label}
          onChange={handleToggle}
          checked={value}
        />
        <label className='label d-flex w-30px h-30px position-absolute top-0 rounded-circle bg-light' htmlFor={label}>
          <span className='inner w-100 h-100 text-center fs-7'>{value ? 'YES' : 'NO'}</span>
          <span className='switch'></span>
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;