import {useState} from 'react';
import MyTeams from './my-teams';
import TimeBased from './time-based';
import {createSearchParams, useNavigate} from 'react-router-dom';

const MyTeamPage = () => {
  const [showMyTeam, setShowMyTeam] = useState(window.location.href.split('action=')?.[1] === 'time-based-data' ? false:true);
  const navigate = useNavigate();
  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header justify-content-center border-0 pt-5'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <div
                className={`fs-1 fw-bold nav-link cursor-pointer ${
                  showMyTeam ? 'active border-5 border-primary text-primary' : 'false text-black'
                }`}
                onClick={() => {
                  setShowMyTeam(true);
                  navigate({
                    pathname: '',
                    search: createSearchParams({
                      action: 'my-teams',
                    }).toString(),
                  });
                }}
              >
                My Teams
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={`fs-1 fw-bold nav-link cursor-pointer ${
                  !showMyTeam ? 'active border-5 border-primary text-primary' : 'false text-black'
                }`}
                onClick={() => {
                  setShowMyTeam(false);
                  navigate({
                    pathname: '',
                    search: createSearchParams({
                      action: 'time-based-data',
                    }).toString(),
                  });
                }}
              >
                Time Based Data
              </div>
            </li>
          </ul>
        </div>

        {showMyTeam ? (
          <>
            <MyTeams />
          </>
        ) : (
          <>
            <TimeBased />
          </>
        )}
      </div>
    </>
  );
};

export default MyTeamPage;
