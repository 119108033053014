import {IResponse} from '../../../../utils/common-interface';
import {post} from '../../auth/core/_requests';
import {IAttributeAndConditionsBody, IGradeScaleData} from '../grade-scales-interface';
import {
  ADD_NEW_GRADE_SCALES,
  ARCHIVE_GRADE_SCALES,
  EDIT_GRADE_SCALES,
  GET_ALL_GRADE_SCALES,
  SEARCH_GRADE_SCALES,
} from '../../../../utils/service-constants';
import { IGradeScale } from '../../evaluation/evaluation-interfaces';
export const fetchAllGradeScalesData = async (
  lastEvaluatedKey: string | undefined
) => {
  try {
    const response = await post<
      IResponse<{
        gradeScales: IGradeScaleData[];
        lastEvaluatedKey: string;
      }>
    >(GET_ALL_GRADE_SCALES, lastEvaluatedKey ? {lastEvaluatedKey: lastEvaluatedKey} : {});
    const data = response.data?.success;
    return (
      data || {
        gradeScales: [],
        lastEvaluatedKey: undefined,
      }
    );
  } catch (error) {
    throw error;
  }
};

export const searchGradeScaleData = async (
  lastEvaluatedKey: string | undefined,
  attributeAndCondition: IAttributeAndConditionsBody[]
) => {
  try {
    const body = {
      lastEvaluatedKey: lastEvaluatedKey,
      attributeAndCondition,
    };
    const response = await post<
      IResponse<{
        gradeScales: IGradeScaleData[];
        lastEvaluatedKey: string;
      }>
    >(SEARCH_GRADE_SCALES, body);
    const data = response.data?.success;
    return (
      data || {
        gradeScales: [],
        lastEvaluatedKey: undefined,
      }
    );
  } catch (error) {
    throw error;
  }
};

export const archiveGradeScale = async (gradeScaleSk: string[]): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(ARCHIVE_GRADE_SCALES, {gradeScaleSk});
    return response.data?.success ? true : false;
  } catch (error) {
    throw error;
  }
};

export const editGradeScale = async (editData: Partial<IGradeScaleData>): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(EDIT_GRADE_SCALES, {editData});
    return response.data?.success ? true : false;
  } catch (error) {
    throw error;
  }
};

export const addNewGradeScale = async (
  addNewData: Partial<IGradeScale[]>,
  isHighSchoolScales: boolean
): Promise<boolean> => {
  try {
    const response = await post<IResponse<boolean>>(ADD_NEW_GRADE_SCALES, {
      addNewData: addNewData,
      isHighSchoolScales: isHighSchoolScales,
    });
    return response.data?.success ? true : false;
  } catch (error) {
    throw error;
  }
};
