import {ICredEvalLocalStorageData} from '../../../utils/common-interface';
import {setValueLocalStorage} from '../auth/core/_requests';
import {useNavigate} from 'react-router-dom';
import {
  MY_WORK_STATUS,
  ORG_APP_ID_PATTERN,
  ORG_CODE_CODE_WITH_APPID_PATTERN,
  getStatusString,
} from './my-work-constants';
import {IMyWorkDataTable} from './my-work-interface';
import {priorities} from '../application-priority/application-priority-constants';
import { commonConstant } from '../../../utils/common-constant';

export function MyWorkDataTable({
  organizationName,
  organizationCode,
  isListDataLoading,
  translator,
  setSearchQuery,
  filteredData,
  searchQuery,
}: IMyWorkDataTable): JSX.Element {
  const navigate = useNavigate();
  function redirect(sk: string) {
    const credEvalData: ICredEvalLocalStorageData = {
      organizationName: organizationName,
      organizationCode: organizationCode.toLowerCase(),
      appId: sk.match(ORG_APP_ID_PATTERN)?.[0] || '',
      appIdWithOrgCode: sk.match(ORG_CODE_CODE_WITH_APPID_PATTERN)?.[1] || '',
    };
    setValueLocalStorage(commonConstant.credEvalData, JSON.stringify(credEvalData));
    navigate('/type-of-evaluation');
  }

  return (
    <div className='card-body py-0'>
      <div className='d-flex align-items-center position-relative my-1'>
        <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={translator.formatMessage({id: 'MY_WORK.DATA_TABLE.SEARCH_BY_APP_ID'})}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      </div>
      <div className='table-responsive'>
        <table className='table align-middle gs-0 gy-4' id='kt_table_users' role='table'>
          <thead>
            <tr className='align-middle fw-bold text-muted bg-light'>
              <th
                role='columnheader'
                className='ps-4 min-w-150px rounded-start fs-4'
              >
                {translator.formatMessage({id: 'MY_WORK.DATA_TABLE.APP_ID'})}
              </th>
              <th role='columnheader' className='ps-4 min-w-150px fs-4'>
                {translator.formatMessage({id: 'MY_WORK.DATA_TABLE.DATE_OF_ASSIGNMENT'})}
              </th>
              <th role='columnheader' className='ps-4 min-w-150px fs-4'>
                {translator.formatMessage({id: 'COMMON.STATUS'})}
              </th>
              <th role='columnheader' className='ps-4 min-w-150px fs-4'>
                {translator.formatMessage({id: 'MY_WORK.DATA_TABLE.PRIORITY'})}
              </th>
              <th role='columnheader' className='ps-4 min-w-150px fs-4'>
                {translator.formatMessage({id: 'MY_WORK.DATA_TABLE.REMARKS'})}
              </th>
              <th
                role='columnheader'
                className='ps-4 min-w-150px rounded-end fs-4'
              >
                {translator.formatMessage({id: 'COMMON.ACTION'})}
              </th>
            </tr>
          </thead>
          <tbody>
            {isListDataLoading ? (
              <></>
            ) : (
              <>
                {filteredData.map((item) => (
                  <tr key={item.sk} role='row'>
                    <td role='cell' className='p-4 fs-5'>
                      <div className='text-gray-800 mb-1'>{item.sk.split('#')[1]}</div>
                    </td>
                    <td role='cell' className='p-4 fs-5 text-gray-800'>
                      {item.created_at}
                    </td>
                    <td role='cell' className='p-4 '>
                      <div className={`badge fs-5 ${getStatusString(item?.status)?.bgColor}`}>
                        {translator.formatMessage({id: `MY_WORK.${MY_WORK_STATUS[item?.status]}`})}
                      </div>
                    </td>
                    <td
                      role='cell'
                      className={`p-4 fs-5 ${
                        priorities.find((e) => e?.value === item?.priority)?.className || ''
                      }`}
                    >
                      {priorities.find((e) => e.value === item?.priority)?.label || '-'}
                    </td>
                    <td role='cell' className='p-4 fs-5 text-gray-800'>
                      {item.remarks ? item.remarks : '-'}
                    </td>
                    <td role='cell' className='p-4 fs-5'>
                      <div
                        className='badge badge-light-success fw-bolder fs-5 cursor-pointer'
                        onClick={() => redirect(item.sk)}
                      >
                        {translator.formatMessage({id: 'MY_WORK.DATA_TABLE.GO_TO_APPLICATION'})}
                      </div>
                    </td>
                  </tr>
                ))}
                {filteredData.length === 0 && (
                  <tr className='text-center py-4 fs-3 text-muted font-weight-normal fw-medium'>
                    <td>{translator.formatMessage({id: 'COMMON.DATA_NOT_FOUND'})}</td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
